import { getPrefix } from './getProperPrefix';

let formatFunction: (any) => string;
export function addPrefixes(v) {
  const { value, prefix } = getPrefix(v);

  return formatFunction(value) + prefix;
}

export function uplotGetAxes(_formatFunction: any, dateFormat) {
  formatFunction = _formatFunction;
  const labelsColor = '#637381';
  const gridColor = '#E9ECEE';

  const gridTickConfig = {
    width: 1 / devicePixelRatio,
    stroke: gridColor,
  };

  return [
    {
      space: 150,
      // labelGap: 100,
      stroke: labelsColor,
      grid: gridTickConfig,
      ticks: gridTickConfig,
      values: (u, vals) => vals.map((v) => dateFormat(v * 1000)),
    },
    {
      // space: 50,
      size: 60,
      stroke: labelsColor,
      grid: gridTickConfig,
      ticks: gridTickConfig,
      values: (u, val) => {
        return val.map(addPrefixes);
      },
      // filter: (val) => !!val,
    },
  ];
}
