<div class="tree-button-container">
  <div class="top">
    <app-tree-search class="searchBar" [treeNodeService]="treeNodeService"></app-tree-search>
    <ng-container *ngIf="!mobileLayout" [ngTemplateOutlet]="buttons"></ng-container>
  </div>
  <ng-container *ngIf="mobileLayout" [ngTemplateOutlet]="buttons"></ng-container>
</div>

<ng-template #buttons>
  <div class="buttons">
    <button class="primary" [matTooltip]="'BUTTON.FILTERS' | translate" (click)="openFilter()">
      <mat-icon class="toolbar-icon" color="primary" svgIcon="filter"></mat-icon>
    </button>

    <button
      class="primary"
      data-cy="controls-refresh"
      [matTooltip]="'TOOLTIP.REFRESH_DATA' | translate"
      (click)="refresh()"
    >
      <mat-icon svgIcon="refresh" class="toolbar-icon refresh"></mat-icon>
    </button>
    <!-- TODO remove buttonMode after consultation -->
    <app-toggle-expand [buttonMode]="true" [treeNodeService]="treeNodeService"></app-toggle-expand>
  </div>
</ng-template>
