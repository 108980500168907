import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogActions } from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Counter } from '../../../utils/counter';
import { DisabledDirective } from '../../../directives/disabled.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgIf, LowerCasePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

export interface ConfirmDeleteDialogConfig {
  name?: string;
  customTitle?: string;
  customLine?: string;
  customBaseLine?: string;
  counter?: number;
  confirmDeletion?: boolean;
  customValueToConfirm?: string;
  customDeleteButtonLabel?: string;
}

@Component({
  selector: 'app-confirm-delete-dialog',
  templateUrl: 'confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    MatDialogActions,
    DisabledDirective,
    LowerCasePipe,
    TranslateModule,
  ],
})
export class ConfirmDeleteDialogComponent {
  static turnOffConfirmation = false;

  name: string;

  counter: Counter; //provide counter property in dialog_config.data to wait x sec for unlocking delete button

  customLine: string;

  customTitle?: string;

  customBaseLine?: string;

  confirmDeletion: boolean = true;

  confirmInputValue: string; //

  customValueToConfirm: string;

  customDeleteButtonLabel?: string;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    item: ConfirmDeleteDialogConfig,
    private translateService: TranslateService,
  ) {
    if (ConfirmDeleteDialogComponent.turnOffConfirmation) {
      this.dialogRef.close({ result: true });
      return;
    }
    this.name = item.name;
    this.customLine = item.customLine;
    this.customValueToConfirm = item.customValueToConfirm;
    this.confirmDeletion = item.confirmDeletion || this.customValueToConfirm != null;
    this.customBaseLine = item.customBaseLine;
    this.customTitle = item.customTitle;
    this.customDeleteButtonLabel = item.customDeleteButtonLabel;

    // if (item.customDeleteButtonLabel) this.customDeleteButtonLabel = item.customDeleteButtonLabel;
    if (item.counter && typeof item.counter == 'number') this.counter = new Counter(item.counter);
  }

  onDelete() {
    if (this.isDeleteEnabled()) {
      this.dialogRef.close({ result: true });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  get counterValue() {
    return this.counter != null ? this.counter.value : null;
  }

  isDeleteEnabled() {
    return (
      this.counterValue === null &&
      (!this.confirmDeletion || this.confirmInputValue == this.valueToConfirm)
    );
  }

  get valueToConfirm() {
    if (this.customValueToConfirm != null) return this.customValueToConfirm;
    return this.name != ''
      ? this.name
      : this.translateService.instant('BUTTON_DELETE').toLowerCase();
  }
}
