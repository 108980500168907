import { getMatrix } from './getMatrix';
const offset = 10;
const bottomPadding = 40;

type Pos = { x: number; y: number };

export function positionDashboardItemSettings(boundingContClass, settingsCont): Pos {
  const boundingContainer = <HTMLElement>document.querySelector(boundingContClass);
  const settingsContainer = settingsCont.nativeElement;

  if (!settingsContainer) return null;
  const settings = settingsContainer?.getBoundingClientRect();
  const item = <HTMLElement>document.querySelector('gridster-item.selected');

  if (!settings || !item || !boundingContainer) return null;

  const itemWidth = item.offsetWidth;
  const itemX = getMatrix(item).x;

  if (!isOverlapping(item, settingsContainer, boundingContainer)) return null;
  const afterItem = itemX + itemWidth + offset * 2;
  const beforeItem = itemX - settings.width - offset;
  const settingsY = getMatrix(settingsContainer).y ?? 0;
  const settingsX = getMatrix(settingsContainer).x ?? 0;

  const canBePlacedBefore = beforeItem > 0;
  const canBePlacedAfter = afterItem + settings.width < boundingContainer.offsetWidth;

  const offsetTop =
    settingsY +
    settingsContainer.offsetHeight -
    boundingContainer.offsetHeight +
    bottomPadding +
    offset;
  let desiredY = settingsY - (offsetTop > 0 ? offsetTop : 0);
  desiredY = desiredY >= 0 ? desiredY : offset;

  if (canBePlacedAfter && canBePlacedBefore) {
    if (Math.abs(afterItem - settingsX) > Math.abs(beforeItem - settingsX))
      return getPos(beforeItem, desiredY);
    else return getPos(afterItem, desiredY);
  }

  if (canBePlacedAfter) return getPos(afterItem, desiredY);
  else if (canBePlacedBefore) return getPos(beforeItem, desiredY);
  return null;
}

function isOverlapping(
  item: HTMLElement,
  settingsContainer: HTMLElement,
  boundingContainer: HTMLElement,
): boolean {
  const baseSettingsHeight =
    (<HTMLElement>document.querySelector('.base-settings'))?.offsetHeight * 1.2;
  const itemWidth = item.offsetWidth;
  const itemHeight = item.offsetHeight + baseSettingsHeight;
  const itemMatrix = getMatrix(item);
  const itemX = itemMatrix.x;
  const itemY = itemMatrix.y;

  const settingsWidth = settingsContainer.offsetWidth;
  const settingsHeight = settingsContainer.offsetHeight;
  const settingsMatrix = getMatrix(settingsContainer);
  const settingsX = settingsMatrix.x;
  const containerScrollY = document.querySelector('gridster')?.scrollTop ?? 0;
  const settingsY = settingsMatrix.y + containerScrollY;

  const overlappingX =
    (settingsX <= itemX && settingsX + settingsWidth >= itemX) ||
    (itemX <= settingsX && itemX + itemWidth >= settingsX);

  const overlappingY =
    (settingsY <= itemY && settingsY + settingsHeight >= itemY) ||
    (settingsY >= itemY && settingsY <= itemY + itemHeight);

  const overflowY =
    settingsY + settingsContainer.offsetHeight - boundingContainer.offsetHeight + bottomPadding > 0;

  return (overlappingX && overlappingY) || overflowY;
}

function getPos(x, y): Pos {
  return { x: x, y: y };
}
