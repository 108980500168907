import { TreeFormData } from './TreeFormData';
import { ItemTreeDto } from '../../../../../api-main';
import { TreeDialogMode } from './TreeDialogMode';

export function getTreeNodePayload(data: TreeFormData): ItemTreeDto & { metadata?: any } {
  let parentId: any;
  switch (data.mode) {
    case TreeDialogMode.Create:
      parentId = data.item.id;
      break;
    case TreeDialogMode.Edit:
      parentId = data.item.parent_id;
      break;
  }
  if (parentId == 0) parentId = null;

  return {
    id: data.mode == TreeDialogMode.Edit ? +data.item.id : null,
    icon: data.icon$.value,
    parent_id: parentId,
    item_type_id: data.typeSelected$.value.id,
    item_id: null,
    items: data.names,
    metadata: data.typeSelected$.value?.type_metadata?.length > 0 ? data.metadata : null,
  };
}
