import { UplotRange } from '../models/UplotRange.model';

export function getRangeString(range: UplotRange): string {
  if (!range || (!range.min && !range.max)) return '';
  const startDate = new Date(range.min * 1000);
  const endDate = new Date(range.max * 1000);
  //TODO TIMEZONE
  return `${startDate.toLocaleString()} - ${endDate.toLocaleString()}`;
}

export function getNameForDownloadFile(title: string, range: UplotRange) {
  const graphName = title.replaceAll(' ', '_');

  const toDate = (ts) => {
    const date = new Date(ts * 1000);
    return date.toLocaleDateString().replaceAll('.', '_');
  };

  let rangeString = `${toDate(range.min)}-${toDate(range.max)}`;

  return `${graphName}_${rangeString}`;
}

export function isRangeValid(range: UplotRange): boolean {
  try {
    const now = new Date();
    const minTimestamp = now.setFullYear(2000) / 1000;
    const maxTimestamp = now.setFullYear(2100) / 1000;
    Object.keys(range).forEach((key: string) => {
      if (range[<string>key] < minTimestamp) throw new Error('Invalid cached range');
      if (range[<string>key] > maxTimestamp) throw new Error('Invalid cached range');
    });
    return true;
  } catch (e) {
    console.warn('Plot range is invalid');
    return false;
  }
}
