import { DataDisplayDefaultRanges } from '../models/DataDisplayDefaultRanges';

const DAY = 24;
export function getRangeFromDataDisplayDefaultRanges(
  scale: DataDisplayDefaultRanges,
  timezoneOffset = false,
): {
  min: number;
  max: number;
} {
  let endDate = new Date();
  let startDate = new Date();
  switch (scale) {
    case DataDisplayDefaultRanges.LAST_12_HOURS: {
      endDate.setMinutes(0, 1, 0);
      startDate.setHours(endDate.getHours() - 12, 0, 0, 0);
      break;
    }
    case DataDisplayDefaultRanges.LAST_24_HOURS: {
      endDate.setMinutes(0, 1, 0);
      startDate.setHours(endDate.getHours() - 24, 0, 0, 0);
      break;
    }

    case DataDisplayDefaultRanges.CURRENT_DAY: {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(DAY, 0, 0, 0);
      break;
    }
    case DataDisplayDefaultRanges.LAST_DAY: {
      startDate.setHours(-DAY, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      break;
    }
    case DataDisplayDefaultRanges.DAY_BEFORE_YESTERDAY: {
      startDate.setHours(-DAY * 2, 0, 0, 0);
      endDate.setHours(-DAY, 0, 0, 0);
      break;
    }

    case DataDisplayDefaultRanges.LAST_7_DAYS: {
      startDate.setHours(-DAY * 7, 0, 0, 0);
      endDate.setHours(DAY, 0, 0, 0);
      break;
    }

    case DataDisplayDefaultRanges.LAST_30_DAYS: {
      startDate.setHours(-DAY * 30, 0, 0, 0);
      endDate.setHours(DAY, 0, 0, 0);
      break;
    }

    case DataDisplayDefaultRanges.CURRENT_WEEK: {
      startDate = getMonday();
      endDate = getMonday();
      endDate.setHours(DAY * 7, 0, 0, 0);
      break;
    }

    case DataDisplayDefaultRanges.BEFORE_LAST_WEEK: {
      startDate = getMonday();
      endDate = getMonday();
      startDate.setHours(-DAY * 14, 0, 0, 0);
      endDate.setHours(-DAY * 7, 0, 0, 0);
      break;
    }
    case DataDisplayDefaultRanges.LAST_WEEK: {
      startDate = getMonday();
      endDate = getMonday();
      startDate.setHours(-DAY * 7, 0, 0, 0);
      break;
    }
    case DataDisplayDefaultRanges.CURRENT_MONTH: {
      const date = new Date();
      startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      break;
    }
    case DataDisplayDefaultRanges.LAST_MONTH: {
      const date = new Date();
      startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      endDate = new Date(date.getFullYear(), date.getMonth(), 1);
      break;
    }
    case DataDisplayDefaultRanges.BEFORE_LAST_MONTH: {
      const date = new Date();
      startDate = new Date(date.getFullYear(), date.getMonth() - 2, 1);
      endDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      break;
    }
    case DataDisplayDefaultRanges.LAST_3_MONTHS: {
      const date = new Date();
      startDate = new Date(date.getFullYear(), date.getMonth() - 2, 1);
      endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      break;
    }
    case DataDisplayDefaultRanges.LAST_6_MONTHS: {
      const date = new Date();
      startDate = new Date(date.getFullYear(), date.getMonth() - 5, 1);
      endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      break;
    }
    case DataDisplayDefaultRanges.LAST_12_MONTHS: {
      const date = new Date();
      startDate = new Date(date.getFullYear(), date.getMonth() - 11, 1);
      endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      break;
    }

    case DataDisplayDefaultRanges.CURRENT_QUARTER: {
      const date = new Date();
      const currentQuarter = getQuarter(date);
      startDate = new Date(date.getFullYear(), currentQuarter * 3 - 3, 1);
      endDate = new Date(date.getFullYear(), (currentQuarter + 1) * 3 - 3, 1);
      break;
    }
    case DataDisplayDefaultRanges.LAST_QUARTER: {
      const date = new Date();
      const quarter = getQuarter(date) - 1;
      startDate = new Date(date.getFullYear(), quarter * 3 - 3, 1);
      endDate = new Date(date.getFullYear(), (quarter + 1) * 3 - 3, 1);
      break;
    }
    case DataDisplayDefaultRanges.BEFORE_LAST_QUARTER: {
      const date = new Date();
      const quarter = getQuarter(date) - 2;
      startDate = new Date(date.getFullYear(), quarter * 3 - 3, 1);
      endDate = new Date(date.getFullYear(), (quarter + 1) * 3 - 3, 1);
      break;
    }
    case DataDisplayDefaultRanges.CURRENT_YEAR: {
      const date = new Date();
      startDate = new Date(date.getFullYear(), 0, 1);
      endDate = new Date(date.getFullYear() + 1, 0, 1);
      break;
    }
    case DataDisplayDefaultRanges.LAST_YEAR: {
      const date = new Date();
      startDate = new Date(date.getFullYear() - 1, 0, 1);
      endDate = new Date(date.getFullYear(), 0, 1);
      break;
    }
    case DataDisplayDefaultRanges.BEFORE_LAST_YEAR: {
      const date = new Date();
      startDate = new Date(date.getFullYear() - 2, 0, 1);
      endDate = new Date(date.getFullYear() - 1, 0, 1);
      break;
    }
  }

  const offset = timezoneOffset ? endDate.getTimezoneOffset() * 60 : 0;

  const min = Math.floor(startDate.getTime() / 1000 + offset);
  const max = Math.ceil(endDate.getTime() / 1000 + offset) - 1;
  return { min, max };
}
function getMonday() {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  const day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

function getQuarter(d: Date) {
  return Math.floor((d.getMonth() + 3) / 3);
}
