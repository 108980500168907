import { Component, Input } from '@angular/core';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataRequestDto } from '../../../../../../api-main';
import { getScaleTypes } from '../utils/getScaleModes';
import { isIntervalAvailableForRange, uplotGetScaleMode } from '../config/uplotScales';

@Component({
  selector: 'app-scale-settings',
  standalone: true,
  imports: [MatOption, MatSelect, NgForOf, NgIf, TranslateModule],
  templateUrl: './scale-settings.component.html',
  styleUrl: './scale-settings.component.scss',
})
export class ScaleSettingsComponent {
  @Input({ required: true }) currentScale$: BehaviorSubject<DataRequestDto.ScaleEnum>;

  @Input({ required: true }) rangeInHours: number;

  public scaleTypes = getScaleTypes();

  UplotScaleModes = DataRequestDto.ScaleEnum;

  isScaleEnabled(scale: DataRequestDto.ScaleEnum = null): boolean {
    return isIntervalAvailableForRange(scale, this.rangeInHours);
  }

  getRealCurrentScale() {
    return uplotGetScaleMode(this.rangeInHours);
  }

  public getScaleString(scale: DataRequestDto.ScaleEnum): string {
    return `SCALE.${scale.toUpperCase()}`;
  }
}
