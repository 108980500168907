import { AbstractControl, ValidatorFn } from '@angular/forms';

export const validateEmail: (value: string) => boolean = (email) => {
  if (!email || email.length === 0) return false;
  if (!email) return true;
  return (
    String(email)
      .toLowerCase()
      .match(
        // eslint-disable-next-line security/detect-unsafe-regex
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )?.length > 0
  );
};

export function formValidateEmail(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const regex =
      // eslint-disable-next-line security/detect-unsafe-regex
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = regex.test(control.value);
    return valid ? null : { invalidEmail: { value: control.value } };
  };
}
