<app-emu-list-input
  *ngIf="canEditRole()"
  [value]="role"
  (valueChange)="onRoleChange($event)"
  [className]="'tree-permission'"
  [filteredOptions$]="of(roles)"
  (optionCleared)="onRoleChange(null)"
  [autocomplete]="false"
  [placeholder]="parentRole"
  [disabled]="disableEdit()"
  [clearButtonHidden]="clearButtonHidden()"
>
  <ng-template let-item
  ><p>{{('TREE.ROLE_' +  (item | uppercase)) | translate}}</p></ng-template
  >
</app-emu-list-input>
<!--role: {{role}}, id: {{node.id}}, parentId: {{node.parent_id}}-->
<!--parent: {{getParentRole()}}-->

<!--<div>-->
<!--  <mat-form-field style="width: 100%" *ngIf="node.is_authorized">-->
<!--    &lt;!&ndash;      <mat-label>{{'TREE.ROLE'|translate}}</mat-label>&ndash;&gt;-->
<!--    <mat-label>{{ 'TREE.PERMISSION' | translate }}</mat-label>-->
<!--    &lt;!&ndash;      <mat-label>-</mat-label>&ndash;&gt;-->
<!--    <mat-select-->
<!--      data-cy="node-role"-->
<!--      [value]="role"-->
<!--      (selectionChange)="onRoleChange($event)"-->
<!--      [disabled]="!allowEdit()"-->
<!--    >-->
<!--      <mat-option *ngFor="let r of roles" [value]="r">{{ r }}</mat-option>-->
<!--    </mat-select>-->

<!--    <button-->
<!--      [disabled]="!node.id || !role || !allowEdit()"-->
<!--      mat-icon-button-->
<!--      color="warn"-->
<!--      onclick="this.blur()"-->
<!--      (click)="onRoleRemove(); $event.preventDefault(); $event.stopPropagation()"-->
<!--      matSuffix-->
<!--      data-cy="clear-role"-->
<!--    >-->
<!--      <mat-icon>close</mat-icon>-->
<!--    </button>-->
<!--  </mat-form-field>-->

<!--  <mat-form-field style="width: 100%" *ngIf="!node.is_authorized">-->
<!--    <mat-label>{{ 'TEMPLATE.YOU_HAVE_NO_ACCESS' | translate }}</mat-label>-->
<!--    <mat-select [disabled]="true"></mat-select>-->
<!--  </mat-form-field>-->
<!--</div>-->
