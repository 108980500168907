<section class="input-container" [class.disabled]="disabled" #container [ngClass]="className">
  <span
    [cpDisabled]="disabled"
    class="color-picker"
    [style.background-color]="color"
    [cpPosition]="'bottom'"
    [cpPositionOffset]="'50%'"
    [cpPositionRelativeToArrow]="true"
    [colorPicker]="color ?? defaultColor"
    (colorPickerChange)="colorChange.emit($event)"
    (colorPickerOpen)="onOpened()"
    (colorPickerClose)="onClosed()"
  ></span>
  <span class="value">{{ colorText }}</span>
</section>
