/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CustomerDataDto {
  /**
   * device_id
   */
  device_id: number;
  /**
   * device_id
   */
  device_name: string;
  /**
   * price_list_id
   */
  price_list_id: number;
  /**
   * price_list_id
   */
  price_list_tree_id: number;
  /**
   * price_list tree name
   */
  price_list_tree_name: string;
  /**
   * tree_device_id
   */
  tree_device_id: number;
}
