import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SIPrefix } from '../../../../../shared/models/SIPrefix';
import { of } from 'rxjs';
import { EmuInputComponent } from '../../../../../shared/components/inputs/emu-input/emu-input.component';
import { EmuListInputComponent } from '../../../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { SelectingValueDataComponent } from '../../shared/selecting-value-data/selecting-value-data.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { getDefinedRanges } from '../../../../../shared/components/graph-uplot/graph/utils/getDefinedRanges';
import { getValueModes, ValueDisplayMode } from '../ValueDisplayMode.model';
import { DashboardItemTypeValueDisplay } from '../../../models/DashboardItemTypeValueDisplay';
import { DataDisplayDefaultRanges } from '../../../../../shared/models/DataDisplayDefaultRanges';
import { RangePickerComponent } from '../../dashboard-graph-settings/range-picker/range-picker.component';
import { UplotRange } from '../../../../../shared/components/graph-uplot/graph/models/UplotRange.model';
import {
  cfgUnits,
  getCfgUnitIdByUnit,
} from '../../../../../shared/components/graph-uplot/graph/models/CfgUnits';
import { getObisCodeGroupForSeries } from '../../../../../shared/components/graph-uplot/graph/models/ObisCodeCfgUnits';

@Component({
  selector: 'app-dashboard-value-display-settings-input',
  standalone: true,
  imports: [
    CommonModule,
    EmuInputComponent,
    EmuListInputComponent,
    FormsModule,
    MatCheckboxModule,
    MatSliderModule,
    SelectingValueDataComponent,
    TranslateModule,
    RangePickerComponent,
  ],
  templateUrl: './dashboard-value-display-settings-input.component.html',
  styleUrl: './dashboard-value-display-settings-input.component.scss',
})
export class DashboardValueDisplaySettingsInputComponent {
  ranges = getDefinedRanges(true);

  modes = getValueModes();

  public unitPrefixes = SIPrefix;

  public units = [...cfgUnits].map(([id, value]) => ({ id, ...value }));

  @Input() data: DashboardItemTypeValueDisplay;

  constructor(
    protected cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  private unitsCache = {};

  getUnitsForSeries(series: DashboardItemTypeValueDisplay) {
    if (!series.loggerId || !series.valueId) return null;

    if (this.unitsCache[`${series.loggerId}-${series.valueId}`])
      return this.unitsCache[`${series.loggerId}-${series.valueId}`];

    console.log('-> series', series, this.units);
    const obisCodeGroup = getObisCodeGroupForSeries(<any>series);
    const units = obisCodeGroup?.cfgUnitId.map((o) => ({ id: o, unit: cfgUnits.get(o).unit }));
    this.unitsCache[`${series.loggerId}-${series.valueId}`] = units;
    return [...units];
  }

  get anyData(): any {
    return this.data;
  }

  formatLabel(value: number): string {
    return `${value}`;
  }

  public getRangeString(scale: string): string {
    return this.translate.instant(`UPLOT.RANGE.${scale.toUpperCase()}`);
    // return this.translate.instant(
    //   `UPLOT.RANGE.${DataDisplayDefaultRanges[Number(scale)].toUpperCase()}`,
    // );
  }

  public getModeString(scale: number): string {
    return this.translate.instant(
      `DASHBOARD.VALUE_MODE.${ValueDisplayMode[Number(scale)].toUpperCase()}`,
    );
  }

  onUnitChange(series: DashboardItemTypeValueDisplay, unit: string) {
    const foundCfgUnitId = getCfgUnitIdByUnit(unit)?.id;
    if (foundCfgUnitId != null) series.cfg_unit = foundCfgUnitId;
  }

  // public displayFnPrefix(unit: any): string {
  //   return `${unit ?? ''}${this.data.unit}`;
  // }

  public transformValue(unit: any) {
    return unit.unit;
  }

  getRange(): UplotRange {
    return this.data.range;
  }

  changeRange(range: UplotRange) {
    this.data.range = range;
  }

  protected readonly of = of;

  protected readonly DataDisplayDefaultRanges = DataDisplayDefaultRanges;
}
