<div
  #slider
  class="palette-slider"
  *ngIf="heatmapLegend"
  [style.background]="this.paletteGradient"
  (mousedown)="onSliderClick($event)"
>
  @for (palette of heatmapLegend; track palette) {
    <div
      (mousedown)="onHandleMouseDown($index)"
      [style.left]="getBoxLeft($index)"
      *ngIf="!$first"
      class="handle"
      [class.last-selected]="$index === lastSelectedIndex"
    ></div>
    <span
      (mousedown)="onHandleMouseDown($index);$event.stopPropagation()"
      [style.left]="getBoxLeft($index)"
      class="label"
      [class.last-selected]="$index === lastSelectedIndex"
      [matTooltip]="(breakpoints[$index] * 100 | formatValue: 2) + '%'"
      [matTooltipHideDelay]="500"
      >{{ palette.min | formatValue: 2 }} - {{ palette.max | formatValue: 2 }}</span
    >

    <!--      <span [style.left]="getBoxLeft($index)" [style.width]="getBoxWidth($index)"-->
    <!--            [style.background]="palette.color" class="box"></span>-->
  }
</div>
