import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  concat,
  distinctUntilChanged,
  map,
  Observable,
  of,
  skip,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { LoggerDto } from '../../../../../../../api-main';
import { LoggerService } from '../../../../../logger/logger.service';
import { getLoggerListBody, getLoggerListBodyById } from '../../getRequestBodys';
import { ValueDataModel } from '../../../../models/ValueData.model';
import { EmuListInputComponent } from '../../../../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import { TimestampRelativeComponent } from '../../../../../../shared/components/timestamp-relative/timestamp-relative.component';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutService } from '../../../../../../layout/layout.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TreeNode } from '../../../../../tree/tree-controller/tree-async/models/TreeNode';
import { TreeService } from '../../../../../tree/tree.service';

@Component({
  selector: 'app-settings-logger',
  templateUrl: './value-display-settings-logger.component.html',
  styleUrls: ['./value-display-settings-logger.component.scss'],
  standalone: true,
  imports: [EmuListInputComponent, TimestampRelativeComponent, TranslateModule],
})
export class ValueDisplaySettingsLoggerComponent implements OnInit {
  @ViewChild('loggerList') loggerList: EmuListInputComponent;

  @Input() data: ValueDataModel;

  @Input() reloadDevice$: Subject<number>;

  loading$ = new BehaviorSubject(false);

  logger$: Observable<LoggerDto[]>;

  loggerInput$ = new Subject<string>();

  private unsubscribe = takeUntilDestroyed();

  listState$ = new BehaviorSubject<boolean>(false);

  @Output() valueSelected = new EventEmitter<string>();

  constructor(
    private loggerService: LoggerService,
    private layoutService: LayoutService,
  ) {
    this.listState$.pipe(this.unsubscribe).subscribe((val) => {
      if (val == false) return;

      this.layoutService.treeNodeService
        .getItemClick(TreeService.nodesLoggerCodes)
        .pipe(takeUntil(this.listState$.pipe(skip(1))))
        .subscribe((item: TreeNode) => {
          // console.log('=>(value-display-settings-logger.component.ts:83) item', item);
          const id = item.item_id;
          this.assignLoggerId(id);
          this.reloadDevice$.next(id);
          this.loggerList.closePanel();
          this.loadSelectedLogger();
        });
    });
  }

  private _selectedLogger: LoggerDto;

  get selectedLogger(): LoggerDto {
    return this._selectedLogger;
  }

  set selectedLogger(value: LoggerDto) {
    this._selectedLogger = value;
    if (value?.system_id) {
      this.assignLoggerId(value.system_id);
      this.reloadDevice$.next(value.system_id);
    }
  }

  assignLoggerId(id: number) {
    if (this.data.loggerId == id) return;
    this.data.loggerId = id;
    this.valueSelected.emit();
    this.data.deviceId = null;
    this.data.valueId = null;
  }

  resetValues() {
    this.data.loggerId = null;
    this.data.deviceId = null;
    this.data.valueId = null;
  }

  ngOnInit() {
    this.loadLoggers();
    if (this.data.loggerId) this.loadSelectedLogger();
  }

  private loadLoggers() {
    this.logger$ = concat(
      of([]), // default items
      this.loggerInput$.pipe(
        startWith(''),
        distinctUntilChanged(),
        tap(() => this.loading$.next(true)),
        switchMap((term: LoggerDto | string) => {
          const description: string = typeof term === 'object' ? term.name : term;
          return this.loggerService.getLoggerList(getLoggerListBody(description)).pipe(
            map((response) => response.data),
            catchError(() => of([])), // empty list on error
            tap(() => this.loading$.next(false)),
          );
        }),
      ),
    );
  }

  private loadSelectedLogger() {
    this.loading$.next(true);
    this.loggerService
      .getLoggerList(getLoggerListBodyById(this.data.loggerId))
      .pipe(map((response) => (response?.data ? response.data[0] : null)))
      .pipe(this.unsubscribe)
      .subscribe((logger: LoggerDto) => {
        this.selectedLogger = logger;
        this.loading$.next(false);
      });
  }

  displayLoggerFn(item: LoggerDto): string {
    return item ? `${item.name}` : '';
  }
}
