<app-modal
  [title]="getTitle()"
  [saveButtonName]="getSaveButtonName()"
  [cancelButtonName]="getCancelButtonName()"
  [shouldSaveBeDisabled]="blockInput || !isCodeValid()"
  (save)="onSubmit()"
>
  <ng-container body>
      <div class="body">

        <app-emu-input
          name="INVITATION.INVITATION_CODE"
          placeholder="x-XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
          [(value)]="code"
          (paste)="onPaste($event)"
        >
        </app-emu-input>
        <p *ngIf="!isCodeValid() && code.length>0" class="error">
          {{ 'FIELD.INCORRECT' | translate }}
        </p>

      </div>
  </ng-container>
</app-modal>

