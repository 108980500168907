<div
  cdkDrag
  class="wrapper"
  cdkDragBoundary="{{ boundingContainer }}"
  [cdkDragFreeDragPosition]="dragPosition"
  #settingsContainer
  *ngIf="display"
>
  <section class="settings-container" >
    <div class="settings-header" cdkDragHandle>
      @if (displayItemSettings) {
        <button class="hide-button dashboard-button" (click)="unselectItem()">
          <!--          matTooltip="{{'DASHBOARD.HIDE_ITEM_SETTINGS' |translate}}"-->
          <mat-icon svgIcon="go_back"></mat-icon>
        </button>
        <span> {{ 'DASHBOARD.TYPE_' + DashboardItemType[selectedItem.type] | translate }}</span>
        <app-item-base-settings></app-item-base-settings>
      } @else {
        <button
          class="hide-button dashboard-button"
          (click)="hide()"
          matTooltip="{{ 'DASHBOARD.HIDE' | translate }}"
        >
          <!--          <mat-icon svgIcon="go_back"></mat-icon>-->
          <mat-icon>close</mat-icon>
        </button>
        <span> {{ 'DASHBOARD.TOOLBAR' | translate }}</span>
      }
    </div>
    <div class="overflow-wrapper">
      <div class="settings">
        @if (displayItemSettings) {
          <!--          <app-item-base-settings></app-item-base-settings>-->
          <app-dashboard-item-settings-per-type
            [item]="selectedItem"
          ></app-dashboard-item-settings-per-type>
          <app-item-layer-buttons></app-item-layer-buttons>
        } @else {
          <app-dashboard-toolbar></app-dashboard-toolbar>
          <app-dashboard-layers></app-dashboard-layers>
        }
      </div>
    </div>
  </section>
</div>
