import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localePl from '@angular/common/locales/pl';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en-GB';
import localeCH from '@angular/common/locales/de-CH';
import { CookieService } from 'ngx-cookie';
import { TranslateService } from '@ngx-translate/core';
import { catchError, take } from 'rxjs';
import * as getTimezoneOffset from 'get-timezone-offset';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserService } from './user.service';
import { DateAdapter } from '@angular/material/core';
/**
 * Provide locale handling as factory on app module
 */
@Injectable({
  providedIn: 'root',
})
export class LocaleSessionService {
  private readonly defaultLng = 'en';

  public readonly langCookieKey = 'langCookieKey';

  private _locale: string = 'en'; //default en

  private _lang: string;

  private _currency: string;

  public supportedLanguage: string[] = ['en', 'pl', 'fr'];

  _timezone = null;

  set timezone(val: string) {
    this._timezone = val;
  }

  get timezone() {
    return this._timezone;
  }

  constructor(
    private cookieService: CookieService,
    private translateService: TranslateService, // private dictionaryService: DictionaryService,
    private userService: UserService,
    private dateAdapter: DateAdapter<Date>,
  ) {
    let cookieLng = this.cookieService.get('langCookieKey');
    if (cookieLng !== undefined) {
      this.updateCulture(cookieLng);
    }
    if (!this.timezone) this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.userService.userData.pipe(takeUntilDestroyed()).subscribe((data) => {
      if (data?.user_metadata?.timezone) this.timezone = data.user_metadata.timezone;
    });
  }

  changeLanguage(language: string) {
    this.translateService
      .use(language)
      .pipe(
        take(1),
        catchError(() => {
          console.warn(`Failed to load ${language}, loading default (${this.defaultLng})`);
          language = this.defaultLng;
          return this.translateService.use(language);
        }),
      )
      .subscribe({
        next: () => {
          this.updateCulture(language);
          this.cookieService.put(this.langCookieKey, language);
          // console.log(`Language set: ${language}`);
        },
        error: () => {
          console.error(`Failed to load ${language}`);
        },
      });

    // Save information about language to Auth0 for e-mail composition
  }

  get lang() {
    return this._lang;
  }

  set locale(value: string) {
    this._locale = value;
  }

  get locale(): string {
    return this._locale;
  }

  set currency(lang: string) {
    this._currency = lang;
  }

  get currency(): string {
    return this._currency;
  }

  public getDateSeparator(): string {
    const currentLang = this.translateService.currentLang;

    switch (currentLang) {
      case 'pl':
        return '.';
      case 'fr':
      case 'en':
        return '/';
      default:
        return '.';
    }
  }

  public getDateFormat(): string {
    const currentLang = this.translateService.currentLang;

    switch (currentLang) {
      case 'pl':
        return 'DD.MM.YYYY';
      case 'fr':
        return 'DD/MM/YYYY';
      case 'en':
        return 'DD/MM/YYYY';
      default:
        return 'DD.MM.YYYY';
    }
  }

  // if user browser has differ lang from array set default EN language
  public checkLangExistence() {
    let cookie = this.cookieService.get('langCookieKey');
    if (!cookie) {
      this.translateService.addLangs(this.supportedLanguage); //what for?
      const langs = this.translateService.getLangs();

      if (!langs.includes(this.translateService.getBrowserLang())) {
        this.translateService.setDefaultLang(langs[0]);
      } else {
        this.translateService.setDefaultLang(this.translateService.getBrowserLang());
      }
    }
  }

  // If no argument, is load localeDate from service, if argument is register new localeDate
  public updateCulture(culture?: string) {
    const newLocale = culture ? culture : this.translateService.currentLang;

    this.setCurrency(newLocale);
    // this.locale = newLocale;
    this.setLanguages(newLocale);
    // update culture

    this.dateAdapter.setLocale(newLocale); //dd/MM/yyyy
  }

  private setLanguages(lang: string): void {
    this._lang = lang;
    // English language not set because its default
    let locale = '';
    switch (lang) {
      case 'pl': {
        locale = 'pl';
        registerLocaleData(localePl, locale);

        break;
      }
      case 'fr': {
        locale = 'fr';
        registerLocaleData(localeFr, locale);

        break;
      }
      case 'de': {
        locale = 'de';
        registerLocaleData(localeDe, locale);

        break;
      }
      case 'en': {
        locale = 'en-GB';
        registerLocaleData(localeEn, locale);

        break;
      }
      case 'ch': {
        locale = 'de-CH';
        registerLocaleData(localeCH, locale);
        break;
      }
    }
    this.locale = locale;
  }

  private setCurrency(lang: string): void {
    switch (lang) {
      case 'pl':
        this.currency = 'PLN';
        break;
      case 'fr':
      case 'de':
      case 'ch':
        this.currency = 'EUR';
        break;
      case 'en':
        this.currency = 'GBP';
        break;
      default:
        this.currency = 'USD';
        break;
    }
  }

  getTimezoneOffsetInHours(date = new Date()): number {
    return -getTimezoneOffset(this.timezone, date) / 60;
  }
}
