import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
export type DateFormat = 'full' | 'long' | 'medium' | 'short' | undefined;
@Injectable({
  providedIn: 'root',
})
export class DateDisplayService {
  private formatId = 0;

  private formats: DateFormat[] = ['medium', 'short', 'long', 'full'];

  format$ = new BehaviorSubject<DateFormat>(this.formats[this.formatId]);

  formatChange() {
    this.formatId++;
    if (this.formatId >= this.formats.length) this.formatId = 0;
    this.format$.next(this.formats[this.formatId]);
  }

  // constructor() {}
}
