/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DbConfigDto } from '../model/dbConfigDto';
import { DbConfigListResponseDto } from '../model/dbConfigListResponseDto';
import { DbDefaultResponse } from '../model/dbDefaultResponse';
import { DeviceDto } from '../model/deviceDto';
import { DeviceListResponseDto } from '../model/deviceListResponseDto';
import { IotAuthResponseDto } from '../model/iotAuthResponseDto';
import { ListFilterRequestDto } from '../model/listFilterRequestDto';
import { LoggerDto } from '../model/loggerDto';
import { LoggerListResponseDto } from '../model/loggerListResponseDto';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class LoggerService {
  protected basePath = 'http://127.0.0.1:4201/api/';

  public defaultHeaders = new HttpHeaders();

  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get database item
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dbConfigControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DbConfigDto>;
  public dbConfigControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DbConfigDto>>;
  public dbConfigControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DbConfigDto>>;
  public dbConfigControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling dbConfigControllerGet.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DbConfigDto>(
      'get',
      `${this.basePath}/logger/db/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Get logger database list
   *
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public dbConfigControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DbConfigListResponseDto>;
  public dbConfigControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DbConfigListResponseDto>>;
  public dbConfigControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DbConfigListResponseDto>>;
  public dbConfigControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling dbConfigControllerList.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<DbConfigListResponseDto>(
      'post',
      `${this.basePath}/logger/db/list`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   *
   * @param id
   * @param logger_id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deviceControllerGet(
    id: number,
    logger_id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DeviceDto>;
  public deviceControllerGet(
    id: number,
    logger_id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DeviceDto>>;
  public deviceControllerGet(
    id: number,
    logger_id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DeviceDto>>;
  public deviceControllerGet(
    id: number,
    logger_id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deviceControllerGet.',
      );
    }

    if (logger_id === null || logger_id === undefined) {
      throw new Error(
        'Required parameter logger_id was null or undefined when calling deviceControllerGet.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DeviceDto>(
      'get',
      `${this.basePath}/logger/${encodeURIComponent(String(logger_id))}/device/${encodeURIComponent(
        String(id),
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   *
   * @param body
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deviceControllerList(
    body: ListFilterRequestDto,
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DeviceListResponseDto>;
  public deviceControllerList(
    body: ListFilterRequestDto,
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DeviceListResponseDto>>;
  public deviceControllerList(
    body: ListFilterRequestDto,
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DeviceListResponseDto>>;
  public deviceControllerList(
    body: ListFilterRequestDto,
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling deviceControllerList.',
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deviceControllerList.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<DeviceListResponseDto>(
      'post',
      `${this.basePath}/logger/${encodeURIComponent(String(id))}/device/list`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Refresh download code of logger for a given id
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loggerControllerCodeRefresh(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<any>;
  public loggerControllerCodeRefresh(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<any>>;
  public loggerControllerCodeRefresh(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<any>>;
  public loggerControllerCodeRefresh(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling loggerControllerCodeRefresh.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/logger/code-refresh/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loggerControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DbDefaultResponse>;
  public loggerControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DbDefaultResponse>>;
  public loggerControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DbDefaultResponse>>;
  public loggerControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling loggerControllerDelete.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DbDefaultResponse>(
      'delete',
      `${this.basePath}/logger/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loggerControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<LoggerDto>;
  public loggerControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<LoggerDto>>;
  public loggerControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<LoggerDto>>;
  public loggerControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling loggerControllerGet.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<LoggerDto>(
      'get',
      `${this.basePath}/logger/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Get SSL certificate
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loggerControllerGetSSL(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<string>;
  public loggerControllerGetSSL(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<string>>;
  public loggerControllerGetSSL(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<string>>;
  public loggerControllerGetSSL(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling loggerControllerGetSSL.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<string>(
      'get',
      `${this.basePath}/logger/${encodeURIComponent(String(id))}/ssl`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        //@ts-ignore
        responseType: 'text',
      },
    );
  }

  /**
   * Prepare authorization token for wss connection. Return token
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loggerControllerIotAuth(
    id: string,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<IotAuthResponseDto>;
  public loggerControllerIotAuth(
    id: string,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<IotAuthResponseDto>>;
  public loggerControllerIotAuth(
    id: string,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<IotAuthResponseDto>>;
  public loggerControllerIotAuth(
    id: string,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling loggerControllerIotAuth.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<IotAuthResponseDto>(
      'post',
      `${this.basePath}/logger/iot/auth/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        //@ts-ignore
        responseType: 'text',
      },
    );
  }

  /**
   * Request live data for next 60 seconds for logger id
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loggerControllerIotLive(
    id: string,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<any>;
  public loggerControllerIotLive(
    id: string,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<any>>;
  public loggerControllerIotLive(
    id: string,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<any>>;
  public loggerControllerIotLive(
    id: string,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling loggerControllerIotLive.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/logger/iot/live/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   *
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loggerControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<LoggerListResponseDto>;
  public loggerControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<LoggerListResponseDto>>;
  public loggerControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<LoggerListResponseDto>>;
  public loggerControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling loggerControllerList.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<LoggerListResponseDto>('post', `${this.basePath}/logger/list`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Save logger. If system_id is not provided - device will be added
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loggerControllerSave(
    body: LoggerDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<any>;
  public loggerControllerSave(
    body: LoggerDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<any>>;
  public loggerControllerSave(
    body: LoggerDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<any>>;
  public loggerControllerSave(
    body: LoggerDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling loggerControllerSave.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/logger`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
