import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { DictionaryService } from '../../../../../shared/services/dictionary.service';
import { LocaleSessionService } from '../../../../../shared/services/localeSession.service';
import { TreeNodeName } from './TreeNodeName';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TreeFormData } from '../TreeFormData';
import { ItemTreeDto, LanguageDto } from '../../../../../../api-main';
import { TreeService } from '../../../tree.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getDefaultNodeName } from './getDefaultNodeName';
import { SkeletonComponent } from '../../../../../shared/components/skeleton/skeleton.component';
import { EmuInputComponent } from '../../../../../shared/components/inputs/emu-input/emu-input.component';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgOptimizedImage } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { TreeDialogMode } from '../TreeDialogMode';

@Component({
  selector: 'app-tree-form-name-input',
  templateUrl: './tree-form-name-input.component.html',
  styleUrl: './tree-form-name-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatTabsModule,
    NgFor,
    MatIconModule,
    NgOptimizedImage,
    EmuInputComponent,
    SkeletonComponent,
    TranslateModule,
  ],
})
export class TreeFormNameInputComponent implements AfterViewInit {
  loaded = false;

  @Input({ required: true }) data: TreeFormData;

  constructor(
    private dictionaryService: DictionaryService,
    private localeService: LocaleSessionService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private treeService: TreeService,
  ) {}

  private $unsubscribe = takeUntilDestroyed();

  ngAfterViewInit() {
    // console.log('-> this.names', JSON.parse(JSON.stringify(this.names)));

    this.dictionaryService
      .getLanguageList()
      .pipe(this.$unsubscribe)
      .subscribe({
        next: (res: LanguageDto[]) => {
          for (let i = 0; i < res.length; i++) {
            const lng: string = res[i as number].lng;
            const obj: TreeNodeName = { lng, name: '' };
            const foundName = this.data.names.find((o) => o.lng == lng);
            if (!foundName) this.data.names.push(obj);
            // if (lng == this.localeService.lang) this.names.unshift(obj);
          }
          this.sortNames();

          if (this.data.item && this.data.mode == TreeDialogMode.Edit) {
            this.fetchAndSetNames();
          } else {
            this.loaded = true;
            this.cdr.detectChanges();
          }
        },
      });
  }

  private fetchAndSetNames() {
    return this.treeService
      .getTree(+this.data.item.id)
      .pipe(this.$unsubscribe)
      .subscribe((item: ItemTreeDto) => {
        const names = item.items;
        this.data.names.forEach((name) => {
          const foundName = names.find((o) => o.lng == name.lng);
          if (foundName) name.name = foundName.name;
        });
        this.loaded = true;
        this.cdr.detectChanges();
      });
  }

  getDefaultName() {
    return getDefaultNodeName(this.data);
    //use any not empty name, prefer current lng (array is sorted)
    // const someName = this.data.names?.find((o) => o.name && o.name.length > 0)?.name;
    // return someName ?? this.data.typeSelected$.value.name;
  }

  getLanguageNameToTranslate(lng: string) {
    return 'NAVBAR.' + lng.toUpperCase();
  }

  private sortNames() {
    const getLngName = (lng: string) =>
      this.translate.instant(this.getLanguageNameToTranslate(lng));

    this.data.names.sort((a, b) => {
      //place current language as first
      if (a.lng == this.localeService.lang) return -1;
      if (b.lng == this.localeService.lang) return 1;
      else return getLngName(a.lng).localeCompare(getLngName(b.lng));
    });
  }
}
