<div class="tree-container" *ngIf="treeNodeService">
  <div class="action-buttons" [class.blocked]="loading">
    <ng-content select="[buttons]"></ng-content>
    <app-tree-header-buttons [treeNodeService]="treeNodeService"></app-tree-header-buttons>
  </div>
  <!--  <mat-divider></mat-divider>-->

  <div class="tree">
    <ng-content select="[customNode]"></ng-content>
    <ng-container *ngIf="rootNode; else loadingOrError">
      <app-tree-node
        [data]="{ node: rootNode, treeNodeService: treeNodeService }"></app-tree-node>

      <!--      <ng-container *ngIf="!loading; else loadingOrError">-->

      <!--        <app-tree-node *ngFor="let node of tree" [attr.data-cy]="node.item_name+'-node'"-->
      <!--          [data]="{node:node, treeNodeService:treeNodeService}"></app-tree-node>-->

      <!--        <app-tree-node *ngFor="let temp of temporaryChildren" [attr.data-cy]="temp.item_name+'-node'"-->
      <!--          [data]="{node:temp, treeNodeService:treeNodeService}" [new]="true">-->
      <!--        </app-tree-node>-->
      <!--      </ng-container>-->
    </ng-container>
  </div>
</div>

<ng-template #loadingOrError>
  <app-skeleton></app-skeleton>
</ng-template>
