import { TreeNode } from '../models/TreeNode';
import { TreeNodeService } from '../TreeNodeService';

export function addNodeToCache(node: TreeNode, treeNodeService: TreeNodeService) {
  if (!treeNodeService?.cachedNodes) return;
  const index = treeNodeService.cachedNodes.findIndex((o) => o.id == node.id);
  if (index >= 0) treeNodeService.cachedNodes[Number(index)] = node;
  else treeNodeService.cachedNodes.push(node);
  treeNodeService.saveTreeState();
}

export function removeFromCache(node: TreeNode, treeNodeService: TreeNodeService) {
  treeNodeService.cachedNodes = treeNodeService.cachedNodes.filter((o) => o.id != node.id);
  treeNodeService.saveTreeState();
}
