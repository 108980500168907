import { Injectable } from '@angular/core';
import {
  DeviceDto,
  DeviceValuesDto,
  DeviceValuesService,
  ListFilterRequestDto,
  LoggerService as ApiLoggerService,
} from '../../../api-main';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../shared/services/config.service';
import { DashboardService } from '../dashboard/services/dashboard.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  public savedFilter: ListFilterRequestDto = {
    filter: [],
    sort: [],
    page_size: 25,
    sort_direction: 'asc',
    page_index: 0,
  };

  private defaultFilter: ListFilterRequestDto = {
    filter: [],
    sort: [],
    page_size: 1000,
    sort_direction: 'asc',
    page_index: 0,
  };

  constructor(
    private loggerService: ApiLoggerService,
    private http: HttpClient,
    private deviceValuesService: DeviceValuesService,
    private dashboardService: DashboardService,
  ) {
    this.loggerService = new ApiLoggerService(this.http, ConfigService.getApiConfig(), undefined);
    this.deviceValuesService = new DeviceValuesService(
      this.http,
      ConfigService.getApiConfig(),
      undefined,
    );
  }

  private injectDashboardId(body: ListFilterRequestDto) {
    if (!body) return;
    const dashboard = this.dashboardService.dashboard;
    if (!dashboard) return;
    body.dashboard_id = dashboard.id;
  }

  public deviceList(body: ListFilterRequestDto, loggerId: number) {
    if (body == null) body = this.savedFilter;
    this.injectDashboardId(body);
    return this.loggerService.deviceControllerList(body, loggerId);
  }

  public getDevice(id: number, logger_id: number): Observable<DeviceDto> {
    return this.loggerService.deviceControllerGet(id, logger_id);
  }

  public getDeviceValues(
    device_id: number,
    logger_id: number,
    id: number,
  ): Observable<DeviceValuesDto> {
    return this.deviceValuesService.deviceValuesControllerGet(id, device_id, logger_id);
  }

  public getDeviceValuesList(
    device_id: number,
    logger_id: number,
    body: ListFilterRequestDto = null,
  ) {
    if (body == null) body = this.defaultFilter;
    this.injectDashboardId(body);
    return this.deviceValuesService.deviceValuesControllerList(body, device_id, logger_id);
  }
}
