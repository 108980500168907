/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DataRequestValueItemDto } from './dataRequestValueItemDto';

export interface DataRequestDto {
  /**
   * Start date timestamp in seconds
   */
  min: number;
  /**
   * End date timestamp in seconds
   */
  max: number;
  /**
   * Get permissions based on dashboard
   */
  dashboard_id?: number;
  /**
   * Data series scale
   */
  scale: DataRequestDto.ScaleEnum;
  /**
   * Array with id value series
   */
  series: Array<DataRequestValueItemDto>;
}
export namespace DataRequestDto {
  export type ScaleEnum =
    | 'AUTO'
    | 'FIFTEENMINUTES'
    | 'HOURLY'
    | 'DAILY'
    | 'WEEKLY'
    | 'MONTHLY'
    | 'QUARTER'
    | 'HALFYEARLY'
    | 'YEARLY';
  export const ScaleEnum = {
    AUTO: 'AUTO' as ScaleEnum,
    FIFTEENMINUTES: 'FIFTEENMINUTES' as ScaleEnum,
    HOURLY: 'HOURLY' as ScaleEnum,
    DAILY: 'DAILY' as ScaleEnum,
    WEEKLY: 'WEEKLY' as ScaleEnum,
    MONTHLY: 'MONTHLY' as ScaleEnum,
    QUARTER: 'QUARTER' as ScaleEnum,
    HALFYEARLY: 'HALFYEARLY' as ScaleEnum,
    YEARLY: 'YEARLY' as ScaleEnum,
  };
}
