import { Component, Input } from '@angular/core';
import {
  AccountConfiguration,
  SignupAccountConfiguration,
} from '../../models/AccountConfiguration.model';
import { UserTypes } from '../../models/UserTypes.enum';
import { SignupData } from '../../SignupData';
import { accountConfigurations } from '../../data/AccountConfigurations';
import { TranslateModule } from '@ngx-translate/core';
import { DisabledDirective } from '../../../shared/directives/disabled.directive';
import { MatOptionModule } from '@angular/material/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-signup-data',
  templateUrl: './signup-configuration.component.html',
  styleUrls: ['./signup-configuration.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatOptionModule,
    DisabledDirective,
    TranslateModule,
  ],
})
export class SignupConfigurationComponent {
  configOptionsPrefix = 'SIGNUP.CONFIG_OPTIONS.';

  @Input() signupData: SignupData;

  get data(): SignupAccountConfiguration {
    return this.signupData.configuration;
  }

  get userType(): UserTypes {
    return this.signupData.userType;
  }

  accountConfiguration: AccountConfiguration[] = accountConfigurations;

  isConfigAvailable(config) {
    try {
      return config.userType.includes(this.userType);
    } catch (e) {
      return false;
    }
  }
}
