import { DataDisplayDefaultRanges } from '../../../../models/DataDisplayDefaultRanges';

let definedRanges = new Set<DataDisplayDefaultRanges>();
export function getDefinedRanges(custom = true): DataDisplayDefaultRanges[] {
  const definedRangesArray = Array.from(definedRanges);
  if (definedRangesArray.length === 0) setDefinedRanges();

  if (!custom) return definedRangesArray.filter((o) => o !== DataDisplayDefaultRanges.CUSTOM);
  else return definedRangesArray;
}

function setDefinedRanges() {
  for (let enumMember in DataDisplayDefaultRanges) {
    // console.log('=>(getDefinedRanges.ts:14) enumMember', enumMember);
    definedRanges.add(<DataDisplayDefaultRanges>enumMember);
    // const isValueProperty = Number(enumMember) >= 0;
    // if (isValueProperty) {
    //   definedRanges.push(Number(enumMember));
    // }
  }
}
