import { UplotRange } from '../models/UplotRange.model';
import { getFilteredData } from './getFilteredData';
import { TranslateService } from '@ngx-translate/core';

let translateService: TranslateService;
function getHeaders(series) {
  let headers = [];

  const unitIfExist = (serie) => {
    return serie.unit && serie.unit != '' ? ` [${serie.unit}]` : '';
  };
  headers.push(translateService.instant('CSV.DATE_UTC')); //DATE
  headers.push(translateService.instant('CSV.DATE_LOCALE')); //DATE
  series.slice(1).forEach((serie) => {
    if (!serie.show) return;
    headers.push(`"${serie.label}${unitIfExist(serie)}"`);
  });

  return headers;
}

function download(csvContent, options: CSVOptions) {
  // const encodedUri = encodeURI(csvContent);
  // link.setAttribute('href', encodedUri);
  // link.setAttribute('download', `${filename}.csv`);
  const separator = options.separator ?? ';';
  const link = document.createElement('a');
  const universalBOM = '\uFEFF';

  link.setAttribute(
    'href',
    `data:text/csv; charset=utf-8;sep=${separator},` +
      encodeURIComponent(universalBOM + csvContent),
  );
  link.setAttribute('download', `${options.fileName}.csv`);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// function downloadBlob(csvContent, options: CSVOptions) {
//   // Create a blob
//   const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
//   const url = URL.createObjectURL(blob);
//
//   // Create a link to download it
//   const pom = document.createElement('a');
//   pom.href = url;
//   pom.setAttribute('download', options.fileName);
//   pom.click();
// }

interface CSVOptions {
  fileName: string;
  range?: UplotRange;
  separator?: string;
  // decimalSeparator?: string;
  formatDate?: (Date) => string;
  formatDateUtc?: (Date) => string;
  formatValues: any;
  translateService: TranslateService;
}

export function uplotDownloadCsv(u, options: CSVOptions) {
  translateService = options.translateService;
  const separator = options.separator ?? ';';
  // let csvContent = `data:text/csv;charset=utf-8;sep=${separator}`;
  let csvContent = ``;

  // const decimalSeparator = options.decimalSeparator ?? ',';
  //TODO TIMEZONE
  const formatDate = options.formatDate ? options.formatDate : (d: Date) => d.toLocaleString();
  const formatDateUtc = options.formatDateUtc
    ? options.formatDateUtc
    : (d: Date) => d.toUTCString();

  const rawData = JSON.parse(JSON.stringify(u._data));
  const data = options.range == null ? rawData : getFilteredData(rawData, options.range);

  const series = u.series;
  // console.log('-> u.series', u.series);
  const newLine = '\r\n';

  const dates = data[0].map((o) => new Date(o * 1000));

  csvContent += getHeaders(series).join(separator) + newLine;

  dates.forEach((date, id) => {
    let row = [];
    row.push(`"${formatDateUtc(date)}"`);

    const timezone = -date.getTimezoneOffset() / 60;
    const sign = timezone >= 0 ? '+' : '-';
    row.push(`"${formatDate(date)} UTC${sign}${Math.abs(timezone)}"`);

    series.slice(1).forEach((serie, serieId) => {
      if (!serie.show) return;
      let value = data[serieId + 1][id];
      value = options.formatValues(value);
      // Number(value).toFixed(2).replace('.', decimalSeparator);

      row.push(`"${value}"`);
    });

    csvContent += row.join(separator) + newLine;
  });

  download(csvContent, options);
  // downloadBlob(csvContent, options);
}
