import { Component } from '@angular/core';
import { UserService } from '../../../../shared/services/user.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogContent } from '@angular/material/dialog';
import { ModalComponent } from '../../../../shared/components/dialogs/modal/modal.component';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
  standalone: true,
  imports: [ModalComponent, MatDialogContent, TranslateModule],
})
export class LogoutModalComponent {
  constructor(public userService: UserService) {}

  onLogout() {
    this.userService.logout();
  }

  getTitle() {
    return 'DIALOG.LOGOUT_TITLE';
  }

  getSaveButtonName() {
    return 'BUTTON.LOGOUT';
  }

  getCancelButtonName() {
    return 'BUTTON.CANCEL';
  }
}
