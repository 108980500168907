interface CompanyInfoInputs {
  displayName: Uppercase<string>;
  name: string;
  autocompleteTag: string;
}
export const companyInfoInputs: Array<CompanyInfoInputs> = [
  {
    displayName: 'ORGANIZATION.COMPANY_NAME',
    name: 'companyName',
    autocompleteTag: 'organization',
  },
  {
    displayName: 'ORGANIZATION.STREET',
    name: 'street',
    autocompleteTag: 'address-level3',
  },
  {
    displayName: 'ORGANIZATION.HOUSE_NUMBER',
    name: 'houseNumber',
    autocompleteTag: 'address-level4',
  },
  {
    displayName: 'ORGANIZATION.ZIP',
    name: 'zip',
    autocompleteTag: 'postal-code',
  },
  {
    displayName: 'ORGANIZATION.CITY',
    name: 'city',
    autocompleteTag: 'address-level2',
  },
  {
    displayName: 'ORGANIZATION.COUNTRY',
    name: 'country',
    autocompleteTag: 'country-name',
  },
  // {
  //   displayName: 'ORGANIZATION.DUNS_NUMBER',
  //   name: 'dunsNumber',
  //   autocompleteTag: 'off',
  // },
  {
    displayName: 'ORGANIZATION.VAT_NUMBER',
    name: 'vatNumber',
    autocompleteTag: 'off',
  },
  {
    displayName: 'ORGANIZATION.EMAIL_FOR_INVOICE',
    name: 'emailForInvoice',
    autocompleteTag: 'email',
  },
];
