<ng-container *ngIf="item" [ngSwitch]="item.type">
  <app-dashboard-graph *ngSwitchCase="DashboardItemType.GRAPH" [item]="item"></app-dashboard-graph>
  <app-dashboard-text *ngSwitchCase="DashboardItemType.TEXT" [item]="item"></app-dashboard-text>
  <app-dashboard-image *ngSwitchCase="DashboardItemType.IMAGE" [item]="item"></app-dashboard-image>
  <app-dashboard-value-display
    *ngSwitchCase="DashboardItemType.VALUE_DISPLAY"
    [item]="item"
  ></app-dashboard-value-display>
  <app-dashboard-multiple-value-display
    *ngSwitchCase="DashboardItemType.MULTI_VALUE_DISPLAY"
    [item]="item"
  ></app-dashboard-multiple-value-display>
</ng-container>
