import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorPickerModule } from 'ngx-color-picker';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, filter, skip } from 'rxjs';

@Component({
  selector: 'app-emu-color-input',
  standalone: true,
  imports: [CommonModule, ColorPickerModule],
  templateUrl: './emu-color-input.component.html',
  styleUrl: './emu-color-input.component.scss',
})
export class EmuColorInputComponent implements AfterViewInit, OnDestroy {
  @ViewChild('container') container: ElementRef;

  readonly defaultColor = '#fff';

  @Input() color: string;

  @Input() disabled: boolean;

  @Input() className: string;

  @Output() colorChange = new EventEmitter<string>();

  takeUntilDestroyed = takeUntilDestroyed();

  opened$ = new BehaviorSubject<boolean>(false);

  get colorText() {
    if (!this.color) return this.defaultColor;
    if (this.color.charAt(0) === '#') return this.color.substring(1);
    else return this.rgbaToHex(this.color);
  }

  private rgbaToHex(color: string) {
    let a;
    // eslint-disable-next-line security/detect-unsafe-regex
    const rgb: any[] = color.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
    const alpha = ((rgb && rgb[4]) || '').trim();
    let hex = rgb
      ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
        (rgb[2] | (1 << 8)).toString(16).slice(1) +
        (rgb[3] | (1 << 8)).toString(16).slice(1)
      : color;

    if (alpha !== '') a = alpha;
    else a = 0o1;

    // multiply before convert to HEX
    a = ((a * 255) | (1 << 8)).toString(16).slice(1);
    hex = hex + a;

    return hex;
  }

  private modalRef: HTMLElement;

  ngAfterViewInit() {
    this.positionModal();
  }

  private positionModal() {
    this.opened$
      .pipe(
        skip(1),
        filter((o) => !!o),
        this.takeUntilDestroyed,
      )
      .subscribe(() => {
        if (!this.modalRef) this.moveModalInDOMandSaveRef();

        if (!this.modalRef) return;

        const container = this.container?.nativeElement;

        //calculate position
        let top = container.getBoundingClientRect().top + container.offsetHeight;
        const left = container.getBoundingClientRect().left + 40;
        const modalHeight = this.modalRef.offsetHeight;

        //if modal is to low and is overflowed move it up and move arrow to point on left
        if (top + modalHeight > window.innerHeight) {
          top = window.innerHeight - modalHeight - 40;
          this.setArrowElement(
            container.getBoundingClientRect().top - top + container.offsetHeight / 3,
          );
        }
        //else reset arrow
        else this.setArrowElement(null);

        this.modalRef.style.left = left + 'px';
        this.modalRef.style.top = top + 'px';
        this.modalRef.style.position = 'fixed';
      });
  }

  private moveModalInDOMandSaveRef() {
    this.modalRef = this.container?.nativeElement.querySelector('color-picker .color-picker');
    document.body.after(this.modalRef);
  }

  private setArrowElement(top: number | null) {
    const arrowElement: HTMLElement = this.modalRef.querySelector('.arrow');
    if (!top) {
      arrowElement.style.transform = '';
      arrowElement.style.left = '';
      arrowElement.style.top = '';
      return;
    }

    arrowElement.style.transform = 'rotate(-90deg)';
    arrowElement.style.left = '-16px';
    arrowElement.style.top = top + 'px';
  }

  onOpened() {
    this.opened$.next(true);
  }

  onClosed() {
    this.opened$.next(false);
  }

  ngOnDestroy() {
    this.modalRef?.remove();
  }
}
