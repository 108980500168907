import { SelectedSeriesModel } from '../../../../../pages/data/select-series/models/SelectedSeries.model';

export type ObisCodeCfgUnits = {
  id: number;
  obis: string[];
  units: string[];
  cfgUnitId: number[];
  label: string;
  baseUnit?: string;
};
export const obisCodeCfgUnits: ObisCodeCfgUnits[] = [
  {
    id: 1,
    obis: ['1-0:1.8.*', '1-0:2.8.*'],
    units: ['Wh', 'kWh', 'MWh', 'GWh', 'J', 'kJ', 'MJ'],
    cfgUnitId: [1, 2, 3, 4, 40, 41, 42],
    label: 'Active energy',
    baseUnit: 'kWh',
  },
  {
    id: 2,
    obis: ['1-0:3.8.*', '1-0:4.8.*'],
    units: ['kvarh', 'Mvarh', 'Gvarh'],
    cfgUnitId: [6, 7, 8],
    label: 'Blind energy',
    baseUnit: 'kvarh',
  },
  {
    id: 3,
    obis: ['1-0:9.8.*'],
    units: ['Vah', 'kVAh', 'MVAh', 'GVAh'],
    cfgUnitId: [10, 11, 12],
    label: 'Apparent energy',
    baseUnit: 'kVAh',
  },
  {
    id: 4,
    obis: [
      '1-0.1.7.*',
      '1-0.21.7.*',
      '1-0.41.7.*',
      '1-0.61.7.*',
      '1-0.2.7.*',
      '1-0.22.7.*',
      '1-0.42.7.*',
      '1-0.62.7.*',
    ],
    units: ['W', 'kW', 'MW', 'GW'],
    cfgUnitId: [13, 14, 15, 16],
    label: 'Effective power',
    baseUnit: 'W',
  },
  {
    id: 5,
    obis: [
      '1-0.3.7.*',
      '1-0.23.7.*',
      '1-0.43.7.*',
      '1-0.63.7.*',
      '1-0.4.7.*',
      '1-0.24.7.*',
      '1-0.44.7.*',
      '1-0.64.7.*',
    ],
    units: ['var', 'kvar', 'Mvar', 'Gvar'],
    cfgUnitId: [17, 18, 19, 20],
    label: 'Reactive power',
    baseUnit: 'kvar',
  },
  {
    id: 6,
    obis: ['1-0.9.7.*', '1-0.29.7.*', '1-0.49.7.*', '1-0.69.7.*'],
    units: ['VA', 'kVA', 'MVA', 'GVA'],
    cfgUnitId: [21, 22, 23, 24],
    label: 'Apparent power',
    baseUnit: 'kVA',
  },
  {
    id: 7,
    obis: ['1-0:32.7.*', '1-0:52.7.*', '1-0:72.7.*'],
    units: ['mV', 'V', 'kV'],
    cfgUnitId: [25, 26, 27],
    label: 'Voltage',
    baseUnit: 'V',
  },
  {
    id: 8,
    obis: ['1-0:11.7.*', '1-0:31.7.*', '1-0:51.7.*', '1-0:71.7.*'],
    units: ['mA', 'A', 'kA'],
    cfgUnitId: [28, 29, 30],
    label: 'Current',
    baseUnit: 'A',
  },
  {
    id: 9,
    obis: ['4-0:3.3.*', '5-0:3.3.*', '6-0:3.3.*'],
    units: ['l/s', 'm³/s', 'm³/h'],
    cfgUnitId: [55, 51, 53],
    label: 'Flow',
    baseUnit: 'm³/s',
  },
  {
    id: 10,
    obis: ['8-0:1.0.*', '9-0:1.0.*', '6-0:2.0.*', '5-0:2.0.*', '4-0:2.0.*'],
    units: ['hl', 'm³', 'dam³'],
    cfgUnitId: [50],
    label: 'Volume',
    baseUnit: 'm³',
  },
  {
    id: 11,
    obis: ['6-0:8.0.*'],
    units: ['W', 'kW', 'MW', 'GW'],
    cfgUnitId: [13, 14, 15, 16],
    label: 'Heat output',
    baseUnit: 'kW',
  },
  {
    id: 12,
    obis: ['6-0:1.0.*'],
    units: ['Wh', 'kWh', 'MWh', 'GWh', 'J', 'kJ', 'MJ'],
    cfgUnitId: [1, 2, 3, 4, 40, 41, 42],
    label: 'Heat energy',
    baseUnit: 'kWh',
  },
  {
    id: 13,
    obis: ['5-0:1.0.*'],
    units: ['Wh', 'kWh', 'MWh', 'GWh', 'J', 'kJ', 'MJ'],
    cfgUnitId: [1, 2, 3, 4, 40, 41, 42],
    label: 'Refrigeration energy',
    baseUnit: 'kWh',
  },
  {
    id: 14,
    obis: [],
    units: ['°C', '°F', 'K'],
    cfgUnitId: [37, 38, 39],
    label: 'Temperature',
  },
];

type ObisSeries = {
  [key: string]: any;
  cfg_obis_code?: string;
  cfg_unit?: number;
  unit?: string;
  unit_str?: string;
};

export function getObisCodeGroupForSeries(series: ObisSeries) {
  let unit = series.unit ?? series.unit_str;
  // if (!series.cfg_obis_code || series.cfg_obis_code.length == 0) return null;

  let obisGroup = obisCodeCfgUnits.find(
    (o) =>
      series.cfg_obis_code &&
      series.cfg_obis_code.length > 0 &&
      isObisCodeInArray(series.cfg_obis_code, o.obis),
  );
  if (obisGroup) return obisGroup;

  obisGroup = obisCodeCfgUnits.find(
    (o) => series.cfg_unit && o.cfgUnitId.includes(series.cfg_unit),
  );
  if (obisGroup) return obisGroup;

  obisGroup = obisCodeCfgUnits.find((o) => unit && o.units.includes(unit));
  if (obisGroup) return obisGroup;

  return null;
}

export function filterSeriesForObisGroup(
  obisCodeGroup: ObisCodeCfgUnits,
  seriesArr: SelectedSeriesModel[],
) {
  return seriesArr
    .map((o) => o.subsets)
    .flat()
    .filter(
      (o) =>
        isObisCodeInArray(o.cfg_obis_code, obisCodeGroup.obis) ||
        obisCodeGroup.cfgUnitId.includes(o.cfg_unit) ||
        obisCodeGroup.units.includes(o.unit),
    );
}

export function isObisCodeInArray(obisCode: string, obisArray: string[]): boolean {
  if (!obisCode || obisCode.length == 0) return false;
  const regexPatterns = obisArray.map(
    // eslint-disable-next-line security/detect-non-literal-regexp
    (obisPattern) => new RegExp(obisPattern.replace(/\*/g, `(\\d+|\\*)`).replace(/\./g, '\\.')),
  );

  return regexPatterns.some((pattern) => pattern.test(obisCode));
}
