import { Component, Input, OnDestroy } from '@angular/core';
import { debounceTime, filter, map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { TreeNodeService } from '../../TreeNodeService';
import { TreeNodeActionType } from '../../models/TreeNodeActionType';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { UserGuideDirective } from '../../../../../../shared/app-support/user-guide/user-guide.directive';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-toggle-expand',
  templateUrl: './toggle-expand.component.html',
  styleUrls: ['./toggle-expand.component.scss'],
  standalone: true,
  imports: [AsyncPipe, TranslateModule, UserGuideDirective, MatIcon, MatTooltip],
})
export class ToggleExpandComponent implements OnDestroy {
  private _treeNodeService: TreeNodeService;

  private unsubscribe$ = new Subject<void>();

  get treeNodeService(): TreeNodeService {
    return this._treeNodeService;
  }

  areNodesExpanded$: Observable<boolean>;

  @Input() set treeNodeService(value: TreeNodeService) {
    this._treeNodeService = value;

    this.areNodesExpanded$ = this.treeNodeService.nodeAction$.pipe(
      filter((a) => [TreeNodeActionType.ON_INIT, TreeNodeActionType.ON_EXPAND].includes(a.action)),
      map(() => this.areNodesExpanded()),
      startWith(false),
      debounceTime(300),
      takeUntil(this.unsubscribe$),
    );
  }

  @Input() buttonMode: boolean = false;

  toggleExpandAll() {
    const expand = !this.areNodesExpanded();
    this._treeNodeService.expandAll$.next(expand);
    //in case of bub when deleting children set expanded nodes as all expandable nodes
    if (expand == false) this._treeNodeService.expandedNodes.clear();
  }

  areNodesExpanded() {
    return Array.from(this._treeNodeService.expandableNodes).every((nodeId) =>
      this._treeNodeService.expandedNodes.has(nodeId),
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
