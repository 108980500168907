<div>
  <app-emu-list-input
    [value]="localeSessionsService.timezone"
    (optionSelected)="localeSessionsService.timezone = $event"
    [name]="'PROFILE.TIMEZONE'"
    (valueChange)="timezoneInput$.next($event)"
    [clearButtonHidden]="true"
    [filteredOptions$]="timezoneList$"
  >
    <ng-template let-item>
      <p class="device-name">
        {{item }}
      </p>
    </ng-template>
  </app-emu-list-input>
</div>
