import { Component, Input, isDevMode } from '@angular/core';
import { uplotDownloadImg } from '../utils/uplotDownloadImage';
import { uplotDownloadCsv } from '../utils/uplotDownloadCsv';
import { getNameForDownloadFile } from '../utils/uplotUtil';
import { uplotZoom } from '../utils/uplotZoom';
import { uplotToggleSeriesFill } from '../config/uplotSeriesConfiguration';
import { getScaleTypes } from '../utils/getScaleModes';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataDisplayDefaultRanges } from '../../../../models/DataDisplayDefaultRanges';
import { getDefinedRanges } from '../utils/getDefinedRanges';
import { isIntervalAvailableForRange, uplotGetScaleMode } from '../config/uplotScales';
import { DatePipe, NgIf, NgFor } from '@angular/common';
import { LocaleSessionService } from '../../../../services/localeSession.service';
import { FormatValuePipe } from '../../../../pipes/format-value.pipe';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DisabledDirective } from '../../../../directives/disabled.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DropdownComponent } from '../../../dropdown/dropdown.component';
import { DataRequestDto } from '../../../../../../api-main';
export type GraphButtonsOpts = {
  calcRangeInHours: any;
  currentRange: any;
  title: string;
  uplot: any;
  currentScale$: BehaviorSubject<DataRequestDto.ScaleEnum>;
  userDefinedRange$: BehaviorSubject<DataDisplayDefaultRanges>;
  refresh$: Subject<void>;
  showCustomRangesSettings: boolean;
};

@Component({
  selector: 'app-graph-buttons',
  templateUrl: './graph-buttons.component.html',
  styleUrls: ['./graph-buttons.component.scss'],
  standalone: true,
  imports: [
    DropdownComponent,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    DisabledDirective,
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    TranslateModule,
  ],
  providers: [DatePipe, FormatValuePipe],
})
export class GraphButtonsComponent {
  @Input({ required: true }) opts: GraphButtonsOpts;

  @Input({ required: true }) rangeInHours: number;

  public scaleTypes = getScaleTypes();

  public definedRanges = getDefinedRanges();

  UplotScaleModes = DataRequestDto.ScaleEnum;

  public get isFullScreen(): boolean {
    return document.fullscreenElement != null;
  }

  constructor(
    private datePipe: DatePipe,
    private localeService: LocaleSessionService,
    private formatValuePipe: FormatValuePipe,
    private translateService: TranslateService,
  ) {}

  //

  public downloadPng(): void {
    if (!this.opts.uplot) return;
    uplotDownloadImg(this.opts.uplot, this.opts.title, this.opts.currentRange);
  }

  public downloadCsv(): void {
    const formatDate = (date) => {
      return this.datePipe
        .transform(date, 'short', this.localeService.timezone, this.localeService.locale)
        .replace(',', '');
    };

    const formatDateUtc = (date) => {
      return this.datePipe
        .transform(date, 'short', 'UTC', this.localeService.locale)
        .replace(',', '');
    };

    if (!this.opts.uplot) return;
    uplotDownloadCsv(this.opts.uplot, {
      //TODO file name could be from taken template name
      fileName: getNameForDownloadFile(this.opts.title, this.opts.currentRange),
      range: this.opts.currentRange,
      formatDate: formatDate,
      formatDateUtc: formatDateUtc,
      formatValues: this.formatValuePipe.transform,
      translateService: this.translateService,
    });
  }

  public zoomPlot(zoomIn = true) {
    if (!this.opts.uplot) return;
    const mod = zoomIn ? 1 : -1;
    uplotZoom(this.opts.uplot, mod, this.opts.calcRangeInHours);
  }

  get uplotShowTooltip() {
    return this.opts.uplot?._userConfig.showTooltip;
  }

  set uplotShowTooltip(value: boolean) {
    if (!this.opts.uplot) return;
    this.opts.uplot._userConfig.showTooltip = value;
  }

  get uplotShowTooltipExt() {
    return this.opts.uplot?._userConfig.showTooltipExtData;
  }

  set uplotShowTooltipExt(value: boolean) {
    if (!this.opts.uplot) return;
    this.opts.uplot._userConfig.showTooltipExtData = value;
  }

  get seriesFilled() {
    return this.opts.uplot._userConfig.fillSeries;
  }

  set seriesFilled(value: boolean) {
    if (!this.opts.uplot) return;
    this.opts.uplot._userConfig.fillSeries = value;
    uplotToggleSeriesFill(this.opts.uplot, this.seriesFilled);
  }

  get uplotShowAvg() {
    return this.opts.uplot?._userConfig?.showAvgLines;
  }

  set uplotShowAvg(value: boolean) {
    if (!this.opts.uplot) return;
    this.opts.uplot._userConfig.showAvgLines = value;
    this.opts.uplot.redraw();
  }

  get uplotShowMin() {
    return this.opts.uplot?._userConfig?.showMinLines;
  }

  set uplotShowMin(value: boolean) {
    if (!this.opts.uplot) return;
    this.opts.uplot._userConfig.showMinLines = value;
    this.opts.uplot.redraw();
  }

  get uplotShowMax() {
    return this.opts.uplot?._userConfig?.showMaxLines;
  }

  set uplotShowMax(value: boolean) {
    if (!this.opts.uplot) return;
    this.opts.uplot._userConfig.showMaxLines = value;
    this.opts.uplot.redraw();
  }

  get isLogarithmicScale() {
    return this.opts.uplot?.scales?.y.distr == 3;
  }

  set isLogarithmicScale(value: boolean) {
    if (value) {
      this.opts.uplot.scales.y.distr = 3; //log
    } else {
      this.opts.uplot.scales.y.distr = 1; //linear
    }
    console.log('-> this.opts.uplot', this.opts.uplot);
    this.opts.uplot.redraw();
    // setTimeout(() => this.opts.uplot.setScale('x', this.opts.uplot.scales));
  }

  public getScaleString(scale: DataRequestDto.ScaleEnum): string {
    return `SCALE.${scale.toUpperCase()}`;
  }

  getRealCurrentScale() {
    return uplotGetScaleMode(this.rangeInHours);
  }

  public getRangeString(scale: DataDisplayDefaultRanges): string {
    return `UPLOT.RANGE.${scale.toUpperCase()}`;
  }

  public requestFullScreen() {
    this.opts.uplot.container.requestFullscreen();
  }

  public async exitFullScreen() {
    await document.exitFullscreen();
  }

  // private readonly SCALE_MODES_LENGTH = Object.keys(UplotScaleModes).length / 2 - 1;

  isScaleEnabled(scale: DataRequestDto.ScaleEnum = null): boolean {
    return isIntervalAvailableForRange(scale, this.rangeInHours);
  }

  protected readonly isDevMode = isDevMode;

  refreshGraph() {
    if (this.opts.refresh$) this.opts.refresh$.next();
  }

  refreshButtonAvailable() {
    return this.opts.uplot.loadingElement.isVisible();
  }
}
