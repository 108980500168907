/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GpListResponseItemDto {
  /**
   * Item identification
   */
  id: number;
  /**
   * Number of total users of this gp
   */
  members_count: number;
  /**
   * Number of invited users to this gp
   */
  invited_count: number;
  /**
   * Number of users which accepted invite to this gp
   */
  accepted_count: number;
  /**
   * Is active
   */
  is_active?: boolean;
  /**
   * Policy name
   */
  name: string;
  /**
   * Policy is valid from date. Could be null
   */
  dt_from: string;
  /**
   * Policy is valid to date. Could be null
   */
  dt_to: string;
}
