import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, EventType, Router } from '@angular/router';
import { filter, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type HandleQueryEvent = { key: string; value: string };
@Component({
  selector: 'app-handle-query',
  template: '',
  standalone: true,
})
export class HandleQueryComponent implements AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.router.events
      .pipe(
        filter((o) => o.type == EventType.NavigationEnd),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.handleOpenQuery();
      });
  }

  ngAfterViewInit() {
    this.handleOpenQuery();
  }

  @Output() query = new EventEmitter<HandleQueryEvent>();

  @Input() watchedKeys = [];

  private handleOpenQuery() {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      // console.log('-> params', params);
      const newParams = JSON.parse(JSON.stringify(params));
      const keys = Object.keys(params).filter((o) => this.watchedKeys.includes(o));
      if (keys.length == 0) return;
      keys.forEach((k) => {
        delete newParams[k as string];
        this.event({ key: k, value: params[k as string] });
      });

      this.router
        .navigate([], { relativeTo: this.route, queryParams: newParams })
        .catch(console.error);
    });
  }

  private event(event: { key: string; value: string }) {
    this.query.emit(event);
  }
}
