export function getMatrix(element) {
  const values = element.style.transform.split(/\w+\(|\);?/);
  const transform = values[1]?.split(/,\s?/g).map((v) => parseInt(v)) ?? [];

  return {
    x: transform[0] ?? 0,
    y: transform[1] ?? 0,
    z: transform[2] ?? 0,
  };
}
