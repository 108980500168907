import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, retry, throwError, timer } from 'rxjs';

import { AlertService } from '../services/alert.service';
@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private translate: TranslateService,
    private alert: AlertService,
    private ngZone: NgZone,
  ) {}

  //If some request has their own retry mechanism, this could lead to big count of requests
  //Also make sure that requests are cancelled after leaving page,
  // because otherwise it could lead to displaying errors from previous pages

  //count of additional retry request
  private readonly retryCount = 2;

  private readonly delayBetweenRetries = 2000;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retry({
        count: this.retryCount,
        delay: (err) => this.shouldRetry(err), // () => timer(this.delayBetweenRetries),
      }),
      catchError((error) => {
        this.handleError(error);
        return throwError(() => error);
      }),
    );
  }

  private shouldRetry(error: HttpErrorResponse): Observable<number> {
    // const excludedCodes = [400, 401, 402, 404, 409];
    // if (excludedCodes.includes(error.status))

    //dont retry 4XX errors
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    if (error.status >= 400 && error.status < 500) throw error;

    return timer(this.delayBetweenRetries);
  }

  private handleError(error) {
    const { message } = error?.error ?? error?.message;
    if (!error.status) {
      this.displayError(message ? message : this.translate.instant('ERROR.CONNECTION'));
      return;
    }
    switch (error.status) {
      case 400: {
        this.displayError(message ? message : this.translate.instant('ERROR.BAD_REQUEST'));
        break;
      }
      case 401: {
        this.displayError(message ? message : this.translate.instant('ERROR.INVALID_AUTH'));
        break;
      }
      case 403: {
        this.displayError(message ? message : this.translate.instant('ERROR.NOT_AUTHORIZED'));
        break;
      }
      case 404: {
        this.displayError(message ? message : this.translate.instant('ERROR.NO_RECORDS'));
        break;
      }
      case 500: {
        this.displayError(message ? message : this.translate.instant('ERROR.GENERAL'));
        break;
      }
      case 504: {
        const mess = message
          ? message
          : this.translate.instant('ERRORS.TIMEOUT') === 'ERRORS.TIMEOUT'
            ? 'Communication timeout '
            : this.translate.instant('ERRORS.TIMEOUT');

        this.displayError(mess);
        break;
      }
      default: {
        if (error?.error.message) {
          this.displayError(error.error.message);
          return;
        }
        if (error?.error.statusText) {
          this.displayError(error.statusText);
        }
      }
    }
  }

  private displayError(message: string) {
    this.ngZone.run(() => {
      this.alert.error(message);
    });
  }
}
