import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget.component';
import { DashboardItemTypeValueDisplay } from '../../models/DashboardItemTypeValueDisplay';
import { BehaviorSubject, debounceTime, interval, takeUntil, takeWhile } from 'rxjs';
import { DashboardService } from '../../services/dashboard.service';
import { LocaleSessionService } from '../../../../shared/services/localeSession.service';
import { DataServiceProvider } from '../../../data/api-data.service';
import { SingleDataItemDto, SingleDataResponseDto } from '../../../../../api-main';
import {
  getModeString,
  ValueDisplayMode,
} from '../../widgets-settings/dashboard-value-display-settings/ValueDisplayMode.model';
import { getRangeFromDataDisplayDefaultRanges } from '../../../../shared/utils/getRangeFromDataDisplayDefaultRanges';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SingleValueRequestService } from '../../services/single-value-request.service';
import { FitTextDirective } from '../../../../shared/directives/fit-text.directive';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AsyncPipe, NgIf } from '@angular/common';
import { FormatValuePipe } from '../../../../shared/pipes/format-value.pipe';
import { DateDisplayComponent } from '../../../../shared/components/date-display/date-display.component';
import { DataDisplayDefaultRanges } from '../../../../shared/models/DataDisplayDefaultRanges';
import { cfgUnits } from '../../../../shared/components/graph-uplot/graph/models/CfgUnits';

@Component({
  selector: 'app-dashboard-value-display',
  templateUrl: './dashboard-value-display.component.html',
  styleUrls: ['./dashboard-value-display.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatProgressBarModule,
    FitTextDirective,
    AsyncPipe,
    TranslateModule,
    FormatValuePipe,
    DateDisplayComponent,
  ],
})
export class DashboardValueDisplayComponent extends BaseWidgetComponent implements OnInit {
  protected override detach: boolean = false;

  constructor(
    private dataService: DataServiceProvider,
    public localeSession: LocaleSessionService,
    private translate: TranslateService,
    protected override dashboardService: DashboardService,
    protected override cd: ChangeDetectorRef,
    private singleValueService: SingleValueRequestService,
  ) {
    super(dashboardService, cd);
  }

  public value: number;

  public date: Date;

  public unit: string;

  savedResponse: SingleDataResponseDto;

  loading = new BehaviorSubject(false);

  @Input() dataInput: DashboardItemTypeValueDisplay;

  override get data(): DashboardItemTypeValueDisplay {
    if (this.dataInput) return this.dataInput;
    return this.item?.data?.valueDisplay ?? <DashboardItemTypeValueDisplay>{};
  }

  get editMode(): boolean {
    return this.dashboardService.editMode$.value;
  }

  get isDataValid(): boolean {
    return this.data.loggerId != null && this.data.deviceId != null && this.data.valueId != null;
  }

  ngOnInit() {
    this.observeInterval();
    this.detectChangesOnLoading();
    this.observeData();
    this.dashboardService.editMode$.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      if (value) this.watchData();
    });
    this.requestData();
  }

  get valueString(): number {
    let value = this.value;
    const unit = cfgUnits.get(this.data.cfg_unit);
    if (!unit) {
      console.error('No unit found');
      return 0;
    }
    if (unit.factor === 0) {
      console.error('Incorrect unit factor');
      return 0;
    }
    return value / unit.factor ?? 1;
    // if (this.data.unitPrefix && this.data.unit) {
    //   const prefix = SIPrefix.find((o) => o.prefix == this.data.unitPrefix);
    //   if (prefix) value = value * prefix.multiply;
    //   else this.data.unitPrefix = null;
    // }
    // return value;
  }

  get header() {
    if (this.data.header && this.data.header != '') return this.data.header;
    return this.savedResponse?.description != null
      ? `${this.savedResponse?.description} (${this.translate.instant(
          getModeString(this.data.mode),
        )})`
      : '';
  }

  requestData() {
    if (!this.isDataValid) return;
    this.loading.next(true);
    this.singleValueService.sendRequest(this.getRequest(), this.item.id);
  }

  private observeInterval() {
    this.singleValueService.refresh$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.requestData());
  }

  private observeData() {
    this.singleValueService
      .getResponse$(this.item.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        if (response != null) this.updateValues(response);
        this.loading.next(false);
        this.itemLoaded();
      });
  }

  private detectChangesOnLoading() {
    this.loading
      .pipe(takeUntil(this.unsubscribe), debounceTime(100))
      .subscribe(() => this.detectChanges());
  }

  private getRequest(): SingleDataItemDto {
    // const range =
    //   this.data.rangeMode != DataDisplayDefaultRanges.CUSTOM
    //     ? getRangeFromDataDisplayDefaultRanges(this.data.rangeMode)
    //     : this.data.range;
    this.setupRange();
    return {
      logger_id: this.data.loggerId,
      value_id: this.data.valueId,
      range_start: this.data.range.min / 1e3,
      range_end: this.data.range.max / 1e3,
      mode: <any>ValueDisplayMode[this.data.mode].toLowerCase(),
      consumption: this.data.consumption,
    };
  }

  private setupRange() {
    if (this.data.rangeMode == null) return;

    if (this.data.rangeMode !== DataDisplayDefaultRanges.CUSTOM) {
      const range = getRangeFromDataDisplayDefaultRanges(this.data.rangeMode);
      this.data.range = Object.assign({}, range);
      this.data.range.min *= 1e3;
      this.data.range.max *= 1e3;
    } else {
      const range = this.data.range;
      this.data.range = Object.assign({}, range);
    }
    this.detectChanges();
  }

  private watchData() {
    let valueId = this.data.valueId;
    let mode = this.data.mode;
    let rangeMode = this.data.rangeMode;
    let consumption = this.data.consumption;
    let range = this.data.range;
    interval(300)
      .pipe(takeUntil(this.unsubscribe))
      .pipe(takeWhile(() => this.editMode, true))
      .subscribe(() => {
        if (this.data.valueId == null) {
          this.value = null;
          return;
        }
        if (
          this.data.valueId != valueId ||
          this.data.mode != mode ||
          this.data.rangeMode != rangeMode ||
          this.data.consumption != consumption ||
          (this.data.rangeMode == DataDisplayDefaultRanges.CUSTOM &&
            (this.data.range.min != range.min || this.data.range.max != range.max))
        ) {
          valueId = this.data.valueId;
          mode = this.data.mode;
          rangeMode = this.data.rangeMode;
          consumption = this.data.consumption;
          range = this.data.range;
          this.setupRange();
          this.requestData();
        }
      });
  }

  private updateValues(value: SingleDataResponseDto) {
    // this.date = null;
    // this.cd.detectChanges();
    // console.log('=>(dashboard-value-display.component.ts:205) value', value);
    this.savedResponse = value;
    this.savedResponse.start *= 1000;
    this.savedResponse.end *= 1000;
    const val = value.value != null ? Number(value.value) : null;
    this.value = !isNaN(val) ? val : null;
    this.unit = value.unit && value.unit.toLowerCase() != 'none' ? value.unit : '';
    this.date = value.time ? new Date(value.time) : null;
    // this.data.unit = this.unit;
    this.cd.detectChanges();
  }
}
