/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GpPrivilegesDto } from './gpPrivilegesDto';

export interface GpPrivilegesListResponseDto {
  /**
   * HTTP response code
   */
  code: number;
  /**
   * Response list
   */
  data: Array<GpPrivilegesDto>;
  /**
   * Has access
   */
  has_access: boolean;
}
