export enum ValueDisplayMode {
  'LAST',
  'AVG',
  'MIN',
  'MAX',
  'TOTAL',
}

let displayModes = [];
export function getValueModes() {
  if (displayModes.length === 0) setModes();

  return displayModes;
}

function setModes() {
  for (let enumMember in ValueDisplayMode) {
    const isValueProperty = Number(enumMember) >= 0;
    if (isValueProperty) {
      displayModes.push(Number(enumMember));
    }
  }
}

export function getModeString(scale: number): string {
  return `DASHBOARD.VALUE_MODE.${ValueDisplayMode[Number(scale)].toUpperCase()}`;
}
