<app-modal
  [title]="getTitle()"
  [saveButtonName]="getSaveButtonName()"
  [cancelButtonName]="getCancelButtonName()"
  (save)="add()"
  [shouldSaveBeDisabled]="form.invalid"
>
  <ng-container body>
    <main *ngIf="data">
      <form [formGroup]="form">
        <app-emu-input formControlName="value" [name]="label"></app-emu-input>
      </form>
    </main>
  </ng-container>
</app-modal>
