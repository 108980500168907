/**
 * EMU Invoice API
 * EMU Invoice API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface InvoiceDataRequest {
  /**
   * id
   */
  readonly customer_id: number;
  /**
   * id
   */
  readonly price_list_tree_id: number;
  /**
   * id
   */
  readonly range_start: string;
  /**
   * id
   */
  readonly range_end: string;
}
