import { DashboardItem } from '../models/DashboardItem';
import { getMinimalSizeForItemType } from './getMinimalSizeForItemType';

export function setMinimalSizeForItem(item: DashboardItem) {
  const minSize = getMinimalSizeForItemType(item.type);

  item.minItemCols = minSize.cols;
  item.minItemRows = minSize.rows;

  item.rows = item.rows < item.minItemRows ? item.minItemRows : item.rows;
  item.cols = item.cols < item.minItemCols ? item.minItemCols : item.cols;
}
