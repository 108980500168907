import { getTimezoneOffsetInHours } from '../../pages/profile/profile/timezoneList';
import { LocaleSessionService } from '../services/localeSession.service';

export function formatDateForRequest(date: Date | null): string {
  if (!date) return null;
  return new Date(date).toUTCString();
}

export function formatUplotDateForRequest(
  date: number,
  localeSession: LocaleSessionService,
): number {
  const timestamp = new Date(date * 1e3);
  timestamp.setHours(
    timestamp.getHours() -
      timestamp.getTimezoneOffset() / 60 -
      getTimezoneOffsetInHours(localeSession.timezone, timestamp),
  );
  return timestamp.getTime() / 1e3;
}
