import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BehaviorSubject,
  bufferWhen,
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  Observable,
  of,
  startWith,
  switchMap,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { LayoutService } from '../../../layout/layout.service';
import { EmuListInputComponent } from '../../components/inputs/emu-list-input/emu-list-input.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { LocaleSessionService } from '../../services/localeSession.service';
import { TimezoneChangeDebugComponent } from './timezone-change-debug/timezone-change-debug.component';
import { MatDialog } from '@angular/material/dialog';
import { WaitService } from '../../services/wait.service';
import { ConfirmDeleteDialogComponent } from '../../components/dialogs/confirm-delete-dialog/confirm-delete-dialog.component';

//DEBUG TOOL, click 5 times alt+ctrl+g to open
//Add useful actions to extend functionality
@Component({
  selector: 'app-debug',
  standalone: true,
  imports: [
    CommonModule,
    CdkDrag,
    MatIconModule,
    EmuListInputComponent,
    TranslateModule,
    MatButtonModule,
  ],
  templateUrl: './debug.component.html',
  styleUrl: './debug.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugComponent {
  private readonly actions: ReadonlyArray<string> = [
    'reset_selected_item',
    'log_tree_node_service',
    'log_layoutService',
    'log_translateService',
    'log_localeService',
    'change_timezone',
    'waitServiceStart',
    'waitServiceStop',
    'turnOffDeleteConfirmation',
  ];

  actionInput$ = new BehaviorSubject<string>('');

  filteredActions$: Observable<string[]>;

  opened = signal(false);

  protected readonly of = of;

  constructor(
    private layoutService: LayoutService,
    private translateService: TranslateService,
    private localeService: LocaleSessionService,
    private dialog: MatDialog,
    private waitService: WaitService,
    // private alertService: AlertService,
  ) {
    const keyClicked$ = fromEvent(document, 'keyup').pipe(
      filter((keyEvent: KeyboardEvent) => {
        return keyEvent.key === 'g' && keyEvent.ctrlKey && keyEvent.altKey;
      }),
    );
    keyClicked$
      .pipe(
        bufferWhen(() => keyClicked$.pipe(debounceTime(200))),
        map((list) => list.length),
        filter((length) => length >= 5),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        console.log('Opened debug tool');
        this.opened.set(true);
      });

    this.filteredActions$ = this.actionInput$.pipe(
      startWith(''),
      distinctUntilChanged(),
      switchMap((val) => {
        return of(this.actions.filter((action) => action.includes(val)));
      }),
    );
  }

  actionButtonTriggered() {
    const action = this.actionInput$.value;
    try {
      console.log(`%cEXECUTING ACTION: ${action}`, 'color:yellow');
      this.executeAction(action);
      console.log(`%cEXECTUING ACTION "${action}" SUCCEED`, 'color:lime');
    } catch (e) {
      console.log(`%cFAILED ACTION "${action}": ${e}`, 'color:red');
      // console.error(`FAILED EXECTUING ACTION "${action}":
      // ${e}`);
    }
  }

  private executeAction(action: string) {
    switch (action) {
      case 'reset_selected_item': {
        this.layoutService.selectedItem = null;
        break;
      }
      case 'log_tree_node_service': {
        console.log('treeNodeService:', this.layoutService.treeNodeService);
        break;
      }
      case 'turnOffDeleteConfirmation': {
        ConfirmDeleteDialogComponent.turnOffConfirmation = true;
        break;
      }

      case 'log_layoutService': {
        console.log('layoutService:', this.layoutService);
        break;
      }
      case 'log_translateService': {
        console.log('translateService:', this.translateService);
        break;
      }
      case 'log_localeService': {
        console.log('localeService:', this.localeService);
        break;
      }

      case 'change_timezone': {
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        this.dialog.open(TimezoneChangeDebugComponent, {});
        break;
      }
      case 'waitServiceStart': {
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        this.waitService.start();
        break;
      }
      case 'waitServiceStop': {
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        this.waitService.stop();
        break;
      }

      default: {
        throw new Error(`Action doesn't exist!`);
      }
    }
  }
}
