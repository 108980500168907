import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TreeService } from '../../tree.service';
import { AlertService } from '../../../../shared/services/alert.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { WaitService } from '../../../../shared/services/wait.service';
import { BehaviorSubject, filter, finalize, of, switchMap, take } from 'rxjs';
import { TreeFormIcon } from './tree-form-icon-select/TreeFormIcon';
import { TreeFormData } from './TreeFormData';
import { ItemTreeDto, TreeItemTypeListItemDto } from '../../../../../api-main';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getDefaultNodeName } from './tree-form-name-input/getDefaultNodeName';
import { getTreeNodePayload } from './getTreeNodePayload';
import { TreeNode } from '../tree-async/models/TreeNode';
import { TreeFormIconSelectComponent } from './tree-form-icon-select/tree-form-icon-select.component';
import { TreeFormNameInputComponent } from './tree-form-name-input/tree-form-name-input.component';
import { ModalComponent } from '../../../../shared/components/dialogs/modal/modal.component';
import { TreeDialogMode } from './TreeDialogMode';
import { TreeFormSelectNodeTypeComponent } from './tree-form-select-node-type/tree-form-select-node-type.component';
import { TypeMetadataComponent } from './type-metadata/type-metadata.component';

enum FormStages {
  'TYPE',
  'NAME_AND_ICON',
  'TYPE_METADATA',
  // 'ICON',
}
@Component({
  selector: 'app-tree-node-form',
  templateUrl: './tree-node-form.component.html',
  styleUrl: './tree-node-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalComponent,
    TreeFormSelectNodeTypeComponent,
    TreeFormNameInputComponent,
    TreeFormIconSelectComponent,
    TranslateModule,
    TypeMetadataComponent,
  ],
})
export class TreeNodeFormComponent {
  formStage: FormStages = 0;

  data: TreeFormData = {
    typeSelected$: new BehaviorSubject<TreeItemTypeListItemDto>(null),
    names: [],
    icon$: new BehaviorSubject<TreeFormIcon>(null),
    item: null,
    mode: null,
    metadata: null,
  };

  private unsubscribe = takeUntilDestroyed();

  blockInput = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: {
      mode: TreeDialogMode;
      item: TreeNode;
    },
    private treeService: TreeService,
    private matDialogRef: MatDialogRef<TreeNodeFormComponent>,
    private alertService: AlertService,
    private translate: TranslateService,
    private waitService: WaitService,
    private cdr: ChangeDetectorRef,
  ) {
    this.data.item = data.item;
    this.data.mode = data.mode;

    this.data.typeSelected$
      .pipe(
        filter((o) => !!o),
        take(1),
      )
      .subscribe(() => {
        this.formStage = FormStages.TYPE + 1;
        this.cdr.detectChanges();
      });
  }

  isEdit() {
    return this.data.mode == TreeDialogMode.Edit;
  }

  getTitle(): string {
    switch (this.data.mode) {
      case TreeDialogMode.CreateRoot:
        return 'TREE.CREATE_NEW_NODE';
      case TreeDialogMode.Create:
        return `${this.translate.instant('TREE.CREATE_NEW_NODE_FOR')} ${
          this.data.item?.item_name ?? ''
        }`;
      case TreeDialogMode.Edit:
        return `${this.translate.instant('TREE.EDITING_NODE')} ${this.data.item?.item_name ?? ''}`;
      default:
        return '';
    }
  }

  getSaveButtonName(): string {
    if (this.getNextStage() != null) return 'DIALOG.NEXT';

    return this.data.mode == TreeDialogMode.Edit
      ? 'DIALOG.BUTTONS.SAVE'
      : 'CREATE_DIALOG.BUTTONS.CREATE';
  }

  getCancelButtonName(): string {
    if (!this.isFirstStage()) return 'BUTTON.BACK';
    return 'BUTTON.CANCEL';
  }

  onCancelButtonClick() {
    if (!this.isFirstStage()) {
      this.formStage--;
      return;
    }
    this.matDialogRef.close(null);
  }

  isNextAvailable(): boolean {
    switch (this.formStage) {
      case FormStages.TYPE:
        return this.data.typeSelected$.value != null;
      case FormStages.NAME_AND_ICON:
        const atLeastOneNameEntered = this.data.names.some((o) => o.name && o.name.length > 0);
        const iconSelected = this.data.icon$.value != null;
        return (atLeastOneNameEntered || getDefaultNodeName(this.data) != null) && iconSelected;
      case FormStages.TYPE_METADATA:
        return (
          this.data.metadata?.filter((o) => o.value == null || o.value.length == 0)?.length == 0
        );
    }
  }

  private getNextStage() {
    switch (this.formStage) {
      case FormStages.TYPE:
        return FormStages.NAME_AND_ICON;
      case FormStages.NAME_AND_ICON:
        const hasMetadataData = false; //this.data.typeSelected$.value?.type_metadata?.length > 0;
        return hasMetadataData && !this.isEdit() ? FormStages.TYPE_METADATA : null;
      case FormStages.TYPE_METADATA:
        return null;
    }
  }

  onNextButtonClick() {
    const nextStage = this.getNextStage();

    if (nextStage != null) {
      this.formStage = nextStage;
      return;
    }
    this.onSave();
  }

  update() {
    this.cdr.detectChanges();
  }

  private setUnsetNames() {
    const defaultName = getDefaultNodeName(this.data);
    this.data.names.forEach((name) => {
      if (!name.name || name.name.length == 0) name.name = defaultName;
    });
  }

  private isFirstStage() {
    return (
      this.formStage == 0 ||
      (this.formStage == FormStages.NAME_AND_ICON && this.data.mode == TreeDialogMode.Edit)
    );
  }

  private onSave() {
    this.waitService.start();
    this.blockInput = true;
    this.setUnsetNames();

    const payload = getTreeNodePayload(this.data);
    // console.log('-> payload', payload);
    // return;
    this.treeService
      .postItemTree(payload)
      .pipe(
        finalize(() => {
          this.waitService.stop();
          this.blockInput = false;
        }),
        switchMap((res: { id: number; code: number; item_id: number }) => {
          return of({ id: res.id, item_id: res.item_id });
        }),
        switchMap((data) => this.getTree(data.id)),
        this.unsubscribe,
      )
      .subscribe({
        next: (res: ItemTreeDto) => {
          // console.log('-> res', res);
          const newNode: TreeNode = {
            ...res,
            role: 'Edit',
            id: res.id,
            item_name: res.items.find((o) => o.lng == this.translate.currentLang)?.name,
            is_authorized: true,
            is_end_type: this.data.typeSelected$.value.end_type,
            ...{ refresh: !!payload.metadata },
          };

          this.matDialogRef.close(newNode);
          this.alertService.info(this.translate.instant('DIALOG.SAVED'));
        },
        error: (error) => {
          if (typeof error == 'string') this.alertService.error(error);
          else if (error.error?.message) this.alertService.error(error.error.message);
          else console.error('-> error', error);
        },
      });
  }

  private getTree(id: number) {
    return this.treeService.getTree(id);
  }

  protected readonly FormStages = FormStages;
}
