<ng-container *ngIf="data">
  <div class="img-container">
    <!--    <img *ngIf="data.url" ngSrc="{{ data.url }}" alt="img"-->
    <!--         [style.border-radius]="data.borderRadius + 'px'" fill />-->

    <img
      *ngIf="data.url"
      src="{{ data.url }}"
      (load)="onImageLoaded()"
      alt="img"
      [style.border-radius]="data.borderRadius + 'px'"
    />
  </div>
</ng-container>
