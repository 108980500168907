import { TranslateService } from '@ngx-translate/core';

export function getRelativeDateString(last: Date, translate: TranslateService): string {
  const now = Date.now();
  const lastMilliseconds = last.getTime();
  const relativeDate = dhm(now - lastMilliseconds);
  const rtf = new Intl.RelativeTimeFormat(translate.currentLang, {
    style: 'long',
    numeric: 'always',
  });
  return rtf.format(-relativeDate.value, relativeDate.unit as Intl.RelativeTimeFormatUnit);
}

function dhm(ms) {
  const weeks = Math.floor(ms / (7 * 24 * 60 * 60 * 1000));
  if (weeks !== 0) return { value: weeks, unit: 'week' };

  const weeksMs = ms % (7 * 24 * 60 * 60 * 1000);
  const days = Math.floor(weeksMs / (24 * 60 * 60 * 1000));
  if (days !== 0) return { value: days, unit: 'day' };

  const daysMs = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysMs / (60 * 60 * 1000));
  if (hours !== 0) return { value: hours, unit: 'hour' };

  const hoursMs = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursMs / (60 * 1000));
  if (minutes !== 0) return { value: minutes, unit: 'minute' };

  const minutesMs = ms % (60 * 1000);
  const sec = Math.floor(minutesMs / 1000);
  return { value: sec, unit: 'second' };
}
