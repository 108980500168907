import { DeviceValuesDto } from '../../../api-main';

export function sortByObisCode(arr: DeviceValuesDto[]) {
  return arr.sort(function (a, b) {
    if (a.cfg_obis_code && !b.cfg_obis_code) return -1;
    if (!a.cfg_obis_code && b.cfg_obis_code) return 1;

    const aObisIndex = sortingList.indexOf(a.cfg_obis_code);
    const bObisIndex = sortingList.indexOf(b.cfg_obis_code);
    if (aObisIndex >= 0 && bObisIndex >= 0) {
      const obisSort = sortingList.indexOf(a.cfg_obis_code) - sortingList.indexOf(b.cfg_obis_code);
      if (obisSort != 0) return obisSort;
    }

    const obisDefaultSort = a.cfg_obis_code?.localeCompare(b.cfg_obis_code, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
    if (obisDefaultSort != 0) return obisDefaultSort;

    const tariffA = Number(a.cfg_tariff ?? a.tariff);
    const tariffB = Number(b.cfg_tariff ?? b.tariff);
    if (tariffA && tariffB && !isNaN(tariffA) && !isNaN(tariffB)) {
      const tariffSort = tariffA - tariffB;
      if (tariffSort != 0) return tariffSort;
    }

    const phaseA = Number(a.cfg_phase);
    const phaseB = Number(b.cfg_phase);
    if (phaseA && phaseB && !isNaN(phaseA) && !isNaN(phaseB)) {
      const phaseSort = phaseA - phaseB;
      if (phaseSort != 0) return phaseSort;
    }
    return 0;
  });
}

const sortingList = [
  '1-0:1.8.0',
  '1-0:1.8.1',
  '1-0:1.8.2',
  '1-0:1.8.3',
  '1-0:1.8.4',
  '1-0:2.8.0',
  '1-0:2.8.1',
  '1-0:2.8.2',
  '1-0:2.8.3',
  '1-0:2.8.4',
  '1-0:3.8.0',
  '1-0:3.8.1',
  '1-0:3.8.2',
  '1-0:3.8.3',
  '1-0:3.8.4',
  '1-0:4.8.0',
  '1-0:4.8.1',
  '1-0:4.8.2',
  '1-0:4.8.3',
  '1-0:4.8.4',
  '1-0:9.8.0',
  '1-0:9.8.1',
  '1-0:9.8.2',
  '1-0:9.8.3',
  '1-0:9.8.4',
  '8-0:1.0.0',
  '8-0:1.0.1',
  '8-0:1.0.2',
  '8-0:1.0.3',
  '8-0:1.0.4',
  '9-0:1.0.0',
  '9-0:1.0.1',
  '9-0:1.0.2',
  '9-0:1.0.3',
  '9-0:1.0.4',
  '6-0:2.0.0',
  '6-0:2.0.1',
  '6-0:2.0.2',
  '6-0:2.0.3',
  '6-0:2.0.4',
  '5-0:2.0.0',
  '5-0:2.0.1',
  '5-0:2.0.2',
  '5-0:2.0.3',
  '5-0:2.0.4',
  '4-0:2.0.0',
  '4-0:2.0.1',
  '4-0:2.0.2',
  '4-0:2.0.3',
  '4-0:2.0.4',
  '6-0:8.0.0',
  '6-0:8.0.1',
  '6-0:8.0.2',
  '6-0:8.0.3',
  '6-0:8.0.4',
  '6-0:1.0.0',
  '6-0:1.0.1',
  '6-0:1.0.2',
  '6-0:1.0.3',
  '6-0:1.0.4',
  '5-0:1.0.0',
  '5-0:1.0.1',
  '5-0:1.0.2',
  '5-0:1.0.3',
  '5-0:1.0.4',
  '1-0.1.7.0',
  '1-0.1.7.1',
  '1-0.1.7.2',
  '1-0.1.7.3',
  '1-0.1.7.4',
  '1-0.21.7.0',
  '1-0.21.7.1',
  '1-0.21.7.2',
  '1-0.21.7.3',
  '1-0.21.7.4',
  '1-0.41.7.0',
  '1-0.41.7.1',
  '1-0.41.7.2',
  '1-0.41.7.3',
  '1-0.41.7.4',
  '1-0.61.7.0',
  '1-0.61.7.1',
  '1-0.61.7.2',
  '1-0.61.7.3',
  '1-0.61.7.4',
  '1-0.2.7.0',
  '1-0.2.7.1',
  '1-0.2.7.2',
  '1-0.2.7.3',
  '1-0.2.7.4',
  '1-0.22.7.0',
  '1-0.22.7.1',
  '1-0.22.7.2',
  '1-0.22.7.3',
  '1-0.22.7.4',
  '1-0.42.7.0',
  '1-0.42.7.1',
  '1-0.42.7.2',
  '1-0.42.7.3',
  '1-0.42.7.4',
  '1-0.62.7.0',
  '1-0.62.7.1',
  '1-0.62.7.2',
  '1-0.62.7.3',
  '1-0.62.7.4',
  '1-0.3.7.0',
  '1-0.3.7.1',
  '1-0.3.7.2',
  '1-0.3.7.3',
  '1-0.3.7.4',
  '1-0.23.7.0',
  '1-0.23.7.1',
  '1-0.23.7.2',
  '1-0.23.7.3',
  '1-0.23.7.4',
  '1-0.43.7.0',
  '1-0.43.7.1',
  '1-0.43.7.2',
  '1-0.43.7.3',
  '1-0.43.7.4',
  '1-0.63.7.0',
  '1-0.63.7.1',
  '1-0.63.7.2',
  '1-0.63.7.3',
  '1-0.63.7.4',
  '1-0.4.7.0',
  '1-0.4.7.1',
  '1-0.4.7.2',
  '1-0.4.7.3',
  '1-0.4.7.4',
  '1-0.24.7.0',
  '1-0.24.7.1',
  '1-0.24.7.2',
  '1-0.24.7.3',
  '1-0.24.7.4',
  '1-0.44.7.0',
  '1-0.44.7.1',
  '1-0.44.7.2',
  '1-0.44.7.3',
  '1-0.44.7.4',
  '1-0.64.7.0',
  '1-0.64.7.1',
  '1-0.64.7.2',
  '1-0.64.7.3',
  '1-0.64.7.4',
  '1-0.9.7.0',
  '1-0.9.7.1',
  '1-0.9.7.2',
  '1-0.9.7.3',
  '1-0.9.7.4',
  '1-0.29.7.0',
  '1-0.29.7.1',
  '1-0.29.7.2',
  '1-0.29.7.3',
  '1-0.29.7.4',
  '1-0.49.7.0',
  '1-0.49.7.1',
  '1-0.49.7.2',
  '1-0.49.7.3',
  '1-0.49.7.4',
  '1-0.69.7.0',
  '1-0.69.7.1',
  '1-0.69.7.2',
  '1-0.69.7.3',
  '1-0.69.7.4',
  '1-0:11.7.0',
  '1-0:11.7.1',
  '1-0:11.7.2',
  '1-0:11.7.3',
  '1-0:11.7.4',
  '1-0:31.7.0',
  '1-0:31.7.1',
  '1-0:31.7.2',
  '1-0:31.7.3',
  '1-0:31.7.4',
  '1-0:51.7.0',
  '1-0:51.7.1',
  '1-0:51.7.2',
  '1-0:51.7.3',
  '1-0:51.7.4',
  '1-0:71.7.0',
  '1-0:71.7.1',
  '1-0:71.7.2',
  '1-0:71.7.3',
  '1-0:71.7.4',
  '1-0:32.7.0',
  '1-0:32.7.1',
  '1-0:32.7.2',
  '1-0:32.7.3',
  '1-0:32.7.4',
  '1-0:52.7.0',
  '1-0:52.7.1',
  '1-0:52.7.2',
  '1-0:52.7.3',
  '1-0:52.7.4',
  '1-0:72.7.0',
  '1-0:72.7.1',
  '1-0:72.7.2',
  '1-0:72.7.3',
  '1-0:72.7.4',
  '4-0:3.3.0',
  '4-0:3.3.1',
  '4-0:3.3.2',
  '4-0:3.3.3',
  '4-0:3.3.4',
  '5-0:3.3.0',
  '5-0:3.3.1',
  '5-0:3.3.2',
  '5-0:3.3.3',
  '5-0:3.3.4',
  '6-0:3.3.0',
  '6-0:3.3.1',
  '6-0:3.3.2',
  '6-0:3.3.3',
  '6-0:3.3.4',
];
