<div *ngIf="organization() && loaded()" class="organization-container">
  <div class="logo-container" [matTooltip]="organization().company_name" (click)="openModal()">
    <img
      *ngIf="organization().company_logo; else defaultIcon"
      class="logo"
      [src]="organization().company_logo"
      alt=" "
      (error)="failedToLoadImg($event)"
    />
  </div>
  <!--  <div class="open-button" (click)="onClick()" [matTooltip]="'ORGANIZATION.SELECT_ORGANIZATION'|translate"></div>-->
  <mat-icon
    class="open-button"
    (click)="openModal()"
    [matTooltip]="'ORGANIZATION.SELECT_ORGANIZATION' | translate"
  >
    expand_more
  </mat-icon>
</div>
<div *ngIf="organization() == null && loaded()" class="organization-container">
  <div
    class="logo-container"
    [matTooltip]="'ORGANIZATION.SELECT_ORGANIZATION' | translate"
    (click)="openModal()"
  >
    <!--    <div class="logo" ></div>-->
    <ng-container *ngTemplateOutlet="noOrganization"></ng-container>
  </div>
  <!--  <div class="open-button" (click)="onClick()"  [matTooltip]="'ORGANIZATION.SELECT_ORGANIZATION'|translate"></div>-->

  <mat-icon
    class="open-button"
    (click)="openModal()"
    [matTooltip]="'ORGANIZATION.SELECT_ORGANIZATION' | translate"
  >
    expand_more
  </mat-icon>
</div>
<ng-template #defaultIcon>
  <div class="logo">
    <mat-icon>corporate_fare</mat-icon>
  </div>
</ng-template>

<ng-template #noOrganization>
  <div class="logo">
    <mat-icon>domain_disabled</mat-icon>
  </div>
</ng-template>
