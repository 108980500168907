import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { SkeletonComponent } from '../../../shared/components/skeleton/skeleton.component';

@Component({
  selector: 'app-no-item-selected',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, MatCardModule, SkeletonComponent],
  templateUrl: './no-item-selected.component.html',
  styleUrl: './no-item-selected.component.scss',
})
export class NoItemSelectedComponent {}
