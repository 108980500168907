<app-emu-input [value]="(nodeFromTreeRequest$|async) ? ('TREE.SELECT_ON_TREE' | translate) :rootItem?.item_name"
               [name]="'ROOT ELEMENT'" [forceClearButton]="true"
               (cleared)="selectedItemCleared();"
               [readonly]="true" (click)="getNodeFromTree()"></app-emu-input>

<mat-progress-bar *ngIf="loading$ | async" mode="indeterminate"></mat-progress-bar>

<section class="item-add-controls" *ngIf="addingItem!==null">

  <p>{{ 'TREE.SELECT_ON_TREE' | translate }}</p>

  <button class="button-primary" mat-flat-button (click)="addCustomItem()">
    <span>{{ 'DASHBOARD.OR_ADD_CUSTOM' | translate }}</span>
  </button>

  <button class="button-secondary" mat-flat-button (click)="cancelAddingItem()">
    <span>{{ 'BUTTON.CANCEL' | translate }}</span>
  </button>

</section>

<div class="structure-container" *ngIf="structure">

  <app-sankey-chart-config-item [item]="structure" [isRoot]="true"
                                (addItem)="addItem($event)" (addItemToParents)="addItemToParents($event)"
                                (editItem)="editItem($event)"
                                (addSeries)="addSeries($event)"
                                (removeItem)="removeItem($event.parent, $event.item,$event.prop)"
                                (removeSeries)="removeSeries($event.item,$event.series)"
  ></app-sankey-chart-config-item>

</div>

<button class="button-primary apply-button" mat-flat-button (click)="applyDataToChart()">
  <span>{{ 'BUTTON.SAVE' | translate }}</span>
</button>
