import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { debounceTime, merge, of, Subject, take, takeUntil } from 'rxjs';

// use on container
//children shouldn't have padding or margin;
@Directive({
  selector: '[appFitText]',
  standalone: true,
})
export class FitTextDirective implements OnDestroy, AfterViewInit {
  private baseFontSize;

  private readonly element: HTMLElement;

  private readonly debounceTime = 10;

  private offset = 0.95;

  private readonly fontSizeToHeightRatio = 1; //1.2;

  private readonly maxIteration = 1000; //1.2;

  private unsubscribe = new Subject<void>();

  private get width(): number {
    return this.element.offsetWidth * this.offset - this.getElementPaddingAndMargin(this.element);
  }

  private get height(): number {
    return this.element.offsetHeight * this.offset - this.getElementPaddingAndMargin(this.element);
  }

  private get children(): Array<HTMLElement> {
    return <Array<HTMLElement>>Array.from(this.element.children);
  }

  private get fontSize() {
    return parseInt(window.getComputedStyle(this.element, null).getPropertyValue('font-size'));
  }

  private set fontSize(value: number) {
    this.element.style.fontSize = value + 'px';
  }

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngAfterViewInit() {
    this.baseFontSize = this.fontSize;
    this.observe();
  }

  private observe() {
    const resize$ = new Subject<void>();
    const mutation$ = new Subject<void>();

    const mutationObserver = new MutationObserver(() => mutation$.next());
    const resizeObserver = new ResizeObserver(() => resize$.next());

    mutationObserver.observe(this.element, { subtree: true, childList: true, characterData: true });
    resizeObserver.observe(this.element);

    this.unsubscribe.pipe(take(1)).subscribe(() => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    });

    // resize$.subscribe(() => console.log('event'));

    mutation$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.styleChildren();
    });

    merge(of(null), mutation$, resize$.pipe(debounceTime(this.debounceTime)))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        // console.log('function');
        try {
          this.resizeText();
        } catch (e) {
          console.warn(
            'Failed to properly resize text:',
            e,
            '\n Perhaps children have to big margin or padding',
          );
          this.fontSize = this.baseFontSize;
        }
      });
  }

  private styleChildren() {
    this.children.forEach((element: HTMLElement) => {
      element.style.fontSize = 'unset';
      element.style.width = 'unset';
      element.style.height = 'unset';
    });
  }

  private resizeText() {
    let counter = 0;
    const textSize = this.textSize;

    if (this.width * this.height * textSize.height * textSize.width == 0) return;

    if (this.textTooSmall(textSize))
      do {
        if (counter++ > this.maxIteration) throw new Error('Max iteration exceeded!');
        this.fontSize++;
      } while (this.textTooSmall());
    if (this.textTooBig(textSize))
      do {
        if (counter++ > this.maxIteration) throw new Error('Max iteration exceeded!');
        this.fontSize--;
      } while (this.textTooBig());
    // console.log(
    //   `width: ${this.width}, height: ${this.height}, textWidth: ${textSize.width} textHeight: ${textSize.height}`,
    // );
    // console.log('-> this.textSize', this.fontSize);
  }

  private textTooBig(textSize = this.textSize): boolean {
    const fontSizeScale = (this.fontSize - 1) / this.fontSize;
    return (
      textSize.width * fontSizeScale > this.width || textSize.height * fontSizeScale > this.height
    );
  }

  private textTooSmall(textSize = this.textSize): boolean {
    const fontSizeScale = (this.fontSize + 1) / this.fontSize;
    return (
      textSize.width * fontSizeScale < this.width && textSize.height * fontSizeScale < this.height
    );
  }

  private get textSize() {
    let width = 0;
    let height = 0;
    this.children.forEach((element: HTMLElement) => {
      width += element.offsetWidth;
      width += this.getElementPaddingAndMargin(element);
      if (element.offsetHeight > height) height = element.offsetHeight;
    });
    let heightCalc = this.fontSize * this.fontSizeToHeightRatio;
    height = Math.max(height, heightCalc);
    return { width, height };
  }

  private getElementPaddingAndMargin(element: HTMLElement) {
    const margin = parseInt(window.getComputedStyle(element, null).getPropertyValue('margin'));
    const padding = parseInt(window.getComputedStyle(element, null).getPropertyValue('padding'));

    return margin + padding;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.unsubscribe();
  }
}
