import { fillStyle, getMinMax, updatePaletteBreakPoints } from '../heatmapDrawUtils';

export function heatmapDailyDraw() {
  // let global min/max
  let uplot: any;
  const xValueMultiplier = 1e3;
  const yAxisPointCount = 12;

  // function getDayWidth(u: any): number {
  //   return u.valToPos(1, 'x', true) - u.valToPos(0, 'x', true);
  // }

  return {
    hooks: {
      draw: (u) => {
        const { ctx, data } = u;
        if (!u.heatmap) u.heatmap = {};
        uplot = u;
        updatePaletteBreakPoints(uplot);

        // console.log('=>(heatmapDailyDraw.ts:69) data', data);
        // console.log('=>(heatmapDailyDraw.ts:48) u', u);

        let xData: Array<number> = data[0];
        let yData: Array<Array<number>> = data.slice(1).filter((o, i) => u.series[i + 1]?.show);
        if (!yData[0] || !xData) return;

        //Find winter time change

        // console.log('=>(heatmapDailyDraw.ts:74) yData', yData);
        const getValue = (i: number) => {
          const value = yData.map((o) => o[i]).reduce((acc, val) => acc + val, 0);
          if (isNaN(value)) {
            console.log('data:', data);
            throw new Error(`invalid value for index ${i}`);
          }
          return value;
        };

        const [minValue, maxValue] = getMinMax(yData);
        u.heatmap.min = minValue;
        u.heatmap.max = maxValue;

        ctx.save();
        ctx.beginPath();
        ctx.rect(u.bbox.left, u.bbox.top, u.bbox.width, u.bbox.height);
        ctx.clip();

        // const boxTopOffset: number = u.bbox.top;
        const boxHeight: number = u.bbox.height; // - boxTopOffset;

        //DRAWN BOXES SIZE:
        const height = boxHeight / yAxisPointCount;
        // const width = getDayWidth(u) * 1.02;
        const currentYear = new Date().getFullYear();
        u.heatmap.items = [];
        const drawBlock = (
          val: number,
          i: number,
          options: { selected?: boolean; widthMod?: number } = {},
        ) => {
          const date = new Date(xData[i] * xValueMultiplier);
          const month = date.getMonth();
          const yearOffset = (date.getFullYear() - currentYear) * 12;
          //reset hour for proper placement

          //calculate x position for every day
          const xPos = u.valToPos(date.getDate(), 'x', true);
          const width = u.valToPos(date.getDate() + 1, 'x', true) - xPos;

          //calculate y position based on hour (remember y=0 is top of the chart)
          // const yPos = month * height + boxTopOffset;

          const yPos = u.valToPos(month + yearOffset, 'y', true);

          const x = xPos - width / 2;
          const y = yPos - height / 2;
          u.heatmap.items.push({
            id: i,
            x: x,
            y: y,
            width,
            height,
          });

          ctx.fillStyle = fillStyle(uplot, val, minValue, maxValue);

          if (options?.selected) {
            ctx.fillRect(x, y, width, height);
            ctx.strokeStyle = 'black';
            ctx.lineWidth = 2;
            ctx.strokeRect(x, y, width, height);
          } else {
            ctx.fillRect(x, y, width, height);
          }
        };

        for (let i = 0; i < xData.length; i++) {
          const val = getValue(i);
          drawBlock(val, i);
        }

        //DRAW CURRENTLY SELECTED BOX
        const selectedId = u.cursor.idx;
        if (selectedId != null && selectedId < xData.length) {
          drawBlock(getValue(selectedId), selectedId, { selected: true });
        }

        //RESTORE CTX TO DEFAULT STYLES
        ctx.restore();
      },
    },
  };
}
