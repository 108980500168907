<section>
  <mat-checkbox data-cy="toggle-header" [(ngModel)]="data.showHeader">
    {{ 'DASHBOARD.SHOW_HEADER' | translate }}
  </mat-checkbox>
</section>

<section *ngIf="data.showHeader">
  <app-emu-input
    name="{{ 'DASHBOARD.HEADER' | translate }}"
    placeholder="{{ 'DEFAULT' | translate }}"
    [(value)]="data.header"
    [className]="'dashboard-settings'"
  ></app-emu-input>
</section>

<app-selecting-value-data [data]="anyData"></app-selecting-value-data>

<section>
  <app-emu-list-input
    [(value)]="data.rangeMode"
    [name]="'DASHBOARD.VALUE_RANGE'"
    [autocomplete]="false"
    [displayWith]="getRangeString.bind(this)"
    [filteredOptions$]="of(ranges)"
    [clearButtonHidden]="true"
    [className]="'dashboard-settings'"
  >
    <ng-template let-item
      ><p>{{ getRangeString(item) | translate }}</p></ng-template
    >
  </app-emu-list-input>

  @if(data.rangeMode === DataDisplayDefaultRanges.CUSTOM){
    <!--        [disabled]="rangePickDisabled"-->
    <app-range-picker
      [mod]="1"
      [currentRange]="getRange()"
      (rangeHasChanged)="changeRange($event)"
    >
    </app-range-picker>
  }
</section>

<section>
  <app-emu-list-input
    [(value)]="data.mode"
    [name]="'DASHBOARD.MODE'"
    [autocomplete]="false"
    [displayWith]="getModeString.bind(this)"
    [filteredOptions$]="of(modes)"
    [clearButtonHidden]="true"
    [className]="'dashboard-settings'"
  >
    <ng-template let-item
      ><p>{{ getModeString(item) | translate }}</p></ng-template
    >
  </app-emu-list-input>
</section>

<ng-container *ngIf="data.unit">
  <section>
    <mat-checkbox data-cy="toggle-units" [(ngModel)]="data.showUnit">
      {{ 'DASHBOARD.SHOW_UNIT' | translate }}
    </mat-checkbox>
  </section>

  @if (data.showUnit) {
    <app-emu-list-input
      [(value)]="data.unit"
      [name]="'VALUE_PREFIX'"
      [autocomplete]="false"
      [valueFnc]="transformValue.bind(this)"
      [clearButtonHidden]="true"
      (valueChange)="onUnitChange(data, $event)"
      [displayWith]="transformValue.bind(this)"
      [filteredOptions$]="of(getUnitsForSeries(data))"
      [className]="'dashboard-settings'"
    >
      <ng-template let-item
        ><p>{{ item?.unit ?? '' }}</p></ng-template
      >
    </app-emu-list-input>
  }
</ng-container>

<section>
  <mat-checkbox data-cy="toggle-date" [(ngModel)]="data.showDate">
    {{ 'DASHBOARD.SHOW_DATE' | translate }}
  </mat-checkbox>
</section>

<section>
  <mat-slider [min]="0" [max]="8" [step]="1" [displayWith]="formatLabel" discrete>
    <span style="width: 100px"
      >{{ 'DASHBOARD.DECIMAL_POINTS' | translate }} : {{ data.decimalPlaces }}</span
    >
    <input matSliderThumb [(ngModel)]="data.decimalPlaces" />
  </mat-slider>
</section>
