import { Component, Input, ViewChild } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { DashboardItem } from '../models/DashboardItem';
import { isItemBlocked } from '../utils/isItemBlocked';
import { DashboardItemType } from '../models/DashboardItemType.model';
import { GridsterItemComponent } from 'angular-gridster2';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';
import { DashboardItemContentComponent } from './dashboard-item-content/dashboard-item-content.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'app-dashboard-item',
  templateUrl: './dashboard-item.component.html',
  styleUrls: ['./dashboard-item.component.scss'],
  standalone: true,
  imports: [NgIf, GridsterItemComponent, NgClass, DashboardItemContentComponent],
})
export class DashboardItemComponent {
  @ViewChild('gridsterItemComponent') gridsterItemComponent: GridsterItemComponent;

  @Input() item: DashboardItem;

  get editMode(): boolean {
    return this.dashboardService.editMode$.value;
  }

  get isSelected() {
    return this.dashboardService.selectedItem$.value === this.item;
  }

  get isLayerSelected() {
    return this.dashboardService.selectedLayerId$.value === this.item.id;
  }

  get itemBlocked(): boolean {
    return isItemBlocked(this.item);
  }

  constructor(private dashboardService: DashboardService) {
    this.dashboardService.itemChanged$
      .pipe(
        filter((o) => o && o.id == this.item.id),
        takeUntilDestroyed(),
      )
      .subscribe((data) => {
        if (!data) return;
        data.value = Number(data.value);
        if (data.mode != 'layer') return;
        if (data.value > 0) this.gridsterItemComponent?.bringToFront(data.value);
        else if (data.value < 0) this.gridsterItemComponent?.sendToBack(-data.value);
        else if (data.value < 0) this.gridsterItemComponent?.sendToBack(0);

        // this.gridsterItemComponent?.itemChanged()
      });
  }

  showSettings() {
    if (!this.itemBlocked) this.dashboardService.triggerSettings$.next(true);
  }

  select() {
    if (this.editMode == false) return;
    this.dashboardService.selectedItem$.next(this.item);
    this.gridsterItemComponent.itemChanged();
  }

  get typeClass() {
    if (!this.item?.type) return null;
    const typeString = DashboardItemType[this.item.type]?.toLowerCase();
    return `type-${typeString}`;
  }
}
