<div class="names-container">
  @if (loaded) {
    <!--    names: {{names | json}}-->
    <mat-tab-group>
      <mat-tab class="name" *ngFor="let name of data.names">
        <ng-template mat-tab-label>
          <div class="lng-label">
            <mat-icon class="flag-svg">
              <img
                alt="{{ name.lng.toLocaleUpperCase() }}"
                fill
                ngSrc="../../assets-common/flags/{{ name.lng.toLocaleUpperCase() }}.svg"
              />
            </mat-icon>
            <span>{{ getLanguageNameToTranslate(name.lng) | translate }}</span>
          </div>
        </ng-template>
        <div class="lng-content">
          <app-emu-input
            [(value)]="name.name"
            name="TREE.NODE_NAME"
            [placeholder]="getDefaultName()"
          ></app-emu-input>
        </div>
      </mat-tab>
    </mat-tab-group>
  } @else {
    <app-skeleton></app-skeleton>
  }
</div>
