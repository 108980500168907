import { AfterViewInit, Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { LocaleSessionService } from '../services/localeSession.service';
import { BehaviorSubject, debounceTime, filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[numericOnly]',
  standalone: true,
})
export class NumericOnlyDirective implements AfterViewInit {
  constructor(
    private ngControl: NgControl,
    private localeService: LocaleSessionService,
  ) {
    //it's needed to be done with debounce because  formcontrol updating value after input
    // and value is sent 2 times, which breaks values with decimal part
    this.input$
      .pipe(
        debounceTime(10),
        filter((o) => o && o.length > 0),
        takeUntilDestroyed(),
      )
      .subscribe((value: string) => {
        this.transform(value);
      });
  }

  @Input('numericOnly') apply: any;

  @Input() integer: boolean = false;

  private input$ = new BehaviorSubject<string>(null);

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.ngControl.control.value) this.onInput(this.ngControl.control.value);
    });
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this.input$.next(value);
    // this.transform(value);
  }

  private transform(value: string) {
    if (!this.apply) return;
    const decimalSeparator = '\\' + this.getDecimalSeparator(this.localeService.locale);
    let regexString = `[^0-9${decimalSeparator}]|((?<=${decimalSeparator}.*?)${decimalSeparator})|^0+(?=[1-9])`;
    if (this.integer) regexString = `[^0-9]|((?<=.*?))`;

    // eslint-disable-next-line security/detect-non-literal-regexp
    const regex = new RegExp(regexString, 'g');

    if (value && value.length > 0) {
      this.ngControl.control.patchValue(value.replace(regex, ''));
    }
  }

  @HostListener('keydown', ['$event'])
  keydown(event: KeyboardEvent) {
    const value = this.ngControl.control.getRawValue();
    let mod = 0;
    switch (event.key) {
      case 'ArrowDown':
        mod = -1;
        break;
      case 'ArrowUp':
        mod = 1;
        break;
    }
    let numericValue = Number(value);
    if (isNaN(numericValue)) return;
    this.onInput((numericValue + mod).toString());
    // this.ngControl.control.patchValue(numericValue + mod);
  }

  private getDecimalSeparator(locale: string) {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(locale)
      .formatToParts(numberWithDecimalSeparator)
      .find((part) => part.type === 'decimal').value;
  }
}
