/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DbConfigDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * Item identification
   */
  id?: number;
  /**
   * Database user (login)
   */
  db_user: string;
  /**
   * RW ID
   */
  rw_id: number;
  /**
   * User password from database
   */
  db_password?: string;
  /**
   * Database host
   */
  db_host: string;
  /**
   * Database name
   */
  db_name: string;
  /**
   * Database port
   */
  db_port: number;
  /**
   * is master
   */
  master: boolean;
  /**
   * is slaved
   */
  slaved: boolean;
  /**
   * description
   */
  description?: string;
}
