import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  standalone: true,
  imports: [MatIconModule, MatDialogClose, MatDividerModule, TranslateModule],
})
export class ErrorDialogComponent {
  text = '';

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.text = data.text;
  }
}
