/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UplotSeriesConfigDto } from './uplotSeriesConfigDto';

export interface DataResponseDto {
  /**
   * Data - first element is always timestamps, and next elements are data for series. Length of all arrays suppose to be the same
   */
  data: Array<Array<number>>;
  /**
   * Value data identifier
   */
  series: Array<UplotSeriesConfigDto>;
}
