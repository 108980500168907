import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { DeviceService } from '../../../../../logger/device.service';
import { DeviceValuesDto } from '../../../../../../../api-main';
import { ValueDataModel } from '../../../../models/ValueData.model';
import { isSeriesConsumptionValue } from '../../../../../../shared/components/graph-uplot/graph/utils/isSeriesConsumptionValue';
import { DisabledDirective } from '../../../../../../shared/directives/disabled.directive';
import { EmuListInputComponent } from '../../../../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import { TimestampRelativeComponent } from '../../../../../../shared/components/timestamp-relative/timestamp-relative.component';
import { SelectingValueDataService } from '../selecting-value-data.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { getDeviceParameterNameSetTranslate } from './getDeviceParameterName';
import { getObisCodeGroupForSeries } from '../../../../../../shared/components/graph-uplot/graph/models/ObisCodeCfgUnits';
import { NgIf } from '@angular/common';
import { sortByObisCode } from '../../../../../../shared/utils/obisCodeSorting';

@Component({
  selector: 'app-settings-value-data',
  templateUrl: './value-display-settings-values.component.html',
  styleUrls: ['./value-display-settings-values.component.scss'],
  standalone: true,
  imports: [
    EmuListInputComponent,
    DisabledDirective,
    TimestampRelativeComponent,
    TranslateModule,
    NgIf,
  ],
})
export class ValueDisplaySettingsValuesComponent implements OnInit, OnDestroy {
  @Input() data: ValueDataModel;

  @Input() reloadDeviceValues$: Subject<number>;

  @Input() reloadDevice$: Subject<number>;

  @Output() valueSelected = new EventEmitter<string>();

  deviceValueInput$ = new Subject<string>();

  deviceValues$: Observable<DeviceValuesDto[]>;

  loading$ = new BehaviorSubject(false);

  deviceValues: DeviceValuesDto[] = [];

  private _selectedDeviceValue: DeviceValuesDto;

  private unsubscribe = new Subject<void>();

  get selectedDeviceValue(): DeviceValuesDto {
    if (this.data.valueId != this._selectedDeviceValue?.id) return null;
    return this._selectedDeviceValue;
  }

  set selectedDeviceValue(value: DeviceValuesDto) {
    this._selectedDeviceValue = value;
    if (value?.id != null) {
      // console.log('-> value', JSON.parse(JSON.stringify(value)));
      if (this.data.consumption == undefined || this.data.valueId != value.id)
        this.data.consumption = isSeriesConsumptionValue(<any>value);
      this.data.valueId = value.id;
      this.data['name'] = this.getDeviceParameterName(value); //value.description_str;
      this.data['obis_code'] = value.cfg_obis_code;

      const units = getObisCodeGroupForSeries(value);

      if (units?.units?.includes(this.data['unit'])) return;
      this.data['cfg_unit'] = value.cfg_unit;
      this.data['unit'] = value.unit_str;
    }

    this.valueDisplaySelected();
  }

  constructor(
    private deviceService: DeviceService,
    private selectingValueDataService: SelectingValueDataService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.loadDeviceValues();
  }

  private loadDeviceValues() {
    let deviceId: number;
    const deviceValue$ = this.reloadDeviceValues$.pipe(
      tap(() => {
        this.loading$.next(true);
        this.selectedDeviceValue = null;
      }),
      switchMap((id) => {
        deviceId = id;
        if (this.selectingValueDataService.savedDeviceValues[`${this.data.loggerId}-${deviceId}`])
          return of(
            this.selectingValueDataService.savedDeviceValues[`${this.data.loggerId}-${deviceId}`],
          );
        return this.deviceService
          .getDeviceValuesList(id, this.data.loggerId)
          .pipe(map((o) => sortByObisCode(o.data)));
      }),
      tap((data) => {
        this.selectingValueDataService.savedDeviceValues[`${this.data.loggerId}-${deviceId}`] =
          data;
      }),
    );

    deviceValue$.pipe(takeUntil(this.unsubscribe)).subscribe((values) => {
      this.loading$.next(false);
      this.deviceValues = values;
      this.setupDeviceValuesFilter(values);
      if (this.isDataAvailable() && this.data.valueId != null)
        this.selectedDeviceValue = values.find((o) => o.id == this.data.valueId);
    });
  }

  setupDeviceValuesFilter(devices: DeviceValuesDto[]) {
    this.deviceValues$ = this.deviceValueInput$.pipe(
      startWith(''),
      distinctUntilChanged(),
      map((term: DeviceValuesDto | string) => {
        // if (typeof term != 'string') {
        //   return [term];
        // }

        if (!term || typeof term != 'string' || term.length == 0) return devices;

        return devices.filter((device: DeviceValuesDto) => {
          const formatValue = (value: string) =>
            value
              .toUpperCase()
              .trim()
              .replaceAll(/[\s,;:_.]+/g, '');
          return (
            formatValue(device.description_str).includes(formatValue(term)) ||
            formatValue(this.getDeviceParameterName(device)).includes(formatValue(term)) ||
            device.cfg_obis_code?.includes(term)
          );
        });
      }),
    );
  }

  isDataAvailable() {
    return this.data.deviceId != null && this.data.loggerId != null;
  }

  valueDisplaySelected() {
    this.valueSelected.emit();
  }

  resetValues() {
    this.data.valueId = null;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected readonly getDeviceParameterName = getDeviceParameterNameSetTranslate(
    this.translateService,
  );
}
