import { LocaleSessionService } from '../../../../services/localeSession.service';
import { TranslateService } from '@ngx-translate/core';

export function uplotTooltipPlugin(opts: {
  formatFunction: any;
  showTotal?: boolean;
  localeService: LocaleSessionService;
  translateService: TranslateService;
}) {
  let tooltipLeftOffset = 0;
  let tooltipTopOffset = 0;
  // let shiftX = 10;
  let shiftY = 10;
  let dataIdx = null;
  let over;

  const tooltip = document.createElement('div');
  tooltip.className = 'u-tooltip';
  tooltip.style.display = 'none';

  function showTooltip() {
    tooltip.style.display = 'block';
  }

  function hideTooltip() {
    tooltip.style.display = 'none';
  }

  function setTooltip(u) {
    if (!u._userConfig?.showTooltip) return;
    showTooltip();
    moveTooltip(u);
    setTooltipText(u);
  }

  async function setTooltipText(u) {
    return new Promise<void>((resolve) => {
      const ignoreAdditionalValues = !u._userConfig?.showTooltipExtData;
      tooltip.classList.toggle('hideAdditional', ignoreAdditionalValues);
      const label = tooltip.childNodes[0];
      const date = new Date(u.data[0][dataIdx] * 1000);
      // const dayName = date.toString().split(' ')[0];
      const dayName = date.toLocaleString(opts.localeService.locale, {
        weekday: 'short',
        timeZone: opts.localeService.timezone,
      });
      label.childNodes[0].textContent = `${dayName} ${date.toLocaleString(opts.localeService.locale, { timeZone: opts.localeService.timezone })}`;
      label.childNodes[1].textContent =
        u.selection != null
          ? `${u.selection.min ? new Date(u.selection.min * 1000).toLocaleString(opts.localeService.locale, { timeZone: opts.localeService.timezone }) : '?'} - ${
              u.selection.max
                ? new Date(u.selection.max * 1000).toLocaleString(opts.localeService.locale, {
                    timeZone: opts.localeService.timezone,
                  })
                : '?'
            }`
          : null;

      // label.textContent = `${date.toLocaleString()}`;

      (<HTMLElement>label).classList.add('u-tooltip-label');

      const table = tooltip.childNodes[1];

      table.childNodes.forEach((node: HTMLElement) => {
        node.classList.toggle('hide', true);
      });

      if (u.series.slice(1).filter((o) => o.show).length > 0)
        (<HTMLElement>table.childNodes[0]).classList.toggle('hide', false);

      u.series.slice(1).forEach((series, serieId) => {
        const id = serieId + 1;

        if (!table.childNodes[id]) tooltipAddRow(series, table);
        const row = <HTMLElement>table.childNodes[id];

        row.classList.toggle('hide', !series.show);
        const spans = row.childNodes;

        const seriesLabelText =
          series.rawLabel && series.rawLabel.length > 0
            ? series.rawLabel
            : opts.translateService.instant('INSPECT.NO_DATA');
        spans[1].childNodes[0].textContent = `${seriesLabelText}`;

        const value = u.data[id][dataIdx] != null ? opts.formatFunction(u.data[id][dataIdx]) : null;
        const unit = u.series[id].unit;
        spans[2].childNodes[0].textContent =
          value != null ? `${value} ${unit ? '[' + unit + ']' : ''}` : '--';

        const color = <HTMLElement>spans[0].childNodes[0];
        color.style.borderColor = series._stroke;
        color.style.backgroundColor = series._fill;

        if (ignoreAdditionalValues) return;
        //add additional data
        spans[3].childNodes[0].textContent = opts.formatFunction(u.series[id].avg);
        spans[4].childNodes[0].textContent = opts.formatFunction(u.series[id].min);
        spans[5].childNodes[0].textContent = opts.formatFunction(u.series[id].max);
        spans[6].childNodes[0].textContent = opts.formatFunction(u.series[id].total);
      });

      if (opts.showTotal) {
        const id = u.series.length;
        if (!table.childNodes[id]) tooltipAddRow({}, table);
        const row = <HTMLElement>table.childNodes[id];

        row.classList.toggle('hide', false);
        row.classList.toggle('total', true);
        const spans = row.childNodes;

        spans[1].childNodes[0].textContent = `${opts.translateService.instant('DASHBOARD.VALUE_MODE.TOTAL')}:`;

        let value = u.data
          .filter((o, i) => u.series[i].show)
          .slice(1)
          .map((o) => o[dataIdx])
          .reduce((a, b) => a + b, 0);

        value = opts.formatFunction(value);
        const units = u.series.slice(1).map((o) => o.unit);
        let unit;
        //set unit only if every series has the same unit
        if (units.every((o) => o == units[0])) unit = units[0];

        spans[2].childNodes[0].textContent =
          value != null ? `${value} ${unit ? '[' + unit + ']' : ''}` : '--';

        // const color = <HTMLElement>spans[0].childNodes[0];
        // color.style.borderColor = null;
        // color.style.backgroundColor = null;

        //MAYBE TO DO IN FUTURE
        if (ignoreAdditionalValues) return;
        //add additional data
        // spans[3].childNodes[0].textContent = opts.formatFunction(u.series[id].avg);
        // spans[4].childNodes[0].textContent = opts.formatFunction(u.series[id].min);
        // spans[5].childNodes[0].textContent = opts.formatFunction(u.series[id].max);
        // spans[6].childNodes[0].textContent = opts.formatFunction(u.series[id].total);
      }

      resolve();
    });
  }

  async function moveTooltip(u) {
    return new Promise<void>((resolve) => {
      if (u.loadingElement.isBlocked()) {
        hideTooltip();
      }
      const tooltipWidth = tooltip.offsetWidth;
      const tooltipHeight = tooltip.offsetHeight;
      const leftOffset = 50;
      const topOffset = tooltipTopOffset + shiftY;

      let left = u.cursor.left - tooltipWidth + leftOffset;
      let top = u.cursor.top - tooltipHeight;

      // if (tooltipWidth + left - tooltipLeftOffset > u.over.offsetWidth)
      //   left = left - tooltipWidth - leftOffset / 4;

      if (left < tooltipLeftOffset) left = left + tooltipWidth + leftOffset / 2;

      const offset = left + tooltipWidth - tooltipLeftOffset - u.over.offsetWidth;
      if (offset > 0) left -= offset;
      // console.log('-> offset', offset);

      if (top < 0) top = top + tooltipHeight + topOffset;

      const heightOffset = Math.min(u.root.offsetHeight - (tooltipHeight + top + 10), 0);
      top = top + heightOffset;

      tooltip.style.top = top + 'px';
      tooltip.style.left = left + 'px';
      resolve();
    });
  }

  return {
    hooks: {
      ready: [
        (u) => {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          createTooltipElement(u);
          u.over.addEventListener('mouseleave', hideTooltip);
        },
      ],
      update: (u) => {
        setTooltip(u);
      },
      setCursor: [
        (u) => {
          update(u);
        },
      ],
      // draw: (u) => {
      //   update(u);
      // },
      destroy: (u) => {
        u.over.addEventListener('mouseleave', hideTooltip);
      },
    },
  };

  function update(u) {
    let c = u.cursor;

    //if idx not changed only update tooltip pos;
    // if (dataIdx != c.idx) {
    dataIdx = c.idx;

    if (dataIdx == undefined) {
      hideTooltip();
      return;
    }
    setTooltip(u);
    // }
    moveTooltip(u);
  }

  function createTooltipElement(u) {
    over = u.over;
    tooltipLeftOffset = parseFloat(over.style.left);
    tooltipTopOffset = parseFloat(over.style.top);
    const wrap = u.root.querySelector('.u-wrap');
    if (!wrap) throw new Error('No uplot wrap element found!');
    wrap.appendChild(tooltip);

    const table = document.createElement('table');
    const header = document.createElement('tr');

    createHeader(null);
    createHeader(opts.translateService.instant('GRAPH_TOOLTIP.NAME'));
    createHeader(opts.translateService.instant('GRAPH_TOOLTIP.VALUE'));
    createHeader(opts.translateService.instant('GRAPH_TOOLTIP.AVG'), true);
    createHeader(opts.translateService.instant('GRAPH_TOOLTIP.MIN'), true);
    createHeader(opts.translateService.instant('GRAPH_TOOLTIP.MAX'), true);
    createHeader(opts.translateService.instant('GRAPH_TOOLTIP.SUM'), true);
    // createHeader('Σ', true);

    function createHeader(text: string, additional = false) {
      const element = document.createElement('div');
      if (text) element.textContent = text;
      addToRow(header, element, additional);
    }

    table.appendChild(header);

    u.series.slice(1).forEach((series) => {
      tooltipAddRow(series, table);
    });
    // if (opts.showTotal) tooltipAddRow({}, table);

    const span = document.createElement('span');
    span.appendChild(document.createElement('span'));
    span.appendChild(document.createElement('span'));

    tooltip.appendChild(span);
    tooltip.appendChild(table);
  }

  function tooltipAddRow(series, table) {
    const row = document.createElement('tr');
    const color = document.createElement('div');
    color.className = 'u-tooltip-color';

    //could be problematic if color was changed during using graph or sth
    color.style.borderColor = series._stroke;
    color.style.backgroundColor = series._fill;

    const name = document.createElement('span');
    name.className = 'u-tooltip-name';

    const value = document.createElement('span');
    value.className = 'u-tooltip-value';

    const avg = document.createElement('span');
    avg.className = 'u-tooltip-value';

    const min = document.createElement('span');
    min.className = 'u-tooltip-value';

    const max = document.createElement('span');
    max.className = 'u-tooltip-value';

    const total = document.createElement('span');
    max.className = 'u-tooltip-value';

    addToRow(row, color);
    addToRow(row, name);
    addToRow(row, value);
    addToRow(row, avg, true);
    addToRow(row, min, true);
    addToRow(row, max, true);
    addToRow(row, total, true);
    table.appendChild(row);
  }

  function addToRow(root, element, additional = false) {
    const td = document.createElement('td');
    td.appendChild(element);
    if (additional) td.className = 'additional';
    root.appendChild(td);
  }
}
