/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GpInvitationDto } from './gpInvitationDto';
import { GpItemDto } from './gpItemDto';

export interface GpDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * Item identification. If not present or 0 - will save as new item
   */
  id?: number;
  /**
   * Policy name
   */
  name?: string;
  /**
   * Is active
   */
  is_active?: boolean;
  /**
   * Items list
   */
  readonly items?: Array<GpItemDto>;
  /**
   * User list with invitation, only for getting data
   */
  readonly invitations?: Array<GpInvitationDto>;
}
