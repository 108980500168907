import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DashboardItemData } from '../models/DashboardItemData.model';
import { DashboardItemType } from '../models/DashboardItemType.model';
import { DashboardItem } from '../models/DashboardItem';
import { getDefaultItemStyles } from '../utils/getDefaultItemStyles';
import { isItemBlocked } from '../utils/isItemBlocked';

@Component({
  selector: 'app-dashboard-item-settings',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DashboardItemSettingsComponent implements OnInit, OnChanges {
  @Input() item: DashboardItem;

  get data(): any {
    return this.itemData;
  }

  @HostBinding('class.item-blocked') get blocked() {
    return isItemBlocked(this.item);
  }

  itemData: DashboardItemData;

  DashboardItemType = DashboardItemType;

  constructor(protected cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.itemData = null;
      this.cd.detectChanges();
      this.itemData = this.item.data;
      this.setDefaultStyling();
      this.setDefault();
      this.cd.detectChanges();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item'].previousValue !== changes['item'].currentValue) this.ngOnInit();
  }

  setDefaultStyling() {
    this.item.styles = getDefaultItemStyles();
  }

  protected setDefault() {
    this.itemData = {};
  }
}
