import { Directive, ElementRef, Input, isDevMode, Renderer2 } from '@angular/core';

//FOR proper working input need to have [disabled]="var" declared explicitly
@Directive({
  selector: '[disabled]',
  standalone: true,
})
export class DisabledDirective {
  get disabled(): any {
    return this._disabled;
  }

  @Input() set disabled(value: any) {
    // console.log('-> value', value);
    if (typeof value === 'boolean') this._disabled = value;
    else this._disabled = value !== null && value !== 'false';
    this.handleDisabled();
  }

  private _disabled: any;

  constructor(
    public renderer: Renderer2,
    public hostElement: ElementRef,
  ) {}

  handleDisabled() {
    try {
      let element = this.hostElement.nativeElement;
      if (!element || element.nodeName !== 'INPUT') return;

      while (!element.classList.contains('mat-mdc-form-field')) {
        element = element.parentElement;
      }

      if (this._disabled == true) this.renderer.setAttribute(element, 'disabled', 'true');
      else this.renderer.removeAttribute(element, 'disabled');
    } catch (e) {
      if (isDevMode()) console.warn('failed to find element', e);
    }
  }
}
