<app-modal
  [title]="getTitle()"
  [saveButtonName]="getSaveButtonName()"
  [cancelButtonName]="getCancelButtonName()"
  (save)="onSave()"
  [isDataLoaded]="!!data"
  [shouldSaveBeDisabled]="!isValid || blockInput"
>
  <ng-container body>
    <!-- <mat-dialog-content> -->

   <div class="body-container">
     <form [formGroup]="form">
       <div class="organization-logo">
         <div class="logo-container" *ngIf="form.get('logo')?.value">
           <div class="unset-logo-button" (click)="form.get('logo').setValue(null)">
             <mat-icon>close</mat-icon>
           </div>

           <img [src]="form.get('logo').value" alt="logo" />
         </div>
       </div>

       <section *ngFor="let input of inputs">

         <app-emu-input [name]="input.displayName"
                        [formControlName]="input.name"
                        autocompleteTag="{{ input.autocompleteTag }}"
         ></app-emu-input>

       </section>

       <section>
         <app-emu-list-input
           formControlName="timezone"
           [name]="'PROFILE.TIMEZONE'"
           (valueChange)="timezoneInput$.next($event)"
           [clearButtonHidden]="true"
           [filteredOptions$]="filteredTimezones$"
         >
           <ng-template let-item>
             <p class="device-name">
               {{item }}
             </p>
           </ng-template>
         </app-emu-list-input>
       </section>

       <button
         class="upload-logo-button"
         type="button"
         mat-raised-button
         (click)="fileInput.click()"
       >
         {{ 'ORGANIZATION.UPLOAD_LOGO' | translate }}
       </button>
       <input hidden (change)="onFileSelected()" #fileInput type="file" id="file" accept="image/*" />

     </form>
   </div>

    <!-- </mat-dialog-content> -->
  </ng-container>
</app-modal>
