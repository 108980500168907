import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { UplotRangePickerComponent } from '../../../../../shared/components/graph-uplot/uplot-range-picker/uplot-range-picker.component';
import { AsyncPipe, NgIf, TitleCasePipe } from '@angular/common';
import { MatProgressBar } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardPieChartComponent } from './dashboard-pie-chart.component';
import { UplotResponseData } from '../../../../../shared/components/graph-uplot/graph/models/UplotResponseData.model';
import { UplotSeriesConfig } from '../../../../../shared/components/graph-uplot/graph/models/UplotSeriesConfig.model';
import { DataServiceProvider } from '../../../../data/api-data.service';
import { DashboardService } from '../../../services/dashboard.service';
import { LocaleSessionService } from '../../../../../shared/services/localeSession.service';
//Base class for aggregating data on pie charts
@Component({
  standalone: true,
  imports: [
    BaseChartDirective,
    UplotRangePickerComponent,
    NgIf,
    AsyncPipe,
    MatProgressBar,
    TranslateModule,
  ],
  providers: [TitleCasePipe],
  templateUrl: './dashboard-pie-chart.component.html',
  styleUrl: './dashboard-pie-chart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class DashboardPieChartAggComponent extends DashboardPieChartComponent {
  private seriesShow: boolean[] = Array.from(Array(this.getSeriesLength())).map(() => true);

  protected seriesDate: Date[] = Array.from(Array(this.getSeriesLength())).map(() => null);

  override rangePickDisabled: boolean = false;

  constructor(
    override dataService: DataServiceProvider,
    override dashboardService: DashboardService,
    override cd: ChangeDetectorRef,
    protected localeService: LocaleSessionService,
    protected capitalize: TitleCasePipe,
  ) {
    super(dataService, dashboardService, cd);
  }
  protected abstract getSeriesLength(): number;
  protected abstract seriesIndexExtraction(date: Date): number;
  protected abstract getSeriesLabel(i: number): string;

  override formatData(responseData: UplotResponseData): number[] {
    const aggregatedData = Array.from(Array(this.getSeriesLength())).map(() => []);

    const dates = responseData.data[0];
    const data = responseData.data[1];
    dates.forEach((o, i) => {
      const date = new Date(o * 1000);
      const seriesIndex: number = this.seriesIndexExtraction(date);
      this.seriesDate[seriesIndex as number] = date;
      aggregatedData[seriesIndex as number].push(data[i as number]);
    });

    const summedDataArr = aggregatedData.map((o) => o.reduce((a, b) => a + b, 0));
    summedDataArr.forEach((o, i) => {
      this.seriesShow[i as number] = o > 0;
    });
    // console.log('=>(dashboard-pie-chart.component.ts:109) summedDataArr', summedDataArr);

    if (summedDataArr.every((o) => o <= 0)) this.noData = true;
    return summedDataArr.filter((o, i) => this.seriesShow[i as number]);
  }

  override getSeries(): UplotSeriesConfig[] {
    const baseSeries = this.series[0];

    const series: UplotSeriesConfig[] = Array.from(Array(this.getSeriesLength())).map((o, i) => {
      return {
        ...baseSeries,
        label: this.getSeriesLabel(i),
        stroke: null,
      };
    });
    return series.filter((o, i) => this.seriesShow[i as number]);
  }
}
