/**
 * EMU Invoice API
 * EMU Invoice API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DbDefaultResponse {
  /**
   * Id of created item
   */
  id?: number;
  /**
   * 200
   */
  code: number;
  /**
   * Error message if occurred
   */
  error?: string;
}
