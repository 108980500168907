import { Component, OnInit } from '@angular/core';
import { DashboardItemSettingsComponent } from '../dashboard-item-settings.component';
import { DashboardItemDataText } from '../../models/DashboardItemDataText.model';
import { TranslateModule } from '@ngx-translate/core';
import { MatSliderModule } from '@angular/material/slider';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatIconModule } from '@angular/material/icon';
import { EmuInputComponent } from '../../../../shared/components/inputs/emu-input/emu-input.component';
import { NgIf } from '@angular/common';
import { EmuColorInputComponent } from '../../../../shared/components/inputs/emu-color-input/emu-color-input.component';
import { of } from 'rxjs';
import { EmuListInputComponent } from '../../../../shared/components/inputs/emu-list-input/emu-list-input.component';

@Component({
  selector: 'app-dashboard-text-settings',
  templateUrl: './dashboard-text-settings.component.html',
  styleUrls: ['../../styles/widget-settings.scss', './dashboard-text-settings.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    EmuInputComponent,
    MatIconModule,
    ColorPickerModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatSliderModule,
    TranslateModule,
    EmuColorInputComponent,
    EmuListInputComponent,
  ],
})
export class DashboardTextSettingsComponent
  extends DashboardItemSettingsComponent
  implements OnInit
{
  minFontSize: number = 8;

  maxFontSize: number = 100;

  fontSizeStep: number = 2;

  fontSizeList = Array.from(
    Array(Math.ceil((this.maxFontSize - this.minFontSize) / this.fontSizeStep) + 1).keys(),
  ).map((o, i) => this.minFontSize + this.fontSizeStep * i);

  override setDefaultStyling() {
    this.item.styles.padding = true;
  }

  override get data(): DashboardItemDataText {
    return this.itemData?.text;
  }

  protected override setDefault() {
    if (this.data == undefined)
      this.itemData.text = {
        text: '...',
        color: '#000000',
        size: 16,
        align: 'center',
        autoSize: true,
      };
  }

  setAlignment(alignment: 'left' | 'center' | 'right' | 'justify') {
    this.data.align = alignment;
  }

  formatLabel(value: number): string {
    return `${value}px`;
  }

  protected readonly of = of;
}
