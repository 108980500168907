import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { Auth0Service } from '../api-auth';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './app.module';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { AuthModule } from '@auth0/auth0-angular';
import { ConfigService } from './shared/services/config.service';
import { CookieModule } from 'ngx-cookie';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { ApiModule } from '../api-main';
import { ApiModule as InvoiceApiModule } from '../api-invoice';
import { CacheModule } from './shared/modules/cache.module';
import { MaterialModule } from './shared/modules/material.module';
import { Auth0Interceptor } from './shared/interceptors/auth0.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { LangInterceptor } from './shared/interceptors/lang.interceptor';
import { LocaleSessionService } from './shared/services/localeSession.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { OrganizationInterceptor } from './shared/interceptors/organization.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    Auth0Service,
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
        compiler: {
          provide: TranslateCompiler,
          useClass: TranslateMessageFormatCompiler,
        },
      }),
      AuthModule.forRoot(ConfigService.getAuthConfig()),
      CookieModule.withOptions(),
      AppRoutingModule, //withComponentInputBinding
      BrowserModule,
      ApiModule,
      InvoiceApiModule,
      CacheModule,
      MaterialModule, //NEED to be here
    ),
    { provide: HTTP_INTERCEPTORS, useClass: Auth0Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OrganizationInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      deps: [LocaleSessionService],
      useFactory: (localeSessionService) => localeSessionService.locale,
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideCharts(withDefaultRegisterables()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
