<main class="wrapper">
  <div class="bg-wrapper"></div>

  <div class="wrapper-content">
    @if (loading()) {
      <app-loading-circle></app-loading-circle>
    } @else {
      <ng-container  [ngTemplateOutlet]="modalWrapper"></ng-container>
    }
  </div>
</main>

<ng-template #modalWrapper>
  <div class="modal-wrapper">
    <header class="modal-header">
      <img [src]="logoURL" alt="oem logo" class="header-img" />
      <h1 class="header-title">{{ 'VERIFY_EMAIL.TITLE' | translate }}</h1>
      <div class="header-content">
        <p>{{ 'VERIFY_EMAIL.DESCRIPTION_1' | translate }} <b>{{ userEmail }}</b>. {{ 'VERIFY_EMAIL.DESCRIPTION_2' | translate }}</p>
      </div>
    </header>
    <div class="modal-content">
      <button class="content-btn" (click)="continue()">{{ 'BUTTON.CONTINUE' | translate }}</button>
      <div class="content-text">
        <p>{{ 'VERIFY_EMAIL.GET_EMAIL' | translate }}
          <span [class.resending]="resending()" (click)="resend()">{{ 'LABEL.RESEND' | translate }}</span>
        </p>
      </div>
    </div>
    <div class="line-through"></div>
    <footer class="modal-footer">
      <div class="footer-content">
        <p>{{ 'VERIFY_EMAIL.WRONG_ACC' | translate }} <span (click)="logout()">{{ 'LABEL.LOGOUT' | translate }}</span> </p>
      </div>
    </footer>
  </div>
</ng-template>
