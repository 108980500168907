import { DOCUMENT, NgClass } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ConfigService } from './shared/services/config.service';
import { LocaleSessionService } from './shared/services/localeSession.service';
import { DictionaryService } from './shared/services/dictionary.service';
import { LayoutService } from './layout/layout.service';
import { trackMousePosInCss } from './shared/utils/trackMousePosInCss';
import { CheckCookiesSupportComponent } from './shared/app-support/check-cookies-support/check-cookies-support.component';
import { RouterOutlet } from '@angular/router';
import { DebugComponent } from './shared/app-support/debug/debug.component';
import { SignupHandlerService } from './signup/signup-handler.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgClass, RouterOutlet, CheckCookiesSupportComponent, DebugComponent],
})
export class AppComponent implements OnInit {
  title = 'profile-module';

  theme: string;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private localeSessionService: LocaleSessionService,
    private dictionaryService: DictionaryService,
    private layoutService: LayoutService,
    private signUpService: SignupHandlerService, // Don't remove - we need to load user data at the beginning
  ) {}

  ngOnInit() {
    let oemId = ConfigService.getIdConfig();
    this.doc
      .getElementById('appFavicon')
      .setAttribute('href', `../assets-common/${oemId}/favicon.ico`);
    this.theme = `${oemId}-theme`;
    this.loadOemTheme(this.theme);
    this.dictionaryService.getLanguageList().subscribe(() => {
      this.localeSessionService.checkLangExistence();
      this.layoutService.languageSet = true;
    });
    trackMousePosInCss();
  }

  private loadOemTheme(themeName: string) {
    const headEl = this.doc.getElementsByTagName('head')[0];

    const newLinkEl = this.doc.createElement('link');

    newLinkEl.rel = 'stylesheet';
    newLinkEl.href = `${themeName}.css`;

    headEl.appendChild(newLinkEl);
  }
}
