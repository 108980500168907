import { Component } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { getDashboardItemTypes } from '../../../utils/getDashboardItemTypes';
import { DashboardItem } from '../../../models/DashboardItem';
import { getDefaultItemStyles } from '../../../utils/getDefaultItemStyles';
import { setMinimalSizeForItem } from '../../../utils/setMinimalSizeForItem';
import { DashboardItemType } from '../../../models/DashboardItemType.model';
import { getItemTypeIcon } from '../../../utils/itemTypeIcons';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-dashboard-toolbar',
  templateUrl: './dashboard-toolbar.component.html',
  styleUrl: './dashboard-toolbar.component.scss',
  standalone: true,
  imports: [NgFor, MatTooltipModule, MatIconModule, TranslateModule],
})
export class DashboardToolbarComponent {
  constructor(private dashboardService: DashboardService) {}

  readonly itemTypes = getDashboardItemTypes();

  DashboardItemType = DashboardItemType;

  getItemTypeIcon = getItemTypeIcon;

  get dashboardItems() {
    return this.dashboardService.items;
  }

  private setMinimalSize(item: DashboardItem) {
    if (item.type === DashboardItemType.EMPTY) item.styles = getDefaultItemStyles();
    this.setMinimalSizeForType(item);
    this.dashboardService.changedOptions();
  }

  private setMinimalSizeForType(item: DashboardItem) {
    setMinimalSizeForItem(item);
  }

  add(type: DashboardItemType) {
    const item = new DashboardItem();
    item.type = type;
    this.setMinimalSize(item);
    this.dashboardService.selectedItem$.next(item);
    this.dashboardService.addItem(item);
  }

  getTooltip(type: DashboardItemType): string {
    switch (type) {
      case DashboardItemType.EMPTY:
        return 'DASHBOARD.TOOLTIP.EMPTY';
      case DashboardItemType.VALUE_DISPLAY:
        return 'DASHBOARD.TOOLTIP.VALUE_DISPLAY';
      default:
        return '';
    }
  }
}
