import { Component, Input } from '@angular/core';
import { DashboardItem } from '../models/DashboardItem';
import { DashboardItemType } from '../models/DashboardItemType.model';
import { DashboardValueDisplaySettingsComponent } from './dashboard-value-display-settings/dashboard-value-display-settings.component';
import { DashboardImageSettingsComponent } from './dashboard-image-settings/dashboard-image-settings.component';
import { DashboardTextSettingsComponent } from './dashboard-text-settings/dashboard-text-settings.component';
import { DashboardGraphSettingsComponent } from './dashboard-graph-settings/dashboard-graph-settings.component';
import { NgSwitch, NgSwitchCase } from '@angular/common';
import { DashboardMultipleValueDisplaySettingsComponent } from './dashboard-multiple-value-display-settings/dashboard-multiple-value-display-settings.component';

@Component({
  selector: 'app-dashboard-item-settings-per-type',
  templateUrl: './dashboard-item-settings-per-type.component.html',
  styleUrls: ['./dashboard-item-settings-per-type.component.scss'],
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    DashboardGraphSettingsComponent,
    DashboardTextSettingsComponent,
    DashboardImageSettingsComponent,
    DashboardValueDisplaySettingsComponent,
    DashboardMultipleValueDisplaySettingsComponent,
  ],
})
export class DashboardItemSettingsPerTypeComponent {
  @Input() item: DashboardItem;

  DashboardItemType = DashboardItemType;
}
