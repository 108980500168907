<span class="name">
  {{ 'DASHBOARD.LAYER_POSITION' | translate }}
</span>

<div class="layout-buttons-container">
  <div class="row">
    <button (click)="moveItem('front')" [disabled]="!isMoveAvailable('front') || itemBlocked">
      <span>{{ 'DASHBOARD.LAYER_MOVE.TO_FRONT' | translate }}</span>
      <mat-icon>arrow_upward</mat-icon>
    </button>
    <button (click)="moveItem('back')" [disabled]="!isMoveAvailable('back') || itemBlocked">
      <span>{{ 'DASHBOARD.LAYER_MOVE.TO_BACK' | translate }}</span>
      <mat-icon>arrow_downward</mat-icon>
    </button>
  </div>

  <div class="row">
    <button (click)="moveItem('top')" [disabled]="!isMoveAvailable('top')">
      <span>{{ 'DASHBOARD.LAYER_MOVE.FORWARD' | translate }}</span>
      <mat-icon>keyboard_double_arrow_up</mat-icon>
    </button>
    <button (click)="moveItem('bottom')" [disabled]="!isMoveAvailable('bottom')">
      <span>{{ 'DASHBOARD.LAYER_MOVE.BACKWARD' | translate }}</span>
      <mat-icon>keyboard_double_arrow_down</mat-icon>
    </button>
  </div>

  <div class="row" *ngIf="layerBroken">
    <button (click)="fixLayers()">
      <span>{{ 'DASHBOARD.FIX_LAYERS' | translate }}</span>
      <mat-icon svgIcon="settings"></mat-icon>
    </button>
  </div>
</div>
