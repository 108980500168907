export function assignOldObjectValues(target, source) {
  if (!source) return;
  Object.keys(target).forEach((key: string) => {
    if (target[key] && typeof target[key] === 'object' && typeof source[key] === 'object')
      return assignOldObjectValues(target[key], source[key]);
    else {
      if (source[key] == null) delete target[key];
      else target[key] = source[key];
      return;
    }
  });
  assignOldValues(target, source);
  // iterate by Object.keys(source)
  // assign only if source have value that target doesnt
}

function assignOldValues(target, source) {
  Object.keys(source).forEach((key: string) => {
    if (source[key] && source[key] === 'object' && target[key]) {
      assignOldValues(source[key], target[key]);
    } else if (source[key] != null) target[key] = source[key];
  });
}
//what if source has properties that target doesnt
