/**
 * EMU API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserMetadataDto } from './userMetadataDto';

export interface ProfileSaveRequestDto {
  /**
   * User e-mail
   */
  email: string;
  /**
   * Name of this user.
   */
  name: string;
  /**
   * Preferred nickname or alias of this user.
   */
  nickname: string;
  /**
   * URL to picture, photo, or avatar of this user
   */
  picture: string;
  /**
   * User metadata to which this user has read/write access
   */
  user_metadata: UserMetadataDto;
  /**
   * Given name/first name/forename of this user
   */
  given_name?: string;
  /**
   * Family name/last name/surname of this user
   */
  family_name?: string;
  /**
   * Connection for e-mail update
   */
  connection: string;
}
