<app-modal
  [title]="getTitle()"
  [saveButtonName]="getSaveButtonName()"
  [cancelButtonName]="getCancelButtonName()"
  (save)="onLogout()"
>
  <ng-container body>
    <div mat-dialog-content>{{ 'DIALOG.LOGOUT_PROMPT' | translate }}</div>
  </ng-container>
</app-modal>

<!--<h1 class="item" mat-dialog-title>{{'DIALOG.LOGOUT_TITLE'|translate}}</h1>-->
<!--<div mat-dialog-content>{{'DIALOG.LOGOUT_PROMPT'|translate}}</div>-->
<!--<div mat-dialog-actions class="align-right">-->
<!--  <button mat-flat-button mat-dialog-close  class="button-primary">{{'BUTTON.CANCEL'|translate}}</button>-->
<!--  <button mat-flat-button mat-dialog-close cdkFocusInitial  class="button-warn" (click)="onLogout()">-->
<!--    {{'BUTTON.LOGOUT'|translate}}-->
<!--  </button>-->
<!--</div>-->
