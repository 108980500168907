export enum TreeNodeActionType {
  'ON_CLICK', // called node is clicked
  'ON_ADD_CLICKED', // called when add button is clicked
  'ON_EDIT_CLICKED', // called when edit button is clicked
  'ON_MOVE_START', //
  'ON_MOVE_ENDED', //
  'ON_DELETE_CLICKED', // called when delete button is clicked
  'ON_EXPAND', // called when node is expanded/collapsed
  'ON_INIT', // called on init and after node edit
  'ON_ROLE_CHANGE', // called when role changes
  'ON_ROLE_REMOVED', // called when role removed
}
