import { SignupAccountConfiguration } from '../models/AccountConfiguration.model';
import { UserTypes } from '../models/UserTypes.enum';
import { accountConfigurations } from '../data/AccountConfigurations';

export function assignSignupConfiguration(data: SignupAccountConfiguration, userType: UserTypes) {
  accountConfigurations.forEach((config) => {
    const configData = config.data.filter((o) => o.userType.includes(userType));
    data[config.name] = configData.length > 0 ? configData[0]?.value : null;
  });
}
