/**
 * EMU Invoice API
 * EMU Invoice API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DbDefaultResponse } from '../model/dbDefaultResponse';
import { ListFilterRequestDto } from '../model/listFilterRequestDto';
import { PriceListDto } from '../model/priceListDto';
import { PriceListListResponseDto } from '../model/priceListListResponseDto';
import { PriceListSaveDto } from '../model/priceListSaveDto';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class PriceListService {
  protected basePath = 'http://127.0.0.1:4201/api/';

  public defaultHeaders = new HttpHeaders();

  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public priceListControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DbDefaultResponse>;
  public priceListControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DbDefaultResponse>>;
  public priceListControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DbDefaultResponse>>;
  public priceListControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling priceListControllerDelete.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DbDefaultResponse>(
      'delete',
      `${this.basePath}/price-list/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public priceListControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<PriceListDto>;
  public priceListControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<PriceListDto>>;
  public priceListControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<PriceListDto>>;
  public priceListControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling priceListControllerGet.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<PriceListDto>(
      'get',
      `${this.basePath}/price-list/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   *
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public priceListControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<PriceListListResponseDto>;
  public priceListControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<PriceListListResponseDto>>;
  public priceListControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<PriceListListResponseDto>>;
  public priceListControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling priceListControllerList.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<PriceListListResponseDto>(
      'post',
      `${this.basePath}/price-list/list`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   *
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public priceListControllerSave(
    body: PriceListSaveDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<any>;
  public priceListControllerSave(
    body: PriceListSaveDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<any>>;
  public priceListControllerSave(
    body: PriceListSaveDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<any>>;
  public priceListControllerSave(
    body: PriceListSaveDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling priceListControllerSave.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/price-list`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
