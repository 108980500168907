import { Injectable, signal } from '@angular/core';
import { CookieService } from 'ngx-cookie';
@Injectable({
  providedIn: 'root',
})
export class CookiesConsentService {
  constructor(private cookieService: CookieService) {}

  public cookiesConsented = signal(this.cookieService.get('cookie-consent'));

  public setCookiesConsent() {
    this.cookieService.put('cookie-consent', 'consented');
    this.cookiesConsented.set('consented');
  }
}
