import { uplotGetScales } from '../../graph/config/uplotScales';

export function heatmapDailyScales() {
  const scales = uplotGetScales();

  //TODO
  const DAY_IN_UPLOT_TIME = 3600 * 24;
  scales.x.minRange = DAY_IN_UPLOT_TIME * 7;
  scales.x.maxRange = (DAY_IN_UPLOT_TIME * 365) / 2;

  scales.y = <any>{
    auto: false,
    dir: -1,
    range: (u, min, max) => {
      min = min ?? 0;
      max = max ?? 12;

      // const offset = 0.5;
      // min -= offset;
      // max += offset;

      const diff = 12 - (max - min);
      if (diff != 0) {
        min -= diff / 2;
        max += diff / 2;
      }
      return [min, max];
    },
  };

  scales.x = <any>{
    auto: false,
    range: () => {
      return [0.5, 31.5];
      // return [0, 23];
    },
  };

  return scales;
}
