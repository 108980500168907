/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.4
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MetadataUpdateKey {
  /**
   * Key of metadata
   */
  key: string;
  /**
   * metadata_id
   */
  metadata_id: number;
  /**
   * visible
   */
  visible?: boolean;
  /**
   * inherit
   */
  inherit?: boolean;
  /**
   * MetadataSource
   */
  preferred_source?: MetadataUpdateKey.PreferredSourceEnum;
}
export namespace MetadataUpdateKey {
  export type PreferredSourceEnum = 'INHERITED' | 'DEVICE' | 'DIRECT';
  export const PreferredSourceEnum = {
    INHERITED: 'INHERITED' as PreferredSourceEnum,
    DEVICE: 'DEVICE' as PreferredSourceEnum,
    DIRECT: 'DIRECT' as PreferredSourceEnum,
  };
}
