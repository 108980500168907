import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class IconModule {
  private path = '../../assets-common/icons';

  public icons: ReadonlyArray<string> = [
    'refresh',
    'filter',
    'download',
    'edit',
    'edit2',
    'certificate',
    'ok',
    'logger',
    'file',
    'dashboard',
    'lora',
    'folder',
    'user',
    'delete',
    'add',
    'add2',
    'calendar',
    'settings',
    'building',
    'tree',
    'info',
    'go_back',
    'chart2',
    'document',
    'electric_meter',
    'image',
    'text',
    'align-center',
    'align-left',
    'align-right',
    'align-justify',
    'graph',
    'add_circle',
    'policy',
    'eye',
    'user_policy',
    'send',
    'history',
    'device',
    'server',
    'database',
    'socket',
    'ssd_round',
    'devices',
    'lock',
    'arrow-up',
    'arrow-down',
    'tiles',
    'table',
    'm_center',
    'emu_prof',
  ];

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.icons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(icon, this.setPath(`${this.path}/${icon}.svg`));
    });
  }

  private setPath(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
