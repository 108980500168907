import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { DashboardItemDataGraph } from '../../../models/DashboardItemDataGraph.model';
import { LayoutService } from '../../../../../layout/layout.service';
import { BehaviorSubject, filter, skip, Subject, switchMap, take, takeUntil } from 'rxjs';
import { TreeService } from '../../../../tree/tree.service';
import { TreeNode } from '../../../../tree/tree-controller/tree-async/models/TreeNode';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EmuInputComponent } from '../../../../../shared/components/inputs/emu-input/emu-input.component';
import { AsyncPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { SeriesDataStructureDto, TreeDataStructureDto } from '../../../../../../api-main';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SankeyChartData } from './SankeyChartData.dto';
import { MatTooltip } from '@angular/material/tooltip';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddSeriesModalComponent } from './add-series-modal/add-series-modal.component';
import { ValueDataModel } from '../../../models/ValueData.model';
import { AlertService } from '../../../../../shared/services/alert.service';
import { SankeyChartConfigItemComponent } from './sankey-chart-config-item/sankey-chart-config-item.component';
import { ConfirmDeleteDialogComponent } from '../../../../../shared/components/dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { SingleInputModalComponent } from '../../../../../shared/components/single-input-modal/single-input-modal.component';

export type DataStructureTree = {
  id?: number;
  item_id?: number;
  code?: string;
  parent_id?: number;
  series?: Array<SeriesDataStructureDto>;
  item_name: string;
  children?: DataStructureTree[];
  type?: SankeyChartData['type'];
};
export type RootDataStructureTree = DataStructureTree & {
  parents?: DataStructureTree[];
};
@Component({
  selector: 'app-sankey-chart-config',
  standalone: true,
  imports: [
    EmuInputComponent,
    AsyncPipe,
    MatProgressBar,
    NgIf,
    MatIcon,
    NgTemplateOutlet,
    NgForOf,
    MatButton,
    TranslateModule,
    MatMiniFabButton,
    MatTooltip,
    SankeyChartConfigItemComponent,
  ],
  templateUrl: './sankey-chart-config.component.html',
  styleUrl: './sankey-chart-config.component.scss',
})
export class SankeyChartConfigComponent implements AfterViewInit {
  @Input() data: DashboardItemDataGraph;

  constructor(
    private layoutService: LayoutService,
    private treeService: TreeService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translate: TranslateService,
  ) {}

  unsubscribe = takeUntilDestroyed();

  nodeFromTreeRequest$ = new BehaviorSubject<boolean>(false);

  rootItem: TreeNode;

  structure: RootDataStructureTree;

  // root: RootDataStructureTree;

  loading$ = new BehaviorSubject<boolean>(false);

  private addOnlyLoggers: boolean = false;

  ngAfterViewInit() {
    if (this.data.sankeyRootItem) {
      this.rootItem = this.data.sankeyRootItem;
      if (this.data.sankeyData) {
        this.structure = this.revertApplyDataToChart(this.data.sankeyData);
      } else this.getDataStructure(+this.rootItem.id);
    } else this.getNodeFromTree();
  }

  selectedItemCleared() {
    this.nodeFromTreeRequest$.next(false);
    this.rootItem = null;
    this.structure = null;
    this.data.sankeyData = null;
    this.data.refresh = true;
    this.cd.detectChanges();
  }

  getNodeFromTree() {
    this.nodeFromTreeRequest$.next(true);
    this.layoutService.treeNodeService
      //to verify 'OTHER'
      .getItemClick(['FOLDER', 'OTHER'])
      .pipe(
        take(1),
        switchMap((item: TreeNode) => {
          this.rootItem = item;
          this.data.sankeyRootItem = item;
          this.loading$.next(true);
          this.cd.detectChanges();
          return this.treeService.getDataStructure(+item.id);
        }),
        takeUntil(this.nodeFromTreeRequest$.pipe(skip(1))),
        this.unsubscribe,
      )
      .subscribe((structure: TreeDataStructureDto[]) => {
        // console.log('=>(sankey-chart-config.component.ts:63) response', structure);
        this.nodeFromTreeRequest$.next(false);
        this.structure = this.createTreeStructure(structure);

        // console.log('structure', this.structure);
        this.loading$.next(false);
        this.cd.detectChanges();
      });
  }

  private getDataStructure(id: number) {
    this.loading$.next(true);
    this.treeService
      .getDataStructure(id)
      .pipe(this.unsubscribe)
      .subscribe((structure: TreeDataStructureDto[]) => {
        // console.log('=>(sankey-chart-config.component.ts:63) response', structure);
        this.structure = this.createTreeStructure(structure);
        this.loading$.next(false);
        this.cd.detectChanges();
      });
  }

  private createTreeStructure(data: TreeDataStructureDto[]): RootDataStructureTree {
    const root: DataStructureTree = data.find((o) => o.id === this.rootItem.id);
    // const hasAnyChildren = (id: number) => data.filter((o) => o.parent_id == id).length > 0;

    for (let i = 0; i < data.length; i++) {
      const item: DataStructureTree = <DataStructureTree>data[i as number];
      item.children = data.filter((o) => o.parent_id == item.id);
    }

    this.assignData(root);
    this.addSeriesFromChildren(root);

    // return root;

    return {
      id: 0,
      series: [],
      item_name: 'GRAPH.CONSUMPTION_TOTAL',
      parents: [root].map((o) => ({ ...o, children: null })),
      children: root.children,
    };
  }

  private assignData(parent: DataStructureTree, root: DataStructureTree = parent) {
    if (!root.series) root.series = [];
    if (parent.children != null && parent.children.length == 0) return true;

    const childrenToDelete = new Set();
    if (parent.children)
      parent.children.forEach((child, id) => {
        const gotData = this.assignData(child, root);
        if (gotData) {
          if (!parent.series) parent.series = [];
          if (child.series) parent.series.push(...child.series);

          if (this.addOnlyLoggers && TreeService.isLoggerNode(child.code))
            // parent.series.push({ logger_id: child.item_id });
            root.series.push({ logger_id: child.item_id });
          childrenToDelete.add(id);
        }
      });
    if (parent.children) {
      parent.children = parent.children.filter((o, i) => !childrenToDelete.has(i));
      if (parent.children?.length == 0) parent.children = null;
    }

    if (parent.series != null)
      parent.series = this.filterOutDuplicates(parent.series).filter((o) => o.logger_id != null);
    if ((!parent.series || parent.series.length == 0) && !parent.children) return true;

    return false;
  }

  private filterOutDuplicates(series: DataStructureTree['series']) {
    return (
      series
        //sort series to preserve series with most data (in case of duplicates)
        .sort((a, b) => Object.keys(b)?.length - Object.keys(a)?.length)
        .filter(
          (o, i, self) =>
            self.findIndex(
              (s) => s.logger_id == o.logger_id && s.device_value_id == o.device_value_id,
            ) == i,
        )
        .sort((a, b) => {
          if (a.logger_id != b.logger_id) return a.logger_id - b.logger_id;
          else return a.device_value_id - b.device_value_id;
        })
    );
  }

  private addSeriesFromChildren(root: DataStructureTree) {
    const addSeriesFromChildren = (parent: DataStructureTree) => {
      if (!parent.series) parent.series = [];

      if (parent.children)
        parent.children.forEach((child) => {
          const childSeries = addSeriesFromChildren(child);
          if (childSeries) parent.series.push(...childSeries);
          parent.series = this.filterOutDuplicates(parent.series);
        });

      return this.filterOutDuplicates(parent.series);
    };

    root.series = addSeriesFromChildren(root);
  }

  applyDataToChart() {
    const assignToNewDataType = (
      data: DataStructureTree | RootDataStructureTree,
      iteration = 1,
    ): SankeyChartData => {
      return {
        id: data.id,
        item_name: data.item_name,
        series: data.series,
        order: iteration,
        type: data.type ?? 'meter',
        children: data.children
          ? data.children.map((o) => assignToNewDataType(o, iteration + 1))
          : null,
        parents:
          'parents' in data && data.parents
            ? data.parents.map((o) => assignToNewDataType(o, iteration - 1))
            : null,
      };
    };

    const root = assignToNewDataType(JSON.parse(JSON.stringify(this.structure)));
    // console.log('=>(sankey-chart-config.component.ts:354) root', root);
    this.data.sankeyData = root;
    this.data.refresh = true;
  }

  private revertApplyDataToChart(_data: SankeyChartData) {
    const assignToNewDataType = (data: SankeyChartData): DataStructureTree => {
      return <any>{
        id: data.id,
        item_name: data.item_name,
        series: data.series,
        type: data.type,
        children: data.children ? data.children.map(assignToNewDataType) : null,
        parents: 'parents' in data && data.parents ? data.parents.map(assignToNewDataType) : null,
      };
    };

    return assignToNewDataType(_data);
  }

  addSeries(item: DataStructureTree) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {};
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(AddSeriesModalComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((data: ValueDataModel) => {
        if (!data) return;

        if (!item.series) item.series = [];
        const found = item.series.find(
          (o) => o.logger_id == data.loggerId && o.device_value_id == data.valueId,
        );
        if (found) return;

        item.series.push({
          logger_id: data.loggerId,
          device_value_id: data.valueId,
          device_id: data.deviceId,
          name: data['name'],
          obis_code: data['obis_code'],
        });
        this.cd.detectChanges();
      });
  }

  removeSeries(item: DataStructureTree, series: SeriesDataStructureDto) {
    this.deleteConfirm(() => {
      const index = item.series.findIndex(
        (o) => o.logger_id == series.logger_id && o.device_value_id == series.device_value_id,
      );
      if (index >= 0) item.series.splice(index, 1);
      this.cd.detectChanges();
    });
  }

  removeItem(
    parent: DataStructureTree,
    item: DataStructureTree,
    prop: 'children' | 'parents' = 'children',
  ) {
    this.deleteConfirm(() => {
      const id = parent[prop].findIndex((o) => o.id == item.id);
      if (id >= 0) parent[prop].splice(id, 1);
      this.cd.detectChanges();
    });
  }

  private deleteConfirm(fn: () => void) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {};
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((o) => !!o),
      )
      .subscribe(() => {
        fn();
      });
  }

  cancelAddingItem$ = new Subject<void>();

  addingItem: { item: DataStructureTree; prop: 'children' | 'parents' } | null = null;

  addItem(parent: DataStructureTree, prop: 'children' | 'parents' = 'children') {
    this.addingItem = { item: parent, prop };
    this.cancelAddingItem$.next();
    this.alertService.info(this.translate.instant('TREE.SELECT_ON_TREE'));

    this.layoutService.treeNodeService
      .getItemClick(['FOLDER', 'OTHER'])
      .pipe(takeUntil(this.cancelAddingItem$), take(1), this.unsubscribe)
      .subscribe((item: TreeNode) => {
        this.addingItem = null;
        // eslint-disable-next-line security/detect-object-injection
        if (!parent[prop]) parent.children = [];
        // eslint-disable-next-line security/detect-object-injection
        parent[prop].push(<any>{
          ...item,
          id: `t-${item.id}`,
          code: item.item_type_code,
        });
        this.cd.detectChanges();
      });
  }

  addItemToParents(parent: RootDataStructureTree) {
    this.addItem(parent, 'parents');
  }

  editItem(item: DataStructureTree) {
    this.openNameModal((val) => {
      item.item_name = val;
      this.cd.detectChanges();
    }, item.item_name);
  }

  cancelAddingItem() {
    this.cancelAddingItem$.next();
    this.addingItem = null;
  }

  addCustomItem() {
    if (!this.addingItem.item || !this.addingItem.prop) throw new Error('No adding item data');
    this.openNameModal((name) => {
      this.addingItem.item[this.addingItem.prop].push(<any>{
        item_name: name,
        id: window?.crypto?.randomUUID() ?? Math.random().toString(),
        code: null,
      });
      this.cancelAddingItem();
      this.cd.detectChanges();
    });
  }

  openNameModal(callback: (value: string) => void, value: string = '') {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'CREATE_DIALOG.INPUT_NAME',
      label: 'TREE.ITEM_NAME',
      value: value,
    };
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(SingleInputModalComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((o) => !!o),
      )
      .subscribe((val) => {
        callback(val);
      });
  }
}
