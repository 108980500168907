export * from './counterTemplate.service';
import { CounterTemplateService } from './counterTemplate.service';
export * from './general.service';
import { GeneralService } from './general.service';
export * from './invoice.service';
import { InvoiceService } from './invoice.service';
export * from './invoicePdf.service';
import { InvoicePdfService } from './invoicePdf.service';
export * from './invoiceTemplate.service';
import { InvoiceTemplateService } from './invoiceTemplate.service';
export * from './priceList.service';
import { PriceListService } from './priceList.service';
export const APIS = [
  CounterTemplateService,
  GeneralService,
  InvoiceService,
  InvoicePdfService,
  InvoiceTemplateService,
  PriceListService,
];
