import { uplotGetSize } from '../config/uplotDrawConfig';

//this plugin makes sure that graph is in right size
export function uplotPluginResize(container: HTMLElement) {
  let resizeObserver: ResizeObserver;
  let header: HTMLElement;
  let legend: HTMLElement;
  return {
    hooks: {
      init: (u) => {
        resizeObserver = new ResizeObserver(() =>
          setTimeout(() => u.setSize(uplotGetSize(container)), 0),
        );
        header = container.querySelector('.header');
        legend = container.querySelector('.u-legend');
        resizeObserver.observe(container);
        if (header) resizeObserver.observe(header);
        if (legend) resizeObserver.observe(legend);
      },
      destroy: () => {
        resizeObserver.unobserve(container);
        if (header) resizeObserver.unobserve(header);
        if (legend) resizeObserver.unobserve(legend);
      },
    },
  };
}
