import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TreeNodeService } from '../../TreeNodeService';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { TreeNodeRefreshOptions } from '../../models/TreeNodeRefreshOptions';
import { TreeNodeMutateOptions } from '../../models/TreeNodeMutateOptions';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { PresetTreeElementsService } from '../../preset-tree-elements.service';
import { TreeService } from '../../../../tree.service';
import { WaitService } from '../../../../../../shared/services/wait.service';
import { MatIconModule } from '@angular/material/icon';
import { EmuInputComponent } from '../../../../../../shared/components/inputs/emu-input/emu-input.component';
import { TreeItemFindResponseDto } from '../../../../../../../api-main';

@Component({
  selector: 'app-tree-search',
  templateUrl: './tree-search.component.html',
  styleUrls: ['./tree-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, MatIconModule, TranslateModule, EmuInputComponent],
})
export class TreeSearchComponent implements OnInit, OnDestroy {
  searchVisible: boolean = true;

  searchForm: FormGroup;

  unsubscribe$ = new Subject<void>();

  @Input() treeNodeService: TreeNodeService;

  private previousExpandState: Set<number | string>;

  private resetDistinct = false;

  showClearButton = false;

  constructor(
    private fb: FormBuilder,
    private treeService: TreeService,
    private waitService: WaitService,
    private translate: TranslateService,
    private presetElements: PresetTreeElementsService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      name: [],
    });
    this.searchForm.valueChanges
      .pipe(
        tap(() => this.updateClearButton()),
        map((o) => o.name),
        filter((name) => {
          if (name?.length > 0) {
            if (!this.previousExpandState)
              this.previousExpandState = new Set(this.treeNodeService.expandedNodes);
            return true;
          }
          this.searchIsEmpty();
          return false;
        }),
        debounceTime(400),
        tap(() => this.waitService.start()),
        distinctUntilChanged((prev, next) => {
          if (this.resetDistinct) {
            this.resetDistinct = false;
            return false;
          }
          return prev === next;
        }),
        switchMap((name) => {
          const presetItemsFind = this.presetElements
            .getPresetElements()
            .filter((o) =>
              this.translate.instant(o.item_name).toLowerCase().includes(name.toLowerCase()),
            );
          return this.treeService.findTree(name).pipe(
            catchError(() => of({ data: [] })),
            tap((o) => {
              if (o.data == null) o.data = [];
              if (presetItemsFind && o.data) o.data.push(...(<any>presetItemsFind));
            }),
          );
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (val) => {
          if (val) this.setFilter(val);
          this.waitService.stop();
        },
        error: () => {
          this.waitService.stop();
        },
      });

    this.treeNodeService.mutateNode$
      .pipe(
        filter((m) => m.mode == TreeNodeMutateOptions.ADD),
        filter(() => this.searchVisible == true),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((action) => {
        this.treeNodeService.filterOptions?.visibleIds?.push(action.node.id);
      });
  }

  private setFilter(val: TreeItemFindResponseDto) {
    // console.log('-> val', val);

    const visibleNodesID = new Set<number>();
    const expandedNodes = new Set<number>();
    if (val.data)
      val.data.forEach((item) => {
        visibleNodesID.add(item.id);
        if (!item.parents) return;
        item.parents.forEach((parentId) => {
          visibleNodesID.add(parentId);
          expandedNodes.add(parentId);
        });
      });

    //add root item
    visibleNodesID.add(this.presetElements.rootId);
    expandedNodes.add(this.presetElements.rootId);

    this.treeNodeService.filterOptions.filterIds = true;
    this.treeNodeService.filterOptions.visibleIds = Array.from(visibleNodesID);
    this.treeNodeService.expandedNodes = new Set(expandedNodes);

    this.treeNodeService.refresh(TreeNodeRefreshOptions.VIEW);
  }

  private searchIsEmpty() {
    this.treeNodeService.filterOptions.filterIds = false;
    if (this.previousExpandState != null)
      this.treeNodeService.expandedNodes = new Set(this.previousExpandState);
    this.treeNodeService.refresh(TreeNodeRefreshOptions.VIEW);
    this.resetDistinct = true;
    this.previousExpandState = null;
  }

  private updateClearButton() {
    const value = this.searchForm.get('name').value;
    this.showClearButton = value && value.length > 0;
    this.cdr.detectChanges();
  }

  clearSearchInput() {
    this.searchForm.get('name').setValue('');
    this.updateClearButton();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
