import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TreeService } from '../../../tree.service';
import { TreeItemTypeListItemDto, TreeItemTypeListResponseDto } from '../../../../../../api-main';
import { TreeFormData } from '../TreeFormData';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SkeletonComponent } from '../../../../../shared/components/skeleton/skeleton.component';
import { MatIconModule } from '@angular/material/icon';
import { NgFor } from '@angular/common';
import { TreeDialogMode } from '../TreeDialogMode';

@Component({
  selector: 'app-tree-form-select-node-type',
  templateUrl: './tree-form-select-node-type.component.html',
  styleUrl: './tree-form-select-node-type.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, MatIconModule, SkeletonComponent],
})
export class TreeFormSelectNodeTypeComponent implements OnInit {
  // @Input({ required: true }) typeSelected$: BehaviorSubject<TreeItemTypeListItemDto>;

  @Input({ required: true }) data: TreeFormData;

  loaded = false;

  @Output() changes = new EventEmitter<void>();

  constructor(
    private treeService: TreeService,
    private cdr: ChangeDetectorRef,
  ) {}

  private $unsubscribe = takeUntilDestroyed();

  ngOnInit() {
    this.treeService
      .getItemTypes()
      .pipe(this.$unsubscribe)
      .subscribe((types: TreeItemTypeListResponseDto) => {
        this.loaded = true;
        this.nodeTypes = types.data;
        if (this.data.item && this.data.mode == TreeDialogMode.Edit)
          this.data.typeSelected$.next(
            this.nodeTypes.find((o) => o.id == this.data.item.item_type_id),
          );
        this.changes.emit();
        this.cdr.detectChanges();
      });
  }

  nodeTypes: TreeItemTypeListItemDto[];

  typeClicked(type: TreeItemTypeListItemDto) {
    this.data.typeSelected$.next(type);
    this.cdr.detectChanges();
  }
}
