import { fromEvent, take } from 'rxjs';

export function uplotZoom(u, mod: 1 | -1, calcRangeInHours: () => number) {
  let factor = mod * calcRangeInHours();

  let zoomed = false;
  const zoom = () => {
    zoomUplot(u, factor);
    factor = mod * calcRangeInHours();
    zoomed = true;
  };

  const interval = setInterval(zoom, 100);

  const cancelInterval = () => {
    // console.log('canceled');
    clearInterval(interval);
    if (!zoomed) zoom();
  };

  fromEvent(document, 'mouseup').pipe(take(1)).subscribe(cancelInterval);
}

function zoomUplot(u, factor) {
  // console.log('-> factor', factor);

  const zoomFactor = (factor * 3600) / 10;

  const min = u.scales.x.min;
  const max = u.scales.x.max;

  const nMin = min + zoomFactor;
  const nMax = max - zoomFactor;

  u.setScale('x', {
    min: nMin,
    max: nMax,
  });
}
