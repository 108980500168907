import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  // TODO
  // We could expand this service by providing every table an id, make this service included in base-list.component.ts.
  // This would require changing every constructor, but it would enable us to make refresh from any point of application
  public refreshTable$: Subject<void> = new Subject<void>();
}
