/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GpInvitationDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * Item identification. If not present or 0 - will save as new item
   */
  id?: number;
  /**
   * Reference to group policy
   */
  gp_id: number;
  /**
   * Is active
   */
  is_active?: boolean;
  /**
   * Policy is valid from date. Could be null
   */
  dt_from: string;
  /**
   * Policy is valid to date. Could be null
   */
  dt_to: string;
  /**
   * User identification string. Present after accepting invitation
   */
  user_id?: string;
  /**
   * Member e-mail
   */
  email: string;
  /**
   * email status
   */
  email_status?: GpInvitationDto.EmailStatusEnum;
  /**
   * Date and time when this user accepted invitation (ISO_8601 format).
   */
  accepted_at?: string;
}
export namespace GpInvitationDto {
  export type EmailStatusEnum = 'PROCESSING' | 'FAILED' | 'SENT';
  export const EmailStatusEnum = {
    PROCESSING: 'PROCESSING' as EmailStatusEnum,
    FAILED: 'FAILED' as EmailStatusEnum,
    SENT: 'SENT' as EmailStatusEnum,
  };
}
