import { fillStyle, getMinMax, updatePaletteBreakPoints } from '../heatmapDrawUtils';
import { LocaleSessionService } from '../../../../services/localeSession.service';
export function heatmapHourlyDraw(opts: { localeService: LocaleSessionService }) {
  // let global min/max
  let uplot: any;
  const xValueMultiplier = 1e3;
  const yAxisPointCount = 24;
  const timezoneOffsetCache = {};

  function getDayWidth(u: any): number {
    const startDate = new Date(0);
    const endDate = new Date(0);
    startDate.setFullYear(2000);
    endDate.setFullYear(2004);
    const diff =
      u.valToPos(endDate.getTime() / xValueMultiplier, 'x', true) -
      u.valToPos(startDate.getTime() / xValueMultiplier, 'x', true);

    return diff / 4 / 365;
  }

  return {
    hooks: {
      draw: (u) => {
        const { ctx, data } = u;
        // console.log('=>(heatmapHourlyDraw.ts:25) data', data);
        if (!u.heatmap) u.heatmap = {};
        uplot = u;
        updatePaletteBreakPoints(uplot);

        // console.log('=>(heatmapDailyDraw.ts:69) data', data);
        // console.log('=>(heatmapDailyDraw.ts:48) u', u);

        let xData: Array<number> = data[0];
        let yData: Array<Array<number>> = data.slice(1).filter((o, i) => u.series[i + 1]?.show);
        if (!yData[0] || !xData) return;

        //Find winter time change

        // console.log('=>(heatmapDailyDraw.ts:74) yData', yData);
        const getValue = (i: number) => {
          const value = yData.map((o) => o[i]).reduce((acc, val) => acc + val, 0);
          if (isNaN(value)) {
            console.log('data:', data);
            throw new Error(`invalid value for index ${i}`);
          }
          return value;
        };

        const [minValue, maxValue] = getMinMax(yData);
        u.heatmap.min = minValue;
        u.heatmap.max = maxValue;

        ctx.save();
        ctx.beginPath();
        ctx.rect(u.bbox.left, u.bbox.top, u.bbox.width, u.bbox.height);
        ctx.clip();

        const boxTopOffset: number = u.bbox.top;
        const boxHeight: number = u.bbox.height; // - boxTopOffset;

        //DRAWN BOXES SIZE:
        const height = boxHeight / yAxisPointCount;
        const width = getDayWidth(u) * 1.02;
        u.heatmap.items = [];
        const drawBlock = (
          val: number,
          i: number,
          options: { selected?: boolean; widthMod?: number } = {},
        ) => {
          const date = new Date(xData[i] * xValueMultiplier);

          if (!timezoneOffsetCache[opts.localeService.timezone])
            timezoneOffsetCache[opts.localeService.timezone] = {};
          //using cache to avoid performance issues
          const offset =
            timezoneOffsetCache[opts.localeService.timezone][date.getTime()] != undefined
              ? timezoneOffsetCache[opts.localeService.timezone][date.getTime()]
              : opts.localeService.getTimezoneOffsetInHours(date);
          timezoneOffsetCache[opts.localeService.timezone][date.getTime()] = offset;

          let hour = date.getUTCHours() + offset;
          if (hour >= 24) {
            hour = hour - 24;
            //move date to next day
            date.setUTCDate(date.getUTCDate() + 1);
          } else if (hour < 0) {
            hour = 24 - Math.abs(hour);
            date.setUTCDate(date.getUTCDate() - 1);
          }
          //reset hour for proper placement
          date.setUTCHours(0, 0, 0, 0);

          //calculate x position for every day
          const xPos = u.valToPos(date.getTime() / xValueMultiplier, 'x', true);

          //calculate y position based on hour (remember y=0 is top of the chart)
          const yPos = boxHeight - (hour + 1) * height + boxTopOffset;

          const x = xPos - width / 2;
          u.heatmap.items.push({
            id: i,
            x: x,
            y: yPos,
            width,
            height,
          });

          ctx.fillStyle = fillStyle(uplot, val, minValue, maxValue);

          if (options?.selected) {
            ctx.fillRect(x, yPos, width, height);
            ctx.strokeStyle = 'black';
            ctx.lineWidth = 2;
            ctx.strokeRect(x, yPos, width, height);
          } else {
            ctx.fillRect(x, yPos, width, height);
          }
        };

        for (let i = 0; i < xData.length; i++) {
          const val = getValue(i);
          drawBlock(val, i);
        }

        //DRAW CURRENTLY SELECTED BOX
        const selectedId = u.cursor.idx;
        if (selectedId != null && selectedId < xData.length) {
          drawBlock(getValue(selectedId), selectedId, { selected: true });
        }

        //RESTORE CTX TO DEFAULT STYLES
        ctx.restore();
      },
    },
  };
}
