/**
 * EMU Invoice API
 * EMU Invoice API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CustomerDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * id
   */
  id?: number;
  /**
   * organization_id
   */
  organization_id: number;
  /**
   * \"name\"
   */
  name: string;
  /**
   * address
   */
  address: string;
  /**
   * postal_code
   */
  postal_code: string;
  /**
   * city
   */
  city: string;
  /**
   * country
   */
  country: string;
  /**
   * vat_number
   */
  vat_number: string;
  /**
   * invoice_email
   */
  invoice_email: string;
}
