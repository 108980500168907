import { Injectable } from '@angular/core';
import { HttpHandler, HttpRequest } from '@angular/common/http';
import { UserService } from '../services/user.service';

@Injectable()
export class OrganizationInterceptor {
  constructor(private userService: UserService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const organizationId = this.userService.organizationId.value;
    // console.log('Intercepting request with organizationId:', organizationId);
    if (!organizationId) return next.handle(request);
    request = request.clone({
      headers: request.headers.set(
        'X-Organization',
        this.userService.organizationId.value.toString(),
      ),
    });
    return next.handle(request);
  }
}
