import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { DataRequestDto } from '../../../../../../api-main';
import { UplotMakeOpts } from '../../graph/uplotMakeUplot';
import { GraphButtonsComponent } from '../../graph/graph-buttons/graph-buttons.component';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { UplotRangePickerComponent } from '../../uplot-range-picker/uplot-range-picker.component';
import { FormatValuePipe } from '../../../../pipes/format-value.pipe';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { HeatmapLegendComponent } from '../heatmap-legend/heatmap-legend.component';
import { HeatMapComponent } from '../heat-map.component';
import { uplotMakeHeatmapDaily } from './uplotMakeHeatmapDaily';
import { UplotOnRescaleData, uplotRescalePlugin } from '../../graph/plugins/uplotPluginRescale';
import { debounceTime, filter, skip, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-heat-map-daily',
  standalone: true,
  imports: [
    GraphButtonsComponent,
    NgIf,
    UplotRangePickerComponent,
    NgForOf,
    FormatValuePipe,
    MatSlider,
    MatSliderThumb,
    HeatmapLegendComponent,
  ],
  templateUrl: '../heat-map.component.html',
  styleUrl: '../heat-map.component.scss',
  providers: [FormatValuePipe, DatePipe],
})
export class HeatMapDailyComponent extends HeatMapComponent implements AfterViewInit, OnDestroy {
  override displayedScale: DataRequestDto.ScaleEnum = DataRequestDto.ScaleEnum.DAILY;

  override fetchScale: DataRequestDto.ScaleEnum = DataRequestDto.ScaleEnum.DAILY;

  override makeChart(param: UplotMakeOpts) {
    return uplotMakeHeatmapDaily(param, this.heatmapPaletteColors);
  }

  override setScaleAndAllowRefreshOnScale() {
    // const offset = 0.5;

    // let min = this.formatDateToYScale(this.displayedRange.min); // - offset;
    // let max = this.formatDateToYScale(this.displayedRange.max); // + offset;

    const u = this.uplot;
    const [min, max] = u.scales.y.range(
      u,
      this.formatDateToYScale(this.displayedRange.min),
      this.formatDateToYScale(this.displayedRange.max),
    );

    setTimeout(() => {
      this.uplot.setScale('y', { min: min, max: max });
      setTimeout(() => (this.preventRefreshingOnRescale = false), 100);
    });
  }

  formatDateToYScale(_date: number): number {
    const currentYear = new Date().getFullYear();
    const date = new Date(_date * 1e3);
    return date.getMonth() + (date.getFullYear() - currentYear) * 12;
  }

  formatYScaleToDate(_date: number): Date {
    const date = new Date();
    date.setMonth(_date);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  override setDataOnRescale() {
    const onRescale: UplotOnRescaleData = new Subject();
    const cancelLastRequest = new Subject<void>();
    onRescale
      .pipe(
        filter(() => this.preventRefreshingOnRescale == false),
        skip(1), //skip first event after setting graph - consider starting listening only after graph is fully loaded
        debounceTime(400),
        // skipWhile(() => this.preventRefreshing || this.preventRefreshingOnRescale),
        takeUntil(this.unsubscribe),
      )
      .subscribe((rescale) => {
        const selectedRange = rescale.scalesY;
        const monthOffset = 1;
        const min = this.formatYScaleToDate(selectedRange.min - monthOffset);
        const max = this.formatYScaleToDate(selectedRange.max + monthOffset);

        // console.log('min', min, 'max', max);
        this.displayedRange = { min: min.getTime() / 1e3, max: max.getTime() / 1e3 };
        this.rangeChanged.emit(this.displayedRange);

        const getNewData = !this.isDataWithinTheScale();

        if (!getNewData) {
          cancelLastRequest.next();
          rescale.data.next(null);
          return;
        }

        this.setLoadedRange();
        cancelLastRequest.next();
        rescale.data.next(this.getData());
      });

    return uplotRescalePlugin(onRescale, cancelLastRequest, this.unsubscribe);
  }

  override isDataWithinTheScale(): boolean {
    if (!this.loadedRange || !this.displayedRange) return true;
    //2 months
    const maxDifference = (3600 * 24 * 365) / 12 / 2;

    return (
      this.loadedRange.min < this.displayedRange.min + maxDifference &&
      this.loadedRange.max > this.displayedRange.max - maxDifference
    );
  }
}
