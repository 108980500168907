import { UplotData } from '../models/UplotData.model';
import { UplotRange } from '../models/UplotRange.model';

export function uplotFormatDataTimezoneToLocal(data: UplotData): UplotData {
  return data;
  // data[0] = data[0].map((d) => {
  //   const date = new Date(d * 1000);
  //   const offset = date.getTimezoneOffset();
  //   return d - offset * 60;
  // });
  // return data;
}

export function uplotFormatRangeTimezoneToUtc(range: UplotRange): UplotRange {
  return {
    min: format(range.min),
    max: format(range.max),
  };
  function format(d: number) {
    const date = new Date(d * 1000);
    const offset = date.getTimezoneOffset();
    return d - offset * 60;
  }
}
