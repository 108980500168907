import { UplotSeriesConfig } from '../models/UplotSeriesConfig.model';
import { uplotDrawStyles, uplotPalette } from '../utils/uplotConstants';
import { uPlotDrawConfig } from './uplotDrawConfig';
import { uplotPaths } from './uplotPaths';
import tinycolor from 'tinycolor2';
import { SeriesCalculationMode } from '../models/SeriesCalculationMode';
import { consumptionGraphPathStyle } from '../../../../../pages/data/select-series/utils/addSubsetsToSeries';

export function uplotGetSeries(
  seriesConfig: UplotSeriesConfig[] = [],
  customDraw: boolean = false,
) {
  const series: any[] = [
    {
      label: 'X',
    },
  ];

  seriesConfig.forEach((config: UplotSeriesConfig, i) => {
    // console.log('-> config', config);
    let pathStyle = config.pathStyle ?? 'linear';

    if (config.mode == SeriesCalculationMode.CONSUMPTION_CALC || (<any>config).consumption)
      pathStyle = consumptionGraphPathStyle;

    const drawCfg = uPlotDrawConfig.find((o) => o.title == pathStyle);
    let stroke = config.stroke ?? uplotPalette[i % uplotPalette.length];
    const color = tinycolor(stroke);

    if (color.isValid()) stroke = color.toHex8String();
    const colorWithAlpha = color.setAlpha(color.getAlpha() * 0.3).toHex8String();

    //TODO figure out nice way to translate this
    //translateService.instant('INSPECT.NO_DATA')
    const label =
      config.label && config.label.length > 0 ? `${config.label} [${config.unit}]` : 'No Data';
    series.push({
      id: i,
      label: label,
      rawLabel: config.label ?? '',
      width: (1 / devicePixelRatio) * 2,
      paths: customDraw ? () => null : uplotPaths,
      drawStyle: drawCfg?.drawStyle ?? uplotDrawStyles.points,
      lineInterpolation: drawCfg?.lineInterpolation ?? null,
      stroke: stroke,
      fill: drawCfg?.fill ? colorWithAlpha : null,
      fillColor: colorWithAlpha,
      unit: config.unit ?? '',
      pathStyle: customDraw ? null : pathStyle,
      logger_id: config.logger_id,
      value_id: config.value_id,
      uniqueId: config.uniqueId,
      mode: config.mode,
      points: {
        show: false,
        // show: cfg.drawStyle !== uplotDrawStyles.multiBars,
      },
    });
  });
  return series;
}

function updateSeriesOnLegend(u) {
  const legend = u.root.querySelector('.u-legend');
  if (!legend) throw new Error('No legend element found!');
  legend.childNodes.forEach((element, i) => {
    element.classList.toggle('u-off', !u.series[i].show);
  });
}
export function uplotSetNewSeries(u, seriesConfig: UplotSeriesConfig[]) {
  const uplotSeries = uplotGetSeries(seriesConfig, u.disableDefaultSeriesDraw).slice(1);
  const activeSeriesLength = u.series.slice(1).length;
  const uplotSeriesId = (id) => id + 1;

  const saveShowProp: Array<{ show: boolean; uniqueId: string }> = [];

  for (let i = activeSeriesLength - 1; i >= 0; i--) {
    const series = u.series[uplotSeriesId(i)];
    saveShowProp.push({
      show: series.show,
      uniqueId: series.uniqueId,
    });
    //fixing error when deleting series
    u.delSeries(uplotSeriesId(i));
  }
  for (let i = 0; i < uplotSeries.length; i++) {
    const series = uplotSeries[i];
    series.show = saveShowProp.find((o) => o.uniqueId == series.uniqueId)?.show ?? true;
    u.addSeries(series, uplotSeriesId(i));
  }
  updateSeriesOnLegend(u);
}

export function uplotToggleSeriesFill(u, fill = true) {
  if (fill == true) {
    u.series.slice(1).forEach((serie) => {
      // serie.fill = () => serie.fillColor;

      // fill: (u, seriesIdx)
      serie.fill = () => {
        // console.log('=>(uplotSeriesConfiguration.ts:19) u', u);

        const TRANSPARENT = '#ffffff00';
        const minScale = u.scales.y.min ?? -Infinity;
        const maxScale = serie.min ?? Infinity;

        const deff = (maxScale - minScale) / 2;

        const staleStops = [
          [minScale, TRANSPARENT],
          [minScale + deff, serie.fillColor],
          [maxScale, serie.fillColor],
          // [max * 1.0, 'red'],
        ];
        // console.log('=>(uplotSeriesConfiguration.ts:19) staleStops', staleStops);

        return scaleGradient(u, serie.scale, 1, staleStops);
      };
    });
  }

  if (fill == false) {
    u.series.slice(1).forEach((serie) => {
      const cfg = uPlotDrawConfig.find((o) => o.title == serie.pathStyle);
      serie.fill = () => (cfg && cfg.fill ? serie.fillColor : null);
    });
  }
  u.redraw();
}

function scaleGradient(u, scaleKey, ori, scaleStops, discrete = false) {
  let scale = u.scales[scaleKey];

  // we want the stop below or at the scaleMax
  // and the stop below or at the scaleMin, else the stop above scaleMin
  let minStopIdx;
  let maxStopIdx;

  for (let i = 0; i < scaleStops.length; i++) {
    let stopVal = scaleStops[i][0];

    if (stopVal <= scale.min || minStopIdx == null) minStopIdx = i;

    maxStopIdx = i;

    if (stopVal >= scale.max) break;
  }

  if (minStopIdx == maxStopIdx) return scaleStops[minStopIdx][1];

  let minStopVal = scaleStops[minStopIdx][0];
  let maxStopVal = scaleStops[maxStopIdx][0];

  if (minStopVal == -Infinity) minStopVal = scale.min;

  if (maxStopVal == Infinity) maxStopVal = scale.max;

  let minStopPos = u.valToPos(minStopVal, scaleKey, true);
  let maxStopPos = u.valToPos(maxStopVal, scaleKey, true);
  let range = minStopPos - maxStopPos;

  let x0, y0, x1, y1;

  if (ori == 1) {
    x0 = x1 = 0;
    y0 = minStopPos;
    y1 = maxStopPos;
  } else {
    y0 = y1 = 0;
    x0 = minStopPos;
    x1 = maxStopPos;
  }

  let grd = u.ctx.createLinearGradient(x0, y0, x1, y1);

  let prevColor;

  for (let i = minStopIdx; i <= maxStopIdx; i++) {
    let s = scaleStops[i];

    let stopPos =
      i == minStopIdx
        ? minStopPos
        : i == maxStopIdx
          ? maxStopPos
          : u.valToPos(s[0], scaleKey, true);
    let pct = (minStopPos - stopPos) / range;

    if (discrete && i > minStopIdx) grd.addColorStop(pct, prevColor);

    grd.addColorStop(pct, (prevColor = s[1]));
  }

  return grd;
}
