import { uplotGetAxes } from '../../graph/config/uplotAxes';
import { UplotMakeOpts } from './uplotMakeHeatmapHourly';

export function heatmapHourlyAxes(param: UplotMakeOpts) {
  const axes = uplotGetAxes(param.valueFormatFunction, param.dateFormatFunction);

  // X AXE
  (<any>axes[0]).splits = (self: any, axisIdx: number, scaleMin: number, scaleMax: number) => {
    let start = scaleMin;
    const startDate = new Date(start * 1000);
    startDate.setHours(0, 0, 0, 0);
    start = startDate.getTime() / 1000;
    let end = scaleMax;
    const day = 24 * 3600;
    const arr = [];

    let currentDate = start;
    while (currentDate < end) {
      if (currentDate >= self.scales.x.min && currentDate <= self.scales.x.max)
        arr.push(currentDate);
      currentDate += day;
    }
    const widthMod = 65 * devicePixelRatio; //Its supposed to be date string length  + offset - basically how much width wee need for every axis label
    const width = self.bbox.width;
    const maxArrLength = Math.floor(width / widthMod);
    const arrLength = arr.length;

    if (arrLength <= maxArrLength) return arr;

    const delInterval = Math.max(Math.ceil(arrLength / maxArrLength), 2);

    return arr.filter((o, i) => i % delInterval == 0);
  };

  //Y AXE
  axes[1].values = (u, val) => {
    return val.map((hour: any) => {
      if (Math.floor(hour) != hour) return '';
      if (hour < 10) hour = `0${hour}`;
      return `${hour}:00`;
    });
  };

  return axes;
}
