import { Component, computed } from '@angular/core';
import { MatCard, MatCardActions, MatCardContent } from '@angular/material/card';
import { MatButton } from '@angular/material/button';
import { CookiesConsentService } from '../../services/cookiesConsent.service';
import { NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cookie-consent-banner',
  standalone: true,
  imports: [MatCard, MatCardContent, MatCardActions, MatButton, NgIf, TranslateModule],
  templateUrl: './cookie-consent-banner.component.html',
  styleUrl: './cookie-consent-banner.component.scss',
})
export class CookieConsentBannerComponent {
  constructor(
    private cookieConsentService: CookiesConsentService,
    protected translateService: TranslateService,
  ) {}

  public checkCookiesConsent = computed(() => {
    return this.cookieConsentService.cookiesConsented();
  });

  public consentCookie() {
    this.cookieConsentService.setCookiesConsent();
  }
}
