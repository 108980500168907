<div class="series-container" cdkDrag cdkDragBoundary="app-sankey-chart-config">
  <button mat-mini-fab class="warn-fab action-button hide-button"
          (click)="hideSeries.emit()" matTooltip="{{ 'BUTTON.CLOSE_EDIT' | translate }}">
    <mat-icon>close</mat-icon>
  </button>

<!--
isGridMeter grid- 1-0:1.8.* photovoltaic - 1-0:2.8.*
isMeter 1-0:2.8.*
isSubMeter 1-0:2.8.*
isPhotovoltaicMeter  1-0:2.8.*
-->

  <span class="name">{{ item.item_name }}

    <button mat-mini-fab class="primary-fab action-button"  (click)="editItem.emit(item)"
            [matTooltip]="'BUTTON.EDIT' | translate">
        <mat-icon svgIcon="edit"></mat-icon>
    </button>

  </span>
  <span class="item-type">

    <app-emu-list-input
      [name]="'DASHBOARD.SANKEY_SERIES_TYPE'"
      [autocomplete]="false"
      [className]="'dashboard-settings'"
      [filteredOptions$]="types$"
      [(value)]="item.type"
    >
    <ng-template let-type>
      <p class="type">
        {{ ('DASHBOARD.SANKEY_SERIES_TYPE_' + type.toUpperCase()) | translate }}
      </p>
    </ng-template>
  </app-emu-list-input>

  </span>

  @if(item.series){
    <ng-container *ngFor="let series of item.series">
      <div *ngIf="item" class="series">
        <span>{{getSeriesName(series)}}</span>
        <button mat-mini-fab class="warn-fab action-button" (click)="removeSeries.emit({item,series})"
                [matTooltip]="'DASHBOARD.DELETE_SERIES' | translate">
          <mat-icon svgIcon="delete"></mat-icon>
        </button>
      </div>
    </ng-container>
  }
  <div class="add-button">
    <button (click)="addSeries.emit(item)" mat-mini-fab class="primary-fab action-button"
            [matTooltip]="'DASHBOARD.ADD_SERIES' | translate">
      <mat-icon svgIcon="add"></mat-icon>
    </button>
  </div>
</div>


