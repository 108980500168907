/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DbListFrontendDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * Item identification
   */
  id: number;
  /**
   * Item shared?
   */
  shared: boolean;
  /**
   * Item parameters count
   */
  parameter_count: number;
  /**
   * Item max capacity
   */
  max_capacity: number;
  /**
   * Database name
   */
  db_name: string;
  /**
   * description
   */
  description: string;
}
