import { assignOldObjectValues } from './assignOldObjectValues';
import { DashboardItem } from '../models/DashboardItem';
import { deepEquals } from '../../../shared/utils/deepEquals';

export function restoreDashboardItemArray(target: DashboardItem[], source: DashboardItem[]) {
  for (let i = target.length - 1; i >= 0; i--) {
    //In reverse order, as we remove the elements
    const item = target[i];
    const saved = source.find((o) => o.id === item.id);
    if (!saved) {
      deleteItem(target, item);
      continue;
    }
    if (deepEquals(item, saved)) continue;
    assignOldObjectValues(item, saved);
    additionalActions(item);
  }
  //restore deleted items
  source.forEach((item) => {
    if (target.find((o) => o.id == item.id)) return;
    target.push(item);
  });
}

function deleteItem(items: DashboardItem[], item: DashboardItem) {
  items.splice(items.indexOf(item), 1);
}

function additionalActions(item) {
  if (item.data.graph) item.data.graph.refresh = true;
}
