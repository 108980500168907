<div class="dropdown">
  <ng-content select="button.dropdown-button"></ng-content>

  <button
    #button
    mat-mini-fab
    class="accent-fab dropdown-button"
    data-cy="button-dropdown"
    (click)="button.focus()"
  >
    <ng-content select="[header]"></ng-content>
  </button>
  <div class="dropdown-content options" [class.right]="position === 'right'" data-cy="dropdown-content">
    <ng-content select="[body]"></ng-content>
  </div>
</div>
