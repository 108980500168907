import { UplotResponseData } from '../models/UplotResponseData.model';

export function uplotValidateData(response: UplotResponseData) {
  if (response.data.length == 0) throw new Error('No data found');

  if (response.data.length != response.series.length + 1)
    throw new Error('Wrong data! (series and data count does not match)');

  const differentDataLength = response.data
    .map((data) => data.length)
    .filter((o, id, self) => id > 0 && o != self[id - 1]);
  if (differentDataLength.length > 0) throw new Error('Data arrays do not have equal length');
}
