import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DashboardService } from '../../../services/dashboard.service';
import { DashboardItem } from '../../../models/DashboardItem';
import { isItemBlocked } from '../../../utils/isItemBlocked';
import { TranslateModule } from '@ngx-translate/core';
import { LayerMoveDirection } from '../../../models/LayerMoveDirection.model';

@Component({
  selector: 'app-item-layer-buttons',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, TranslateModule],
  templateUrl: './item-layer-buttons.component.html',
  styleUrl: './item-layer-buttons.component.scss',
})
export class ItemLayerButtonsComponent {
  constructor(private dashboardService: DashboardService) {}

  get selectedItem(): DashboardItem {
    return this.dashboardService.selectedItem$.value;
  }

  moveItem(mode: LayerMoveDirection) {
    //swap layer indexes
    if (!this.isMoveAvailable(mode)) return;

    const itemIndex: number = this.dashboardService.items.findIndex(
      (el) => el.id === this.selectedItem.id,
    );
    const itemsArrLen = this.dashboardService.items.length;

    //TODO in future we can check what items are overlapping
    // and  move layer index within this overlap when moving back/front

    switch (mode) {
      case 'back':
        this.moveItemLayerBy(this.dashboardService.items[itemIndex - 1], 1);
        this.moveItemLayerBy(this.selectedItem, -1);
        break;

      case 'front':
        this.moveItemLayerBy(this.dashboardService.items[itemIndex + 1], -1);
        this.moveItemLayerBy(this.selectedItem, 1);
        break;

      case 'top':
        this.dashboardService.items
          .filter((o, i) => i > itemIndex)
          .forEach((el) => {
            this.moveItemLayerBy(el, -1);
          });

        this.moveItemLayerBy(this.selectedItem, itemsArrLen - this.selectedItem.layerIndex);
        break;

      case 'bottom':
        this.dashboardService.items
          .filter((o, i) => i < itemIndex)
          .forEach((el) => {
            this.moveItemLayerBy(el, 1);
          });

        this.moveItemLayerBy(this.selectedItem, -this.selectedItem.layerIndex);
        break;
    }

    this.dashboardService.sortLayers();
    this.fixLayers();
  }

  private moveItemLayerBy(item: DashboardItem, value: number) {
    item.layerIndex += value;
    // this.dashboardService.itemChanged$.next({
    //   id: item.id,
    //   mode: 'layer',
    //   value: value,
    // });
  }

  get layerBroken(): boolean {
    return this.dashboardService.items
      .map((o) => o.layerIndex)
      .some((o, i, self) => self.findIndex((val) => val == o) != i);
  }

  fixLayers() {
    this.dashboardService.fixLayers();
  }

  isMoveAvailable(mode: LayerMoveDirection) {
    const min = this.dashboardService.options.baseLayerIndex;
    const max = this.dashboardService.options.maxLayerIndex;
    const current = this.selectedItem?.layerIndex ?? this.dashboardService.options.baseLayerIndex;

    switch (mode) {
      case 'front':
      case 'top':
        return current < max;
      case 'back':
      case 'bottom':
        return current > min;
      default:
        return false;
    }
  }

  get itemBlocked(): boolean {
    return isItemBlocked(this.selectedItem);
  }
}
