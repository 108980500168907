import { computed, Injectable, signal } from '@angular/core';
import { OrganizationDto, OrganizationMemberDto } from '../../../api-main';
import { TreeNode } from '../../pages/tree/tree-controller/tree-async/models/TreeNode';
import { TreeNodeRoles } from '../../pages/tree/tree-controller/tree-async/models/TreeNodeRoles';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class PrivilegesService {
  public noOrganizationInfoDisplayed = false;

  private _checkOrganizationStatus$ = new Subject<void>();

  checkOrganizationStatus$ = this._checkOrganizationStatus$.asObservable();

  organizationModalTrigger$ = new Subject<boolean>();

  checkOrganizationStatus() {
    this._checkOrganizationStatus$.next();
  }

  private readonly itemEditRoles: ReadonlyArray<TreeNodeRoles> = ['Edit'];

  hasEditPrivileges(item: TreeNode) {
    if (!item) return false;
    return this.itemEditRoles.includes(item.role);
  }

  openOrganizationModal(disableClose: boolean = false) {
    this.organizationModalTrigger$.next(disableClose);
  }

  readonly hasTableEditAccess = computed<boolean>(() => {
    return true;
  });

  readonly organization = signal<OrganizationDto>(null);

  readonly organizationRole = computed<OrganizationMemberDto.RoleEnum>(() => {
    if (!this.organization()?.role) return null;
    return this.organization().role;
  });

  readonly isGPUser = computed<boolean>(() => {
    return this.organization() && this.organizationRole() == null;
  });

  readonly isOrganizationAdmin = computed<boolean>(() => {
    return this.organizationRole() != null;
  });

  readonly isInOrganization = computed<boolean>(() => {
    return this.isOrganizationAdmin() || this.isGPUser();
  });
}
