import { Component } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { DashboardItem } from '../../../models/DashboardItem';
import { getItemTypeIcon } from '../../../utils/itemTypeIcons';
import { DashboardItemType } from '../../../models/DashboardItemType.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { JsonPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-dashboard-layers',
  templateUrl: './dashboard-layers.component.html',
  styleUrl: './dashboard-layers.component.scss',
  standalone: true,
  imports: [MatTooltipModule, MatIconModule, JsonPipe, TranslateModule],
})
export class DashboardLayersComponent {
  protected readonly getItemTypeIcon = getItemTypeIcon;

  protected readonly DashboardItemType = DashboardItemType;

  get opened(): boolean {
    return this.dashboardService.layersOpened;
  }

  set opened(value: boolean) {
    this.dashboardService.layersOpened = value;
  }

  constructor(
    private dashboardService: DashboardService,
    private translate: TranslateService,
  ) {}

  get items(): Array<DashboardItem> {
    return this.dashboardService.items;
  }

  select(item: DashboardItem) {
    if (this.dashboardService.editMode$.value == false) return;
    this.dashboardService.selectedItem$.next(item);
  }

  get sortedItems() {
    return this.items.slice().reverse();
  }

  //TODO sort by created at date
  getItemId(item: DashboardItem) {
    const itemsOfType = this.dashboardService.items
      .filter((o) => o.type == item.type)
      .sort((a, b) => a.id.localeCompare(b.id));
    return itemsOfType.findIndex((o) => o.id == item.id) + 1;
  }

  layerSelected(id: string) {
    this.dashboardService.selectedLayerId$.next(id);
  }

  layerUnselected(id: string) {
    if (this.dashboardService.selectedLayerId$.value == id)
      this.dashboardService.selectedLayerId$.next(null);
  }

  getItemName(item: DashboardItem) {
    let name: string;
    const defaultName = `${this.translate.instant('DASHBOARD.TYPE_' + DashboardItemType[item.type])} #${this.getItemId(item)}`;

    name = item?.data?.graph?.name ?? item?.data?.valueDisplay?.header ?? null;
    if (name && name.length > 0) return name;

    return defaultName;
  }
}
