<ng-container *ngIf="data">
  <!--  -->

  @if (data.autoSize) {
    <div appFitText [style.justify-content]="justifyContent">
      <a
        [style.color]="data.color"
        [style.text-align]="data.align"
        [attr.href]="link ?? null"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ data.text }}
      </a>
    </div>
  } @else {
    <div *ngIf="!data.autoSize" [style.justify-content]="justifyContent">
      <a
        [style.color]="data.color"
        [style.text-align]="data.align"
        [style.font-size]="data.size + 'px'"
        [attr.href]="link ?? null"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ data.text }}
      </a>
    </div>
  }
</ng-container>
