import { BehaviorSubject, filter, Observable, Subject, take, takeUntil } from 'rxjs';
import { TreeNodeAction } from './models/TreeNodeAction';
import { TreeNodeRefresh } from './models/TreeNodeRefresh';
import { TreeNodeMutate } from './models/TreeNodeMutate';
import { TreeNode } from './models/TreeNode';
import { TreeNodeFilterOptions } from './models/TreeNodeFilterOptions';
import { TreeNodeConfig } from './models/TreeNodeConfig';
import { TreeNodeActionType } from './models/TreeNodeActionType';
import { TreeNodeRefreshOptions } from './models/TreeNodeRefreshOptions';
import { GpItemDto } from '../../../../../api-main';

type TreeLsData = {
  expandedNode: Array<number | string>;
  cachedNodes: TreeNode[];
  createDate: number;
};
type TreeNodeServiceConfig = {
  useCache?: boolean;
  shareData?: boolean;
  shareExpandState?: boolean;
};

export class TreeNodeService {
  readonly selectedNodeId$: BehaviorSubject<number | string>;

  readonly nodeAction$: Subject<TreeNodeAction>;

  readonly refresh$: Subject<TreeNodeRefresh>; //if refresh all;

  readonly mutateNode$: Subject<TreeNodeMutate>; //CURD operations

  filterOptions: TreeNodeFilterOptions = {};

  config: TreeNodeConfig = {
    expandAfterAddChild: true,
    displayActionButtons: false,
    selectNodes: true,
    displayRoles: false,
    hideNodesWithNoAccess: false,
  };

  toggleRoleDisplay(value: boolean = null, roleItems: Array<GpItemDto> = undefined) {
    // console.log('=>(TreeNodeService.ts:42) roleItems', roleItems);
    this.config.tempInheritedRole = [];
    if (roleItems !== undefined) this.config.roleItems = roleItems == null ? [] : roleItems;
    this.config.displayRoles = value != null ? value : !this.config.displayRoles;
    this.refresh(TreeNodeRefreshOptions.VIEW);
    //important to display roles on gp hover
    setTimeout(() => this.refresh(TreeNodeRefreshOptions.VIEW), 0);
  }

  toggleHighlight(itemId: number, value: boolean = null) {
    if (!this.config.highlightedNodes) this.config.highlightedNodes = new Set<number>();

    if (value == null) {
      value = this.config.highlightedNodes.has(itemId);
    }

    if (value == true) {
      this.config.highlightedNodes.add(itemId);
    } else {
      this.config.highlightedNodes.delete(itemId);
    }

    setTimeout(() => this.refresh(TreeNodeRefreshOptions.VIEW, { nodeId: itemId }), 0);
  }

  expandedNodes: Set<number | string>;

  expandableNodes: Set<number | string>;

  cachedNodes: TreeNode[] = [];

  readonly expandAll$: BehaviorSubject<boolean>;

  private treeMutateBC = new BroadcastChannel('tree-broadcast-channel-mutation');

  private treeExpandStateBC = new BroadcastChannel('tree-broadcast-channel-expand-state');

  private readonly treeStateLSKey: string = 'tree_state';

  private unsubscribe$ = new Subject<void>();

  //service should be destroyed when not needed anymore
  constructor(private readonly serviceConfig: TreeNodeServiceConfig = null) {
    //DEFAULT CONFIG
    this.serviceConfig = {
      shareData: true,
      shareExpandState: true,
      useCache: true,
      ...serviceConfig,
    };

    this.selectedNodeId$ = new BehaviorSubject(null);
    this.expandAll$ = new BehaviorSubject(null);
    this.nodeAction$ = new Subject();
    this.refresh$ = new Subject();
    this.mutateNode$ = new Subject();

    this.expandedNodes = new Set();
    this.expandedNodes.add(0);
    this.expandableNodes = new Set();

    if (this.serviceConfig.useCache) this.loadTreeState();
    if (this.serviceConfig.shareData) this.observeMutation();
    if (this.serviceConfig.shareExpandState) this.observeExpandState();
  }

  refresh(
    mode: TreeNodeRefreshOptions,
    options: {
      nodeId?: number | string;
      value?: any;
      itemId?: number;
    } = null,
  ) {
    this.refresh$.next({ mode, ...options });
  }

  saveTreeState(): void {
    //don't save if cache is turned off or filter applies
    if (!this.serviceConfig.useCache || this.filterOptions.filterIds == true) return;
    //don't save preset elements
    this.cachedNodes = this.cachedNodes.filter((o) => !isNaN(Number(o.id)) && Number(o.id) > 0);
    const data: TreeLsData = {
      expandedNode: Array.from(this.expandedNodes),
      cachedNodes: this.cachedNodes,
      createDate: Date.now(),
    };
    localStorage.setItem(this.treeStateLSKey, JSON.stringify(data));
  }

  clearCachedNodes() {
    this.serviceConfig.useCache = false;
    this.cachedNodes = [];
    const data: TreeLsData = {
      expandedNode: Array.from(this.expandedNodes),
      cachedNodes: [],
      createDate: Date.now(),
    };
    localStorage.setItem(this.treeStateLSKey, JSON.stringify(data));
    // localStorage.removeItem(this.treeStateLSKey);
  }

  getItemClick(
    availableTypes: string[],
    parentId = null,
    ids: number[] = null,
  ): Observable<TreeNode> {
    return new Observable<TreeNode>((observer) => {
      this.filterOptions.showOverlay = true;
      this.config.selectNodes = false;
      this.filterOptions.typeFilter = availableTypes;
      if (parentId) this.filterOptions.parentFilter = parentId;
      if (ids) {
        this.filterOptions.idFilter = ids;
      }
      this.refresh(TreeNodeRefreshOptions.VIEW);

      const restore = () => {
        this.filterOptions.showOverlay = false;
        this.filterOptions.typeFilter = null;
        this.filterOptions.parentFilter = null;
        this.filterOptions.idFilter = null;
        this.refresh(TreeNodeRefreshOptions.VIEW);
        this.config.selectNodes = true;
      };

      this.nodeAction$
        .pipe(
          filter((o) => o.action === TreeNodeActionType.ON_CLICK),
          take(1),
        )
        .subscribe((nodeAction) => {
          observer.next(nodeAction.node);
          restore();
          observer.complete();
        });

      return () => restore();
      //subscribe for click
    });
  }

  private loadTreeState(): void {
    let lsData: TreeLsData;
    try {
      const rawLsData = localStorage.getItem(this.treeStateLSKey);
      if (!rawLsData) return;
      lsData = JSON.parse(rawLsData);
      const DAY = 1e3 * 3600 * 24;
      if (lsData.createDate < Date.now() - DAY) {
        console.log('tree data is too old - skipping loading from cache');
        return;
      }
      this.expandedNodes = new Set(lsData.expandedNode);
      this.cachedNodes = lsData.cachedNodes;
    } catch (e) {
      console.warn('Failed to load previous tree state', e);
    }
  }

  private observeMutation() {
    this.treeMutateBC.onmessage = (event) => {
      this.mutateNode$.next(event.data);
    };
    this.mutateNode$.pipe(takeUntil(this.unsubscribe$)).subscribe((message) => {
      this.saveTreeState();
      if (message.broadcast) return;
      this.treeMutateBC.postMessage({ ...message, broadcast: true });
    });
  }

  private observeExpandState() {
    this.nodeAction$.pipe(takeUntil(this.unsubscribe$)).subscribe((message) => {
      if (message.action != TreeNodeActionType.ON_EXPAND) return;
      //to prevent overwriting
      this.saveTreeState();
      this.treeExpandStateBC.postMessage({ expandedNodes: this.expandedNodes, broadcast: true });
    });

    this.treeExpandStateBC.onmessage = (event) => {
      const expandedNodes = event.data.expandedNodes;
      this.expandedNodes = new Set(expandedNodes);
      this.refresh$.next({ mode: TreeNodeRefreshOptions.VIEW });
    };
  }

  destroy() {
    if (this.serviceConfig.useCache) this.saveTreeState();
    this.treeExpandStateBC.close();
    this.treeMutateBC.close();
    this.unsubscribe$.next();
  }
}
