export const environment = {
  production: true,
  matIconURL: 'https://fonts.google.com/icons',
  oem: [
    {
      id: 'oem0',
      domain: 'https://local.dev.ems.energy',
      api: 'api',
      iot: {
        ws_live: 'wss://wss-oem1.dev.ems.energy/dev',
        logger_prefix: 'logger',
      },
      title: 'Joulio-Web Dev',
      auth: {
        domain: 'oem1-example-1234.eu.auth0.com',
        clientId: 'qm4pPepHx6jUtDIC1gYZUqNlRmkAzbH5',
      },
    },
    {
      id: 'oem1',
      domain: 'https://oem1.dev.ems.energy',
      api: 'api',
      iot: {
        ws_live: 'wss://wss-oem1.dev.ems.energy/dev',
        logger_prefix: 'logger',
      },
      title: 'Joulio-Web',
      auth: {
        domain: 'joulioweb.eu.auth0.com',
        clientId: 'MPujdZsBgZiVbzBP8EhzAM9fuUlbJ2Ht',
      },
    },
    {
      id: 'oem2',
      domain: 'https://oem2.dev.ems.energy',
      api: 'api',
      iot: {
        ws_live: 'wss://wss-oem1.dev.ems.energy/dev',
        logger_prefix: 'logger',
      },
      title: 'Helvatron',
      auth: {
        domain: 'helvatron.eu.auth0.com',
        clientId: 'gIwq9sRRK4HOwe2ddkH5O1aY8SzW01fS',
      },
    },
  ],
};
