export function getPointsPos(u, seriesIdx: number) {
  const size = 10;
  const offset = u.series[seriesIdx].pointOffset ?? 0;
  const idx = u.cursor.idxs[seriesIdx];

  const top = u.valToPos(u.data[seriesIdx][idx], 'y', false);
  const left = u.valToPos(u.data[0][idx], 'x', false);

  return {
    height: size,
    width: size,
    top: top - size / 2,
    left: left - size / 2 + offset,
  };
}
