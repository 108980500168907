/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MetadataTag } from './metadataTag';

export interface Metadata {
  /**
   * data_point_id
   */
  id: number;
  /**
   * entity_type
   */
  entity_type: Metadata.EntityTypeEnum;
  /**
   * entity_id
   */
  entity_id: number;
  /**
   * MetadataTags
   */
  tags: Array<MetadataTag>;
  /**
   * entity_parent_id
   */
  entity_parent_id: number;
  /**
   * parent
   */
  parent: Metadata;
}
export namespace Metadata {
  export type EntityTypeEnum = 'LOGGER' | 'DEVICE' | 'DEVICE_VALUE' | 'TREE_NODE';
  export const EntityTypeEnum = {
    LOGGER: 'LOGGER' as EntityTypeEnum,
    DEVICE: 'DEVICE' as EntityTypeEnum,
    DEVICEVALUE: 'DEVICE_VALUE' as EntityTypeEnum,
    TREENODE: 'TREE_NODE' as EntityTypeEnum,
  };
}
