export function getPrefix(v: number, prefix: string = ''): { value: number; prefix: string } {
  // return v;
  const prefixes = ['n', 'μ', 'm', '', 'k', 'M', 'G', 'T'];
  const MIN_VALUE = 0.01;
  const MAX_VALUE = 1000;

  let prefixId = prefixes.findIndex((o) => o == prefix);
  // console.log('-> startValue', startValue);
  const negativeModifier = v > 0 ? 1 : -1; // to handle negative values
  while (
    (v * negativeModifier >= MAX_VALUE || v * negativeModifier <= MIN_VALUE) &&
    prefixId < prefixes.length - 1 &&
    prefixId > 0 &&
    v != 0 &&
    v != null
  ) {
    if (v * negativeModifier >= MAX_VALUE) {
      v = v / 1000;
      prefixId++;
    }

    if (v * negativeModifier <= MIN_VALUE) {
      v = v * 1000;
      prefixId--;
    }
  }
  return { value: v, prefix: prefixes[prefixId] };
}
