import {
  DictionaryService as ApiDictionary,
  LanguageDto,
  LanguageListResponseDto,
} from '../../../api-main';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { map, Observable, shareReplay, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { LocaleSessionService } from './localeSession.service';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  private lngList: LanguageDto[] = [];

  private lngList$: Observable<LanguageDto[]>;

  public readonly langCookieKey = 'langCookieKey';

  constructor(
    private http: HttpClient,
    private dictionaryService: ApiDictionary,
    public translate: TranslateService,
    private cookieService: CookieService,
    private localeSessionsService: LocaleSessionService,
  ) {
    this.dictionaryService = new ApiDictionary(this.http, ConfigService.getApiConfig(), undefined);
  }

  public getLanguageList(): Observable<LanguageDto[]> {
    //TODO IS getting language from API needed if we store translations on frontend anyway?
    if (!this.lngList$) {
      this.lngList$ = this.dictionaryService
        .languageControllerList({
          filter: [],
          page_index: 0,
          page_size: 10000,
          sort_direction: 'asc',
        })
        .pipe(
          map((response: LanguageListResponseDto) => response.data),
          tap((languages: LanguageDto[]) => {
            this.setupTranslation(languages.map((o: LanguageDto) => o.lng));
            this.lngList = languages;
          }),
          shareReplay(1),
        );
    }
    return this.lngList$;
  }

  private setupTranslation(languages: string[]) {
    this.translate.addLangs(languages);

    let cookieLng = this.cookieService.get(this.langCookieKey);
    if (cookieLng !== undefined) {
      this.localeSessionsService.changeLanguage(cookieLng);
      return;
    }
    const browserLang = this.translate.getBrowserLang();
    const initialLang = languages.includes(browserLang) ? browserLang : 'en';
    this.localeSessionsService.changeLanguage(initialLang);
    // this.cookieService.put(this.langCookieKey, initialLang);
    // this.translate.use(initialLang);
  }

  getLanguageName(lng: string): string {
    return this.lngList?.find((item) => item.lng === lng)?.name ?? '-';
  }
}
