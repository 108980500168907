//changing order will affect saved states (graphs/values on dashboard or saved data templates)
export enum DataDisplayDefaultRanges {
  'CUSTOM' = 'CUSTOM',
  'LAST_12_HOURS' = 'LAST_12_HOURS',
  'LAST_24_HOURS' = 'LAST_24_HOURS',
  'CURRENT_DAY' = 'CURRENT_DAY',
  'LAST_DAY' = 'LAST_DAY',
  'DAY_BEFORE_YESTERDAY' = 'DAY_BEFORE_YESTERDAY',
  'LAST_7_DAYS' = 'LAST_7_DAYS',
  'LAST_30_DAYS' = 'LAST_30_DAYS',
  'CURRENT_WEEK' = 'CURRENT_WEEK',
  'LAST_WEEK' = 'LAST_WEEK',
  'BEFORE_LAST_WEEK' = 'BEFORE_LAST_WEEK',
  'CURRENT_MONTH' = 'CURRENT_MONTH',
  'LAST_MONTH' = 'LAST_MONTH',
  'BEFORE_LAST_MONTH' = 'BEFORE_LAST_MONTH',
  'LAST_3_MONTHS' = 'LAST_3_MONTHS',
  'LAST_6_MONTHS' = 'LAST_6_MONTHS',
  'LAST_12_MONTHS' = 'LAST_12_MONTHS',
  'CURRENT_QUARTER' = 'CURRENT_QUARTER',
  'LAST_QUARTER' = 'LAST_QUARTER',
  'BEFORE_LAST_QUARTER' = 'BEFORE_LAST_QUARTER',
  'CURRENT_YEAR' = 'CURRENT_YEAR',
  'LAST_YEAR' = 'LAST_YEAR',
  'BEFORE_LAST_YEAR' = 'BEFORE_LAST_YEAR',
}
