import { Component, Inject } from '@angular/core';
import { ConfigService } from '../../../../shared/services/config.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { User } from '@auth0/auth0-angular';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '../../../../shared/services/alert.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { ModalComponent } from '../../../../shared/components/dialogs/modal/modal.component';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
  standalone: true,
  imports: [ModalComponent, MatDialogContent, TranslateModule],
})
export class ChangePasswordModalComponent {
  user: User;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: { email: string },
    private dialogRef: MatDialogRef<ChangePasswordModalComponent>,
  ) {}

  onChangePassword() {
    const authConfig = ConfigService.getAuthConfig();
    this.http
      .post(
        `https://${authConfig.domain}/dbconnections/change_password`,
        {
          client_id: authConfig.clientId,
          email: this.data.email,
          connection: 'Username-Password-Authentication',
        },
        { responseType: 'text' },
      )
      .subscribe({
        next: () => {
          this.dialogRef.close();
          this.alertService.info(this.translate.instant('NOTIFICATION.CHANGE_PASSWORD'));
        },
        error: (error) => {
          this.alertService.error(error.error.text);
        },
      });
  }

  getTitle() {
    return 'DIALOG.CHANGE_PASSWORD_TITLE';
  }

  getSaveButtonName() {
    return 'BUTTON.CHANGE_PASS';
  }

  getCancelButtonName() {
    return 'BUTTON.CANCEL';
  }
}
