<main>
  <div class="widget-options-container">
    <div
      class="widget"
      *ngFor="let typeKey of itemTypes"
      (click)="add(typeKey)"
      matTooltip="{{ getTooltip(typeKey) | translate }}"
      [attr.data-cy]="DashboardItemType[typeKey] + '-item'"
    >
      <p><mat-icon svgIcon="{{ getItemTypeIcon(typeKey) }}"></mat-icon></p>
      <p>{{ 'DASHBOARD.TYPE_' + DashboardItemType[typeKey] | translate }}</p>
    </div>
  </div>
</main>
