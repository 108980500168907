<app-modal
  title="{{ 'SIGNUP.FINISH_REGISTRATION' | translate }}"
  [cancelButtonExists]="false"
  (save)="onSave()"
  [showLanguageButton]="true"
  [isDataLoaded]="!!data"
  [shouldSaveBeDisabled]="!canBeSubmitted()"
>
  <ng-container body>
    <main>
      <section class="account-type">
        <h1>{{ 'SIGNUP.ACCOUNT_TYPE' | translate }}</h1>
        <p>
          <mat-button-toggle-group name="userType" [(ngModel)]="userData.userType">
            <mat-button-toggle
              *ngFor="let userType of userTypes"
              [value]="userType"
              (change)="userTypeChanged()"
            >
              {{ getTimeValuesString(userType) | translate }}</mat-button-toggle
            >
          </mat-button-toggle-group>
        </p>
      </section>

      <section class="flex-2-cols">
        <app-signup-data [signupData]="userData"></app-signup-data>
        <app-company-info
          *ngIf="isExtendedAccount()"
          [data]="userData.companyData"
        ></app-company-info>
      </section>

      <section class="summary">
        <app-pricing [data]="userData"></app-pricing>
        <!--        <p>{{'SIGNUP.TERMS_AND_CONDITIONS' | translate}}</p>-->
        <div class="user-conditions">
          <app-user-conditions [signupData]="userData"></app-user-conditions>
        </div>
      </section>

      <!--      <p style="white-space: pre">{{userData | json}}</p>-->
    </main>
  </ng-container>
</app-modal>
