import { DeviceValuesDto } from '../../../../../../../api-main';

export function deviceParameterSort(parameter: DeviceValuesDto[]) {
  return parameter.sort((a, b) => {
    const nameSort = a.description_str.localeCompare(b.description_str, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
    if (nameSort != 0) return nameSort;

    const tariffA = Number(a.cfg_tariff ?? a.tariff);
    const tariffB = Number(b.cfg_tariff ?? b.tariff);
    if (tariffA && tariffB && !isNaN(tariffA) && !isNaN(tariffB)) {
      const tariffSort = tariffA - tariffB;
      if (tariffSort != 0) return tariffSort;
    }

    const phaseA = Number(a.cfg_phase);
    const phaseB = Number(b.cfg_phase);
    if (phaseA && phaseB && !isNaN(phaseA) && !isNaN(phaseB)) {
      const phaseSort = phaseA - phaseB;
      if (phaseSort != 0) return phaseSort;
    }
    return 0;
  });
}
