<div class="buttons-container">
  <div class="base-buttons">
    <app-dropdown>
      <mat-icon header>file_download</mat-icon>
      <ng-container body>
        <a (click)="downloadPng()">
          <mat-icon class="material-icons-outlined">image</mat-icon>
          <span>{{ 'DOWNLOAD.PNG' | translate }}</span>
        </a>
        <a (click)="downloadCsv()">
          <mat-icon class="material-icons-outlined">text_snippet</mat-icon>
          <span>{{ 'DOWNLOAD.CSV' | translate }}</span>
        </a>
      </ng-container>
    </app-dropdown>

    <button
      mat-mini-fab
      class="accent-fab"
      (mousedown)="zoomPlot(false)"
      [matTooltip]="'TOOLTIP.ZOOM.OUT' | translate"
    >
      <mat-icon>zoom_out</mat-icon>
    </button>

    <button
      mat-mini-fab
      class="accent-fab"
      (mousedown)="zoomPlot(true)"
      [matTooltip]="'TOOLTIP.ZOOM.IN' | translate"
    >
      <mat-icon>zoom_in</mat-icon>
    </button>

    <button
      mat-mini-fab
      class="accent-fab"
      [disabled]="refreshButtonAvailable()"
      (mousedown)="refreshGraph()"
      [matTooltip]="'TOOLTIP.REFRESH' | translate"
    >
      <mat-icon>refresh</mat-icon>
    </button>

    <app-dropdown>
      <mat-icon header>settings</mat-icon>
      <ng-container body>
        <label (mousedown)="requestFullScreen()" *ngIf="!isFullScreen">
          <mat-icon class="material-icons-outlined">fullscreen</mat-icon>
          {{ 'UPLOT.FULLSCREEN' | translate }}
        </label>

        <label>
          <mat-icon class="material-icons-outlined">message</mat-icon>
          {{ 'UPLOT.SHOW_TOOLTIP' | translate }}
          <input type="checkbox" [(ngModel)]="uplotShowTooltip" id="tooltip" name="tooltip" />
        </label>

        <label *ngIf="uplotShowTooltip" style="padding-left: 20px">
          <mat-icon class="material-icons-outlined">format_list_bulleted</mat-icon>
          {{ 'UPLOT.SHOW_TOOLTIP_EXTENDED' | translate }}
          <input
            type="checkbox"
            [(ngModel)]="uplotShowTooltipExt"
            id="extTooltip"
            name="extTooltip"
          />
        </label>

        <label>
          <mat-icon class="material-icons-outlined">area_chart</mat-icon>
          {{ 'UPLOT.SERIES_FILL' | translate }}
          <input type="checkbox" [(ngModel)]="seriesFilled" id="chartFill" name="chartFill" />
        </label>

        <label *ngIf="isDevMode">
          <mat-icon class="material-icons-outlined">query_stats</mat-icon>
          {{ 'UPLOT.LOG_SCALE' | translate }}
          <input type="checkbox" [(ngModel)]="isLogarithmicScale" id="logScale" name="logScale" />
        </label>

        <label>
          <mat-icon class="material-icons-outlined">functions</mat-icon>
          {{ 'UPLOT.SHOW_AVG' | translate }}
          <input type="checkbox" [(ngModel)]="uplotShowAvg" id="avg" name="avg" />
        </label>

        <label>
          <mat-icon class="material-icons-outlined">horizontal_rule</mat-icon>
          {{ 'UPLOT.SHOW_MIN' | translate }}
          <input type="checkbox" [(ngModel)]="uplotShowMin" id="min" name="min" />
        </label>

        <label>
          <mat-icon class="material-icons-outlined">horizontal_rule</mat-icon>
          {{ 'UPLOT.SHOW_MAX' | translate }}
          <input type="checkbox" [(ngModel)]="uplotShowMax" id="max" name="max" />
        </label>
      </ng-container>
    </app-dropdown>

    <button
      *ngIf="isFullScreen"
      mat-icon-button
      (mousedown)="exitFullScreen()"
      [matTooltip]="'UPLOT.EXIT_FULLSCREEN' | translate"
    >
      <mat-icon>close_fullscreen</mat-icon>
    </button>
  </div>

  <div class="range-settings">
    <!--    <mat-select *ngIf="opts.showScaleSettings" [value]="opts.currentScale$.value"-->
    <!--      (selectionChange)="opts.currentScale$.next($event.value)" panelClass="mat-select-min-width-200">-->
    <!--      <mat-option *ngFor="let scaleType of scaleTypes" [value]="scaleType" [disabled]="!isScaleEnabled(scaleType)"-->
    <!--        >{{ getScaleString(scaleType) | translate }}-->
    <!--        <span-->
    <!--          *ngIf="scaleType === UplotScaleModes.AUTO && opts.currentScale$.value === UplotScaleModes.AUTO"-->
    <!--          >({{ getScaleString(getRealCurrentScale()) | translate }})</span-->
    <!--        >-->
    <!--      </mat-option>-->
    <!--    </mat-select>-->

    <mat-select
      *ngIf="opts.showCustomRangesSettings"
      [value]="opts.userDefinedRange$.value"
      (selectionChange)="opts.userDefinedRange$.next($event.value)"
      panelClass="mat-select-min-width-200"
    >
      <mat-option
        *ngFor="let definedRange of definedRanges; let i = index"
        [disabled]="i === 0"
        [value]="definedRange"
        >{{ getRangeString(definedRange) | translate }}</mat-option
      >
    </mat-select>
  </div>

  <ng-content> </ng-content>
</div>
