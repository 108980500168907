import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, map, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { DeviceService } from '../../../../../logger/device.service';
import { DeviceValuesDto } from '../../../../../../../api-main';
import { ValueDataModel } from '../../../../models/ValueData.model';
import { isSeriesConsumptionValue } from '../../../../../../shared/components/graph-uplot/graph/utils/isSeriesConsumptionValue';
import { SIPrefix } from '../../../../../../shared/models/SIPrefix';
import { DisabledDirective } from '../../../../../../shared/directives/disabled.directive';
import { EmuListInputComponent } from '../../../../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import { TimestampRelativeComponent } from '../../../../../../shared/components/timestamp-relative/timestamp-relative.component';
import { SelectingValueDataService } from '../selecting-value-data.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatSelectTrigger } from '@angular/material/select';
import { getDeviceParameterNameSetTranslate } from './getDeviceParameterName';
import { deviceParameterSort } from './deviceParameterSort';
import { getObisCodeGroupForSeries } from '../../../../../../shared/components/graph-uplot/graph/models/ObisCodeCfgUnits';

@Component({
  selector: 'app-settings-value-data',
  templateUrl: './value-display-settings-values.component.html',
  styleUrls: ['./value-display-settings-values.component.scss'],
  standalone: true,
  imports: [
    EmuListInputComponent,
    DisabledDirective,
    TimestampRelativeComponent,
    TranslateModule,
    MatSelectTrigger,
  ],
})
export class ValueDisplaySettingsValuesComponent implements OnInit, OnDestroy {
  @Input() data: ValueDataModel;

  @Input() reloadDeviceValues$: Subject<number>;

  @Input() reloadDevice$: Subject<number>;

  @Output() valueSelected = new EventEmitter<string>();

  loading$ = new BehaviorSubject(false);

  deviceValues: DeviceValuesDto[] = [];

  private _selectedDeviceValue: DeviceValuesDto;

  private unsubscribe = new Subject<void>();

  get selectedDeviceValue(): DeviceValuesDto {
    if (this.data.valueId != this._selectedDeviceValue?.id) return null;
    return this._selectedDeviceValue;
  }

  set selectedDeviceValue(value: DeviceValuesDto) {
    this._selectedDeviceValue = value;
    if (value?.id != null) {
      // console.log('-> value', JSON.parse(JSON.stringify(value)));
      if (this.data.consumption == undefined || this.data.valueId != value.id)
        this.data.consumption = isSeriesConsumptionValue(<any>value);
      this.data.valueId = value.id;
      this.data['name'] = this.getDeviceParameterName(value); //value.description_str;
      this.data['obis_code'] = value.cfg_obis_code;

      const units = getObisCodeGroupForSeries(value);

      if (units?.units?.includes(this.data['unit'])) return;
      this.data['cfg_unit'] = value.cfg_unit;
      this.data['unit'] = value.unit_str;
    }
  }

  constructor(
    private deviceService: DeviceService,
    private selectingValueDataService: SelectingValueDataService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.loadDeviceValues();
  }

  private loadDeviceValues() {
    let deviceId: number;
    const deviceValue$ = this.reloadDeviceValues$.pipe(
      tap(() => {
        this.loading$.next(true);
        this.selectedDeviceValue = null;
      }),
      switchMap((id) => {
        deviceId = id;
        if (this.selectingValueDataService.savedDeviceValues[`${this.data.loggerId}-${deviceId}`])
          return of(
            this.selectingValueDataService.savedDeviceValues[`${this.data.loggerId}-${deviceId}`],
          );
        return this.deviceService
          .getDeviceValuesList(id, this.data.loggerId)
          .pipe(map((o) => deviceParameterSort(o.data)));
      }),
      tap((data) => {
        this.selectingValueDataService.savedDeviceValues[`${this.data.loggerId}-${deviceId}`] =
          data;
      }),
    );

    deviceValue$.pipe(takeUntil(this.unsubscribe)).subscribe((values) => {
      this.loading$.next(false);
      this.deviceValues = values;
      if (this.isDataAvailable() && this.data.valueId != null)
        this.selectedDeviceValue = values.find((o) => o.id == this.data.valueId);
    });
  }

  isDataAvailable() {
    return this.data.deviceId != null && this.data.loggerId != null;
  }

  valueDisplaySelected() {
    this.valueSelected.emit();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected readonly of = of;

  protected readonly unitPrefixes = SIPrefix;

  displayFn(item: DeviceValuesDto): string {
    return item ? `${this.getDeviceParameterName(item)}` : '';
  }

  protected readonly getDeviceParameterName = getDeviceParameterNameSetTranslate(
    this.translateService,
  );
}
