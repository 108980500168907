import { Component, EventEmitter, Input, Output } from '@angular/core';
import { of, Subject } from 'rxjs';
import { ValueDataModel } from '../../../models/ValueData.model';
import { canSeriesBeConsumptionValue } from '../../../../../shared/components/graph-uplot/graph/utils/isSeriesConsumptionValue';
import { SIPrefix } from '../../../../../shared/models/SIPrefix';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { EmuListInputComponent } from '../../../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import { NgIf } from '@angular/common';
import { ValueDisplaySettingsValuesComponent } from './settings-value-data/value-display-settings-values.component';
import { ValueDisplaySettingsDeviceComponent } from './settings-device/value-display-settings-device.component';
import { ValueDisplaySettingsLoggerComponent } from './settings-logger/value-display-settings-logger.component';

@Component({
  selector: 'app-selecting-value-data',
  templateUrl: './selecting-value-data.component.html',
  styleUrls: ['../../../styles/widget-settings.scss', './selecting-value-data.component.scss'],
  standalone: true,
  imports: [
    ValueDisplaySettingsLoggerComponent,
    ValueDisplaySettingsDeviceComponent,
    ValueDisplaySettingsValuesComponent,
    NgIf,
    EmuListInputComponent,
    TranslateModule,
  ],
})
export class SelectingValueDataComponent {
  reloadDevice$ = new Subject<number>();

  reloadDeviceValues$ = new Subject<number>();

  @Output() valueSelected = new EventEmitter<string>();

  @Output() loggerSelected = new EventEmitter<string>();

  @Input() loggerOnly: boolean;

  @Input() data: ValueDataModel;

  @Input() showConsumption: boolean = true;

  // boolean for forced value / null for default
  @Input() set forceConsumption(value: boolean | null) {
    if (value === false || value === true) this.data.consumption = value;
  }

  constructor(private translateService: TranslateService) {}

  consumptionOptions = of([
    { name: 'GRAPH.METER_READING', value: false },
    { name: 'GRAPH.CONSUMPTION', value: true },
  ]);

  showDevices() {
    return !this.loggerOnly;
  }

  showDeviceValues() {
    return !this.loggerOnly;
  }

  consumptionOptionsDisplayFn(val: any) {
    return this.translateService.instant(val.name);
  }

  consumptionOptionsValueFn(val: any) {
    return val.value;
  }

  valueDisplaySelected() {
    this.valueSelected.emit();
  }

  onLoggerSelected() {
    this.loggerSelected.emit();
  }

  canUseConsumption() {
    return canSeriesBeConsumptionValue(<any>this.data);
  }

  protected readonly unitPrefixes = SIPrefix;
}
