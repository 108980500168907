<ng-container *ngIf="data">

  <section>
    <mat-checkbox *ngIf="isDefaultChart()" [(ngModel)]="data.showSettings">
      {{ 'DASHBOARD.GRAPH_SHOW_SETTINGS' | translate }}
    </mat-checkbox>
  </section>

  <section>
    <app-emu-input
      name="{{ 'DASHBOARD.GRAPH_NAME' | translate }}"
      placeholder="{{ 'DEFAULT' | translate }}"
      [(value)]="data.name"
      (valueChange)="refreshSeries()"
      [className]="'dashboard-settings'"
    ></app-emu-input>
  </section>

  <section>
    <app-emu-list-input
      [(value)]="data.type"
      [name]="'DASHBOARD.GRAPH_TYPE'"
      [autocomplete]="false"
      (optionSelected)="reloadGraph()"
      [clearButtonHidden]="true"
      [filteredOptions$]="of(graphTypes)"
      [className]="'dashboard-settings'"
    >
      <ng-template let-item
        ><p>{{ getTypeString(item) | translate }}</p></ng-template
      >
    </app-emu-list-input>
  </section>

  <section>
    <app-emu-list-input
      [(value)]="data.userDefinedRange"
      [name]="'DASHBOARD.GRAPH_RANGE'"
      [autocomplete]="false"
      (optionSelected)="reloadGraph()"
      [clearButtonHidden]="true"
      [filteredOptions$]="of(getDefinedRanges())"
      [className]="'dashboard-settings'"
    >
      <ng-template let-item
        ><p>{{ getRangeString(item) | translate }}</p></ng-template
      >
    </app-emu-list-input>
    @if(data.userDefinedRange === DataDisplayDefaultRanges.CUSTOM){
<!--        [disabled]="rangePickDisabled"-->
      <app-range-picker
        [currentRange]="getRange()"
        (rangeHasChanged)="changeRange($event)"
      >
      </app-range-picker>
    }

  </section>

  <section>
    <mat-checkbox *ngIf="isPiechart()" [(ngModel)]="data.doughnut" (change)="refreshSeries()">
      {{ 'DASHBOARD.DOUGHNUT' | translate }}
    </mat-checkbox>
  </section>

  @if(isSankey()){
  <app-sankey-chart-config [data]="data"></app-sankey-chart-config>
  } @else {
    <section>

      @defer {
        <app-graph-settings-series [data]="data" [forceConsumption]="forceConsumption()" [showConsumption]="showConsumption()"
                                   [showColor]="isColorConfigurable()"></app-graph-settings-series>

      } @placeholder (minimum 100ms) {
        <app-skeleton></app-skeleton>
      }

    </section>
  }




</ng-container>
