<div class="range-settings">
  <mat-select
    [value]="currentScale$.value"
    (selectionChange)="currentScale$.next($event.value)"
    panelClass="mat-select-min-width-200"
  >
    <mat-option
      *ngFor="let scaleType of scaleTypes"
      [value]="scaleType"
      [disabled]="!isScaleEnabled(scaleType)"
      >{{ getScaleString(scaleType) | translate }}
      <span
        *ngIf="scaleType === UplotScaleModes.AUTO && currentScale$.value === UplotScaleModes.AUTO"
        >({{ getScaleString(getRealCurrentScale()) | translate }})</span
      >
    </mat-option>
  </mat-select>
</div>
