import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { AlertComponent } from '../components/alert/alert.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  durationInSeconds = 15;

  message = new Subject<string>();

  constructor(
    private alertBar: MatSnackBar,
    private translate: TranslateService,
  ) {}

  info(message: string) {
    if (message) message = this.translateMessage(message);
    this.alertBar.openFromComponent(AlertComponent, {
      duration: this.durationInSeconds * 1000,
      data: { message, action: 'confirm' },
      panelClass: ['info-snackbar'],
    });
  }

  error(message: string) {
    if (message) message = this.translateMessage(message);
    this.alertBar.openFromComponent(AlertComponent, {
      duration: this.durationInSeconds * 1000,
      data: { message, action: 'error' },
      panelClass: ['error-snackbar'],
    });
  }

  concatMessage(message: string[]) {
    let concatMessage = '';
    message.forEach((m) => (concatMessage += this.translateMessage(m)));
    return concatMessage;
  }

  private translateMessage(message: string) {
    if (Array.isArray(message)) return message.join(', ');
    if (typeof message == 'object') message = (<any>message).message;
    if (message && message != '') return this.translate.instant(message);
    else return message;
  }
}
