import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { HttpLoaderFactory } from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { CacheModule } from './app/shared/modules/cache.module';
import { ApiModule } from './api-main';
import { Auth0Service } from './api-auth';
import { ApiModule as InvoiceApiModule } from './api-invoice';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppRoutingModule } from './app/app-routing.module';
import { CookieModule } from 'ngx-cookie';
import { ConfigService } from './app/shared/services/config.service';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { LocaleSessionService } from './app/shared/services/localeSession.service';
import { LangInterceptor } from './app/shared/interceptors/lang.interceptor';
import { ErrorInterceptor } from './app/shared/interceptors/error.interceptor';
import { AuthModule } from '@auth0/auth0-angular';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http';
import { MaterialModule } from './app/shared/modules/material.module';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { registerPlugin } from './app/pages/dashboard/widgets/dashboard-graph/dashboard-pie-chart/donughtCenterText';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { Auth0Interceptor } from './app/shared/interceptors/auth0.interceptor';

if (environment.production) {
  enableProdMode();
}
// Chart.register(ChartDataLabels);
registerPlugin();
bootstrapApplication(AppComponent, {
  providers: [
    Auth0Service,
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
        compiler: {
          provide: TranslateCompiler,
          useClass: TranslateMessageFormatCompiler,
        },
      }),
      AuthModule.forRoot(ConfigService.getAuthConfig()),
      CookieModule.withOptions(),
      AppRoutingModule, //withComponentInputBinding
      BrowserModule,
      ApiModule,
      InvoiceApiModule,
      CacheModule,
      MaterialModule, //NEED to be here
    ),
    { provide: HTTP_INTERCEPTORS, useClass: Auth0Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      deps: [LocaleSessionService],
      useFactory: (localeSessionService) => localeSessionService.locale,
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideCharts(withDefaultRegisterables()),
  ],
}).catch((err) => console.error(err));
