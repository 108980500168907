<app-modal
  [title]="getTitle()"
  [saveButtonName]="getSaveButtonName()"
  [cancelButtonName]="getCancelButtonName()"
  (save)="onChangePassword()"
>
  <ng-container body>
    <div mat-dialog-content>{{ 'DIALOG.CHANGE_PASSWORD_PROMPT' | translate }}</div>
  </ng-container>
</app-modal>

<!--<h1 class="item" mat-dialog-title>{{'DIALOG.CHANGE_PASSWORD_TITLE'|translate}}</h1>-->
<!--<div mat-dialog-content>{{'DIALOG.CHANGE_PASSWORD_PROMPT'|translate}}</div>-->
<!--<div mat-dialog-actions class="align-right">-->
<!--  <button mat-flat-button mat-dialog-close  class="button-secondary">{{'BUTTON.CANCEL'|translate}}</button>-->
<!--  <button mat-flat-button mat-dialog-close  class="button-primary" color="primary"-->
<!--          (click)="onChangePassword()"-->
<!--  >-->
<!--    <span >{{'BUTTON.CHANGE_PASS'|translate}}</span>-->
<!--  </button>-->
<!--</div>-->
