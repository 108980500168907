<div class="loading-bar" *ngIf="loading | async">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="header" appFitText *ngIf="data?.showHeader" data-cy="value-display-header">
  <span>{{ header }}</span>
</div>

<div *ngIf="data" class="value-container">
  <div class="value" appFitText>
    @if (isDataValid && value != null) {
      <span class="value">{{ valueString | formatValue: this.data.decimalPlaces }}</span>
      <span class="unit" *ngIf="data.showUnit && unit">{{ data.unitPrefix }}{{ unit }}</span>
    } @else {
      <span *ngIf="(loading | async) === false" class="no-data">{{
        'ERROR.NO_RECORDS' | translate
      }}</span>
    }
  </div>
</div>

<div class="date" appFitText *ngIf="data?.showDate">
  <app-date-display *ngIf="date" [value]="date" [format]="'short'"></app-date-display>
  <span *ngIf="!date && data.range">
    <app-date-display
      [value]="savedResponse?.start ?? data.range.min"
      [format]="'short'"
    ></app-date-display>
    -
    <app-date-display
      [value]="savedResponse?.end ?? data.range.max"
      [format]="'short'"
    ></app-date-display>
  </span>
</div>
