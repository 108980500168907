import { DashboardItemType } from '../models/DashboardItemType.model';

const itemTypes = [];
export function getDashboardItemTypes() {
  if (itemTypes.length === 0) setItemTypes();

  return itemTypes;
}

function setItemTypes() {
  for (let enumMember in DashboardItemType) {
    const isValueProperty = Number(enumMember) >= 0;
    if (isValueProperty) {
      itemTypes.push(Number(enumMember));
    }
  }
}
