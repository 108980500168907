import { DashboardItemType } from '../models/DashboardItemType.model';

export function getMinimalSizeForItemType(type: DashboardItemType = null): {
  cols: number;
  rows: number;
} {
  switch (type) {
    case DashboardItemType.GRAPH:
      return { cols: 12, rows: 10 };
    case DashboardItemType.TEXT:
      return { cols: 2, rows: 1 };
    case DashboardItemType.IMAGE:
      return { cols: 2, rows: 2 };
    case DashboardItemType.MULTI_VALUE_DISPLAY:
    case DashboardItemType.VALUE_DISPLAY:
      return { cols: 6, rows: 4 };
    default:
      return { cols: 1, rows: 1 };
  }
}
