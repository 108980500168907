import { GridsterItem } from 'angular-gridster2';
import { DashboardItemType } from './DashboardItemType.model';
import { DashboardItemData } from './DashboardItemData.model';
import { getDefaultItemStyles } from '../utils/getDefaultItemStyles';
import { getMinimalSizeForItemType } from '../utils/getMinimalSizeForItemType';

export class DashboardItem implements GridsterItem {
  // constructor(x, y, cols, rows, styles, data, type) {
  //   this.x = x;
  //   this.y = y;
  //   this.type = type;
  //   this.cols = cols;
  //   this.rows = rows;
  //   this.styles = styles;
  //   this.data = data;
  // }

  id: string = window?.crypto?.randomUUID() ?? Math.random().toString();

  // general
  x: number = 0;

  y: number = 0;

  rows: number = 3;

  layerIndex?: number = 1;

  cols: number = 6;

  // other
  minItemArea: number = 1;

  type: DashboardItemType = DashboardItemType.EMPTY;

  minItemRows: number = getMinimalSizeForItemType(this.type).rows;

  minItemCols: number = getMinimalSizeForItemType(this.type).cols;

  styles: DashboardItemStyles = getDefaultItemStyles();

  data: DashboardItemData = {};

  dragEnabled: boolean = true;

  resizeEnabled: boolean = true;

  //functions
}

export interface DashboardItemStyles {
  padding: boolean;
  showBackground: boolean;
  background?: string;
}
