<ng-container *ngIf="loaded">
<!--  <div *ngIf="src != null" class="movable-container">-->
<!--    <div class="img-container">-->
<!--      <img src="{{ src }}" alt="dashboard" />-->
<!--    </div>-->
<!--  </div>-->

  <div  class="dashboard-container">
<!--    <div class="loading-bar" *ngIf="dashboard.loading">-->
<!--      <mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
<!--    </div>-->
    <app-dashboard #dashboard [id]="dashboardId" [isInPortal]="true" [showHeader]="false"></app-dashboard>
  </div>
</ng-container>

<!--<ng-template #skeleton>-->
<!--  <ng-container>-->
<!--    <div *ngIf="src != null" class="movable-container">-->
<!--      <div class="img-container">-->
<!--        <img src="{{ src }}" alt="dashboard" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div *ngIf="src == null" class="dashboard-container">-->
<!--&lt;!&ndash;     <app-skeleton></app-skeleton>&ndash;&gt;-->
<!--    </div>-->
<!--  </ng-container>-->
<!--</ng-template>-->

