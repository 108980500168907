import { Component } from '@angular/core';
import { FooterComponent } from '../../layout/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { LanguageButtonComponent } from '../../shared/components/language-button/language-button.component';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss'],
  standalone: true,
  imports: [LanguageButtonComponent, RouterOutlet, FooterComponent],
})
export class LoginLayoutComponent {}
