/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LoraServerListDto } from './loraServerListDto';

export interface LoraDeviceProfilesListResponseDto {
  /**
   * Table item count
   */
  total_count: number;
  /**
   * Response item array
   */
  result_list: Array<LoraServerListDto>;
}
