/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.4
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DbDefaultResponse } from '../model/dbDefaultResponse';
import { FindOnTreeRequestDto } from '../model/findOnTreeRequestDto';
import { FindOnTreeResponseDto } from '../model/findOnTreeResponseDto';
import { GetMoveDestinationsRequestDto } from '../model/getMoveDestinationsRequestDto';
import { GetMoveDestinationsResponseDto } from '../model/getMoveDestinationsResponseDto';
import { GpDto } from '../model/gpDto';
import { GpInvitationAcceptRequestDto } from '../model/gpInvitationAcceptRequestDto';
import { GpInvitationDto } from '../model/gpInvitationDto';
import { GpInvitationListResponseDto } from '../model/gpInvitationListResponseDto';
import { GpItemDto } from '../model/gpItemDto';
import { GpListResponseDto } from '../model/gpListResponseDto';
import { GpPrivilegesListResponseDto } from '../model/gpPrivilegesListResponseDto';
import { ItemTreeDto } from '../model/itemTreeDto';
import { ItemTreeListRequestDto } from '../model/itemTreeListRequestDto';
import { ItemTreeListResponseDto } from '../model/itemTreeListResponseDto';
import { ListFilterRequestDto } from '../model/listFilterRequestDto';
import { MoveItemRequestDto } from '../model/moveItemRequestDto';
import { MoveItemResponseDto } from '../model/moveItemResponseDto';
import { TreeDataStructureDto } from '../model/treeDataStructureDto';
import { TreeItemFindResponseDto } from '../model/treeItemFindResponseDto';
import { TreeItemTypeDto } from '../model/treeItemTypeDto';
import { TreeItemTypeListResponseDto } from '../model/treeItemTypeListResponseDto';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class TreeService {
  protected basePath = 'http://127.0.0.1:4201/api/';

  public defaultHeaders = new HttpHeaders();

  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Delete group policy.
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gpControllerGpDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DbDefaultResponse>;
  public gpControllerGpDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DbDefaultResponse>>;
  public gpControllerGpDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DbDefaultResponse>>;
  public gpControllerGpDelete(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling gpControllerGpDelete.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DbDefaultResponse>(
      'delete',
      `${this.basePath}/tree/gp/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gpControllerGpGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<GpDto>;
  public gpControllerGpGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<GpDto>>;
  public gpControllerGpGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<GpDto>>;
  public gpControllerGpGet(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling gpControllerGpGet.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<GpDto>(
      'get',
      `${this.basePath}/tree/gp/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Invite user to group policy
   *
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gpControllerGpInvite(
    body: GpInvitationDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<any>;
  public gpControllerGpInvite(
    body: GpInvitationDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<any>>;
  public gpControllerGpInvite(
    body: GpInvitationDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<any>>;
  public gpControllerGpInvite(
    body: GpInvitationDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling gpControllerGpInvite.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/tree/gp/invite`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Accept invitation to group policy. Send received code
   *
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gpControllerGpInviteAccept(
    body: GpInvitationAcceptRequestDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<any>;
  public gpControllerGpInviteAccept(
    body: GpInvitationAcceptRequestDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<any>>;
  public gpControllerGpInviteAccept(
    body: GpInvitationAcceptRequestDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<any>>;
  public gpControllerGpInviteAccept(
    body: GpInvitationAcceptRequestDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling gpControllerGpInviteAccept.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/tree/gp/invite/accept`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Delete invitation to group policy. Will remove privilege for user
   * You can delete invitation if you are group policy owner or accepted, invited user
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gpControllerGpInviteDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DbDefaultResponse>;
  public gpControllerGpInviteDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DbDefaultResponse>>;
  public gpControllerGpInviteDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DbDefaultResponse>>;
  public gpControllerGpInviteDelete(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling gpControllerGpInviteDelete.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DbDefaultResponse>(
      'delete',
      `${this.basePath}/tree/gp/invite/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * List my accepted invitation.
   * Provides possibility to remove my invitation
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gpControllerGpInviteList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<GpInvitationListResponseDto>;
  public gpControllerGpInviteList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<GpInvitationListResponseDto>>;
  public gpControllerGpInviteList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<GpInvitationListResponseDto>>;
  public gpControllerGpInviteList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling gpControllerGpInviteList.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<GpInvitationListResponseDto>(
      'post',
      `${this.basePath}/tree/gp/invite/list`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Group Policy list for given user and language
   *
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gpControllerGpList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<GpListResponseDto>;
  public gpControllerGpList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<GpListResponseDto>>;
  public gpControllerGpList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<GpListResponseDto>>;
  public gpControllerGpList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling gpControllerGpList.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<GpListResponseDto>('post', `${this.basePath}/tree/gp/list`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * List my owned invitation.
   * Provides possibility to remove my invitation
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gpControllerGpPrivilegesList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<GpPrivilegesListResponseDto>;
  public gpControllerGpPrivilegesList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<GpPrivilegesListResponseDto>>;
  public gpControllerGpPrivilegesList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<GpPrivilegesListResponseDto>>;
  public gpControllerGpPrivilegesList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling gpControllerGpPrivilegesList.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<GpPrivilegesListResponseDto>(
      'post',
      `${this.basePath}/tree/gp/privileges/list`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Update/save new Group Policy item
   * If id is 0, operation will create new item. Invitation is not used.
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gpControllerGpSave(
    body: GpDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DbDefaultResponse>;
  public gpControllerGpSave(
    body: GpDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DbDefaultResponse>>;
  public gpControllerGpSave(
    body: GpDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DbDefaultResponse>>;
  public gpControllerGpSave(
    body: GpDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling gpControllerGpSave.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<DbDefaultResponse>('post', `${this.basePath}/tree/gp`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Delete group policy template item.
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gpControllerGpTemplateItemDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DbDefaultResponse>;
  public gpControllerGpTemplateItemDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DbDefaultResponse>>;
  public gpControllerGpTemplateItemDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DbDefaultResponse>>;
  public gpControllerGpTemplateItemDelete(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling gpControllerGpTemplateItemDelete.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DbDefaultResponse>(
      'delete',
      `${this.basePath}/tree/gp/item/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Update/save new Group Policy Template item
   * If id is 0, operation will create new item.
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gpControllerGpTemplateItemSave(
    body: GpItemDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DbDefaultResponse>;
  public gpControllerGpTemplateItemSave(
    body: GpItemDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DbDefaultResponse>>;
  public gpControllerGpTemplateItemSave(
    body: GpItemDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DbDefaultResponse>>;
  public gpControllerGpTemplateItemSave(
    body: GpItemDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling gpControllerGpTemplateItemSave.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<DbDefaultResponse>('post', `${this.basePath}/tree/gp/item`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Update/save new tree item type
   * If id is 0, operation will create new item in database
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public itemTypeControllerAdd(
    body: TreeItemTypeDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<any>;
  public itemTypeControllerAdd(
    body: TreeItemTypeDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<any>>;
  public itemTypeControllerAdd(
    body: TreeItemTypeDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<any>>;
  public itemTypeControllerAdd(
    body: TreeItemTypeDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling itemTypeControllerAdd.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/tree/dict/item_type`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public itemTypeControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DbDefaultResponse>;
  public itemTypeControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DbDefaultResponse>>;
  public itemTypeControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DbDefaultResponse>>;
  public itemTypeControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling itemTypeControllerDelete.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DbDefaultResponse>(
      'delete',
      `${this.basePath}/tree/dict/item_type/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   *
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public itemTypeControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<TreeItemTypeDto>;
  public itemTypeControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<TreeItemTypeDto>>;
  public itemTypeControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<TreeItemTypeDto>>;
  public itemTypeControllerGet(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling itemTypeControllerGet.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<TreeItemTypeDto>(
      'get',
      `${this.basePath}/tree/dict/item_type/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Item type list
   * Item type list, need to provide in filter language {\&quot;field\&quot;:\&quot;lng\&quot;,\&quot;value\&quot;:\&quot;en\&quot;} element. Other possibilities for filter [{\&quot;field\&quot;:\&quot;lng\&quot;,\&quot;value\&quot;:\&quot;en\&quot;},{\&quot;field\&quot;:\&quot;name\&quot;,\&quot;value\&quot;:\&quot;Documen%\&quot;}]
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public itemTypeControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<TreeItemTypeListResponseDto>;
  public itemTypeControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<TreeItemTypeListResponseDto>>;
  public itemTypeControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<TreeItemTypeListResponseDto>>;
  public itemTypeControllerList(
    body: ListFilterRequestDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling itemTypeControllerList.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<TreeItemTypeListResponseDto>(
      'post',
      `${this.basePath}/tree/dict/item_type/list`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Update/save new tree item
   * If id is 0, operation will create new item in database. If item_id is set item_type_id should be null. In this case item type is taken from item
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public treeControllerAdd(
    body: ItemTreeDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<any>;
  public treeControllerAdd(
    body: ItemTreeDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<any>>;
  public treeControllerAdd(
    body: ItemTreeDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<any>>;
  public treeControllerAdd(
    body: ItemTreeDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling treeControllerAdd.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/tree`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Move tree item
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public treeControllerDataStructure(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<TreeDataStructureDto>;
  public treeControllerDataStructure(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<TreeDataStructureDto>>;
  public treeControllerDataStructure(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<TreeDataStructureDto>>;
  public treeControllerDataStructure(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling treeControllerDataStructure.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<TreeDataStructureDto>(
      'get',
      `${this.basePath}/tree/data_structure/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Delete tree item
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public treeControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<DbDefaultResponse>;
  public treeControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<DbDefaultResponse>>;
  public treeControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<DbDefaultResponse>>;
  public treeControllerDelete(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling treeControllerDelete.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<DbDefaultResponse>(
      'delete',
      `${this.basePath}/tree/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Find node id
   * Finds id of the node
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public treeControllerFindOnTree(
    body: FindOnTreeRequestDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<FindOnTreeResponseDto>;
  public treeControllerFindOnTree(
    body: FindOnTreeRequestDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<FindOnTreeResponseDto>>;
  public treeControllerFindOnTree(
    body: FindOnTreeRequestDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<FindOnTreeResponseDto>>;
  public treeControllerFindOnTree(
    body: FindOnTreeRequestDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling treeControllerFindOnTree.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<FindOnTreeResponseDto>(
      'post',
      `${this.basePath}/tree/findOnTree`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Get full information about tree item
   *
   * @param name
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public treeControllerFindTree(
    name: string,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<TreeItemFindResponseDto>;
  public treeControllerFindTree(
    name: string,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<TreeItemFindResponseDto>>;
  public treeControllerFindTree(
    name: string,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<TreeItemFindResponseDto>>;
  public treeControllerFindTree(
    name: string,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (name === null || name === undefined) {
      throw new Error(
        'Required parameter name was null or undefined when calling treeControllerFindTree.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<TreeItemFindResponseDto>(
      'get',
      `${this.basePath}/tree/find/${encodeURIComponent(String(name))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Get full information about tree item
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public treeControllerFindTreeById(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<TreeItemFindResponseDto>;
  public treeControllerFindTreeById(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<TreeItemFindResponseDto>>;
  public treeControllerFindTreeById(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<TreeItemFindResponseDto>>;
  public treeControllerFindTreeById(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling treeControllerFindTreeById.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<TreeItemFindResponseDto>(
      'get',
      `${this.basePath}/tree/find_by_id/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Move tree item
   *
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public treeControllerGetMoveDestinations(
    body: GetMoveDestinationsRequestDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<GetMoveDestinationsResponseDto>;
  public treeControllerGetMoveDestinations(
    body: GetMoveDestinationsRequestDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<GetMoveDestinationsResponseDto>>;
  public treeControllerGetMoveDestinations(
    body: GetMoveDestinationsRequestDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<GetMoveDestinationsResponseDto>>;
  public treeControllerGetMoveDestinations(
    body: GetMoveDestinationsRequestDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling treeControllerGetMoveDestinations.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<GetMoveDestinationsResponseDto>(
      'post',
      `${this.basePath}/tree/move/destinations`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Get full information about tree item
   *
   * @param id
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public treeControllerGetTree(
    id: number,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<ItemTreeDto>;
  public treeControllerGetTree(
    id: number,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<ItemTreeDto>>;
  public treeControllerGetTree(
    id: number,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<ItemTreeDto>>;
  public treeControllerGetTree(
    id: number,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling treeControllerGetTree.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ItemTreeDto>(
      'get',
      `${this.basePath}/tree/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Tree list for given user and language
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public treeControllerGetTreeList(
    body: ItemTreeListRequestDto,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<ItemTreeListResponseDto>;
  public treeControllerGetTreeList(
    body: ItemTreeListRequestDto,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<ItemTreeListResponseDto>>;
  public treeControllerGetTreeList(
    body: ItemTreeListRequestDto,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<ItemTreeListResponseDto>>;
  public treeControllerGetTreeList(
    body: ItemTreeListRequestDto,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling treeControllerGetTreeList.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ItemTreeListResponseDto>('post', `${this.basePath}/tree/list`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Move tree item
   *
   * @param body
   * @param x_apigateway_event
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public treeControllerMove(
    body: MoveItemRequestDto,
    x_apigateway_event?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<MoveItemResponseDto>;
  public treeControllerMove(
    body: MoveItemRequestDto,
    x_apigateway_event?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<MoveItemResponseDto>>;
  public treeControllerMove(
    body: MoveItemRequestDto,
    x_apigateway_event?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<MoveItemResponseDto>>;
  public treeControllerMove(
    body: MoveItemRequestDto,
    x_apigateway_event?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling treeControllerMove.',
      );
    }

    let headers = this.defaultHeaders;
    if (x_apigateway_event !== undefined && x_apigateway_event !== null) {
      headers = headers.set('x-apigateway-event', String(x_apigateway_event));
    }

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<MoveItemResponseDto>('post', `${this.basePath}/tree/move`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
