import { debounceTime, fromEvent, Subject, takeUntil } from 'rxjs';

const endTracking$ = new Subject<void>();
export function trackMousePosInCss() {
  const root = document.documentElement;

  fromEvent(document, 'mousemove')
    .pipe(takeUntil(endTracking$), debounceTime(10))
    .subscribe((event: MouseEvent) => {
      const x = event.clientX / innerWidth;
      const y = event.clientY / innerHeight;

      root.style.setProperty('--mouse-x', x.toString());
      root.style.setProperty('--mouse-y', y.toString());
    });
}

export function stopTrackingMousePosInCss() {
  endTracking$.next();
}
