import { Observable } from 'rxjs';

export function resizeBase64Img(base64, newWidth: number, newHeight: number) {
  return new Observable((observer) => {
    const canvas = document.createElement('canvas');
    canvas.width = newWidth;
    canvas.height = newHeight;
    let context = canvas.getContext('2d');
    let img = document.createElement('img');
    img.src = base64;
    img.onload = function () {
      context.scale(newWidth / img.width, newHeight / img.height);
      context.drawImage(img, 0, 0);
      observer.next(canvas.toDataURL());
      observer.complete();
      // document.removeChild(canvas);
    };
  });
}
