import { Component, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import {
  filter,
  finalize,
  fromEvent,
  merge,
  race,
  Subject,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  timer,
} from 'rxjs';
import { TreeNodeAction } from './tree-async/models/TreeNodeAction';
import { TreeNodeActionType } from './tree-async/models/TreeNodeActionType';
import { TreeNode } from './tree-async/models/TreeNode';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { AlertService } from '../../../shared/services/alert.service';
import { WaitService } from '../../../shared/services/wait.service';
import { TreeNodeMutateOptions } from './tree-async/models/TreeNodeMutateOptions';
import { TreeNodeRefreshOptions } from './tree-async/models/TreeNodeRefreshOptions';
import { LayoutService } from '../../../layout/layout.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TreeNodeFormComponent } from './tree-node-form/tree-node-form.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { TreeNodeService } from './tree-async/TreeNodeService';
import { TreeAsyncComponent } from './tree-async/tree-async.component';
import { TreeService } from '../tree.service';
import { TreeDialogMode } from './tree-node-form/TreeDialogMode';
import {
  // PresetTreeElementsId,
  PresetTreeElementsService,
} from './tree-async/preset-tree-elements.service';
import { TableService } from '../../../shared/components/table/table.service';

@Component({
  selector: 'app-tree-controller',
  templateUrl: './tree-controller.component.html',
  styleUrls: ['./tree-controller.component.scss'],
  standalone: true,
  imports: [TreeAsyncComponent],
})
export class TreeControllerComponent implements OnDestroy {
  treeNodeService: TreeNodeService = new TreeNodeService();

  unsubscribe$ = new Subject<void>();

  @Output() itemSelected = new EventEmitter<TreeNode>();

  @Output() itemUpdated = new EventEmitter<TreeNode>();

  private moveHandled$ = new Subject<void>();

  @Output() treeNodeServiceSet = new EventEmitter<TreeNodeService>();

  constructor(
    private treeService: TreeService,
    private alertService: AlertService,
    private waitService: WaitService,
    protected dialog: MatDialog,
    private layoutService: LayoutService,
    private translateService: TranslateService,
    private router: Router,
    private presetElements: PresetTreeElementsService,
    private tableService: TableService,
  ) {
    this.treeNodeService.nodeAction$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action) => this.handleNodeAction(action));

    this.treeNodeService.config.displayActionButtons = true;

    merge(this.layoutService.organizationChanged$, this.translateService.onLangChange)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.treeNodeService.refresh(TreeNodeRefreshOptions.ALL);
      });
    // this.handleLSItemSelection();

    setTimeout(() => this.treeNodeServiceSet.emit(this.treeNodeService));
  }

  private handleNodeAction(action: TreeNodeAction) {
    if (!action.node) return;
    switch (action.action) {
      case TreeNodeActionType.ON_ADD_CLICKED:
        this.onAdd(action.node);
        break;
      case TreeNodeActionType.ON_EDIT_CLICKED:
        this.onEdit(action.node);
        break;
      case TreeNodeActionType.ON_DELETE_CLICKED:
        this.onDelete(action.node);
        break;
      case TreeNodeActionType.ON_CLICK:
        if (this.treeNodeService.filterOptions.moveNodeFilter != null) {
          this.handleMove(action.node.id);
          break;
        }
        if (this.treeNodeService.config.selectNodes) this.onSelectionChanged(action.node);
        break;
      case TreeNodeActionType.ON_INIT:
        this.onItemUpdated(action.node);
        break;
      case TreeNodeActionType.ON_MOVE_START:
        this.onMoveStart(action.node);
        break;
      case TreeNodeActionType.ON_MOVE_ENDED:
        this.treeNodeService.filterOptions.moveNodeFilter = null;
        this.treeNodeService.filterOptions.moveNodeDestinations = null;
        break;
    }
  }

  private onMoveStart(node: TreeNode) {
    this.treeNodeService.config.moveHandling = true;
    this.treeNodeService.filterOptions.moveNodeFilter = node;
    this.waitService.start();
    const stopLoading$ = new Subject<void>();
    this.handleClosingMenuByRightClick(stopLoading$);
    // console.log('-> node', node);
    this.treeService
      .getMoveDestinationsItemTree({ id: node.id })
      .pipe(
        finalize(() => {
          this.waitService.stop();
          this.treeNodeService.config.moveHandling = false;
          this.treeNodeService.refresh(TreeNodeRefreshOptions.VIEW);
          // el.addEventListener('contextmenu', e => e.preventDefault()
        }),
        takeUntil(stopLoading$),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (data) => {
          // console.log('-> data', data);
          this.treeNodeService.filterOptions.moveNodeDestinations = data.data.map((o) => o.id);
        },
        error: (err) => {
          console.warn(err);
        },
      });
  }

  private handleClosingMenuByRightClick(stopLoading$: Subject<void>) {
    race(
      fromEvent(document, 'contextmenu'),
      fromEvent(document, 'keydown').pipe(filter((e: KeyboardEvent) => e.key == 'Escape')),
    )
      .pipe(
        // filter((e: MouseEvent) => e.button === 2),
        take(1),
        takeUntil(this.moveHandled$),
      )
      .subscribe((e: MouseEvent) => {
        // console.log('menu closed');
        stopLoading$.next();
        e.stopPropagation();
        e.preventDefault();
        this.moveEnded();
      });
  }

  private handleMove(destinationId: number | string | null) {
    if (destinationId === 0) destinationId = null;

    if (this.treeNodeService.config.moveHandling == true) return;
    const movedNode = this.treeNodeService.filterOptions.moveNodeFilter;
    if (movedNode.id == destinationId || movedNode.parent_id == destinationId) {
      this.moveEnded();
      return;
    }
    this.treeNodeService.config.moveHandling = true;
    this.waitService.start();
    this.treeService
      .moveItemTree({ id: movedNode.id, parent_id: destinationId })
      .pipe(
        switchMap(() => {
          this.treeNodeService.mutateNode$.next({
            mode: TreeNodeMutateOptions.DELETE,
            node: movedNode,
          });
          return timer(50);
        }),
        finalize(() => {
          this.waitService.stop();
          this.moveEnded();
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        movedNode.parent_id = destinationId; // ?? 0; //root
        this.treeNodeService.mutateNode$.next({
          mode: TreeNodeMutateOptions.ADD,
          node: movedNode,
        });
      });
  }

  private moveEnded() {
    this.treeNodeService.nodeAction$.next({
      action: TreeNodeActionType.ON_MOVE_ENDED,
      node: this.treeNodeService.filterOptions.moveNodeFilter,
    });
    this.treeNodeService.config.moveHandling = false;
    this.treeNodeService.refresh(TreeNodeRefreshOptions.VIEW);
    this.moveHandled$.next();
  }

  onAdd(node: TreeNode = null) {
    if (!node || Number(node.id) == 0) {
      this.onOpenTreeDialog(TreeDialogMode.CreateRoot, node);
      return;
    }
    this.onOpenTreeDialog(TreeDialogMode.Create, node);
  }

  private onEdit(node: TreeNode) {
    this.onOpenTreeDialog(TreeDialogMode.Edit, node);
  }

  private onDelete(node: TreeNode) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      id: node.id,
      name: node.item_name,
    };
    if (TreeService.isLoggerNode(node.item_type_code) && node.item_id != null) {
      // dialogConfig.data.counter = 3;
      dialogConfig.data.confirmDeletion = true;
    }
    if (node.children_count > 0) {
      dialogConfig.data.customLine = 'TREE.WARNING.ALL_CHILDREN_WILL_BE_DELETED';
      dialogConfig.data.counter = 5;
    }

    this.openDeleteDialog(node, dialogConfig);
  }

  private openDeleteDialog(node: TreeNode, dialogConfig: MatDialogConfig) {
    this.dialog
      .open(ConfirmDeleteDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        takeWhile((result) => result),
        tap(() => this.waitService.start()),
        switchMap(() => this.treeService.deleteItemTree(Number(node.id))),
        finalize(() => this.waitService.stop()),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (res: any) => {
          this.treeNodeService.mutateNode$.next({ mode: TreeNodeMutateOptions.DELETE, node: node });

          const itemsDeleted = res.items_deleted;

          if (node.id == this.layoutService.selectedItem?.id) {
            this.onSelectionChanged(null);
            this.navigate('tree/inspect');
          }

          if (TreeService.isLoggerNode(node.item_type_code)) {
            // Refresh preset logger list element...
            // this.presetElements.refreshPresetElements([PresetTreeElementsId.loggers]);
            // ...and table preset logger list element.
            this.tableService.refreshTable$.next();
          }

          this.alertService.info(
            this.alertService.concatMessage([
              `${itemsDeleted} `,
              itemsDeleted > 1 ? 'TREE.ITEMS' : 'TREE.ITEM',
              ' ',
              itemsDeleted > 1
                ? 'NOTIFICATION.DELETE_TREE_ITEMS_SUCCESSFULLY'
                : 'NOTIFICATION.DELETE_TREE_ITEM_SUCCESSFULLY',
            ]),
          );
        },
        error: () => {
          this.alertService.error('NOTIFICATION.SOMETHING_WENT_WRONG');
        },
      });
  }

  private navigate(url: string) {
    this.router.navigate([url]).catch(console.error);
  }

  private onSelectionChanged(node: TreeNode) {
    this.itemSelected.emit(node);
  }

  private onItemUpdated(node: TreeNode) {
    this.itemUpdated.emit(node);
  }

  private onOpenTreeDialog(mode: TreeDialogMode, node: TreeNode) {
    const dialogConfig = new MatDialogConfig();
    if (mode !== TreeDialogMode.CreateRoot && !node) throw new Error('Wrong dialog configuration!');

    dialogConfig.data = {
      mode: mode,
      item: node,
    };
    dialogConfig.disableClose = true; //TO prevent unintended close and loose of entered data
    this.dialog
      .open(TreeNodeFormComponent, dialogConfig)
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (!result) return;
        // console.log('=>(tree-controller.component.ts:321) result', result);

        let mutationMode: TreeNodeMutateOptions;
        if (mode == TreeDialogMode.Edit) mutationMode = TreeNodeMutateOptions.UPDATE;
        if (mode == TreeDialogMode.Create || mode == TreeDialogMode.CreateRoot) {
          mutationMode = TreeNodeMutateOptions.ADD;
          this.layoutService.treeItemsCount$.next(this.layoutService.treeItemsCount$.value + 1);
        }

        if (result.refresh) {
          this.treeNodeService.refresh(TreeNodeRefreshOptions.ALL);
        } else this.treeNodeService.mutateNode$.next({ mode: mutationMode, node: result });
      });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    this.treeNodeService.destroy();
  }
}
