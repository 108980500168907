import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { debounceTime, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor],
})
export class SkeletonComponent implements AfterViewInit, OnDestroy {
  @ViewChild('skeleton') skeleton: ElementRef;

  private resizeObserver: ResizeObserver;

  private container: HTMLElement;

  private linesLength = 2;

  private onChange$ = new Subject<void>();

  private unsubscribe$ = takeUntilDestroyed();

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.setContainer();
    if (!this.container) return;
    this.onChange$
      .pipe(debounceTime(10), this.unsubscribe$)
      .subscribe(() => this.setWidthBasedOnContainer());

    this.observe();
    this.setWidthBasedOnContainer();
  }

  get lines() {
    // console.log('lines got');
    return [].constructor(this.linesLength);
  }

  setWidthBasedOnContainer() {
    // console.log('set width');
    this.setContainer();
    if (!this.container) return;

    const computedStyle = getComputedStyle(this.container);

    const width =
      this.container.offsetWidth -
      parseFloat(computedStyle.paddingLeft) -
      parseFloat(computedStyle.paddingRight);

    // const height =
    //   this.container.offsetHeight -
    //   parseFloat(computedStyle.paddingBottom) -
    //   parseFloat(computedStyle.paddingTop);

    this.skeleton.nativeElement.style.width = width * 0.95 + 'px';
    // this.skeleton.nativeElement.style.height = height + 'px';
    this.cdr.detectChanges();

    this.linesLength = Math.max(Math.floor((this.container.offsetHeight - 40) / (32 + 40)), 1);
    this.cdr.detectChanges();
  }

  private setContainer() {
    this.container = this.skeleton.nativeElement.parentElement.parentElement;
  }

  private observe() {
    this.resizeObserver = new ResizeObserver(() => this.onChange$.next());
    this.resizeObserver.observe(this.container);
  }

  private unobserve() {
    this.resizeObserver.unobserve(this.container);
  }

  ngOnDestroy() {
    if (this.container) this.unobserve();
  }
}
