export function fillStyle(uplot: any, value: number, minValue: number, maxValue: number): string {
  if (minValue == maxValue) return uplot.heatmap.palette.slice(-1)[0];

  if (value < minValue) return uplot.heatmap.palette[0];

  const paletteLength = uplot.heatmap.palette.length;
  const percentagePos = (value - minValue) / (maxValue - minValue);

  // const percentagePos = value / maxValue;
  const posInPalette = uplot.heatmap.breakpoints.findIndex((o, i, self) => {
    return percentagePos >= o && (i == self.length - 1 || percentagePos < self[i + 1]);
  });
  const isLastElement = posInPalette == paletteLength - 1;
  // const isFirstElement = posInPalette == 0;
  if (isLastElement) return uplot.heatmap.palette[posInPalette];
  // if (isFirstElement) return uplot.heatmap.palette[0];

  if (posInPalette < 0 || posInPalette > uplot.heatmap.breakpoints.length - 1) {
    throw new Error(`Pos in palette is invalid (${percentagePos}% - ${posInPalette})`);
  }

  return getGradient(uplot, percentagePos, posInPalette);
}

export function getGradient(uplot: any, percentagePos: number, posInPalette: number) {
  const col1 = uplot.heatmap.palette[posInPalette];
  const col2 = uplot.heatmap.palette[posInPalette + 1];

  const breakpoint = uplot.heatmap.breakpoints[posInPalette];
  const nextBreakpoint = uplot.heatmap.breakpoints[posInPalette + 1];

  const factor = (percentagePos - breakpoint) / (nextBreakpoint - breakpoint);

  const ar = parseInt(col1.substring(1, 3), 16);
  const ag = parseInt(col1.substring(3, 5), 16);
  const ab = parseInt(col1.substring(5), 16);

  const br = parseInt(col2.substring(1, 3), 16);
  const bg = parseInt(col2.substring(3, 5), 16);
  const bb = parseInt(col2.substring(5), 16);
  return `rgb(${ar + factor * (br - ar)}, ${ag + factor * (bg - ag)}, ${ab + factor * (bb - ab)})`;
}

export function getMinMax(data: Array<Array<number>>, mode: 'ABSOLUTE' | 'SUM' = 'SUM'): number[] {
  const getExtremeVal = (arr: Array<number>, extreme: 'min' | 'max'): number => {
    return arr.reduce(
      (acc, val) => Math[extreme](val, acc),
      extreme == 'min' ? Infinity : -Infinity,
    );
  };

  switch (mode) {
    case 'ABSOLUTE': {
      const maxValue = data.reduce(
        (acc, val) => Math.max(getExtremeVal(val, 'max'), acc),
        -Infinity,
      );
      const minValue = data.reduce(
        (acc, val) => Math.min(getExtremeVal(val, 'min'), acc),
        Infinity,
      );
      return [minValue, maxValue];
    }
    case 'SUM': {
      // const maxValue = data.reduce((acc, values) => getExtremeVal(values, 'max') + acc, 0);
      // const minValue = data.reduce((acc, values) => getExtremeVal(values, 'min') + acc, 0);
      //previous approach (commented) calculated sum of max/min values, current find min/max of summed values

      let summedData = [];
      for (let dataIndex = 0; dataIndex < data[0].length; dataIndex++) {
        let sum = null;
        for (let seriesIndex = 0; seriesIndex < data.length; seriesIndex++) {
          if (!sum) sum = data[seriesIndex][dataIndex];
          else sum += data[seriesIndex][dataIndex];
        }
        if (sum == null || isNaN(sum)) continue;
        summedData.push(sum);
        // summedData[dataIndex] = sum;
      }

      let maxValue = Math.max(...summedData);
      let minValue = Math.min(...summedData);

      if (minValue == Infinity) maxValue = null;
      if (maxValue == -Infinity) maxValue = null;
      return [minValue, maxValue];
    }
  }
}

export function updatePaletteBreakPoints(uplot) {
  if (uplot.heatmap.breakpoints && uplot.heatmap.breakpoints.length > 0) return;
  const paletteLength = uplot.heatmap.palette.length;
  const breakpoints: number[] = [];
  const step = 100 / paletteLength;
  for (let i = 0; i < paletteLength; i++) {
    breakpoints.push((i * step) / 100);
  }

  uplot.heatmap.breakpoints = breakpoints;
}
