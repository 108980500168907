import { DashboardItemDataGraphTypesModel } from '../../../models/DashboardItemDataGraphTypes.model';
const MAX_SERIES = 10;
const singleSeriesGraphTypes = [
  DashboardItemDataGraphTypesModel.PIE_MONTHLY_AGG,
  DashboardItemDataGraphTypesModel.PIE_WEEKLY_AGG,
];
export function maxSeriesForGraphType(type: DashboardItemDataGraphTypesModel) {
  if (singleSeriesGraphTypes.includes(type)) return 1;
  else return MAX_SERIES;
}
