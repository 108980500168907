<form [formGroup]="searchForm" [hidden]="!searchVisible" class="search-form">
  <app-emu-input
    className="search-container"
    [maxLength]="100"
    (keydown)="$event.stopPropagation()"
    formControlName="name"
    placeholder="{{ 'INPUT.SEARCH' | translate }}"
  >
    <mat-icon prefix>search</mat-icon>
  </app-emu-input>
</form>
