<app-dropdown [position]="position" class="indexed">
  <button mat-mini-fab class="primary-fab dropdown-button" data-cy="language-dropdown">
    <mat-icon>language</mat-icon>
  </button>
  <ng-container body>
    <a
      *ngFor="let lang of translate.getLangs()"
      (click)="changeLanguage(lang)"
      [class.selected]="isSelected(lang)"
    >
      <mat-icon class="flag-svg">
        <img
          alt="{{ lang.toLocaleUpperCase() }}"
          src="../../assets-common/flags/{{ lang.toLocaleUpperCase() }}.svg"
        />
      </mat-icon>
      <span>{{ 'NAVBAR.' + lang.toUpperCase() | translate }}</span>
    </a>
  </ng-container>
</app-dropdown>
