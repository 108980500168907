<div class="app-loading" *ngIf="display">
  <div class="logo">
    <img
      ngSrc="../../../assets-common/{{ oemId }}/logo.svg"
      alt=""
      draggable="false"
      fill
      priority
    />
  </div>

  <div class="loading-container">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>

  <!--  <div class="loading-circle"></div>-->
</div>
