<ng-container *ngIf="data">
  <h2>{{ 'SIGNUP.COMPANY_DATA' | translate }}</h2>
  <form [formGroup]="form">
    <section *ngFor="let input of inputs">
      <mat-form-field>
        <mat-label>{{ input.displayName | translate }}</mat-label>
        <input
          matInput
          autocomplete="{{ input.autocompleteTag }}"
          formControlName="{{ input.name }}"
          [(ngModel)]="data[input.name.toString()]"
        />
        <mat-error *ngIf="form.get(input.name).invalid && form.get(input.name).dirty">
          {{ 'SIGNUP.ERROR' | translate }}
        </mat-error>
      </mat-form-field>
    </section>
  </form>
</ng-container>
