<div *ngIf="opened()" class="debug-tool-container" cdkDrag cdkDragBoundary="body">
  <div class="header">
    <span>DEBUG TOOL</span>
    <mat-icon color="warn" (click)="opened.set(false)">close</mat-icon>
  </div>

  <div class="actions">
    <app-emu-list-input
      (valueChange)="actionInput$.next($event)"
      [value]="actionInput$ | async"
      [name]="'ACTION'"
      (optionSelected)="actionInput$.next($event)"
      [filteredOptions$]="filteredActions$"
      (optionCleared)="actionInput$.next('')"
    >
      <ng-template let-item
        ><p class="device-name">{{ item }}</p></ng-template
      >
    </app-emu-list-input>

    <button
      *ngIf="actionInput$ | async"
      mat-flat-button
      class="button-primary"
      (click)="actionButtonTriggered()"
    >
      EXECUTE ACTION "{{ actionInput$ | async }}"
    </button>
  </div>
</div>
