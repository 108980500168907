import { DeviceValueDtoExpandable } from '../../../../../pages/data/select-series/models/DeviceValueDtoExpandable.model';
import { SelectedSeriesModel } from '../../../../../pages/data/select-series/models/SelectedSeries.model';
import { UplotSeriesConfig } from '../models/UplotSeriesConfig.model';
import { cfgUnits } from '../models/CfgUnits';

export function isSeriesConsumptionValue(
  item: DeviceValueDtoExpandable | SelectedSeriesModel | UplotSeriesConfig,
): boolean {
  if ((<any>item).consumption != null) return (<any>item).consumption;

  return canSeriesBeConsumptionValue(item);
}

export function canSeriesBeConsumptionValue(
  item: DeviceValueDtoExpandable | SelectedSeriesModel | UplotSeriesConfig,
): boolean {
  if (item.cfg_unit && !isNaN(Number(item.cfg_unit))) {
    const cfgUnit = cfgUnits.get(Number(item.cfg_unit));
    // console.log('-> cfgUnit', cfgUnit);
    if (cfgUnit != undefined) return cfgUnit.type == 'Consumption';
  }
  return false;
}
