import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { GraphComponent } from '../../../../../shared/components/graph-uplot/graph/graph.component';
import { UplotSeriesConfig } from '../../../../../shared/components/graph-uplot/graph/models/UplotSeriesConfig.model';
import { catchError, map, Observable, of, Subject, tap } from 'rxjs';
import { DataDisplayDefaultRanges } from '../../../../../shared/models/DataDisplayDefaultRanges';
import { DataServiceProvider } from '../../../../data/api-data.service';
import { DashboardService } from '../../../services/dashboard.service';
import { SeriesGraphSettingsModel } from '../../../models/SeriesGraphSettings.model';
import { DashboardItemDataGraph } from '../../../models/DashboardItemDataGraph.model';
import { deepEquals } from '../../../../../shared/utils/deepEquals';
import { DataRequestDto } from '../../../../../../api-main';
import { UplotInputData } from '../../../../../shared/components/graph-uplot/graph/models/UplotInputData.model';
import { UplotResponseData } from '../../../../../shared/components/graph-uplot/graph/models/UplotResponseData.model';
import { DashboardItem } from '../../../models/DashboardItem';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RefreshValue } from '../dashboard-graph.component';
import { getSeriesConfig, getSeriesForRequest } from '../seriesUtils';
import { formatDataByUnit } from '../../../../data/formatDataByUnit';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-uplot-graph',
  standalone: true,
  imports: [GraphComponent, TranslateModule],
  templateUrl: './dashboard-uplot-graph.component.html',
  styleUrl: './dashboard-uplot-graph.component.scss',
})
export class DashboardUplotGraphComponent implements AfterViewInit {
  @ViewChild('graphComponent') graphRef: GraphComponent;

  // refreshGraph$: Subject<void> = new Subject<void>();

  public downloadedSeries: Array<UplotSeriesConfig>;

  seriesConfig$: Subject<UplotSeriesConfig[]> = new Subject<UplotSeriesConfig[]>();

  private lastSavedConfig: UplotSeriesConfig[];

  private userRange: DataDisplayDefaultRanges;

  @Input({ required: true }) item: DashboardItem;

  @Input({ required: true }) refresh$: Observable<RefreshValue>;

  constructor(
    private dataService: DataServiceProvider,
    protected dashboardService: DashboardService,
    protected cd: ChangeDetectorRef,
  ) {}

  private unsubscribe = takeUntilDestroyed();

  ngAfterViewInit() {
    this.setCustomRange();
    this.refresh$.pipe(this.unsubscribe).subscribe((val: string) => {
      switch (val) {
        case 'series':
          this.updateSeries();
          break;
        case 'range':
          this.updateRange();
          break;
        case 'graph':
          this.refreshGraph();
          break;
        default:
          this.refreshOnChanges();
      }
    });
  }

  get series(): Array<SeriesGraphSettingsModel> {
    return this.data?.series ?? [];
  }

  get data(): DashboardItemDataGraph {
    return this.item.data?.graph;
  }

  get defaultRange(): DataDisplayDefaultRanges {
    return this.data.userDefinedRange ?? DataDisplayDefaultRanges.CURRENT_DAY;
  }

  private refreshOnChanges() {
    if (!this.downloadedSeries) return;
    const changes = !deepEquals(
      this.lastSavedConfig,
      getSeriesConfig(this.series, this.downloadedSeries),
    );
    if (!changes) return;
    setTimeout(() => {
      this.refreshGraph();
    }, 100);
  }

  updateSeries() {
    this.lastSavedConfig = getSeriesConfig(this.series, this.downloadedSeries);
    this.seriesConfig$.next(this.lastSavedConfig);
    this.updateRange();
  }

  refreshGraph() {
    this.cd.detectChanges();
    this.updateRange();
    this.graphRef.refresh$.next();
  }

  updateRange() {
    if (this.data.userDefinedRange == null) return;
    this.setCustomRange();
    if (this.userRange == this.data.userDefinedRange) return;

    this.userRange = this.data.userDefinedRange;
    this.graphRef.userDefinedRange$.next(this.data.userDefinedRange);
  }

  private setCustomRange() {
    if (
      this.data.userDefinedRange == DataDisplayDefaultRanges.CUSTOM &&
      this.data.range?.min &&
      this.data.range?.max
    ) {
      this.graphRef.setUplotRange(JSON.parse(JSON.stringify(this.data.range)));
      // this.graphRef.setUplotRange(this.data.range);
    }
  }

  public getData: UplotInputData = (currentScale: DataRequestDto.ScaleEnum, range) => {
    const request: DataRequestDto = {
      min: Math.floor(range.min),
      max: Math.ceil(range.max),
      scale: currentScale,
      series: getSeriesForRequest(this.data.series),
      dashboard_id: this.dashboardService.dashboard?.id,
    };
    if (getSeriesForRequest(this.data.series).length === 0) {
      return of({ data: [[request.min, request.max]], series: [] });
    }
    return <Observable<UplotResponseData>>(<unknown>this.dataService.getData(request).pipe(
      //display empty graph on error
      catchError(() => {
        return of({ data: [[request.min, request.max]], series: [] });
      }),
      tap((response: any) => this.setDownloadSeriesConfig(response.series)),
      map((response: UplotResponseData) =>
        formatDataByUnit({ data: response.data, series: this.series }, response.series),
      ),
      map((response: any) => {
        return {
          data: response.data,
          series: getSeriesConfig(this.series, this.downloadedSeries),
        };
      }),
    ));
    // .pipe(tap((response: any) => console.log('response:', response))));
  };

  private setDownloadSeriesConfig(seriesConfig: UplotSeriesConfig[]) {
    this.downloadedSeries = seriesConfig;
    this.lastSavedConfig = getSeriesConfig(this.series, this.downloadedSeries);
  }

  itemLoaded() {
    setTimeout(() => this.dashboardService.itemLoaded(this.item));
  }
}
