import { UplotMakeOpts } from '../hourly/uplotMakeHeatmapHourly';
import { uplotGetAxes } from '../../graph/config/uplotAxes';

export function heatmapDailyAxes(param: UplotMakeOpts) {
  const axes: any = uplotGetAxes(param.valueFormatFunction, param.dateFormatFunction);

  // X AXE
  (<any>axes[0]).splits = () => {
    return Array.from(Array(31).keys()).map((o) => o + 1);
  };

  axes[0].values = (u, val) => {
    return val.map((day: any) => {
      if (day > 0 && day < 32) return day;
      return null;
    });
  };

  //Y AXE
  axes[1].values = (u, val) => {
    return val.map((month: any) => {
      const date = new Date();
      date.setMonth(month);
      const monthStr = date.toLocaleString(param.localeService.locale, {
        month: 'short',
        timeZone: param.localeService.timezone,
      });
      const yearString = date.getFullYear().toString().substring(2);
      return `${monthStr} '${yearString}`;
    });
  };

  axes[1].size = 70;

  return axes;
}
