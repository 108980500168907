<div class="select-container">
  <app-emu-list-input
    [loading$]="loading$"
    [(value)]="selectedDeviceValue"
    [name]="'LOGGER.DEVICE_VALUES'"
    [autocomplete]="false"
    [disabled]="deviceValues.length === 0 || !isDataAvailable()"
    (optionSelected)="valueDisplaySelected()"
    [clearButtonHidden]="true"
    [filteredOptions$]="of(deviceValues)"
    [className]="'dashboard-settings'"
    [displayWith]="displayFn.bind(this)"
  >

    <ng-template let-item>
      <p class="device-name">{{ getDeviceParameterName(item) }}</p>

      <p class="last-connection">
        {{ 'LOGGER.LAST_CONNECTION' | translate }}:
        <app-timestamp-relative
          [time]="item.last_import"
          [showRelative]="true"
        ></app-timestamp-relative>

      </p>
      <p class="last-connection value">
        ( {{ item.last_value }} [{{ item.unit_str }}])
      </p>
    </ng-template>


    <ng-template let-item #displayedValue>
        <p class="device-name">{{ getDeviceParameterName(item) }}</p>
    </ng-template>

  </app-emu-list-input>
</div>
