/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LanguageDto {
  /**
   * Language short code
   */
  lng: string;
  /**
   * Language description
   */
  name: string;
  /**
   * Information about locale e/g en-US, en-GB
   */
  locale: string;
}
