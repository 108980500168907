import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { PrivilegesService } from '../../../shared/services/privileges.service';
import { LayoutService } from '../../layout.service';
import { NgIf } from '@angular/common';
import { TreeControllerComponent } from '../../../pages/tree/tree-controller/tree-controller.component';
import { TreeNode } from '../../../pages/tree/tree-controller/tree-async/models/TreeNode';
import { TreeNodeService } from '../../../pages/tree/tree-controller/tree-async/TreeNodeService';
import { getNodeUrl } from '../../../pages/tree/tree-controller/tree-async/tree-node/getNodeUrl';
import { TreeService } from '../../../pages/tree/tree.service';
import { forkJoin, map, of, take } from 'rxjs';
import { FindOnTreeRequestDto } from '../../../../api-main';
import { TreeNodeActionType } from '../../../pages/tree/tree-controller/tree-async/models/TreeNodeActionType';
import { PresetTreeElementsService } from '../../../pages/tree/tree-controller/tree-async/preset-tree-elements.service';

@Component({
  selector: 'app-main-layout-tree',
  templateUrl: './main-layout-tree.component.html',
  styleUrls: ['./main-layout-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TreeControllerComponent],
})
export class MainLayoutTreeComponent {
  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private privilegesService: PrivilegesService,
    private layoutService: LayoutService,
    private treeService: TreeService,
    private presetElementsService: PresetTreeElementsService,
  ) {}

  @ViewChild('treeList') treeList: TreeControllerComponent;

  @Output() nodeSelected = new EventEmitter<void>();

  // private selectedItem: TreeNode;

  private getItemFromRoute() {
    const url = this.router.url;
    const isTreeNode = new RegExp('/tree/inspect/[0-9]+').test(url);
    const isLogger = new RegExp('/logger/[0-9]+').test(url);
    const isDashboard = new RegExp('/dashboards/[0-9]+').test(url);

    if (!isTreeNode && !isLogger && !isDashboard) {
      this.handlePresetElement();
      return;
    }

    const id = this.extractIdFromUrl(url);
    if (!id) return;
    const request: FindOnTreeRequestDto = {};

    if (isDashboard) request.dashboard_id = id;
    if (isLogger) request.logger_id = id;
    if (isTreeNode) request.id = id;
    this.treeService
      .findOnTree(request)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          if (data.parents)
            data.parents.forEach((parentId) =>
              this.layoutService.treeNodeService.expandedNodes.add(parentId),
            );

          this.layoutService.treeNodeService.nodeAction$.next({
            node: <any>data,
            action: TreeNodeActionType.ON_CLICK,
          });
        },
        error: (err) => {
          const urlWithoutId = url.replaceAll(id.toString(), '');
          this.router.navigateByUrl(urlWithoutId).catch(console.warn);
          console.warn(err);
        },
      });
  }

  private handlePresetElement() {
    const isOrganizationPage = new RegExp('organization/[0-9]+').test(this.router.url);
    if (isOrganizationPage) {
      this.layoutService.treeNodeService.nodeAction$.next({
        node: <any>{
          id: this.presetElementsService.rootId,
          pointingUrl: `/organization/${this.privilegesService.organization()?.id}`,
        },
        action: TreeNodeActionType.ON_CLICK,
      });
      return;
    }

    const elements = this.presetElementsService.getPresetElements();
    const children = elements.map((o) => o.presetChildren).filter((o) => !!o);

    //gymnastic to retrieve presetChildren which are observables
    //children first to get proper url
    forkJoin([...children, ...elements.map((o) => of(o))])
      // .pipe(switchMap((o) => o))
      .pipe(map((o) => o.flat()))
      .subscribe((presetElements) => {
        const item = presetElements.find((o) => this.router.url.includes(o.pointingUrl));
        if (!item) return;
        this.layoutService.treeNodeService.expandedNodes.add(item.parent_id);
        this.layoutService.treeNodeService.nodeAction$.next({
          node: item,
          action: TreeNodeActionType.ON_CLICK,
        });
      });
  }

  private extractIdFromUrl(url: string = this.router.url): number {
    const match = url.match(/\/(\d+)$/);
    const id = match ? match[1] : null;
    return Number(id);
  }

  itemSelected(item: TreeNode) {
    // console.log('=>(main-layout-tree.component.ts:31) item', item);
    // console.log('main layout item selected -> item', item);

    //redirect to right place, skip if ith child of pointing url

    if (
      item &&
      item.isPreSetElement &&
      item.pointingUrl &&
      !this.router.url.includes(item.pointingUrl)
    ) {
      this.navigate(item.pointingUrl);
      this.nodeSelected.emit();
    }

    if (item && !item.isPreSetElement) this.navigate(getNodeUrl(item));

    if (item && (item.is_end_type || item.id === 0)) this.nodeSelected.emit();

    //TODO redirect to dashboard etc

    // this.selectedItem = item;
    this.layoutService.selectedItem = item;
    this.cdr.detectChanges();
    // if (this.isMobile()) this.openInspectModal();
  }

  setTreeNodeService(treeNodeService: TreeNodeService) {
    this.layoutService.treeNodeService = treeNodeService;
    this.getItemFromRoute();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemUpdated(item: TreeNode) {
    // TODO remove whole function
    // console.debug(item);
    //this causes double item select at tree init
    // if (item.id === this.selectedItem?.id) {
    //   console.log('From item updated', item);
    //   this.itemSelected(item);
    // }
  }

  private navigate(url: string) {
    this.router.navigate([url]).catch(console.error);
  }

  displayTree() {
    return !!this.privilegesService.organization();
  }
}
