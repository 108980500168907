/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.4
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DashboardUpdateDto {
  /**
   * id
   */
  id: number;
  /**
   * Config information for dashboard
   */
  config: Array<any>;
  /**
   * isFavourite
   */
  favourite?: boolean;
}
