/**
 * EMU Invoice API
 * EMU Invoice API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PeriodicInvoiceDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * id
   */
  readonly id?: number;
  /**
   * origin
   */
  readonly origin?: string;
  /**
   * lng
   */
  lng: string;
  /**
   * start_at
   */
  start_at: string;
  /**
   * end_at
   */
  end_at: string;
  /**
   * interval
   */
  interval: number;
  /**
   * interval_type
   */
  interval_type: PeriodicInvoiceDto.IntervalTypeEnum;
  /**
   * organization id
   */
  organization_id: number;
  /**
   * organization id
   */
  customer_id: number;
  /**
   * organization id
   */
  root_tree_id: number;
  /**
   * organization id
   */
  price_list_id: number;
}
export namespace PeriodicInvoiceDto {
  export type IntervalTypeEnum = 'DAY' | 'MONTH';
  export const IntervalTypeEnum = {
    DAY: 'DAY' as IntervalTypeEnum,
    MONTH: 'MONTH' as IntervalTypeEnum,
  };
}
