import { uplotDrawStyles } from '../utils/uplotConstants';

const gapMultiplier = 0.95;
export function multiBars(u, sidx, i0, i1) {
  // console.log('-> u', u);
  const s = u.series[sidx];
  const seriesWithBars = u.series.filter(
    (o) => o.drawStyle === uplotDrawStyles.multiBars && o.show,
  );

  const seriesWithBarId = seriesWithBars.findIndex((o) => o.id == s.id);

  const xdata = u.data[0];
  const ydata = u.data[sidx];

  // const visibleDataCount = getVisibleDataCount(u);

  // const totalWidth = (u.over.offsetWidth / visibleDataCount) * gapMultiplier;
  const totalWidth = getTotalWidth(u);

  const barWid = totalWidth / seriesWithBars.length;
  const offs = (seriesWithBars.length - 1 - seriesWithBarId) * barWid;

  const fill = new Path2D();
  const zeroY = u.valToPos(0, 'y', true);
  const xOffset = -totalWidth / 2 + offs;

  s.pointOffset = xOffset + barWid / 2;
  for (let i = i0; i <= i1; i++) {
    const x0 = u.valToPos(xdata[i], 'x', true);
    const y0 = u.valToPos(ydata[i], 'y', true);

    fill.rect(x0 + xOffset, y0, barWid, zeroY - y0);
  }

  return {
    fill: fill,
    stroke: fill,
  };
}

function getTotalWidth(u) {
  const xData = getVisibleDataX(u);
  if (xData.length == 1)
    return (u.valToPos(u.scales.x.max, 'x', true) - u.valToPos(u.scales.x.min, 'x', true)) * 0.5;
  const x0 = u.valToPos(xData[0], 'x', true);
  const x1 = u.valToPos(xData.slice(-1)[0], 'x', true);
  return ((x1 - x0) / xData.length) * gapMultiplier;
}

function getVisibleDataX(u) {
  const dataXArr = [];
  const dataX = u.data[0];
  const min = u.scales.x.min;
  const max = u.scales.x.max;
  for (let i = dataX.length; i >= 0; i--) {
    if (dataX[i] > min && dataX[i] < max) dataXArr.push(dataX[i]);
  }
  return dataXArr;
}
