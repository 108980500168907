<app-modal
  [title]="'SELECT SERIES'"
  [shouldSaveBeDisabled]="!isValid()"
  (save)="onSave()"
>
  <ng-container body>
    <mat-dialog-content>
      <app-selecting-value-data [data]="data" (valueSelected)="valueSelected()" [showConsumption]="false"
      ></app-selecting-value-data>
    </mat-dialog-content>
  </ng-container>
</app-modal>
