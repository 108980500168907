import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Input } from '@angular/core';
import { TreeNodeService } from '../../TreeNodeService';
import { TreeNode } from '../../models/TreeNode';
import { TreeNodeActionType } from '../../models/TreeNodeActionType';
import { TreeNodeAction } from '../../models/TreeNodeAction';
import { PrivilegesService } from '../../../../../../shared/services/privileges.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { DisabledDirective } from '../../../../../../shared/directives/disabled.directive';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { EmuListInputComponent } from '../../../../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import { filter, of } from 'rxjs';
import { TreeNodeRefreshOptions } from '../../models/TreeNodeRefreshOptions';
import { TreeNodeRoles } from '../../models/TreeNodeRoles';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-tree-node-permission',
  templateUrl: './tree-node-permission.component.html',
  styleUrls: ['./tree-node-permission.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    MatSelectModule,
    DisabledDirective,
    NgFor,
    MatOptionModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    EmuListInputComponent,
    AsyncPipe,
    UpperCasePipe,
  ],
})
export class TreeNodePermissionComponent implements AfterViewInit {
  @Input() node: TreeNode;

  @Input() treeNodeService: TreeNodeService;

  takeUntilDestroy = takeUntilDestroyed();

  parentRole: TreeNodeRoles;

  constructor(
    private cd: ChangeDetectorRef,
    private privilegesService: PrivilegesService,
  ) {}

  ngAfterViewInit() {
    if (this.treeNodeService)
      this.treeNodeService.refresh$
        .pipe(
          filter((val) => val.mode == TreeNodeRefreshOptions.VIEW),
          this.takeUntilDestroy,
        )
        .subscribe(() => {
          this.updateView();
        });
  }

  canEditRole() {
    return this.node.role == 'Edit';
  }

  @HostListener('document:click', ['$event'])
  updateView() {
    if (this.parentRole != this.getParentRole()) {
      this.parentRole = this.getParentRole();
      this.updateTempRole(this.parentRole);
    }
    this.cd.detectChanges();
  }

  disableEdit() {
    return this.role == null && this.getParentRole() == 'Edit';
  }

  clearButtonHidden(): boolean {
    return this.treeNodeService?.config?.disableRoleEdit == true;
  }

  //This basically gets info about higher level permissions
  private getParentRole(): TreeNodeRoles {
    //TODO inspect that mess

    const itemRole = this.role;

    if (itemRole) {
      const data = { id: Number(this.node.id), role: itemRole };
      const index = this.treeNodeService.config.tempInheritedRole?.findIndex(
        (o) => o.id == data.id,
      );
      if (index < 0) this.treeNodeService.config.tempInheritedRole.push(data);
      return null;
    }

    if (!this.node.parent_id) return null;

    const inheritedRole = this.treeNodeService.config.tempInheritedRole?.find(
      (o) => o.id == this.node.parent_id,
    )?.role;

    if (inheritedRole) {
      const data = { id: Number(this.node.id), role: inheritedRole };
      const index = this.treeNodeService.config.tempInheritedRole?.findIndex(
        (o) => o.id == data.id,
      );
      if (index < 0) this.treeNodeService.config.tempInheritedRole.push(data);
      return inheritedRole;
    }

    return null;
  }

  private updateTempRole(role: TreeNodeRoles) {
    if (!role && this.parentRole != null) {
      // console.log('role is null and parent is not');
      role = this.parentRole;
    }

    const tempRole = this.treeNodeService.config.tempInheritedRole.find(
      (o) => o.id == this.node.id,
    );
    if (tempRole) tempRole.role = role;
  }

  onRoleChange(role: TreeNodeRoles) {
    if (this.roleItem == null) {
      this.treeNodeService.config.roleItems.push(<any>{
        // id: null,
        role: role,
        item_tree_id: Number(this.node.id),
      });
    }
    if (role != null && role.length > 0) {
      this.roleItem.role = role;
      this.action(TreeNodeActionType.ON_ROLE_CHANGE, role);
      this.updateTempRole(role);
    } else {
      this.roleItem.role = null;
      this.action(TreeNodeActionType.ON_ROLE_REMOVED, this.roleItem.id);
      this.updateTempRole(null);
    }
    this.treeNodeService.refresh(TreeNodeRefreshOptions.VIEW);
  }

  // onRoleRemove() {
  //   if (this.roleItem)
  //   // this.treeNodeService.refresh(TreeNodeRefreshOptions.VIEW);
  // }

  get roles(): TreeNodeRoles[] {
    if (this.getParentRole() == 'View') return ['Edit'];
    return ['View', 'Edit'];
  }

  get role(): TreeNodeRoles {
    return this.roleItem?.role ?? null;
  }

  private get roleItem() {
    if (!this.treeNodeService?.config?.roleItems) return null;
    return (
      this.treeNodeService.config.roleItems
        // .filter((o) => !!o.role)
        .find((o) => o.item_tree_id == this.node.id)
    );
  }

  private action(action: TreeNodeActionType, value = null) {
    if (!this.treeNodeService?.nodeAction$) return;

    const nodeAction: TreeNodeAction = { node: this.node, action };
    if (value != null) nodeAction.value = value;

    this.treeNodeService.nodeAction$.next(nodeAction);
    // this.treeNodeService.refresh(TreeNodeRefreshOptions.NODE, { nodeId: this.node.id });
    this.treeNodeService.refresh(TreeNodeRefreshOptions.VIEW);
  }

  protected readonly of = of;
}
