import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ProfileDto } from '../../../api-auth';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { LayoutService } from '../../layout/layout.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userData = new BehaviorSubject<ProfileDto>(undefined);

  organizationId = new BehaviorSubject<number>(undefined);

  private appAuthState = new BroadcastChannel('app-auth-state');

  isPageAdmin$: Observable<boolean>;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    public auth: AuthService,
    private router: Router,
    private layoutService: LayoutService,
  ) {
    this.watchLogout();

    this.isPageAdmin$ = this.auth.idTokenClaims$.pipe(
      map((data) => data['emu-roles']?.includes('admin')),
    );
  }

  getUserId() {
    return this.userData.value?.user_id;
  }

  login() {
    this.appAuthState.postMessage('login');
    this.loginActions();
  }

  logout() {
    this.logoutActions();
    this.appAuthState.postMessage('logout');
    this.#logout();
  }

  logoutWithoutRedirect() {
    this.logoutActions();
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }

  #logout() {
    const url = `${this.doc.location.origin}?redirect=${this.router.url}`;
    this.auth.logout({ logoutParams: { returnTo: url } });
  }

  private logoutActions() {
    this.layoutService.treeNodeService?.clearCachedNodes();
  }

  private loginActions() {}

  private watchLogout() {
    this.appAuthState.onmessage = (event) => {
      if (event.data === 'logout') {
        this.logoutActions();
        this.#logout();
      } else {
        if (this.router.url.includes('login')) window.location.reload(); //this.#logout();
      }
    };
  }
}
