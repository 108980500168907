import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CompanyData } from '../../models/CompanyData.model';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { companyInfoInputs } from '../../../pages/organization/organization-save-modal/CompanyInfoInputs';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, NgFor, MatFormFieldModule, MatInputModule, TranslateModule],
})
export class CompanyInfoComponent implements OnInit, OnDestroy {
  constructor(private fb: FormBuilder) {}

  @Input() data: CompanyData;

  form: FormGroup;

  inputs = companyInfoInputs;

  private unsubscribe$ = new Subject<void>();

  ngOnInit() {
    this.form = this.fb.group({
      companyName: [''],
      street: ['', Validators.required],
      houseNumber: ['', Validators.required],
      zip: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      dunsNumber: [''],
      vatNumber: [''],
      emailForInvoice: ['', Validators.required],
    });

    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.data.isValid = this.form.valid;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
