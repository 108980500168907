import { CompactType, DisplayGrid, GridsterConfig, GridType } from 'angular-gridster2';

export function getDefaultGridsterConfig(): GridsterConfig {
  return {
    // allowMultiLayer: false,
    allowMultiLayer: true,
    defaultLayerIndex: 0,
    baseLayerIndex: 0,
    maxLayerIndex: 0,
    swapWhileDragging: false,
    swap: false,
    compactType: CompactType.None,
    displayGrid: DisplayGrid.OnDragAndResize,
    gridType: GridType.VerticalFixed,
    fixedRowHeight: 25,
    draggable: {
      enabled: false,
      dragHandleClass: 'drag-handler',
      ignoreContentClass: 'gridster-item-settings',
      delayStart: 200,
    },
    resizable: {
      enabled: false,
    },
    mobileBreakpoint: 600,
    keepFixedHeightInMobile: true,

    // pushItems: true,
    minCols: 50,
    maxCols: 50,
    minRows: 10,
    maxRows: 400,
    margin: 5,
    scrollSensitivity: 10,
    scrollSpeed: 20,
    outerMargin: true,
    minItemArea: 2,
    disableAutoPositionOnConflict: false,
    scrollToNewItems: true,
    addEmptyRowsCount: 7,
  };
}
