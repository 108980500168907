import { Component, OnDestroy, ViewChild } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { DashboardItem } from '../../models/DashboardItem';
import { debounceTime, filter, fromEvent, merge, Subject, takeUntil, tap } from 'rxjs';
import { DashboardItemType } from '../../models/DashboardItemType.model';
import { positionDashboardItemSettings } from '../../utils/positionDashboardItemSettings';
import { isItemBlocked } from '../../utils/isItemBlocked';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardLayersComponent } from './dashboard-layers/dashboard-layers.component';
import { DashboardToolbarComponent } from './dashboard-toolbar/dashboard-toolbar.component';
import { DashboardItemSettingsPerTypeComponent } from '../../widgets-settings/dashboard-item-settings-per-type.component';
import { ItemBaseSettingsComponent } from './item-base-settings/item-base-settings.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { ItemLayerButtonsComponent } from './item-layer-buttons/item-layer-buttons.component';

@Component({
  selector: 'app-item-settings',
  templateUrl: './item-settings.component.html',
  styleUrls: ['./item-settings.component.scss'],
  standalone: true,
  imports: [
    CdkDrag,
    NgIf,
    CdkDragHandle,
    MatIconModule,
    MatTooltipModule,
    ItemBaseSettingsComponent,
    DashboardItemSettingsPerTypeComponent,
    DashboardToolbarComponent,
    DashboardLayersComponent,
    TranslateModule,
    ItemLayerButtonsComponent,
  ],
})
export class ItemSettingsComponent implements OnDestroy {
  @ViewChild('settingsContainer') settingsContainer;

  @ViewChild(CdkDrag) cdkDrag: CdkDrag;

  private hidden = false;

  // private hiddenItemSettings = false;

  unsubscribe = new Subject<void>();

  DashboardItemType = DashboardItemType;

  dragPosition = { x: 200, y: 200 };

  readonly boundingContainer = '.dashboard-container';

  constructor(private dashboardService: DashboardService) {
    this.observeSettingsVisibility();
    this.observeSettingsPosition();
  }

  private observeSettingsVisibility() {
    merge(
      this.dashboardService.triggerSettings$,
      this.dashboardService.editMode$.pipe(filter((o) => o == true)),
    )
      .pipe(
        tap((showSettings) => {
          if (showSettings) {
            this.hidden = false;
            // this.hiddenItemSettings = false;
          }
        }),
      )
      .pipe(debounceTime(100))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.positionSettings();
      });

    // this.dashboardService.selectedItem$
    //   .pipe(
    //     filter((o) => !!o),
    //     takeUntil(this.unsubscribe),
    //   )
    //   .subscribe(() => (this.hiddenItemSettings = false));

    fromEvent(window, 'keydown')
      .pipe(filter((event: KeyboardEvent) => event.key === 'Escape'))
      .pipe(filter(() => this.display))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.hide());
  }

  private observeSettingsPosition() {
    this.dashboardService.selectedItem$
      .pipe(debounceTime(100))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.positionSettings());
  }

  private positionSettings() {
    if (!this.display) return;
    const pos = positionDashboardItemSettings(this.boundingContainer, this.settingsContainer);
    if (pos !== null) this.dragPosition = pos;
  }

  get selectedItem(): DashboardItem {
    return this.dashboardService.selectedItem$.value;
  }

  get display() {
    return (
      this.dashboardService.editMode$.value &&
      // this.selectedItem.type != DashboardItemType.EMPTY &&
      // !this.isItemBlocked() &&
      !this.hidden
    );
  }

  get displayItemSettings() {
    return this.display && this.selectedItem;
  }

  hide() {
    this.hidden = true;
  }

  unselectItem() {
    // this.hiddenItemSettings = true;
    this.dashboardService.selectedItem$.next(null);
  }

  private isItemBlocked() {
    return isItemBlocked(this.selectedItem);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
