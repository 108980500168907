/**
 * EMU Invoice API
 * EMU Invoice API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PriceItemDto } from './priceItemDto';

export interface PriceListDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * id
   */
  readonly id: number;
  /**
   * item name
   */
  readonly name: string;
  /**
   * organization id
   */
  organization_id: number;
  /**
   * currency
   */
  readonly currency: string;
  /**
   * price list items
   */
  items: Array<PriceItemDto>;
}
