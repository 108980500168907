<div class="modal-container" cdkTrapFocus>
  <header>
    <h1>{{ title | translate }}</h1>
    <!--    <button id="close-button" mat-mini-fab class="accent-fab" color="primary" mat-dialog-close>-->
    <!--      <mat-icon  mat-dialog-close>close</mat-icon>-->
    <!--    </button>-->
    <!--    <mat-icon *ngIf="!showLanguageButton" id="close-button" mat-dialog-close>close</mat-icon>-->
    <app-language-button *ngIf="showLanguageButton"></app-language-button>
  </header>

  <!--<mat-divider></mat-divider>-->
  <div class="modal-body">
    <app-skeleton *ngIf="!isDataLoaded"></app-skeleton>
    <ng-container *ngIf="isDataLoaded">
      <!-- <ng-content cdkFocusInitial  select="[body]" ></ng-content> -->
      <ng-content select="[body]"></ng-content>
    </ng-container>
  </div>
  <!--<mat-divider></mat-divider>-->

  <footer *ngIf="isDataLoaded">
    <!--  <app-timestamp-info *ngIf="timestamp"-->
    <!--    [timestamp]="timestamp"-->
    <!--  ></app-timestamp-info>-->

    <ng-content select="[footer]"></ng-content>

    <mat-dialog-actions>
      <button
        class="button-secondary"
        *ngIf="cancelButtonExists && !_customCancelBehavior"
        mat-dialog-close
        mat-button
        data-cy="cancel-button"
      >
        {{ cancelButtonName | translate }}
      </button>

      <button
        class="button-secondary"
        *ngIf="cancelButtonExists && _customCancelBehavior"
        mat-button
        (click)="onCancel()"
        data-cy="cancel-button"
      >
        {{ cancelButtonName | translate }}
      </button>

      <button
        class="button-primary"
        *ngIf="saveButtonExists"
        mat-flat-button
        (click)="onSave()"
        [disabled]="shouldSaveBeDisabled"
        data-cy="save-button"
      >
        {{ saveButtonName | translate }}
      </button>
    </mat-dialog-actions>
  </footer>
</div>
