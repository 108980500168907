/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.4
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OrganizationMemberDto {
  /**
   * user id
   */
  id: number;
  /**
   * organization_id
   */
  organization_id: number;
  /**
   * \"user_id\"
   */
  user_id?: string;
  /**
   * role
   */
  role: OrganizationMemberDto.RoleEnum;
  /**
   * invitation_email
   */
  invitation_email?: string;
  /**
   * invitation_code
   */
  invitation_code?: string;
  /**
   * accepted_at
   */
  accepted_at?: string;
  /**
   * invited_at
   */
  invited_at?: string;
  /**
   * invited_by
   */
  invited_by?: string;
}
export namespace OrganizationMemberDto {
  export type RoleEnum = 'ADMIN' | 'TECH' | 'READONLY';
  export const RoleEnum = {
    ADMIN: 'ADMIN' as RoleEnum,
    TECH: 'TECH' as RoleEnum,
    READONLY: 'READONLY' as RoleEnum,
  };
}
