import { uplotGetSize } from '../../graph/config/uplotDrawConfig';
import { uplotGetSeries } from '../../graph/config/uplotSeriesConfiguration';
import { uplotPluginResize } from '../../graph/plugins/uplotPluginResize';
import { uplotUtilPlugin } from '../../graph/plugins/uplotUtilPlugin';
import { uplotCreateLoadingCircle } from '../../graph/utils/uplotLoadingCircle';
import { uplotTooltipPlugin } from '../../graph/plugins/uplotPluginTooltip';
import { UplotMakeOpts } from '../hourly/uplotMakeHeatmapHourly';
import { heatmapDailyCursor } from './heatmapDailyCursor';
import { heatmapDailyScales } from './heatmapDailyScales';
import { heatmapDailyAxes } from './heatmapDailyAxes';
import { heatmapDailyDraw } from './heatmapDailyDraw';
import { heatmapDailyPluginMouse } from './heatmapDailyPluginMouse';

export function uplotMakeHeatmapDaily(param: UplotMakeOpts, palette: string[]) {
  const opts = {
    ...uplotGetSize(param.container),
    title: param.title,
    cursor: heatmapDailyCursor(),
    scales: heatmapDailyScales(),
    axes: heatmapDailyAxes(param),
    series: uplotGetSeries(param.seriesConfig, true),
    plugins: [
      heatmapDailyPluginMouse(),
      uplotPluginResize(param.container),
      uplotUtilPlugin(),
      // touchZoomPlugin(),
      uplotTooltipPlugin({
        // formatFunction: param.valueFormatFunction,
        showTotal: true,
        formatFunction: param.valueFormatFunction,
        localeService: param.localeService,
        translateService: param.translateService,
      }),
      // ...(param.isMobile ? [] : [uplotTooltipPlugin()]),
      heatmapDailyDraw(),
      ...param.plugins,
    ],
  };
  const u = new uPlot(opts, param.data, param.container);
  u.heatmap = {
    palette: palette,
  };
  u.disableDefaultSeriesDraw = true;
  u.loadingElement = uplotCreateLoadingCircle(u.root, u);
  return u;
}
