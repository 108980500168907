import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgFor } from '@angular/common';
import { ModalComponent } from '../dialogs/modal/modal.component';
import { EmuInputComponent } from '../inputs/emu-input/emu-input.component';

@Component({
  selector: 'app-single-input-modal',
  templateUrl: './single-input-modal.component.html',
  styleUrls: ['./single-input-modal.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    NgIf,
    NgFor,
    MatTooltipModule,
    MatIconModule,
    TranslateModule,
    EmuInputComponent,
    ReactiveFormsModule,
  ],
})
export class SingleInputModalComponent {
  form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<SingleInputModalComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.form = this.fb.group({
      value: [data.value || '', Validators.required],
    });
  }

  get label(): string {
    return this.data.label ?? '';
  }

  onCancel() {
    this.dialogRef.close();
  }

  add() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value.value);
    }
  }

  getTitle() {
    return this.data.title ?? '';
  }

  getSaveButtonName() {
    return this.data.saveButton ?? 'BUTTON.ADD';
  }

  getCancelButtonName() {
    return 'DIALOG.BUTTONS.CANCEL';
  }
}
