<mat-form-field>
  <!--  <mat-label>Enter a date range</mat-label>-->
  <mat-date-range-input [formGroup]="rangeForm" [rangePicker]="picker" [disabled]="disabled" >
    <input
      matStartDate
      formControlName="start"
      placeholder="{{ 'GROUP_POLICY.TABLE_DT_FROM' | translate }}"
      (focus)="formFocused = true"
      (blur)="formFocused = false"
    />
    <input
      matEndDate
      formControlName="end"
      placeholder="{{ 'GROUP_POLICY.TABLE_DT_TO' | translate }}"
      (focus)="formFocused = true"
      (blur)="formFocused = false"
    />

  </mat-date-range-input>
  <!--  <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>-->
  <mat-datepicker-toggle [disabled]="disabled" matIconSuffix [for]="picker"></mat-datepicker-toggle>

  <mat-date-range-picker #picker></mat-date-range-picker>

  <mat-error *ngIf="rangeForm.controls.start.hasError('matStartDateInvalid')"
    >Invalid start date</mat-error
  >
  <mat-error *ngIf="rangeForm.controls.end.hasError('matEndDateInvalid')"
    >Invalid end date</mat-error
  >
</mat-form-field>
<!--<p>Selected range: {{rangeForm.value | json}}</p>-->
<!--<span>{{ getRangeString(currentRange) }}</span>-->
