<div class="select-container">
  <app-emu-list-input
    [loading$]="loading$"
    [(value)]="selectedDevice"
    [name]="'LOGGER.DEVICES'"
    [autocomplete]="false"
    [disabled]="devices.length === 0 || !data.loggerId"
    [clearButtonHidden]="true"
    [filteredOptions$]="of(devices)"
    [className]="'dashboard-settings'"
  >
    <ng-template let-item>
      <p class="device-name">{{ item.name }}</p>
    </ng-template>
  </app-emu-list-input>
</div>
