import { DownloadableDocuments } from '../models/DownloadableDocuments';

export const legalNotesDocuments: DownloadableDocuments[] = [
  {
    name: 'Terms and Conditions',
    url: '',
  },
  {
    name: 'GDPR',
    url: '',
  },
];
