<ng-container *ngIf="node && treeNodeService">
  <div
    *ngIf="showActionButtons() === true"
    class="action-buttons"
    [class.block-buttons]="isMoveActive() && !isThisNodeMoved()"
    [class.block-buttons-move]="isMoveActive()"
  >
    <!--    <button *ngIf="!isEndType(node.item_type_id)"-->
    <button *ngIf="showAddButton()" class="tree-action-button" (click)="addButtonClicked()">
      <mat-icon svgIcon="add"></mat-icon>
    </button>

    <button
      *ngIf="showEditButton()"
      class="tree-action-button editTreeItem"
      (click)="editButtonClicked()"
    >
      <mat-icon svgIcon="edit" color="primary"></mat-icon>
    </button>

    <button
      *ngIf="showMoveButton()"
      class="tree-action-button moveTreeItem"
      (click)="moveButtonClicked()"
    >
      <mat-icon>{{ !isMoveActive() ? 'drag_handle' : 'close' }}</mat-icon>
    </button>

    <button
      *ngIf="showDeleteButton()"
      class="tree-action-button deleteTreeItem"
      [disabled]="blockDeleteButton()"
      (click)="deleteButtonClicked()"
      [attr.data-cy]="node.item_name + '-delete'"
    >
      <mat-icon svgIcon="delete"></mat-icon>
    </button>
  </div>
</ng-container>
