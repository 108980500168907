import { TranslateService } from '@ngx-translate/core';

let lastTimeMessageShown = null;

let translate: TranslateService;

export function setTranslationServiceForScrollMessage(translateService: TranslateService) {
  translate = translateService;
}

export function uplotShowMessage(over) {
  const message = translate.instant('GRAPH.SCROLL_MESSAGE');
  const messageTimeout = 5 * 1e3;

  if (lastTimeMessageShown && lastTimeMessageShown + messageTimeout > Date.now()) return;

  lastTimeMessageShown = Date.now();

  const className = 'u-wheel-message';
  if (over.querySelector(`.${className}`) != null) return;
  const wheelMessage = document.createElement('div');
  wheelMessage.className = className;
  over.appendChild(wheelMessage);

  const text = document.createElement('p');
  text.textContent = message; //'Use scroll and left mouse button with Ctrl';
  wheelMessage.appendChild(text);

  wheelMessage.addEventListener('mousedown', destroyElement);
  wheelMessage.addEventListener('wheel', destroyElementByWheel);

  const timeout = setTimeout(() => {
    destroyElement();
  }, 3e3);

  function destroyElementByWheel(e) {
    if (e.ctrlKey) {
      destroyElement();
    }
  }

  function destroyElement() {
    clearTimeout(timeout);
    wheelMessage.removeEventListener('mousedown', destroyElement);
    wheelMessage.removeEventListener('wheel', destroyElementByWheel);
    wheelMessage.parentElement.removeChild(wheelMessage);
  }
}
