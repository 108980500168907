import { Injectable, isDevMode, OnDestroy } from '@angular/core';
import {
  SingleDataRequestDto,
  SingleDataResponseDto,
  SingleDataItemDto,
} from '../../../../api-main';
import {
  bufferTime,
  filter,
  interval,
  map,
  switchMap,
  takeUntil,
  Observable,
  Subject,
  take,
  catchError,
  of,
} from 'rxjs';
import { DataServiceProvider } from '../../data/api-data.service';
import { DashboardService } from './dashboard.service';

const INTERVAL_IN_SEC = 120;
@Injectable({
  providedIn: 'root',
})
export class SingleValueRequestService implements OnDestroy {
  refresh$ = new Subject<void>();

  private request$ = new Subject<SingleValueRequest>();

  private response$ = new Subject<SingleValueResponse>();

  private unsubscribe$ = new Subject<void>();

  constructor(
    private dataService: DataServiceProvider,
    private dashboardService: DashboardService,
  ) {
    this.collectRequests();
    this.setupRefreshInterval();
  }

  sendRequest(request: SingleDataItemDto, id: string) {
    this.request$.next({
      id,
      ...request,
    });
  }

  getResponse$(id: string): Observable<SingleDataResponseDto> {
    return this.response$.asObservable().pipe(
      filter((o: SingleValueResponse) => o.id === id),
      map((o: any) => {
        //SingleValueResponse
        delete o.id;
        return <SingleDataResponseDto>o;
      }),
    );
  }

  private collectRequests() {
    const sub = this.request$
      .pipe(
        bufferTime(500),
        filter((o) => o.length > 0),
        switchMap((requests: SingleValueRequest[]) => {
          return this.getSingleData({
            requests: requests,
            dashboard_id: this.dashboardService.dashboard?.id,
          }).pipe(
            catchError((err) => {
              if (isDevMode()) console.warn('single data request error: ', err);
              return of(requests.map((o) => ({ id: o.id })));
            }),
          );
        }),

        takeUntil(this.unsubscribe$),
      )
      .subscribe((responses: SingleValueResponse[]) => {
        // console.log('-> response', response);
        responses.forEach((response) => this.response$.next(response));
      });

    //cancel requests on dashboard change
    this.dashboardService.leavingCurrentDashboard$
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        sub.unsubscribe();
        this.collectRequests();
      });
  }

  private setupRefreshInterval() {
    interval(INTERVAL_IN_SEC * 1e3)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.refresh$.next());
  }

  private getSingleData(request: SingleDataRequestDto): Observable<SingleDataResponseDto[]> {
    return this.dataService.getSingleData(request);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

interface SingleValueRequest extends SingleDataItemDto {
  id: string;
}
interface SingleValueResponse extends SingleDataItemDto {
  id: string;
}
