import { ChartConfiguration } from 'chart.js';
import { Flow, SankeyController } from 'chartjs-chart-sankey';
// eslint-disable-next-line import/no-named-as-default
import Chart from 'chart.js/auto';

Chart.register(SankeyController, Flow);

export const defaultSankeyChartConfig: () => ChartConfiguration = () => {
  return <any>{
    type: 'sankey',
    data: {
      datasets: [
        {
          label: 'My sankey',
          data: [],
          //it is actually used
          colorFrom: (c) => c.dataset?.colors[c.dataset.data[c.dataIndex]?.from] ?? 'grey',
          colorTo: (c) => c.dataset?.colors[c.dataset.data[c.dataIndex]?.to] ?? 'grey',
          colorMode: 'gradient',
          borderWidth: 0,
          labels: {},
          _labels: {},
          colors: {},
          size: 'max',
        },
      ],
    },
    options: {
      // responsive: false, //this on true breaks the chart
      responsive: true,
      maintainAspectRatio: false,
      resizeDelay: 10,
      plugins: {
        tooltip: {
          usePointStyle: true,
          borderWidth: 0,
          boxPadding: 5,
          padding: 5,
          callbacks: {
            title() {
              return '';
            },
            label(context: any) {
              let item = context.dataset?.data[context.dataIndex];
              // console.log('=>(defaultSankeyChartConfig.ts:93) item', item);
              if (!item) return null;

              const flow = Number(item.flow).toFixed(2);

              const from = context.dataset.labels[item.from].split('\n')[0];
              const to = context.dataset.labels[item.to].split('\n')[0];

              const unit = item.unit ? `[${item.unit}]` : '';
              return `${from} → ${to} | ${flow} ${unit}`;
            },
          },
        },
      },
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 20,
          bottom: 20,
        },
      },
    },
  };
};
