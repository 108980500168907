import { UserTypes } from '../models/UserTypes.enum';
import { AccountConfiguration } from '../models/AccountConfiguration.model';

export const accountConfigurations: AccountConfiguration[] = [
  {
    name: 'retentionPeriod',
    displayName: 'SIGNUP.RETENTION_PERIOD',
    data: [
      {
        userType: [UserTypes.STANDARD],
        value: 'STANDARD',
        cost: 10,
      },
      {
        userType: [UserTypes.EXTENDED],
        value: 'LONG',
        cost: 20,
      },
      {
        userType: [UserTypes.EXTENDED],
        value: 'LONGEST',
        cost: 30,
      },
    ],
  },
  {
    name: 'dataStorageLocation',
    displayName: 'SIGNUP.DATA_STORAGE_LOCATION',
    data: [
      {
        userType: [UserTypes.STANDARD],
        value: 'STANDARD',
        cost: 5,
      },
      {
        userType: [UserTypes.EXTENDED],
        value: 'LOC_US',
        cost: 10,
      },
      {
        userType: [UserTypes.EXTENDED],
        value: 'LOC_EUROPE',
        cost: 5,
      },
      {
        userType: [UserTypes.EXTENDED],
        value: 'LOC_SWITZERLAND',
        cost: 10,
      },
    ],
  },
  {
    name: 'databasePerformance',
    displayName: 'SIGNUP.DATABASE_PERFORMANCE',
    data: [
      {
        userType: [UserTypes.STANDARD],
        value: 'STANDARD',
        cost: 0,
      },
      {
        userType: [UserTypes.EXTENDED],
        value: 'FAST',
        cost: 10,
      },
      {
        userType: [UserTypes.EXTENDED],
        value: 'FASTEST',
        cost: 20,
      },
    ],
  },
  {
    name: 'calculationPerformance',
    displayName: 'SIGNUP.CALCULATION_PERFORMANCE',
    data: [
      {
        userType: [UserTypes.STANDARD],
        value: 'STANDARD',
        cost: 0,
      },
      {
        userType: [UserTypes.EXTENDED],
        value: 'FAST',
        cost: 10,
      },
      {
        userType: [UserTypes.EXTENDED],
        value: 'FASTEST',
        cost: 20,
      },
    ],
  },
];
