<div
  class="input-container"
  [class.invalid]="!isValid && isTouched()"
  [class.text-area]="textArea"
  [ngClass]="className"
  [class.disabled]="disabled"
  [class.readonly]="readonly"
>
  <span *ngIf="name" class="input-name">{{ name | translate }}</span>

  <div class="input-icon">
    <ng-content select="[prefix]" />
  </div>

  @if (textArea) {
    <textarea
      [maxLength]="maxLength"
      [(ngModel)]="value"
      (ngModelChange)="updateValue($event)"
      placeholder="{{ placeholder | translate }}"
      [disabled]="disabled"
      (paste)="onPaste($event)"
      (blur)="onBlur()"
      [attr.inert]="readonly?true:null"
      (focusout)="focusOut()"
    ></textarea>
  } @else {
    <input
      [maxLength]="maxLength"
      [numericOnly]="numeric || integer"
      [integer]="integer"
      [(ngModel)]="value"
      (ngModelChange)="updateValue($event)"
      placeholder="{{ placeholder | translate }}"
      [disabled]="disabled"
      (blur)="onBlur()"
      autocomplete="{{autocompleteTag}}"
      [attr.inert]="readonly?true:null"
      (focusout)="focusOut()"
    />
  }

  <div class="input-icon">
    <ng-content select="[suffix]" />
  </div>

  <button
    *ngIf="!disabled && !readonly || forceClearButton"
    tabindex="-1"
    appPreventClickPropagation
    class="warn clear-button"
    onclick="this.blur()"
    (click)="clear()"
    [class.disabled]="(!showClearButton || disabled) && !forceClearButton"
    [class.force]="forceClearButton"
    (blur)="onBlur()"
    type="button"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="error-messages">
  <ng-content select="[errorMessage]" />
</div>
