import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TreeFormData } from '../TreeFormData';
import { EmuInputComponent } from '../../../../../shared/components/inputs/emu-input/emu-input.component';

@Component({
  selector: 'app-type-metadata',
  standalone: true,
  imports: [EmuInputComponent],
  templateUrl: './type-metadata.component.html',
  styleUrl: './type-metadata.component.scss',
})
export class TypeMetadataComponent implements AfterViewInit {
  @Input({ required: true }) data: TreeFormData;

  @Output() changes = new EventEmitter<void>();

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.data.metadata = this.data.typeSelected$.value.type_metadata.map((o) => ({
      id: o.id,
      key: o.key,
      value: null,
    }));
    this.cd.detectChanges();
  }
}
