import { Component } from '@angular/core';
import { EmuListInputComponent } from '../../../components/inputs/emu-list-input/emu-list-input.component';
import { TranslateService } from '@ngx-translate/core';
import { LocaleSessionService } from '../../../services/localeSession.service';
import { distinctUntilChanged, map, Observable, startWith, Subject } from 'rxjs';
import { timezoneList } from '../../../../pages/profile/profile/timezoneList';

@Component({
  selector: 'app-timezone-change-debug',
  standalone: true,
  imports: [EmuListInputComponent],
  templateUrl: './timezone-change-debug.component.html',
  styleUrl: './timezone-change-debug.component.scss',
})
export class TimezoneChangeDebugComponent {
  constructor(
    public translate: TranslateService,
    public localeSessionsService: LocaleSessionService,
  ) {}

  timezoneInput$ = new Subject<string>();

  timezoneList$: Observable<string[]> = this.timezoneInput$.pipe(
    startWith(''),
    distinctUntilChanged(),
    map((term: string) => {
      const transform = (s: string) => s.toLowerCase().replaceAll(' ', '').replaceAll('/', '');
      return timezoneList.filter((o: string) => transform(o).includes(transform(term)));
    }),
  );
}
