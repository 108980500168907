import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DashboardItemSettingsComponent } from '../dashboard-item-settings.component';
import { DashboardItemTypeValueDisplay } from '../../models/DashboardItemTypeValueDisplay';
import { DataDisplayDefaultRanges } from '../../../../shared/models/DataDisplayDefaultRanges';
import { ValueDisplayMode } from './ValueDisplayMode.model';
import { of } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatSliderModule } from '@angular/material/slider';
import { EmuInputComponent } from '../../../../shared/components/inputs/emu-input/emu-input.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EmuListInputComponent } from '../../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import { SelectingValueDataComponent } from '../shared/selecting-value-data/selecting-value-data.component';
import { NgIf } from '@angular/common';
import { DashboardValueDisplaySettingsInputComponent } from './dashboard-value-display-settings-input/dashboard-value-display-settings-input.component';

@Component({
  selector: 'app-dashboard-value-display-settings',
  templateUrl: './dashboard-value-display-settings.component.html',
  styleUrls: ['./dashboard-value-display-settings.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    SelectingValueDataComponent,
    EmuListInputComponent,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    EmuInputComponent,
    MatSliderModule,
    TranslateModule,
    DashboardValueDisplaySettingsInputComponent,
  ],
})
export class DashboardValueDisplaySettingsComponent
  extends DashboardItemSettingsComponent
  implements OnInit
{
  constructor(protected override cd: ChangeDetectorRef) {
    super(cd);
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  override setDefaultStyling() {
    if (this.item?.styles) this.item.styles.padding = true;
  }

  override get data(): DashboardItemTypeValueDisplay {
    return this.itemData?.valueDisplay;
  }

  protected override setDefault() {
    if (this.data == undefined)
      this.itemData.valueDisplay = {
        loggerId: null,
        deviceId: null,
        valueId: null,
        mode: ValueDisplayMode.LAST,
        rangeMode: DataDisplayDefaultRanges.CURRENT_DAY,
        range: null,
        showUnit: true,
        unitPrefix: '',
        showDate: true,
        showHeader: true,
        decimalPlaces: 2,
        consumption: false,
      };
  }

  protected readonly of = of;
}
