import { Component } from '@angular/core';
import { UserService } from '../../shared/services/user.service';

@Component({
  template: ` <p>Logout in progress...</p> `,
  styles: [],
  standalone: true,
})
export class LogoutComponent {
  constructor(private userService: UserService) {
    this.userService.logoutWithoutRedirect();
  }
}
