<ng-container *ngIf="data">
  <section class="img-url">
    <app-emu-input
      name="{{ 'DASHBOARD.IMAGE_URL' | translate }}"
      [(value)]="data.url"
      [className]="'dashboard-settings'"
      [maxLength]="128"
    ></app-emu-input>
  </section>

  <section class="style-settings">
    <mat-checkbox data-cy="image-background" [(ngModel)]="item.styles.showBackground">
      {{ 'DASHBOARD.SHOW_BACKGROUND' | translate }}
    </mat-checkbox>
  </section>

  <section>
    <mat-checkbox data-cy="image-padding" [(ngModel)]="item.styles.padding">
      {{ 'DASHBOARD.ITEM_PADDING' | translate }}
    </mat-checkbox>
  </section>

  <section class="border-radius">
    <span>{{ 'DASHBOARD.BORDER_RADIUS' | translate }} : {{ data.borderRadius }}px</span>
    <mat-slider
      [min]="0"
      [max]="maxRadius"
      [step]="1"
      [displayWith]="formatLabel"
      discrete
      data-cy="image-slider"
    >
      <input matSliderThumb [(ngModel)]="data.borderRadius" />
    </mat-slider>
  </section>
</ng-container>
