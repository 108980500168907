import { DashboardItemType } from '../models/DashboardItemType.model';

export function getItemTypeIcon(itemType: DashboardItemType) {
  switch (itemType) {
    case DashboardItemType.EMPTY:
      return 'document';
    case DashboardItemType.TEXT:
      return 'text';
    case DashboardItemType.GRAPH:
      return 'chart2';
    case DashboardItemType.IMAGE:
      return 'image';
    case DashboardItemType.VALUE_DISPLAY:
      return 'electric_meter';
    case DashboardItemType.MULTI_VALUE_DISPLAY:
      return 'electric_meter';
  }
}
