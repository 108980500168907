<span class="name" [class.opened]="opened" (click)="opened = !opened">
  {{ 'DASHBOARD.LAYERS' | translate }}
  <mat-icon class="open-icon" svgIcon="go_back"></mat-icon>
</span>

<div class="items" [class.opened]="opened">
  @for (item of sortedItems; track item.id) {
    <div
      class="item"
      (click)="select(item)"
      (mouseenter)="layerSelected(item.id)"
      (mouseleave)="layerUnselected(item.id)"
    >
      <p class="item-name">
        <mat-icon svgIcon="{{ getItemTypeIcon(item.type) }}"></mat-icon>
        <span
          >{{getItemName(item) }}</span
        >
      </p>
    </div>
  }
</div>
<!--matTooltipShowDelay="500" matTooltip="{{ item | json }}"-->
