import { UserTypes } from '../models/UserTypes.enum';

let userTypes = [];
export function getUserTypes() {
  if (userTypes.length === 0) setDefinedUserTypes();

  return userTypes;
}

function setDefinedUserTypes() {
  for (let enumMember in UserTypes) {
    const isValueProperty = Number(enumMember) >= 0;
    if (isValueProperty) {
      userTypes.push(Number(enumMember));
    }
  }
}

export function getTimeValuesString(value: UserTypes) {
  return `SIGNUP.USER_TYPES.${UserTypes[Number(value)].toUpperCase()}`;
}
