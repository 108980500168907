import { LocaleSessionService } from '../../../../services/localeSession.service';
import { TranslateService } from '@ngx-translate/core';
import { uplotGetSize } from '../../graph/config/uplotDrawConfig';
import { uplotGetSeries } from '../../graph/config/uplotSeriesConfiguration';
import { uplotMousePlugin } from '../../graph/plugins/uplotPluginMouse';
import { uplotPluginResize } from '../../graph/plugins/uplotPluginResize';
import { uplotUtilPlugin } from '../../graph/plugins/uplotUtilPlugin';
import { touchZoomPlugin } from '../../graph/plugins/uplotMobileTouch';
import { uplotCreateLoadingCircle } from '../../graph/utils/uplotLoadingCircle';
import { UplotData } from '../../graph/models/UplotData.model';
import { UplotSeriesConfig } from '../../graph/models/UplotSeriesConfig.model';
import { heatmapHourlyDraw } from './heatmapHourlyDraw';
import { uplotTooltipPlugin } from '../../graph/plugins/uplotPluginTooltip';
import { heatmapHourlyScales } from './heatmapHourlyScales';
import { heatmapHourlyCursor } from './heatmapHourlyCursor';
import { heatmapHourlyAxes } from './heatmapHourlyAxes';

export function uplotMakeHeatmapHourly(param: UplotMakeOpts, palette: string[]) {
  const opts = {
    ...uplotGetSize(param.container),
    tzDate: (ts) => uPlot.tzDate(new Date(ts * 1e3), param.localeService.timezone),
    title: param.title,
    cursor: heatmapHourlyCursor(),
    scales: heatmapHourlyScales(),
    axes: heatmapHourlyAxes(param),
    series: uplotGetSeries(param.seriesConfig, true),
    plugins: [
      uplotMousePlugin({}),
      uplotPluginResize(param.container),
      uplotUtilPlugin(),
      touchZoomPlugin(),
      uplotTooltipPlugin({
        // formatFunction: param.valueFormatFunction,
        showTotal: true,
        formatFunction: param.valueFormatFunction,
        localeService: param.localeService,
        translateService: param.translateService,
      }),
      // ...(param.isMobile ? [] : [uplotTooltipPlugin()]),
      heatmapHourlyDraw({ localeService: param.localeService }),
      ...param.plugins,
    ],
  };
  const u = new uPlot(opts, param.data, param.container);
  u.heatmap = {
    palette: palette,
  };
  u.disableDefaultSeriesDraw = true;
  u.loadingElement = uplotCreateLoadingCircle(u.root, u);
  return u;
}

export type UplotMakeOpts = {
  data: UplotData;
  container: HTMLElement;
  title: string;
  seriesConfig: UplotSeriesConfig[];
  plugins: Array<any>;
  isMobile?: boolean;
  valueFormatFunction: (number, any) => string;
  dateFormatFunction: (number, any) => string;
  localeService: LocaleSessionService;
  translateService: TranslateService;
};
