<section class="base-settings" #container>
  <div>
    <mat-icon
      *ngIf="displayInfo"
      matTooltip="{{ selectedItem | json }}"
      matTooltipClass="tooltip-pre"
      >info</mat-icon
    >

    <mat-icon (click)="cloneItem()" matTooltip="{{ 'DASHBOARD.CLONE_ITEM' | translate }}"
      >content_copy</mat-icon
    >

    <mat-icon
      (click)="toggleItemLock()"
      matTooltip="{{ (itemBlocked ? 'DASHBOARD.UNLOCK' : 'DASHBOARD.LOCK') | translate }}"
      class="lock-icon"
      >{{ itemBlocked ? 'lock' : 'lock_open' }}</mat-icon
    >

    <mat-icon
      (click)="removeItem()"
      [disabled]="itemBlocked"
      matTooltip="{{ 'BUTTON_DELETE' | translate }}"
      color="{{ itemBlocked ? 'grey' : 'warn' }}"
      >delete</mat-icon
    >

    <ng-content></ng-content>
  </div>
</section>
