import { AfterViewInit, Component } from '@angular/core';
import { delayWhen, filter, fromEvent } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { ErrorDialogComponent } from '../../components/dialogs/error-dialog/error-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-check-cookies-support',
  template: ``,
  standalone: true,
})
export class CheckCookiesSupportComponent implements AfterViewInit {
  dialogVisible: boolean = false;

  readonly AUTH0_ERROR: string = 'UNVERIFIED_EMAIL';

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
  ) {}

  ngAfterViewInit() {
    fromEvent(window, 'message')
      .pipe(
        delayWhen(() =>
          this.authService.error$.pipe(filter((error) => error.message.includes(this.AUTH0_ERROR))),
        ),
      )
      .subscribe((evt: any) => {
        const { type, response } = evt?.data;
        // access_denied error was occurs when user has no verified email in Auth0
        if (response && response.error === 'access_denied') {
          if (type === 'authorization_response') this.showCookiesError();
        }
        // console.log('-> evt', evt);
      });
  }

  showCookiesError() {
    if (this.dialogVisible) return;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true; // Prevent from closing modal when clicking outside
    dialogConfig.data = {
      text: 'DIALOG.ERROR_3RD_PARTY_COOKIES',
    };
    const dialogRef = this.dialog.open(ErrorDialogComponent, dialogConfig);
    this.dialogVisible = true;
    dialogRef.afterClosed().subscribe(() => (this.dialogVisible = false));
  }
}
