<ng-container *ngIf="data">
  <section class="text-value">
    <app-emu-input
      name="{{ 'DASHBOARD.TEXT_VALUE' | translate }}"
      [className]="'dashboard-settings'"
      [(value)]="data.text"
      [textArea]="true"
    ></app-emu-input>
  </section>

  <section class="text-value">
    <app-emu-input
      name="{{ 'DASHBOARD.URL_LINK' | translate }}"
      [className]="'dashboard-settings'"
      [(value)]="data.url"
      [placeholder]="'FIELD.OPTIONAL'"
      [maxLength]="128"
    ></app-emu-input>
  </section>

  <section class="flex-in-row">
    <mat-checkbox [(ngModel)]="data.autoSize" data-cy="auto-size">
      {{ 'DASHBOARD.AUTO_SIZE' | translate }}
    </mat-checkbox>

    <!--      [class.hide]="data.autoSize"-->
    <app-emu-list-input
      [disabled]="data.autoSize"
      [(value)]="data.size"
      [name]="'DASHBOARD.TEXT_SIZE'"
      [autocomplete]="false"
      [className]="'dashboard-settings'"
      [filteredOptions$]="of(fontSizeList)"
      [clearButtonHidden]="true"
    >
      <ng-template let-item
        ><p>{{ item }} px</p></ng-template
      >
    </app-emu-list-input>
  </section>

  <section>
    <app-emu-color-input [(color)]="data.color"></app-emu-color-input>
  </section>

  <section class="text-align">
    <button
      class="dashboard-button"
      [class.selected]="data.align === 'left'"
      (click)="setAlignment('left')"
    >
      <mat-icon svgIcon="align-left"></mat-icon>
    </button>

    <button
      class="dashboard-button"
      [class.selected]="data.align === 'center'"
      (click)="setAlignment('center')"
    >
      <mat-icon svgIcon="align-center"></mat-icon>
    </button>

    <button
      class="dashboard-button"
      [class.selected]="data.align === 'right'"
      (click)="setAlignment('right')"
    >
      <mat-icon svgIcon="align-right"></mat-icon>
    </button>
  </section>

  <section class="flex-in-row">
    <mat-checkbox
      [(ngModel)]="item.styles.showBackground"
      data-cy="show-background"
      class="no-wrap"
    >
      {{ 'DASHBOARD.SHOW_BACKGROUND' | translate }}
    </mat-checkbox>
    <app-emu-color-input
      [disabled]="!item.styles.showBackground"
      [(color)]="item.styles.background"
    ></app-emu-color-input>
  </section>
</ng-container>
