<ng-container *ngIf="data?.ready">
  @switch (data.type) {
    @case (DashboardItemDataGraphTypesModel.PIE) {
      <app-dashboard-pie-chart [item]="item" [refresh$]="refresh$" [data]="data"></app-dashboard-pie-chart>
    }
    @case (DashboardItemDataGraphTypesModel.PIE_MONTHLY_AGG) {
      <app-dashboard-pie-chart-monthly-agg
        [item]="item"
        [refresh$]="refresh$"
        [data]="data"
      ></app-dashboard-pie-chart-monthly-agg>
    }
    @case (DashboardItemDataGraphTypesModel.PIE_WEEKLY_AGG) {
      <app-dashboard-pie-chart-weekly-agg
        [item]="item"
        [refresh$]="refresh$"
        [data]="data"
      ></app-dashboard-pie-chart-weekly-agg>
    }
    @case (DashboardItemDataGraphTypesModel.HEATMAP) {
      <app-dashboard-heatmap [refresh$]="refresh$" [item]="item"></app-dashboard-heatmap>
    }
    @case (DashboardItemDataGraphTypesModel.SANKEY) {
      <app-dashboard-sankey [refresh$]="refresh$" [item]="item" [data]="data"></app-dashboard-sankey>
    }
    @case (DashboardItemDataGraphTypesModel.HEATMAP_DAILY) {
      <app-dashboard-heatmap-daily [refresh$]="refresh$" [item]="item"></app-dashboard-heatmap-daily>
    }
    @default {
      <app-dashboard-uplot-graph [refresh$]="refresh$" [item]="item"> </app-dashboard-uplot-graph>
    }
  }
</ng-container>
