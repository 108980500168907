/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OrganizationDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * id
   */
  id: number;
  /**
   * \"company_name\"
   */
  company_name: string;
  /**
   * street
   */
  street: string;
  /**
   * house_number
   */
  house_number: string;
  /**
   * postal_code
   */
  postal_code: string;
  /**
   * city
   */
  city: string;
  /**
   * country
   */
  country: string;
  /**
   * vat_number
   */
  vat_number: string;
  /**
   * invoice_email
   */
  invoice_email: string;
  /**
   * company_logo
   */
  company_logo: string;
  /**
   * data
   */
  data?: string;
  /**
   * data
   */
  timezone: string;
  /**
   * role
   */
  role?: OrganizationDto.RoleEnum;
}
export namespace OrganizationDto {
  export type RoleEnum = 'ADMIN' | 'TECH' | 'READONLY';
  export const RoleEnum = {
    ADMIN: 'ADMIN' as RoleEnum,
    TECH: 'TECH' as RoleEnum,
    READONLY: 'READONLY' as RoleEnum,
  };
}
