import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from '../../shared/services/config.service';
import { AppState, AuthService } from '@auth0/auth0-angular';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { filter, race, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { UserService } from '../../shared/services/user.service';
import { MatButtonModule } from '@angular/material/button';
import { SkeletonComponent } from '../../shared/components/skeleton/skeleton.component';
import { MatCardModule } from '@angular/material/card';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [NgIf, MatCardModule, SkeletonComponent, MatButtonModule, AsyncPipe, TranslateModule],
})
export class LoginComponent implements OnInit, OnDestroy {
  logoURL: string;

  isLoading = true;

  unsubscribe$: Subject<void> = new Subject<void>();

  readonly verificationError: string = 'UNVERIFIED_EMAIL';

  constructor(
    public auth: AuthService,
    private router: Router,
    public translateService: TranslateService,
    private userService: UserService,
    private route: ActivatedRoute,
  ) {
    this.handlePreviousAppState();
  }

  private handlePreviousAppState() {
    this.auth.isAuthenticated$
      .pipe(
        filter((authCheck) => !!authCheck),
        tap(() => this.userService.login()),
        switchMap(() => race(this.auth.appState$, this.route.queryParams.pipe(take(1)))),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((appState: Params | AppState) => {
        // console.log('-> appState', appState);
        let url: string;
        let params: any = {};

        if (appState.target) {
          url = appState.target.split('?')[0];
          appState.target
            .split('?')
            .slice(1)
            .forEach((param) => {
              const keyValue = param.split('=');
              params[keyValue[0]] = keyValue[1];
            });
        } else {
          url = appState['redirect'] ?? 'profile';
          Object.keys(params)
            .filter((key) => key != 'redirect')
            .forEach((keyValue) => {
              params[keyValue as string] = params[keyValue as string];
            });
        }

        // if (params.redirect) url = params.redirect;
        // console.log('-> url', url);
        // console.log('-> params', params);
        this.router.navigate([url], { queryParams: params }).catch(console.error);
      });
  }

  ngOnInit() {
    let oemId = ConfigService.getIdConfig();
    this.logoURL = `../../../assets-common/${oemId}/logo.svg`;
    this.translateService.onDefaultLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (value) => {
        if (value) {
          this.isLoading = false;
        }
      },
    });
    if (this.translateService.defaultLang) {
      this.isLoading = false;
    }

    this.handleAuth0Error();
  }

  handleAuth0Error(): void {
    this.auth.error$.subscribe({
      next: (error) => {
        if (error.message.includes(this.verificationError)) {
          const split: string[] = error.message.split('Email: ');
          const email: string = split[split.length - 1];
          localStorage.setItem('verificationEmail', email);
          this.router.navigateByUrl('/verify-email').catch();
        }
      },
    });
  }

  login(): void {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      let url = params['redirect'] ?? '/profile';

      this.auth.loginWithRedirect({
        appState: { target: url },
        authorizationParams: {
          ui_locales: this.getLng(),
        },
      });
    });
  }

  signup() {
    this.auth.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        ui_locales: this.getLng(),
      },
    });
  }

  public getLng() {
    const lng = this.translateService.currentLang;
    switch (lng) {
      case 'ch':
        return 'de';
      default:
        return lng;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
