/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ItemTreeListRequestDto {
  /**
   * Return element with  id
   */
  id?: string | number;
  /**
   * Return only root elements
   */
  root_only?: boolean;
  /**
   * Return elements with provided parent id
   */
  parent_id?: string | number;
}
