import { Injectable, isDevMode } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { take } from 'rxjs';
import { SignupModalComponent } from './signup-modal/signup-modal.component';
import { SignupData } from './SignupData';
import { UserService } from '../shared/services/user.service';
import { ProfileService } from '../shared/services/profile.service';
import { LocaleSessionService } from '../shared/services/localeSession.service';
import { ProfileDto } from '../../api-auth';

@Injectable({
  providedIn: 'root',
})
export class SignupHandlerService {
  get isUserRegistered(): boolean {
    return this.#isUserRegistered;
  }

  #isUserRegistered = false;

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private profileService: ProfileService,
    private localeService: LocaleSessionService,
  ) {
    this.profileService
      .get()
      .pipe(take(1))
      .subscribe((userData: { data: ProfileDto }) => {
        // console.log('-> userData', userData);
        this.userService.userData.next(userData.data);
        const lng = userData.data.user_metadata?.locale;
        if (lng) this.localeService.changeLanguage(lng);
        this.checkUserStatus();
        // if (!this.isUserRegistered) this.openSignupModal();
      });
  }

  checkUserStatus() {
    if (!localStorage.getItem('force-register-form')) this.#isUserRegistered = true;
    // else console.log('-> this.userService.userData', this.userService.userData.value);
  }

  openSignupModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      email: this.userService.userData.value.email,
    };
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    this.dialog
      .open(SignupModalComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: SignupData) => {
        if (!result) return this.openSignupModal();
        this.registerUser(result);
      });
  }

  registerUser(result: SignupData) {
    if (isDevMode()) console.log('-> result', result);

    // if ok
    this.#isUserRegistered = true;
  }
}
