export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './dashboardcyclicReport.service';
import { DashboardcyclicReportService } from './dashboardcyclicReport.service';
export * from './dashboardreport.service';
import { DashboardreportService } from './dashboardreport.service';
export * from './dashboardtemplate.service';
import { DashboardtemplateService } from './dashboardtemplate.service';
export * from './data.service';
import { DataService } from './data.service';
export * from './deviceValues.service';
import { DeviceValuesService } from './deviceValues.service';
export * from './dictionary.service';
import { DictionaryService } from './dictionary.service';
export * from './general.service';
import { GeneralService } from './general.service';
export * from './logger.service';
import { LoggerService } from './logger.service';
export * from './loggerLora.service';
import { LoggerLoraService } from './loggerLora.service';
export * from './metadata.service';
import { MetadataService } from './metadata.service';
export * from './organization.service';
import { OrganizationService } from './organization.service';
export * from './tree.service';
import { TreeService } from './tree.service';
export const APIS = [
  DashboardService,
  DashboardcyclicReportService,
  DashboardreportService,
  DashboardtemplateService,
  DataService,
  DeviceValuesService,
  DictionaryService,
  GeneralService,
  LoggerService,
  LoggerLoraService,
  MetadataService,
  OrganizationService,
  TreeService,
];
