export function positionDashboardItemBaseSettings(container, mutations = null) {
  if (mutations && mutations.some((o) => o.attributeName == 'style')) return;

  const element = <HTMLElement>container?.nativeElement;
  if (!element) return;
  const itemWidth = getItemElementWidth(element);
  const right = isNaN(parseInt(element.style.right)) ? 0 : parseInt(element.style.right);

  const gridsterContainer = getContainer(element);
  const gridsterX = gridsterContainer.getBoundingClientRect().x;

  const offset = itemWidth - element.offsetWidth;

  const left = element.getBoundingClientRect().left + offset - gridsterX;

  if (left + right < 0) element.style.right = offset + 'px';
  else element.style.right = '2px';

  function getItemElementWidth(el: HTMLElement) {
    let antiInfLoop = 0;
    while (el.tagName != 'GRIDSTER-ITEM' && el.parentElement) {
      el = el.parentElement;
      if (antiInfLoop++ > 100) throw new Error(`can't find widget element!`);
    }
    return el?.offsetWidth;
  }

  function getContainer(el: HTMLElement): HTMLElement {
    let antiInfLoop = 0;
    while (el.tagName != 'GRIDSTER' && el.parentElement) {
      el = el.parentElement;
      if (antiInfLoop++ > 100) throw new Error(`can't find widget element!`);
    }
    return el;
  }
}
