import { SeriesGraphSettingsModel } from '../../../models/SeriesGraphSettings.model';

export function getDefaultSeries(): SeriesGraphSettingsModel {
  return {
    loggerId: null,
    valueId: null,
    deviceId: null,
    unit: null,
    cfg_unit: null,
    pathStyle: 'linear',
    label: null,
    uniqueId: window?.crypto?.randomUUID() ?? Math.random().toString(),
  };
}
