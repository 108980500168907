<ng-container *ngIf="loaded">
  <div *ngIf="src != null" class="movable-container">
    <div class="img-container">
      <img src="{{ src }}" alt="dashboard" />
    </div>
  </div>

  <div *ngIf="src == null" class="dashboard-container">
    <div class="loading-bar" *ngIf="dashboard.loading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <app-dashboard #dashboard [id]="dashboardId" [isInPortal]="true"></app-dashboard>
  </div>
</ng-container>
