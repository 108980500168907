import { uplotGetScales } from '../../graph/config/uplotScales';

export function heatmapHourlyScales() {
  const scales = uplotGetScales();

  //TODO
  const DAY_IN_UPLOT_TIME = 3600 * 24;
  scales.x.minRange = DAY_IN_UPLOT_TIME * 7;
  scales.x.maxRange = (DAY_IN_UPLOT_TIME * 365) / 2;

  scales.y = <any>{
    auto: false,
    range: () => {
      return [-0.5, 23.5];
      // return [0, 23];
    },
  };

  return scales;
}
