import { WaitService } from '../../../shared/services/wait.service';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationService } from '../organization.service';
import { OrganizationDto } from '../../../../api-main';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { Subject, finalize, takeUntil } from 'rxjs';
import { companyInfoInputs } from './CompanyInfoInputs';
import { resizeBase64Img } from './utils';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgFor } from '@angular/common';
import { ModalComponent } from '../../../shared/components/dialogs/modal/modal.component';
import { EmuInputComponent } from '../../../shared/components/inputs/emu-input/emu-input.component';
import { EmuListInputComponent } from '../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import {
  filteredTimezones,
  getTimezoneOffsetInHours,
  timezoneValidator,
} from '../../profile/profile/timezoneList';
import { formValidateEmail } from '../../../shared/components/inputs/emailValidator';

@Component({
  selector: 'app-organization-save-modal',
  templateUrl: './organization-save-modal.component.html',
  styleUrls: ['./organization-save-modal.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    ReactiveFormsModule,
    NgIf,
    MatIconModule,
    NgFor,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
    EmuInputComponent,
    EmuListInputComponent,
  ],
})
export class OrganizationSaveModalComponent implements OnDestroy {
  organization: OrganizationDto;

  protected blockInput = false;

  inputs = companyInfoInputs;

  isValid: boolean;

  private unsubscribe$ = new Subject<void>();

  form: FormGroup;

  timezoneInput$ = new Subject<string>();

  filteredTimezones$ = filteredTimezones(this.timezoneInput$);

  constructor(
    private dialogRef: MatDialogRef<OrganizationSaveModalComponent>,
    private organizationService: OrganizationService,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private waitService: WaitService,
  ) {
    this.organization = this.data;

    this.setForm();
    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.isValid = this.form.valid;
    });
    // this.dashboard = this.isEditForm ? data : { name: data.name ?? '', config: [], id: null };
  }

  setForm() {
    this.form = this.fb.group({
      companyName: [this.organization.company_name, Validators.required],
      street: [this.organization.street, Validators.required],
      houseNumber: [this.organization.house_number, Validators.required],
      zip: [this.organization.postal_code, Validators.required],
      city: [this.organization.city, Validators.required],
      country: [this.organization.country, Validators.required],
      vatNumber: [this.organization.vat_number, Validators.required],
      emailForInvoice: [
        this.organization.invoice_email,
        [Validators.required, formValidateEmail()],
      ],
      logo: [this.organization.company_logo],
      timezone: new FormControl(this.organization.timezone, [
        Validators.required,
        timezoneValidator(),
      ]),
    });
    this.isValid = this.form.valid;
  }

  get isEditForm() {
    return !!this.data?.id;
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    if (this.blockInput || !this.isValid) return;
    this.blockInput = true;

    // this.organization =
    //   {
    //     company_name:this.form.value
    //   }
    this.organization = {
      id: this.data.id ?? 0,
      company_name: this.form.get('companyName').value,
      street: this.form.get('street').value,
      house_number: this.form.get('houseNumber').value,
      postal_code: this.form.get('zip').value,
      city: this.form.get('city').value,
      country: this.form.get('country').value,
      vat_number: this.form.get('vatNumber').value,
      invoice_email: this.form.get('emailForInvoice').value,
      company_logo: this.form.get('logo').value,
      timezone: this.form.get('timezone').value,
    };
    this.waitService.start();
    this.organizationService
      .save(this.organization)
      .pipe(
        finalize(() => {
          this.waitService.stop();
          this.blockInput = false;
        }),
      )
      .subscribe({
        next: () => {
          this.dialogRef.close(this.organization);
        },
      });
  }

  getTitle() {
    return this.isEditForm ? 'ORGANIZATION.EDIT_ORGANIZATION' : 'ORGANIZATION.CREATE_ORGANIZATION';
  }

  getSaveButtonName() {
    return this.isEditForm ? 'BUTTON.SAVE' : 'BUTTON.ADD';
  }

  getCancelButtonName() {
    return 'DIALOG.BUTTONS.CANCEL';
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const src = e.target.result;
        resizeBase64Img(src, 100, 100).subscribe((val) => {
          this.form.get('logo').setValue(val);
        });
      };

      reader.readAsDataURL(inputNode.files[0]);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected readonly getTimezoneOffsetInHours = getTimezoneOffsetInHours;
}
