import { filter, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { UplotData } from '../models/UplotData.model';

export function uplotRescalePlugin(
  onRescaled: UplotOnRescaleData,
  cancelLastSub: Subject<void>,
  unsubscribe: Subject<void>,
) {
  const data$: Subject<Observable<UplotData>> = new Subject();
  // let cursor;
  let showLoadingElement = true;
  let u;

  data$
    .asObservable()
    .pipe(
      filter((d) => !!d),
      takeUntil(unsubscribe),
      // debounceTime(300),
    )
    .pipe(
      tap((data: Observable<UplotData>) => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setUplotScale(data);
      }),
    )
    .subscribe();

  return {
    hooks: {
      setScale: (uplot) => {
        if (!u) u = uplot;
        const scales = u.scales.x;
        const scalesY = u.scales.y;

        onRescaled.next({ data: data$, scalesX: scales, scalesY });
      },
    },
  };

  function setUplotScale(data: Observable<UplotData>) {
    // cursor = JSON.parse(JSON.stringify(u.cursor));
    if (showLoadingElement) u.loadingElement.show();

    // const id = Math.floor(Math.random() * 10000);
    // console.log('data set start', id);
    data
      .pipe(
        take(1),
        // takeUntil(cancelLastSub), //THIS breaks loading for some reason, not needed though because data requests are cancelled individually when new data requested
        takeUntil(unsubscribe),
      )
      .subscribe((uplotData) => {
        // console.log('data set end', id);
        const scales = JSON.parse(JSON.stringify(u.scales.x));
        u.setData(uplotData);

        u.redraw();
        u.setScale('x', {
          min: scales.min,
          max: scales.max,
        });

        u.loadingElement.hide();

        // u.setCursor({ left: cursor.left, top: cursor.top });
      });
  }
}

export type UplotOnRescaleData = Subject<{
  scalesX: any;
  scalesY: any;
  data: Subject<Observable<UplotData>>;
}>;
