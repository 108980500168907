/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LoraTimeDto } from './loraTimeDto';

export interface LoraServerListDto {
  /**
   * id
   */
  id: number;
  /**
   * unix time of creation
   */
  created_at: Array<LoraTimeDto>;
  /**
   * unix time of last update
   */
  updated_at: Array<LoraTimeDto>;
  /**
   * name of the profile
   */
  name: string;
  region: number;
  mac_version: number;
  reg_params_revision: number;
  supports_otaa: boolean;
  supports_class_b: boolean;
  supports_class_c: boolean;
}
