import { Component } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { InvitationsComponent } from '../../../../invitations/invitations.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { ModalComponent } from '../../../../../shared/components/dialogs/modal/modal.component';

@Component({
  selector: 'app-no-organization-selected',
  templateUrl: './no-organization-selected.component.html',
  styleUrls: ['./no-organization-selected.component.scss'],
  standalone: true,
  imports: [ModalComponent, MatDialogContent, MatDividerModule, RouterLink, TranslateModule],
})
export class NoOrganizationSelectedComponent {
  constructor(
    private dialogRef: MatDialogRef<NoOrganizationSelectedComponent>,
    private dialog: MatDialog,
  ) {}

  close() {
    this.dialogRef.close();
  }

  insertInvitationCode() {
    this.dialog.open(InvitationsComponent, { data: {} });
  }
}
