import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getTimeValuesString, getUserTypes } from '../utils/userTypes';
import { SignupData } from '../SignupData';
import { UserTypes } from '../models/UserTypes.enum';
import { TranslateModule } from '@ngx-translate/core';
import { UserConditionsComponent } from '../inner-components/user-conditions/user-conditions.component';
import { PricingComponent } from '../inner-components/pricing/pricing.component';
import { CompanyInfoComponent } from '../inner-components/company-info/company-info.component';
import { SignupConfigurationComponent } from '../inner-components/signup-data/signup-configuration.component';
import { NgFor, NgIf } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ModalComponent } from '../../shared/components/dialogs/modal/modal.component';

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrls: ['./signup-modal.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    MatButtonToggleModule,
    ReactiveFormsModule,
    FormsModule,
    NgFor,
    SignupConfigurationComponent,
    NgIf,
    CompanyInfoComponent,
    PricingComponent,
    UserConditionsComponent,
    TranslateModule,
  ],
})
export class SignupModalComponent {
  readonly userTypes = getUserTypes();

  getTimeValuesString = getTimeValuesString;

  userData = new SignupData();

  constructor(
    private dialogRef: MatDialogRef<SignupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private cd: ChangeDetectorRef,
  ) {
    dialogRef.disableClose = true;
    if (data.email) this.userData.companyData.emailForInvoice = data.email;
  }

  canBeSubmitted(): boolean {
    return (
      this.userData.conditionsAccepted &&
      (!this.isExtendedAccount() || this.userData.companyData.isValid)
    );
  }

  onSave() {
    if (!this.canBeSubmitted()) return;
    this.dialogRef.close(this.userData);
  }

  isExtendedAccount() {
    return this.userData.userType == UserTypes.EXTENDED;
  }

  userTypeChanged() {
    this.cd.detectChanges();
  }
}
