import { DeviceValuesDto } from '../../../../../../../api-main';
import { TranslateService } from '@ngx-translate/core';

// type ParameterName = {
//   string: string;
//   name: string;
//   phase: string;
//   target: string;
// };

export function getDeviceParameterNameSetTranslate(translateService: TranslateService) {
  return getDeviceParameterName.bind(translateService);
}

//This function requires to be binded to componend with injected service translateService:TranslateService
function getDeviceParameterName(parameter: DeviceValuesDto) {
  const translate: TranslateService = this;
  if (!parameter) return '';
  if (!translate)
    throw new Error('This function requires to be binded to componend with translateService');
  // console.log('=>(getDeviceParameterName.ts:4) parameter', parameter);
  // const hasTariffInName = parameter.description_str.toLowerCase().includes('tariff');

  let name = parameter.description_str;

  name = name.replace(/( )?tariff [0-4](,)?/g, '');
  name = name.replace(/( )?L[0-4](,)?/g, '');

  let tariff = '' + parameter.cfg_tariff ?? parameter.tariff;
  let phase: string = '' + parameter.cfg_phase;
  //Should we allow phase 0 ?
  if (phase && !isNaN(Number(phase)) && Number(phase) > 0) {
    phase = `${translate.instant('LOGGER.PHASE')}: ${phase}`;
  } else phase = null;

  if (tariff && !isNaN(Number(tariff)) && Number(tariff) > 0) {
    tariff = `${translate.instant('LOGGER.TARIFF')}: ${tariff}`;
  } else tariff = null;

  // if (hasTariffInName) tariff = '';

  return `${name} ${phase ?? ''}${phase && tariff ? ', ' : ''}${tariff ?? ''}`;
}
