// plugin for fixes etc.
export function uplotUtilPlugin() {
  return {
    hooks: {
      setSeries: (u) => {
        if (u.seriesVisibiltyChanged$) u.seriesVisibiltyChanged$.next();
        u.redraw();
      },
    },
  };
}
