import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { BehaviorSubject, debounceTime, map, merge, Observable, of, tap } from 'rxjs';
import { RefreshValue } from '../dashboard-graph.component';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, ChartConfiguration } from 'chart.js';
import { DataRequestDto } from '../../../../../../api-main';
import { UplotResponseData } from '../../../../../shared/components/graph-uplot/graph/models/UplotResponseData.model';
import { DashboardItemDataGraph } from '../../../models/DashboardItemDataGraph.model';
import { DataServiceProvider } from '../../../../data/api-data.service';
import { DashboardService } from '../../../services/dashboard.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getRangeFromDataDisplayDefaultRanges } from '../../../../../shared/utils/getRangeFromDataDisplayDefaultRanges';
import { getSeriesConfig, getSeriesForRequest } from '../seriesUtils';
import { uplotPalette } from '../../../../../shared/components/graph-uplot/graph/utils/uplotConstants';
import { SeriesGraphSettingsModel } from '../../../models/SeriesGraphSettings.model';
import { UplotSeriesConfig } from '../../../../../shared/components/graph-uplot/graph/models/UplotSeriesConfig.model';
import { formatDataByUnit } from '../../../../data/formatDataByUnit';
import { defaultPieChartConfig } from './defaultPieChartConfig';
import { UplotRangePickerComponent } from '../../../../../shared/components/graph-uplot/uplot-range-picker/uplot-range-picker.component';
import { UplotRange } from '../../../../../shared/components/graph-uplot/graph/models/UplotRange.model';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatProgressBar } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { getPrefix } from '../../../../../shared/components/graph-uplot/graph/config/getProperPrefix';
import { DashboardItem } from '../../../models/DashboardItem';
import { DataDisplayDefaultRanges } from '../../../../../shared/models/DataDisplayDefaultRanges';

@Component({
  selector: 'app-dashboard-pie-chart',
  standalone: true,
  imports: [
    BaseChartDirective,
    UplotRangePickerComponent,
    NgIf,
    AsyncPipe,
    MatProgressBar,
    TranslateModule,
  ],
  templateUrl: './dashboard-pie-chart.component.html',
  styleUrl: './dashboard-pie-chart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardPieChartComponent implements AfterViewInit {
  @Input({ required: true }) data: DashboardItemDataGraph;

  @Input({ required: true }) item: DashboardItem;

  @Input({ required: true }) refresh$: Observable<RefreshValue>;

  private unsubscribe = takeUntilDestroyed();

  private downloadedSeries: Array<UplotSeriesConfig>;

  protected rangePickDisabled: boolean;

  private valueSum: string;

  currentRange: UplotRange;

  loading$ = new BehaviorSubject<boolean>(false);

  config: ChartConfiguration = defaultPieChartConfig();

  noData = true;

  constructor(
    protected dataService: DataServiceProvider,
    protected dashboardService: DashboardService,
    protected cd: ChangeDetectorRef,
  ) {}

  get series(): Array<SeriesGraphSettingsModel> {
    return this.data?.series ?? [];
  }

  ngAfterViewInit() {
    this.currentRange = this.getCurrentRange();
    this.updateType();
    this.cd.detectChanges();
    // if (this.dashboardService.editMode$.value == false) this.getData();
    // this.getData();

    // this.refresh$.pipe(debounceTime(300), this.unsubscribe).subscribe((val: string) => {
    merge(of('graph'), this.refresh$)
      .pipe(debounceTime(500), this.unsubscribe)
      .subscribe((val: string) => {
        // console.log('=>(dashboard-pie-chart.component.ts:79) val', val);
        switch (val) {
          case 'series':
            this.config.options.animation = false;
            this.updateData();
            break;
          case 'graph':
            this.config.options.animation = Chart.defaults.animation;
            this.currentRange = this.getCurrentRange();
            this.getData();
            break;
          default:
        }
        this.cd.detectChanges();
      });
  }

  private getCurrentRange() {
    return this.data.userDefinedRange != DataDisplayDefaultRanges.CUSTOM
      ? getRangeFromDataDisplayDefaultRanges(this.data.userDefinedRange)
      : this.data.range;
  }

  private updateType() {
    this.config.type = !!this.data.doughnut ? 'doughnut' : 'pie';

    this.config.options.elements = !!this.data.doughnut
      ? <any>{
          center: {
            text: this.valueSum ?? '',
            color: '#4f4f4f', // Default is #000000
            minFontSize: 12, // Default is 20 (in px), set to false and text will not wrap.
          },
        }
      : null;
    this.cd.detectChanges();
  }

  private getSumValue(response: UplotResponseData) {
    const sum = response.data
      .slice(1)
      .reduce((seriesSum, seriesData) => seriesSum + seriesData.reduce((a, b) => a + b, 0), 0);
    const unit = this.downloadedSeries[0].unit;
    let { value, prefix } = getPrefix(sum);
    value = +value.toFixed(2);
    this.valueSum = `${value} [${prefix}${unit}]`;
    this.updateType();
  }

  changeRange(range: UplotRange) {
    if (
      range.max < range.min ||
      (this.currentRange.max == range.max && this.currentRange.min == range.min)
    )
      return;
    // console.log('=>(dashboard-pie-chart.component.ts:73) range', range);
    this.currentRange = range;
    this.getData();
  }

  private getData() {
    const series = getSeriesForRequest(this.data.series);
    if (series.length == 0) return;
    this.loading$.next(true);
    // const range = this.currentRange;

    const request: DataRequestDto = {
      min: Math.floor(this.currentRange.min),
      max: Math.ceil(this.currentRange.max),
      scale: DataRequestDto.ScaleEnum.DAILY,
      series: series,
      dashboard_id: this.dashboardService.dashboard?.id,
    };

    this.noData = false;
    this.dataService
      .getData(request)
      .pipe(
        tap((response: any) => (this.downloadedSeries = response.series)),
        tap((response: any) => this.getSumValue(response)),
        map((response: UplotResponseData) =>
          formatDataByUnit({ data: response.data, series: this.series }, response.series),
        ),
        this.unsubscribe,
      )
      .subscribe({
        next: (responseData: UplotResponseData) => {
          this.updateData(this.formatData(responseData));
          this.cd.detectChanges();
          this.loading$.next(false);
        },
        error: (err: any) => {
          console.error('Failed to download pie chart data', err);
          this.loading$.next(false);
        },
      });
  }

  protected formatData(responseData: UplotResponseData): number[] {
    const seriesData = responseData.data.slice(1);
    const summedDataArr = seriesData.map((o) => o.reduce((a, b) => a + b, 0));
    // console.log('=>(dashboard-pie-chart.component.ts:109) summedDataArr', summedDataArr);

    if (summedDataArr.every((o) => o <= 0)) this.noData = true;
    return summedDataArr;
  }

  protected getSeries(): UplotSeriesConfig[] {
    return getSeriesConfig(this.series, this.downloadedSeries);
  }

  private updateData(data: number[] = null) {
    this.updateType();
    const series = this.getSeries();
    const defaultData = this.config.data.datasets[0]?.data ?? [];
    if (data == null) data = <any>defaultData;
    this.config.data = {
      // labels: series.map((o) => `${o.label} [${o.unit}]`),
      labels: series.map((o) => `${o.label}`),
      datasets: [
        {
          // label: 'Value',
          // @ts-ignore
          series: series, // this is not used by chart itself, but referenced in chart config by custom fn (datalabels) and it's required
          data: data,
          backgroundColor: series.map((o, i) => o.stroke ?? uplotPalette[i % uplotPalette.length]),
          hoverOffset: 4,
        },
      ],
    };
    this.cd.detectChanges();
    this.dashboardService.itemLoaded(this.item);
  }
}
