import { uplotDrawStyles, uplotLineInterpolations } from '../utils/uplotConstants';

const lineInterpolations = uplotLineInterpolations;
const drawStyles = uplotDrawStyles;

export const uPlotDrawConfig = [
  {
    title: 'null-path',
    drawStyle: drawStyles.points,
    lineInterpolation: null,
    fill: false,
  },
  {
    title: 'linear',
    drawStyle: drawStyles.line,
    lineInterpolation: lineInterpolations.linear,
    fill: false,
  },
  {
    title: 'spline',
    drawStyle: drawStyles.line,
    lineInterpolation: lineInterpolations.spline,
    fill: false,
  },
  {
    title: 'stepped+1',
    drawStyle: drawStyles.line,
    lineInterpolation: lineInterpolations.stepAfter,
    fill: false,
  },
  {
    title: 'stepped-1',
    drawStyle: drawStyles.line,
    lineInterpolation: lineInterpolations.stepBefore,
    fill: false,
  },
  {
    title: 'bars0',
    drawStyle: drawStyles.bars,
    lineInterpolation: null,
    fill: true,
  },
  {
    title: 'bars+1',
    drawStyle: drawStyles.barsLeft,
    lineInterpolation: null,
    fill: true,
  },
  {
    title: 'bars-1',
    drawStyle: drawStyles.barsRight,
    lineInterpolation: null,
    fill: true,
  },
  {
    title: 'bars-multi',
    drawStyle: drawStyles.multiBars,
    lineInterpolation: null,
    fill: true,
  },
];

export function uplotGetSize(container: HTMLElement) {
  const padding = parseInt(window.getComputedStyle(container).padding);

  const containerWidth = container.offsetWidth;
  // console.log('-> containerWidth', containerWidth);
  const containerHeight = container.offsetHeight;

  const titleElementHeight = (<HTMLElement>container.querySelector('.u-title'))?.offsetHeight ?? 0;
  const legendElementHeight =
    (<HTMLElement>container.querySelector('.u-legend'))?.offsetHeight ?? 35;
  const headerElementHeight = (<HTMLElement>container.querySelector('.header'))?.offsetHeight ?? 0;

  const heightOffset = titleElementHeight + legendElementHeight + headerElementHeight + padding * 4;
  const width = containerWidth - 2 * padding;
  const height = containerHeight - 2 * padding - heightOffset;
  return { width, height };
}
