<div class="graph-container">
  <div class="loading-bar" *ngIf="loading$ | async">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="header">
    <span class="name">{{ data.name ?? ('DASHBOARD.TYPE_GRAPH' | translate) }}</span>
    <app-uplot-range-picker
      [disabled]="rangePickDisabled"
      [currentRange]="currentRange"
      (rangeHasChanged)="changeRange($event)"
    >
    </app-uplot-range-picker>
  </div>

  <div class="graph">
    @if (!noData) {
      @if(!(loading$|async)){
<!--        <div class="graph-sankey">-->
          <canvas baseChart [data]="config.data" [options]="config.options" [type]="config.type">
          </canvas>
<!--        </div>-->
      } @else {
<!--        <app-skeleton></app-skeleton>-->
      }
    } @else {
      <p class="no-data">{{ 'INSPECT.NO_DATA' | translate }}</p>
    }
  </div>
</div>
