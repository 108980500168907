/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.4
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LoggerDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * system_id
   */
  system_id?: number;
  /**
   * name
   */
  readonly name?: string;
  /**
   * Only for new logger, reference which database will be used to store data.
   */
  database_id: number;
  /**
   * Only for new logger save information where logger should be placed in tree
   */
  tree_id?: number;
  /**
   * status
   */
  status?: LoggerDto.StatusEnum;
  /**
   * Database migration process is on the way
   */
  busy?: boolean;
  /**
   * Current configuration id. Managed by Data Worker. Read only.
   */
  config_counter?: number;
  /**
   * Number of seconds after which the logger /command/ should call
   */
  command_interval: number;
  /**
   * last_connection
   */
  last_connection?: string;
  /**
   * From logger header. Set in data worker (data_save_metadata).
   */
  readonly firmware_version?: string;
  /**
   * From logger header. Set in data worker (data_save_metadata).
   */
  readonly protocol_version?: string;
  /**
   * S/N from logger header. Set in data worker (data_save_metadata).
   */
  readonly serial_number?: string;
  /**
   * Device private key
   */
  ssl_private_key?: string;
  /**
   * Device public key. Not used.
   */
  ssl_public_key?: string;
  /**
   * Device certificate.
   */
  ssl_cert?: string;
  /**
   * type
   */
  type: LoggerDto.TypeEnum;
  /**
   * data
   */
  timezone?: string;
  /**
   * Device template taken from ChirpStack server from Device profiles
   */
  lora_device_template_id?: string;
  /**
   * For LoRa device description - application key
   */
  lora_app_key?: string;
  /**
   * For LoRa device - information about Device EUI
   */
  lora_dev_eui?: string;
  /**
   * ChirpStack server ID, reference
   */
  lora_server_id?: number;
  /**
   * loraData
   */
  lora_data?: any;
  /**
   * IoT code used by the device to download the configuration. UUID
   */
  readonly download_code?: string;
  /**
   * When the download code for IoT will expire
   */
  readonly download_code_expiration?: string;
}
export namespace LoggerDto {
  export type StatusEnum = 'NEVER_SEEN' | 'ACTIVE' | 'INACTIVE' | 'PENDING_SSL' | 'EXPIRED';
  export const StatusEnum = {
    NEVERSEEN: 'NEVER_SEEN' as StatusEnum,
    ACTIVE: 'ACTIVE' as StatusEnum,
    INACTIVE: 'INACTIVE' as StatusEnum,
    PENDINGSSL: 'PENDING_SSL' as StatusEnum,
    EXPIRED: 'EXPIRED' as StatusEnum,
  };
  export type TypeEnum = 'EMU' | 'LORA' | 'IOT';
  export const TypeEnum = {
    EMU: 'EMU' as TypeEnum,
    LORA: 'LORA' as TypeEnum,
    IOT: 'IOT' as TypeEnum,
  };
}
