/**
 * EMU Invoice API
 * EMU Invoice API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PeriodicInvoiceSaveDto {
  /**
   * id
   */
  readonly id?: number;
  /**
   * lng
   */
  lng: string;
  /**
   * start_at
   */
  start_at: string;
  /**
   * end_at
   */
  end_at: string;
  /**
   * interval
   */
  interval: number;
  /**
   * interval_type
   */
  interval_type: PeriodicInvoiceSaveDto.IntervalTypeEnum;
  /**
   * organization id
   */
  customer_id: number;
  /**
   * organization id
   */
  root_tree_id: number;
  /**
   * organization id
   */
  price_list_id: number;
}
export namespace PeriodicInvoiceSaveDto {
  export type IntervalTypeEnum = 'DAY' | 'MONTH';
  export const IntervalTypeEnum = {
    DAY: 'DAY' as IntervalTypeEnum,
    MONTH: 'MONTH' as IntervalTypeEnum,
  };
}
