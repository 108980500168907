import { uplotDrawStyles, uplotLineInterpolations } from '../utils/uplotConstants';
import { multiBars } from './multiBars';

export function uplotPaths(u, seriesIdx, idx0, idx1, extendGap, buildClip) {
  const lineInterpolations = uplotLineInterpolations;
  const drawStyles = uplotDrawStyles;
  const { linear, stepped, bars, spline } = uPlot.paths;
  const bars60to100 = bars({ size: [0.5, 100] });
  const bars100Left = bars({ size: [1], align: 1 });
  const bars100Right = bars({ size: [1], align: -1 });
  const stepBefore = stepped({ align: -1 }); //, ascDesc: true
  const stepAfter = stepped({ align: 1 }); //, ascDesc: true

  let s = u.series[seriesIdx];
  let style = s.drawStyle;
  let interp = s.lineInterpolation;

  let renderer;

  switch (style) {
    case drawStyles.line: {
      renderer =
        interp == lineInterpolations.linear
          ? linear()
          : interp == lineInterpolations.stepAfter
            ? stepAfter
            : interp == lineInterpolations.stepBefore
              ? stepBefore
              : interp == lineInterpolations.spline
                ? spline()
                : null;
      break;
    }
    case drawStyles.bars: {
      renderer = bars60to100;
      break;
    }
    case drawStyles.barsLeft: {
      renderer = bars100Left;
      break;
    }
    case drawStyles.barsRight: {
      renderer = bars100Right;
      break;
    }
    case drawStyles.multiBars: {
      renderer = multiBars;
      break;
    }
    default: {
      renderer = () => null;
      break;
    }
  }

  return renderer(u, seriesIdx, idx0, idx1, extendGap, buildClip);
}
