//TODO sort tree nodes by name
import { TreeNode } from '../models/TreeNode';

export function sortTreeNodes(arr: TreeNode[]): TreeNode[] {
  // console.log('-> arr', arr);
  return arr.sort((a, b) => {
    if (a.isPreSetElement && !b.isPreSetElement) return -1;
    if (!b.isPreSetElement && b.isPreSetElement) return 1;

    return a.item_name?.localeCompare(b.item_name, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  });
}
