import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ModalComponent } from '../../../../../shared/components/dialogs/modal/modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardDto } from '../../../../../../api-main';
import { EmuInputComponent } from '../../../../../shared/components/inputs/emu-input/emu-input.component';

@Component({
  selector: 'app-dashboard-template-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    ModalComponent,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    EmuInputComponent,
  ],
  templateUrl: './dashboard-template-modal.component.html',
  styleUrl: './dashboard-template-modal.component.scss',
})
export class DashboardTemplateModalComponent {
  dashboard: DashboardDto;

  private blockInput = false;

  constructor(
    private dialogRef: MatDialogRef<DashboardTemplateModalComponent>,
    // private dashboardApiService: DashboardApiService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.dashboard = data;
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    if (this.blockInput) return;
    this.blockInput = true;
    this.dialogRef.close(this.dashboard);
  }

  getTitle() {
    return 'DASHBOARD.SAVE_AS_TEMPLATE';
  }

  getSaveButtonName() {
    return 'BUTTON.SAVE';
  }

  getCancelButtonName() {
    return 'DIALOG.BUTTONS.CANCEL';
  }

  isValid() {
    const name = this.dashboard.name;
    return name && name != '';
  }
}
