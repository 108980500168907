import { Component } from '@angular/core';
import { MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../../../../../../shared/components/dialogs/modal/modal.component';
import { SelectingValueDataComponent } from '../../../shared/selecting-value-data/selecting-value-data.component';
import { ValueDataModel } from '../../../../models/ValueData.model';

@Component({
  selector: 'app-add-series-modal',
  standalone: true,
  imports: [ModalComponent, MatDialogContent, SelectingValueDataComponent],
  templateUrl: './add-series-modal.component.html',
  styleUrl: './add-series-modal.component.scss',
})
export class AddSeriesModalComponent {
  data: ValueDataModel = { valueId: null, loggerId: null, deviceId: null };

  constructor(
    private dialogRef: MatDialogRef<AddSeriesModalComponent>,
    // @Inject(MAT_DIALOG_DATA) item: SelectedSeriesModel,
    // private translate: TranslateService,
  ) {
    // console.log('-> item', item);
    // if (!this.item.mode) this.item.mode = SelectedSeriesModeEnum.DEFAULT;
  }

  valueSelected() {}

  isValid() {
    return this.data && this.data.loggerId && this.data.valueId;
  }

  onSave() {
    this.dialogRef.close(this.data);
  }
}
