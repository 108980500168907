<span
  *ngIf="showRelative"
  matTooltip="{{
    time ? timeString : '-'
  }}"
>
  {{ time ? relativeTime : '-' }}
</span>
<span *ngIf="!showRelative" matTooltip="{{ relativeTime }}">
  <app-date-display [value]="time"></app-date-display>
  <!--  {{ time ? (time | date: 'medium' : localeSession.timezone : localeSession.locale) : '-' }}-->
</span>
