/**
 * EMU Invoice API
 * EMU Invoice API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PriceItemDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * id
   */
  readonly id?: number;
  /**
   * item name
   */
  name: string;
  /**
   * item description
   */
  description: string;
  /**
   * item name
   */
  readonly tariff: string;
  /**
   * Value of item
   */
  price: number;
  /**
   * currency
   */
  readonly currency: string;
  /**
   * obis_code
   */
  readonly obis_code: string;
  /**
   * Unit id
   */
  unit_cfg: number;
  /**
   * Tax
   */
  tax_rate: number;
  /**
   * Price list id
   */
  readonly price_list_id?: number;
  /**
   * Is fixed price
   */
  fixed: boolean;
}
