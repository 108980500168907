<gridster-item
  #gridsterItemComponent
  *ngIf="item"
  [item]="item"
  [class.selected]="isSelected"
  [class.selected-layer]="isLayerSelected"
  [class.locked]="itemBlocked && editMode"
  (mousedown)="select()"
  [class.edit]="editMode"
  [class.no-padding]="!item.styles.padding"
  [class.no-background]="!item.styles.showBackground"
  [style.background]="item.styles.background ?? ''"
  [ngClass]="typeClass"
  (dblclick)="showSettings()"
>
  <ng-container *ngIf="editMode">
    <div class="gridster-item-drag"></div>
  </ng-container>
  <div
    class="gridster-item-content"
    (mousedown)="$event.stopPropagation()"
    (touchstart)="$event.stopPropagation()"
  >
    <!--    layer:: {{item.layerIndex}}-->
    <app-dashboard-item-content [item]="item"></app-dashboard-item-content>
  </div>
</gridster-item>
