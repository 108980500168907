import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { TreeFormIcon } from './TreeFormIcon';
import { OtherIcons } from './OtherIcons';
import { TreeFormData } from '../TreeFormData';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TreeDialogMode } from '../TreeDialogMode';

@Component({
  selector: 'app-tree-form-icon-select',
  templateUrl: './tree-form-icon-select.component.html',
  styleUrl: './tree-form-icon-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule, AsyncPipe, TranslateModule],
})
export class TreeFormIconSelectComponent implements AfterViewInit {
  @Input({ required: true }) data: TreeFormData;

  defaultIcon: TreeFormIcon;

  otherIcons: TreeFormIcon[] = OtherIcons;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.setDefaultIcon();
    this.data.icon$.next(this.defaultIcon);
    this.filterOutDefaultIcon();
    if (this.data.item && this.data.mode == TreeDialogMode.Edit)
      this.data.icon$.next(this.data.item.icon);
    if (!this.data.icon$.value) this.selectIcon(this.defaultIcon);
    this.cdr.detectChanges();
  }

  private setDefaultIcon() {
    this.defaultIcon = this.data.typeSelected$.value.icon;
  }

  private filterOutDefaultIcon() {
    this.otherIcons = this.otherIcons.filter((o) => o != this.defaultIcon);
  }

  selectIcon(icon: TreeFormIcon) {
    this.data.icon$.next(icon);
    this.cdr.detectChanges();
  }
}
