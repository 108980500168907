<ng-container *ngIf="data">
  <main>
    <h2>{{ 'SIGNUP.ACCOUNT_CONFIGURATION' | translate }}</h2>
    <section *ngFor="let accountConfig of accountConfiguration">
      <mat-form-field>
        <mat-label>{{ accountConfig.displayName | translate }}</mat-label>
        <mat-select name="{{ accountConfig.name }}" [(ngModel)]="data[accountConfig.name]">
          <mat-option
            *ngFor="let config of accountConfig.data"
            [value]="config.value"
            [disabled]="!isConfigAvailable(config)"
            >{{ configOptionsPrefix + config.value | translate }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </section>

    <!--    <app-pricing [data]="signupData"></app-pricing>-->
  </main>
</ng-container>

<!--      <mat-form-field>-->
<!--        <mat-label>{{accountConfig.displayName | translate}}</mat-label>-->
<!--        <mat-button-toggle-group name="{{accountConfig.name}}" [(ngModel)]="data[accountConfig.name]">-->
<!--          <mat-button-toggle *ngFor="let config of accountConfig.data" [value]="config.value" -->
<!--          [disabled]="!isConfigAvailable(config)">{{(configOptionsPrefix + config.value) | translate}}</mat-button-toggle>-->
<!--        </mat-button-toggle-group>-->
<!--      </mat-form-field>-->
