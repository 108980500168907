<app-modal
  [title]="getTitle()"
  [saveButtonName]="getSaveButtonName()"
  [cancelButtonName]="getCancelButtonName()"
  (save)="onSave()"
  [isDataLoaded]="!!data"
  [shouldSaveBeDisabled]="!isValid()"
>
  <ng-container body>
    <mat-dialog-content>
      <app-emu-input
        [name]="'INPUT.NAME'"
        [validator]="isValid.bind(this)"
        placeholder="{{ 'DASHBOARD.TEMPLATE_NAME' | translate }}"
        [(value)]="dashboard.name"
      >
      </app-emu-input>

      <!--      <mat-form-field style="width: 100%">-->
      <!--        <mat-label>{{ 'INPUT.NAME' | translate }}</mat-label>-->
      <!--        -->
      <!--      </mat-form-field>-->
    </mat-dialog-content>
  </ng-container>
</app-modal>
