import { UserTypes } from './models/UserTypes.enum';
import { SignupAccountConfiguration } from './models/AccountConfiguration.model';
import { CompanyData } from './models/CompanyData.model';
import { assignSignupConfiguration } from './utils/assignSignupConfiguration';

export class SignupData {
  constructor() {
    this.userType = UserTypes.STANDARD;
  }

  conditionsAccepted: boolean = false;

  set userType(value: UserTypes) {
    this._userType = value;
    this.handleTypeChange();
  }

  get userType(): UserTypes {
    return this._userType;
  }

  configuration: SignupAccountConfiguration = {};

  companyData?: CompanyData = {
    street: null,
    city: null,
    country: null,
    zip: null,
    houseNumber: null,
  };

  private _userType: UserTypes;

  private handleTypeChange() {
    assignSignupConfiguration(this.configuration, this.userType);
  }
}
