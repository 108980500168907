import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  ViewContainerRef,
  OnDestroy,
} from '@angular/core';
import { UserGuideService } from './user-guide.service';
import { UserGuideComponent } from './user-guide.component';

@Directive({
  selector: '[appUserGuide]',
  standalone: true,
})
export class UserGuideDirective implements AfterViewInit, OnDestroy {
  @Input() userGuideId: number;

  @Input() set userGuideDisplay(value: boolean) {
    if (value && !this._userGuideDisplay) {
      this._userGuideDisplay = true;
      this._createTooltip();
    } else if (!value && this._userGuideDisplay) {
      this._userGuideDisplay = false;
    }
  }

  @Input() userGuideTooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'right';

  private _userGuideDisplay: boolean = true;

  private observer: ResizeObserver;

  private hasCreated: boolean = false;

  constructor(
    private el: ElementRef,
    private viewContainer: ViewContainerRef,
    private userGuideService: UserGuideService,
  ) {}

  public ngAfterViewInit(): void {
    if (!this.hasCreated && this._userGuideDisplay) {
      this._createTooltip();
    }
  }

  public ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  private _createTooltip() {
    const container = document.getElementById('user-guide-container');
    const component = this.viewContainer.createComponent(UserGuideComponent);

    component.instance.parentCord.set(this.el.nativeElement.getBoundingClientRect());

    this.observer = new ResizeObserver(() => {
      component.instance.parentCord.set(this.el.nativeElement.getBoundingClientRect());
    });

    this.observer.observe(container);
    component.instance.content.set(this.userGuideService.userGuideContent[this.userGuideId]);
    component.instance.position.set(this.userGuideTooltipPosition);
    component.instance.id = this.userGuideId;
    this.hasCreated = true;
  }
}
