import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { registerPlugin } from './app/pages/dashboard/widgets/dashboard-graph/dashboard-pie-chart/donughtCenterText';
import * as Sentry from '@sentry/angular';
import { appConfig } from './app/app.config';

if (environment.production) {
  enableProdMode();
}

registerPlugin();

Sentry.init({
  dsn: 'https://46f799c1b5977b8b933f10269d46f10a@o4508737376550912.ingest.de.sentry.io/4508801189019728',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // 'localhost',
    /^https:\/\/.*\..*\.ems\.energy\/api.*/,
    // /^https:\/\/oem1\.beta\.ems\.energy\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event) => {
    console.warn('Sending event to sentry', event);
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
