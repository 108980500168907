import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DashboardService as ApiDashboardService } from '../../../../api-main/api/dashboard.service';
import { DashboardreportService as ApiDashboardReportService } from '../../../../api-main/api/dashboardreport.service';
import { ConfigService } from '../../../shared/services/config.service';
import {
  DashboardReportDto,
  DashboardReportRequestDto,
  DashboardUpdateDto,
  ListFilterRequestDto,
} from '../../../../api-main';
import { finalize, Observable, throwError } from 'rxjs';
import { WaitService } from '../../../shared/services/wait.service';
import FileTypeEnum = DashboardReportDto.FileTypeEnum;

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService {
  constructor(
    private http: HttpClient,
    private apiDashboardService: ApiDashboardService,
    private apiDashboardReportService: ApiDashboardReportService,
    private waitService: WaitService,
  ) {
    this.apiDashboardService = new ApiDashboardService(
      this.http,
      ConfigService.getApiConfig(),
      undefined,
    );
    this.apiDashboardReportService = new ApiDashboardReportService(
      this.http,
      ConfigService.getApiConfig(),
      undefined,
    );
  }

  get(id: number) {
    return this.apiDashboardService.dashboardControllerGet(id);
  }

  requestReport(req: DashboardReportRequestDto) {
    return this.apiDashboardReportService.dashboardReportControllerRequestReport(req);
  }

  reportList(filter: ListFilterRequestDto = null) {
    if (filter == null)
      filter = {
        page_index: 0,
        page_size: 100,
        sort_direction: 'asc',
      };
    return this.apiDashboardReportService.dashboardReportControllerList(filter);
  }

  downloadReport(id: number, format: FileTypeEnum) {
    return this.apiDashboardReportService.dashboardReportControllerDownloadReport(id, format);
  }

  deleteReport(id: number) {
    return this.apiDashboardReportService.dashboardReportControllerDelete(id);
  }

  save(dashboard: DashboardUpdateDto) {
    return this.apiDashboardService.dashboardControllerSave(dashboard);
  }

  list(filter: ListFilterRequestDto = null) {
    if (filter == null)
      filter = {
        page_index: 0,
        page_size: 100,
        sort_direction: 'asc',
      };
    return this.apiDashboardService.dashboardControllerList(filter);
  }

  delete(id: number) {
    return this.apiDashboardService.dashboardControllerDelete(id);
  }

  saveDashboard(dashboard: DashboardUpdateDto): Observable<boolean> {
    if (!dashboard) return throwError(() => new Error('NO data provided'));
    return new Observable((observer) => {
      this.waitService.start();

      this.save(dashboard)
        .pipe(finalize(() => this.waitService.stop()))
        .subscribe({
          next: () => {
            observer.next(true);
            observer.complete();
          },
          error: (error) => {
            observer.error(error);
          },
        });
    });
  }
}
