<section>
  <span class="name">{{ dashboardName$ | async }}</span>
  <div class="dashboard-buttons">
    <app-dashboard-templates-select
      *ngIf="allowEdit && showTemplateSelect()"
    ></app-dashboard-templates-select>

    <button
      mat-flat-button
      class="button-primary"
      (click)="download()"
      *ngIf="!editMode"
      [disabled]="downloadingPng"
    >
      {{ 'DOWNLOAD.PNG' | translate }}
      <mat-icon iconPositionEnd svgIcon="download"></mat-icon>
    </button>

    @if (editMode) {
      <button mat-flat-button class="button-secondary" (click)="restore()">
        {{ 'DIALOG.BUTTONS.CANCEL' | translate }}
        <mat-icon iconPositionEnd>cancel</mat-icon>
      </button>

      <button
        mat-flat-button
        class="button-primary"
        [disabled]="!changesMade"
        (click)="toggleEditMode()"
      >
        {{ 'DASHBOARD.SAVE' | translate }}
        <mat-icon iconPositionEnd svgIcon="ok"></mat-icon>
      </button>
    } @else {
      <button
        *ngIf="allowEdit"
        mat-flat-button
        class="button-primary"
        (click)="toggleEditMode()"
        [disabled]="downloadingPng"
      >
        {{ 'DASHBOARD.EDIT' | translate }}
        <mat-icon iconPositionEnd svgIcon="edit2"></mat-icon>
      </button>
    }

    <button
      *ngIf="allowEdit && showSaveAsTemplate()"
      mat-flat-button
      class="button-primary"
      (click)="openSaveTemplateModal()"
    >
      {{ 'DASHBOARD.SAVE_AS_TEMPLATE' | translate }}
      <mat-icon iconPositionEnd svgIcon="ok"></mat-icon>
    </button>
  </div>
</section>
