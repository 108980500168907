/**
 * EMU API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ProfileIdentityDto } from './profileIdentityDto';
import { UserMetadataDto } from './userMetadataDto';

export interface ProfileDto {
  /**
   * Date and time when this user was created (ISO_8601 format).
   */
  created_at?: string;
  /**
   * User e-mail
   */
  email: string;
  /**
   * Whether this email address is verified (true) or unverified (false).
   */
  email_verified?: boolean;
  /**
   * Last IP address from which this user logged in.
   */
  last_ip?: string;
  /**
   * Last date and time this user logged in (ISO_8601 format).
   */
  last_login?: string;
  /**
   * Total number of logins this user has performed.
   */
  logins_count?: number;
  /**
   * Name of this user.
   */
  name: string;
  /**
   * Preferred nickname or alias of this user.
   */
  nickname: string;
  /**
   * URL to picture, photo, or avatar of this user
   */
  picture?: string;
  /**
   * Date and time when this user was last updated/modified (ISO_8601 format).
   */
  updated_at: string;
  /**
   * User identification string
   */
  user_id?: string;
  /**
   * Username of this user.
   */
  username: string;
  /**
   * User metadata to which this user has read/write access
   */
  user_metadata: UserMetadataDto;
  /**
   * Given name/first name/forename of this user
   */
  given_name?: string;
  /**
   * Family name/last name/surname of this user
   */
  family_name?: string;
  /**
   * Array of user identity objects when accounts are linked.
   */
  identities: Array<ProfileIdentityDto>;
}
