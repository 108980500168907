import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';

const dbConfig: DBConfig = {
  name: 'cache',
  version: 1,
  objectStoresMeta: [
    {
      store: 'dashboard-previews',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [{ name: 'src', keypath: 'src', options: { unique: false } }],
    },
  ],
};

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxIndexedDBModule.forRoot(dbConfig)],
  exports: [NgxIndexedDBModule],
})
export class CacheModule {}
