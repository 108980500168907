<div class="dashboard-container" *ngIf="!loading" [class.public]="isPublic">
  <section class="header" *ngIf="showHeader&&!isPublic">
    <app-dashboard-header></app-dashboard-header>
  </section>

  <section *ngIf="isPublic&& (organization$ | async) as org" class="public-header">
    <div class="logo-container">
      <img *ngIf="org.company_logo" class="logo" [src]="org.company_logo" alt=" "/>
      <span>{{org.company_name}}</span>
    </div>
  </section>

  <app-item-settings></app-item-settings>

  <main *ngIf="!isPublic || (organization$|async)">
    <div *ngIf="noItemsMessage" class="no-items">
      <img ngSrc="assets-common/images/dashboard_preview.svg" fill="" />
      <div class="info-container">
        <p>
          <mat-icon svgIcon="info"></mat-icon>
          <span class="info"> {{ 'NOTIFICATION.INFO' | translate }}</span>
        </p>
        <span class="message">{{ 'DASHBOARD.EMPTY_DASHBOARD_MESSAGE' | translate }}</span>
      </div>
    </div>
    <gridster [options]="options">
      <app-dashboard-item [item]="item" *ngFor="let item of dashboard"></app-dashboard-item>
    </gridster>
  </main>

  <div *ngIf="isPublic" class="public-footer">
    <app-date-display></app-date-display>
  </div>

</div>
