<!--<button class="accent-fab" mat-mini-fab data-cy="toggle-expand"-->
<!--        [matTooltip]=" ( (areNodesExpanded$ | async ) ? 'TOOLTIP.COLLAPSE_ALL' : 'TOOLTIP.EXPAND_ALL') | translate"-->
<!--        (click)="toggleExpandAll()">-->
<!--  <mat-icon class="toolbar-icon">{{ (areNodesExpanded$ | async) ? 'unfold_less':'unfold_more'}}</mat-icon>-->
<!--</button>-->



@if (buttonMode) {
  <button [matTooltip]="(areNodesExpanded() ? 'TOOLTIP.COLLAPSE_ALL' : 'TOOLTIP.EXPAND_ALL') | translate" class="primary" (click)="toggleExpandAll()">
    <mat-icon color="primary">{{((areNodesExpanded()) ? 'unfold_less' : 'unfold_more')}}</mat-icon>
  </button>
} @else {
  <span  (click)="toggleExpandAll()">
  {{ ((areNodesExpanded$ | async) ? 'TOOLTIP.COLLAPSE_ALL' : 'TOOLTIP.EXPAND_ALL') | translate }}
  </span>
}
