import { Component, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { ConfigService } from '../../shared/services/config.service';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT, NgTemplateOutlet } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { finalize, take } from 'rxjs';
import { LoadingCircleComponent } from '../../layout/loading-circle/loading-circle.component';
import { AlertService } from '../../shared/services/alert.service';
import { ProfileService } from '../../shared/services/profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verify-email',
  standalone: true,
  imports: [TranslateModule, NgTemplateOutlet, LoadingCircleComponent],
  templateUrl: './verify-email.component.html',
  styleUrl: './verify-email.component.scss',
})
export class VerifyEmailComponent implements OnInit {
  readonly userEmail: string;

  logoURL: string;

  loading: WritableSignal<boolean> = signal(false);

  resending: WritableSignal<boolean> = signal(false);

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private auth: AuthService,
    private profileService: ProfileService,
    private alert: AlertService,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.userEmail = localStorage.getItem('verificationEmail');
  }

  ngOnInit(): void {
    if (!this.userEmail) {
      this.router.navigateByUrl('/login').catch();
      return;
    }

    this.loading.set(true);
    let oemId: string = ConfigService.getIdConfig();
    this.logoURL = `../../../assets-common/${oemId}/logo.svg`;

    this.translate.onDefaultLangChange.pipe(take(1)).subscribe({
      next: () => this.loading.set(false),
    });
  }

  getLng(): string {
    const lng: string = this.translate.currentLang;
    switch (lng) {
      case 'ch':
        return 'de';
      default:
        return lng;
    }
  }

  continue(): void {
    this.auth.loginWithRedirect({
      appState: { target: 'profile' },
      authorizationParams: {
        ui_locales: this.getLng(),
      },
    });
  }

  resend(): void {
    if (this.resending()) return;

    this.resending.set(true);
    this.profileService
      .sendVerificationEmail(this.userEmail)
      .pipe(
        take(1),
        finalize(() => this.resending.set(false)),
      )
      .subscribe({
        next: () => this.alert.info(this.translate.instant('VERIFY_EMAIL.EMAIL_SENT')),
        error: () => this.alert.error(this.translate.instant('VERIFY_EMAIL.SENT_ERROR')),
      });
  }

  logout(): void {
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }
}
