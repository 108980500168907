import { Component, Input } from '@angular/core';
import { SignupData } from '../../SignupData';
import { LocaleSessionService } from '../../../shared/services/localeSession.service';
import { accountConfigurations } from '../../data/AccountConfigurations';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class PricingComponent {
  constructor(private localeSession: LocaleSessionService) {}

  @Input() data: SignupData;

  getPricing() {
    let cost = 0;

    Object.keys(this.data.configuration).forEach((key) => {
      const configuration = accountConfigurations.find((o) => o.name == key);
      // eslint-disable-next-line security/detect-object-injection
      const chosenConfiguration = this.data.configuration[key];

      if (!chosenConfiguration || !configuration) return;

      const configCost = configuration.data.find((o) => o.value == chosenConfiguration)?.cost;
      if (configCost && !isNaN(Number(configCost))) cost += Number(configCost);
    });

    // if (this.data.companyData?.companyName) cost += this.data.companyData.companyName.length;
    const currencyMultiplier = this.getCurrencyMultiply();
    return (cost * currencyMultiplier).toFixed(2);
  }

  getCurrency() {
    return this.localeSession.currency;
  }

  getCurrencyMultiply(): number {
    //TODO move to localeSession
    switch (this.getCurrency()) {
      case 'PLN':
        return 4.7;
      case 'EUR':
        return 1;
      case 'USD':
        return 1.8;
      default:
        return 1;
    }
  }
}
