<div class="icons-container">
  <div class="default-icon">
    <p>{{ 'TREE.DEFAULT_ICON' | translate }}</p>
    <mat-icon
      svgIcon="{{ defaultIcon }}"
      (click)="selectIcon(defaultIcon)"
      [class.selected]="(data.icon$ | async) === defaultIcon"
    ></mat-icon>
  </div>

  <div class="other-icons">
    <p>{{ 'TREE.OR_CHOOSE_ICON_FROM_BELLOW' | translate }}</p>
    <div class="icons">
      @for (icon of otherIcons; track otherIcons) {
        <mat-icon
          svgIcon="{{ icon }}"
          (click)="selectIcon(icon)"
          [class.selected]="(data.icon$ | async) === icon"
        ></mat-icon>
      }
    </div>
  </div>
</div>
