<header
  [ngClass]="(layoutService.opened$ | async) ? 'opened' : 'closed'"
  (transitionend)="onTransitionEnd()"
>
  <div class="menu">
    <div class="left-buttons">
      <mat-icon *ngIf="showToggleSidenav" (click)="emitToggleSidenav()">menu</mat-icon>
      <app-organization-select></app-organization-select>
    </div>
    <app-dropdown position="right">
      <button class="dropdown-button">
        <mat-icon svgIcon="user" class="user-icon" color="primary"></mat-icon>
      </button>
      <ng-container body>
        <a (click)="insertInvitationCode()">
          <mat-icon svgIcon="add_circle"></mat-icon>
          {{ 'INVITATION.INVITATION_CODE' | translate }}
        </a>
        <a routerLink="/profile/profile" [routerLinkActive]="['nav-item-active']">
          <mat-icon svgIcon="user"></mat-icon>
          {{ 'TABS.PROFILE' | translate }}
        </a>
        <a routerLink="/profile" [routerLinkActive]="['nav-item-active']" [routerLinkActiveOptions]="{exact: true}">
          <mat-icon svgIcon="tiles"></mat-icon>
          {{ 'NAVBAR.MAIN_PAGE' | translate }}
        </a>
        <a (click)="changePassword()">
          <mat-icon svgIcon="lock">password</mat-icon>
          {{ 'NAVBAR.CHANGE_PASSWORD' | translate }}
        </a>
        <a class="logout" (click)="logout()">
          <mat-icon data-cy="menu-profile-logout">logout</mat-icon>
          {{ 'NAVBAR.LOGOUT' | translate }}
        </a>
      </ng-container>
    </app-dropdown>
  </div>
</header>
