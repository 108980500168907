import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DashboardItemSettingsComponent } from '../dashboard-item-settings.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatSliderModule } from '@angular/material/slider';
import { EmuInputComponent } from '../../../../shared/components/inputs/emu-input/emu-input.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EmuListInputComponent } from '../../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import { SelectingValueDataComponent } from '../shared/selecting-value-data/selecting-value-data.component';
import { NgForOf, NgIf } from '@angular/common';
import { DashboardItemTypeMultipleValueDisplay } from '../../models/DashboardItemTypeMultipleValueDisplay';
import { EmuColorInputComponent } from '../../../../shared/components/inputs/emu-color-input/emu-color-input.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent } from '../../../../shared/components/dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { take } from 'rxjs';
import { ValueDisplayMode } from '../dashboard-value-display-settings/ValueDisplayMode.model';
import { DataDisplayDefaultRanges } from '../../../../shared/models/DataDisplayDefaultRanges';
import { DashboardValueDisplaySettingsComponent } from '../dashboard-value-display-settings/dashboard-value-display-settings.component';
import { DashboardValueDisplaySettingsInputComponent } from '../dashboard-value-display-settings/dashboard-value-display-settings-input/dashboard-value-display-settings-input.component';

@Component({
  selector: 'app-dashboard-multiple-value-display-settings',
  templateUrl: './dashboard-multiple-value-display-settings.component.html',
  styleUrls: [
    '../../styles/widget-settings.scss',
    '../dashboard-value-display-settings/dashboard-value-display-settings.component.scss',
    './dashboard-multiple-value-display-settings.component.scss',
  ],
  standalone: true,
  imports: [
    NgIf,
    SelectingValueDataComponent,
    EmuListInputComponent,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    EmuInputComponent,
    MatSliderModule,
    TranslateModule,
    EmuColorInputComponent,
    MatIconModule,
    MatTabsModule,
    MatTooltipModule,
    NgForOf,
    DashboardValueDisplaySettingsComponent,
    DashboardValueDisplaySettingsInputComponent,
  ],
})
export class DashboardMultipleValueDisplaySettingsComponent
  extends DashboardItemSettingsComponent
  implements OnInit
{
  private readonly MAX_SERIES = 10;

  selectedTabId = 0;

  constructor(
    protected override cd: ChangeDetectorRef,
    private translate: TranslateService,
    private dialog: MatDialog,
  ) {
    super(cd);
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  override setDefaultStyling() {
    this.item.styles.padding = true;
  }

  override get data(): DashboardItemTypeMultipleValueDisplay {
    return this.itemData?.multipleValueDisplay;
  }

  protected override setDefault() {
    if (this.data == undefined)
      this.itemData.multipleValueDisplay = { direction: 'row', values: [] };
  }

  setLayout(value: 'column' | 'row') {
    this.data.direction = value;
  }

  getLayout() {
    return this.data.direction;
  }

  canAddValue() {
    return this.data.values.length < this.MAX_SERIES;
  }

  addValue() {
    if (!this.data?.values || !this.canAddValue()) return;
    this.data.values.push({
      loggerId: null,
      deviceId: null,
      valueId: null,
      mode: ValueDisplayMode.LAST,
      rangeMode: DataDisplayDefaultRanges.CURRENT_DAY,
      range: null,
      showUnit: true,
      unitPrefix: '',
      showDate: true,
      showHeader: true,
      decimalPlaces: 2,
      consumption: false,
    });
    this.selectedTabId = this.data.values.length - 1;
  }

  deleteValue(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      id: 0,
    };
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    this.dialog
      .open(ConfirmDeleteDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;

        this.data.values.splice(id, 1);
        if (this.selectedTabId == this.data.values.length - 1) this.selectedTabId--;
        // this.refreshSeries();
        // this.refreshGraph();
        this.cd.detectChanges();
      });
  }
}
