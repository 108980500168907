import { Injectable } from '@angular/core';
import {
  ItemTreeDto,
  ListFilterRequestDto,
  TreeItemTypeDto,
  TreeItemTypeListResponseDto,
  TreeService as ApiTreeService,
  GpInvitationAcceptRequestDto,
  ItemTreeListRequestDto,
  MoveItemRequestDto,
  GetMoveDestinationsRequestDto,
  TreeDataStructureDto,
  TreeItemFindResponseDto,
  ItemTreeListResponseDto,
  FindOnTreeRequestDto,
  FindOnTreeResponseDto,
} from '../../../api-main';
import { ConfigService } from '../../shared/services/config.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, shareReplay, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TreeService {
  public savedFilter: ListFilterRequestDto = {
    filter: [],
    sort: [],
    page_size: 25,
    sort_direction: 'asc',
    page_index: 0,
  };

  public static readonly nodesLoggerCodes: string[] = ['LOGGER', 'LORA', 'IOT'];

  constructor(
    private treeService: ApiTreeService,
    private http: HttpClient,
    private translateService: TranslateService,
  ) {
    this.treeService = new ApiTreeService(this.http, ConfigService.getApiConfig(), undefined);
  }

  public getTreeList(body: ItemTreeListRequestDto = {}): Observable<ItemTreeListResponseDto> {
    return this.treeService.treeControllerGetTreeList(body);
  }

  public getTree(id: number) {
    return this.treeService.treeControllerGetTree(id);
  }

  public findTree(name: string): Observable<TreeItemFindResponseDto> {
    return this.treeService.treeControllerFindTree(name);
  }

  public findTreeById(id: number): Observable<TreeItemFindResponseDto> {
    return this.treeService.treeControllerFindTreeById(id);
  }

  public findOnTree(data: FindOnTreeRequestDto): Observable<FindOnTreeResponseDto> {
    return this.treeService.treeControllerFindOnTree(data);
  }

  public getDataStructure(id: number): Observable<TreeDataStructureDto> {
    return this.treeService.treeControllerDataStructure(id);
  }

  /*
   *add/update tree item
   */
  public postItemTree(itemTree: ItemTreeDto) {
    return this.treeService.treeControllerAdd(itemTree);
  }

  public moveItemTree(body: MoveItemRequestDto) {
    return this.treeService.treeControllerMove(body);
  }

  public getMoveDestinationsItemTree(body: GetMoveDestinationsRequestDto) {
    return this.treeService.treeControllerGetMoveDestinations(body);
  }

  public deleteItemTree(itemId: number) {
    return this.treeService.treeControllerDelete(itemId);
  }

  private itemTypes$: Observable<TreeItemTypeListResponseDto>;

  public getItemTypes(): Observable<TreeItemTypeListResponseDto> {
    if (!this.itemTypes$) {
      const filter: ListFilterRequestDto = {
        page_index: 0,
        page_size: 1000,
        sort_direction: 'asc',
        filter: [{ field: 'lng', value: this.translateService.currentLang }],
      };
      this.itemTypes$ = this.treeService.itemTypeControllerList(filter).pipe(shareReplay(1));

      this.translateService.onLangChange.pipe(take(1)).subscribe(() => (this.itemTypes$ = null));
    }

    return this.itemTypes$;
  }

  public getItemType(id: number): Observable<TreeItemTypeDto> {
    return this.treeService.itemTypeControllerGet(id);
  }

  public gpControllerGpInviteAccept(body: GpInvitationAcceptRequestDto) {
    return this.treeService.gpControllerGpInviteAccept(body, 'response');
  }

  public static isLoggerNode(item_type_code: string): boolean {
    return this.nodesLoggerCodes.includes(item_type_code);
  }
}
