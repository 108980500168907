<header>
  <h1>{{ 'DIALOG.ERROR' | translate }}</h1>
  <mat-icon id="close-button" mat-dialog-close>close</mat-icon>
</header>

<mat-divider></mat-divider>
<div class="modal-body">
  <span>{{ text | translate }} </span>
</div>
<mat-divider></mat-divider>
