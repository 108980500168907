import { Component, Input } from '@angular/core';
import { SignupData } from '../../SignupData';
import { DownloadableDocuments } from '../../models/DownloadableDocuments';
import { legalNotesDocuments } from '../../data/legalNotesDocuments';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-user-conditions',
  templateUrl: './user-conditions.component.html',
  styleUrls: ['./user-conditions.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    MatIconModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
  ],
})
export class UserConditionsComponent {
  @Input() signupData: SignupData;

  documents: DownloadableDocuments[] = legalNotesDocuments;
}
