import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import domtoimage from 'dom-to-image';
import { Observable } from 'rxjs';

// const key = 'savedDashboardPreviews';
@Injectable({
  providedIn: 'root',
})
export class DashboardPreviewService {
  private readonly storeName = 'dashboard-previews';

  constructor(private dbService: NgxIndexedDBService) {
    //create if not exists
    this.dbService.createObjectStore({
      store: 'dashboard-previews',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [{ name: 'src', keypath: 'src', options: { unique: false } }],
    });
  }

  getRender(id: number): Observable<any> {
    return this.dbService.getByKey(this.storeName, id.toString());
  }

  saveRender(id: number, object: HTMLElement): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (object)
        //it has issues with cors
        domtoimage
          .toPng(object)
          .then((src) => {
            if (src.length < 10) {
              throw new Error(`src is invalid: ${src}`);
            }
            // console.log('-> data', data);
            this.dbService.bulkPut(this.storeName, [{ src, id }]).subscribe({
              next: () => {
                {
                  observer.next(true);
                  observer.complete();
                }
              },
              error: (err) => {
                console.warn('-> Failed to save previews into indexdb: ', err);
                observer.next(false);
                observer.complete();
              },
            });
          })
          .catch((err) => {
            console.warn('-> Failed to save previews, removing dashboard data ', err);
            this.dbService.delete(this.storeName, id);
            observer.next(false);
            observer.complete();
          });
      else {
        observer.next(false);
        observer.complete();
      }
    });
  }
}
