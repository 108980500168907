/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.4
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TreeItemTypeMetadataDto } from './treeItemTypeMetadataDto';

export interface TreeItemTypeListItemDto {
  /**
   * Item type identification
   */
  id?: number;
  /**
   * Item type icon
   */
  icon: string;
  /**
   * Item type name based on lng from filter
   */
  name: string;
  /**
   * Allowed item types to connect with item type. If null - all types allowed
   */
  item_type_allowed?: Array<number>;
  /**
   * Metadata
   */
  type_metadata?: Array<TreeItemTypeMetadataDto>;
  /**
   * Check if children not possible
   */
  end_type: boolean;
}
