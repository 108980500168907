import { UplotRange } from '../models/UplotRange.model';
import { getNameForDownloadFile } from './uplotUtil';

//TODO refactor code
const titleHeight = 50;
const gapX = 30;
const circleRadius = 7;
const labelFontSize = 13;

let range;
let width;
let height;
let ctx;
let rect;
let rectCanvas;
let pxRatio = devicePixelRatio;
let graphName;
let u;

function setFont(size, context = ctx) {
  context.font = `${size}px Roboto`;
  context.fillStyle = 'black';
}

function drawTitle() {
  const top = (rectCanvas.top - rect.top) * pxRatio + titleHeight / 2;
  const fontSize = 18;
  setFont(fontSize);

  const text = graphName;
  const textWidth = ctx.measureText(text).width;
  const x = gapX + (width - gapX * 2) / 2 - textWidth / 2;
  const y = top + fontSize;

  ctx.fillStyle = 'black';
  ctx.fillText(text, x, y);
}

function drawLabels() {
  const startLabelX = u.bbox.left + 10;

  let lastLabelX = startLabelX;
  let lastLabelY = u.ctx.canvas.height + titleHeight;

  u.series
    .slice(1)
    .filter((serie) => serie.show)
    .forEach((serie) => {
      setFont(labelFontSize);
      const label = serie.label;
      const textWidth = ctx.measureText(label).width;
      const labelHeight = circleRadius * 3;
      let labelY = lastLabelY + labelHeight;
      let labelX = lastLabelX;
      const labelWidth = circleRadius * 3 + textWidth + gapX;
      lastLabelX = labelX + labelWidth;

      if (lastLabelX + gapX > width) {
        labelX = startLabelX;
        lastLabelX = labelX + labelWidth;
        lastLabelY = labelY + labelFontSize;
        labelY = lastLabelY + labelHeight;
      }

      //circle
      ctx.beginPath();
      ctx.arc(labelX, labelY, circleRadius, 0, 2 * Math.PI);
      ctx.fillStyle = serie._fill ?? serie._stroke;
      ctx.strokeStyle = serie._stroke;
      ctx.fill();
      ctx.stroke();
      //label
      ctx.fillStyle = 'black';
      ctx.fillText(label, labelX + circleRadius * 2, labelY + labelFontSize / 3);
    });
}

function calculateLabelHeight() {
  //TODO do it better way?
  const ctxIn = u.ctx.canvas.getContext('2d');
  const labelHeight = circleRadius * 3;
  const labelRowWidth = width - (u.bbox.left + 10 + gapX * 3);
  let textWidth = gapX;
  u.series
    .slice(1)
    .filter((series) => series.show)
    .forEach((series) => {
      setFont(labelFontSize, ctxIn);
      textWidth += ctxIn.measureText(series.label).width + gapX + circleRadius * 3;
    });
  return labelHeight * (Math.floor(textWidth / labelRowWidth) + 1);
}

function createCanvas() {
  rect = u.root.getBoundingClientRect();
  rectCanvas = u.ctx.canvas.getBoundingClientRect();

  width = Math.ceil(rect.width * pxRatio);
  const labelHeight = calculateLabelHeight();

  height = Math.ceil(rect.height * pxRatio) + titleHeight + labelHeight;
  const can = document.createElement('canvas');

  can.width = width;
  can.height = height;
  can.style.width = Math.ceil(rect.width) + 'px';
  can.style.height = Math.ceil(rect.height) + 'px';

  ctx = can.getContext('2d');
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, width, height);
  ctx.drawImage(u.ctx.canvas, 0, (rectCanvas.top - rect.top) * pxRatio + titleHeight);
  drawLabels();
  drawTitle();

  // console.log(can);

  return can;
}

function download(can) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  a.href = can.toDataURL();
  a.download = getNameForDownloadFile(graphName, range);
  a.click();
}

export function uplotDownloadImg(uplot: any, title: string, plotRange: UplotRange) {
  range = plotRange;
  graphName = title ?? 'none';
  u = uplot;

  const can = createCanvas();
  download(can);
}
