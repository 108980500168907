import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DashboardtemplateService as ApiTemplateDashboardService } from '../../../../api-main/api/dashboardtemplate.service';
import { ConfigService } from '../../../shared/services/config.service';
import { DashboardTemplateSaveDto, ListFilterRequestDto } from '../../../../api-main';
import { WaitService } from '../../../shared/services/wait.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardTemplateApiService {
  constructor(
    private http: HttpClient,
    private apiDashboardTemplateService: ApiTemplateDashboardService,
    private waitService: WaitService,
  ) {
    this.apiDashboardTemplateService = new ApiTemplateDashboardService(
      this.http,
      ConfigService.getApiConfig(),
      undefined,
    );
  }

  get(id: number) {
    return this.apiDashboardTemplateService.dashboardTemplateControllerGet(id);
  }

  save(dashboard: DashboardTemplateSaveDto) {
    return this.apiDashboardTemplateService.dashboardTemplateControllerSave(dashboard);
  }

  list(filter: ListFilterRequestDto = null) {
    if (filter == null)
      filter = {
        page_index: 0,
        page_size: 100,
        sort_direction: 'asc',
      };
    return this.apiDashboardTemplateService.dashboardTemplateControllerList(filter);
  }

  delete(id: number) {
    return this.apiDashboardTemplateService.dashboardTemplateControllerDelete(id);
  }
}
