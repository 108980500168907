<div class="modal-container">
  <header>
    <h1>{{ (customTitle ? customTitle : 'DIALOG.SHARED.DELETE_TITLE') | translate }}</h1>
  </header>

  <!--  <mat-divider></mat-divider>-->
  <div class="modal-body">
    <div *ngIf="!!name">
      <span class="notification">{{ (customBaseLine ? customBaseLine : 'DIALOG.SHARED.DELETE_DESC') | translate }} </span>
      <span class="item-name selectable">{{ this.name }}</span>
    </div>
    <span *ngIf="!name">
      {{ (customBaseLine ? customBaseLine : 'DIALOG.SHARED.DELETE_DESC_WITHOUT_NAME') | translate }}?
    </span>

    <p *ngIf="customLine">{{ customLine | translate }}</p>

    <div class="confirm" *ngIf="confirmDeletion">
      <span>{{ 'DIALOG.WRITE' | translate | lowercase }} </span>
      <span class="item-name selectable">{{ valueToConfirm }}</span>
      <span> {{ 'DIALOG.TO_CONFIRM' | translate | lowercase }} </span>
      <input [(ngModel)]="confirmInputValue" type="text" />
    </div>
  </div>
  <!--  <mat-divider></mat-divider>-->

  <footer>
    <mat-dialog-actions>
      <button class="button-secondary" mat-flat-button (click)="onCancel()" data-cy="cancel_delete">
        {{ 'CREATE_DIALOG.BUTTONS.CANCEL' | translate }}
      </button>

      <button
        mat-flat-button
        class="button-warn"
        cdkFocusInitial
        (keydown.enter)="$event.preventDefault()"
        (click)="onDelete()"
        [disabled]="!isDeleteEnabled()"
        data-cy="confirm-delete"
      >
        <span *ngIf="counterValue === null">
          {{ (customDeleteButtonLabel ? customDeleteButtonLabel : 'BUTTON.DELETE') | translate }}
        </span>
        <span *ngIf="counterValue !== null">{{ 'WAIT' | translate }} {{ counterValue }}s</span>
      </button>
    </mat-dialog-actions>
  </footer>
</div>
