<div>
  <a *ngFor="let doc of documents" href="{{ doc.url }}" download>
    <p>
      <mat-icon>description</mat-icon>
      <span>{{ doc.name | translate }}</span>
    </p>
  </a>
</div>

<mat-checkbox [(ngModel)]="signupData.conditionsAccepted">{{
  'SIGNUP.ACCEPT_TERMS_AND_CONDITIONS' | translate
}}</mat-checkbox>
