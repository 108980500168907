import { NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from '../services/PaginatorI18n.service';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
} from '@angular/material-moment-adapter';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { IconModule } from '../../../assets-common/modules/icon.module';
//its imported in main.ts
@NgModule({
  imports: [IconModule],
  providers: [
    { provide: MatPaginatorIntl, useClass: PaginatorI18n },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        disableClose: false,
        hasBackdrop: true,
        width: 'auto',
      } as MatDialogConfig,
    },
    //mat-datepicker
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  ],
  declarations: [],
})
export class MaterialModule {}
