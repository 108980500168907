import { Subject, takeUntil, takeWhile, timer } from 'rxjs';

export class Counter {
  public value: number = null;

  private initialValue: number = null;

  private readonly oneSecond = 1e3;

  private unsubscribe = new Subject<void>();

  constructor(initialValue: number = 5) {
    this.initialValue = initialValue;
    this.restartCounter();

    return this;
  }

  public restartCounter(value: number = this.initialValue) {
    this.unsubscribe.next();
    this.value = value;
    this.startCounter();
  }

  private startCounter() {
    timer(this.oneSecond, this.oneSecond)
      .pipe(takeUntil(this.unsubscribe))
      .pipe(takeWhile(() => this.value > 1))
      .subscribe({
        next: () => {
          this.value--;
        },
        complete: () => {
          this.value = null;
        },
      });
  }
}
