import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TimestampModel } from '../../timestamp-info/timestamp-info.component';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { TranslateModule } from '@ngx-translate/core';
import { DisabledDirective } from '../../../directives/disabled.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { SkeletonComponent } from '../../skeleton/skeleton.component';
import { LanguageButtonComponent } from '../../language-button/language-button.component';
import { NgClass, NgIf } from '@angular/common';
import { CdkTrapFocus } from '@angular/cdk/a11y';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    LanguageButtonComponent,
    SkeletonComponent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    DisabledDirective,
    TranslateModule,
    NgClass,
    CdkTrapFocus,
  ],
})
export class ModalComponent {
  @Input() title: string;

  @Input() showLanguageButton: boolean = false;

  @Input() saveButtonName: string = 'DIALOG_SAVE';

  @Input() saveButtonExists: boolean = true;

  @Input() cancelButtonName: string = 'CREATE_DIALOG.BUTTONS.CANCEL';

  @Input() cancelButtonExists: boolean = true;

  @Input() isDataLoaded: boolean = true;

  @Input() shouldSaveBeDisabled: boolean = false;

  @Output() save = new EventEmitter<string>();

  @Output() cancel = new EventEmitter<string>();

  @Input() timestamp: TimestampModel;

  _customCancelBehavior = false;

  @Input() set customCancelBehavior(value: any) {
    this._customCancelBehavior = coerceBooleanProperty(value);
  }

  onSave() {
    this.save.emit();
  }

  onCancel() {
    this.cancel.emit();
  }
}
