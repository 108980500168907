import { Component, Input } from '@angular/core';
import { DashboardItemType } from '../../models/DashboardItemType.model';
import { DashboardItem } from '../../models/DashboardItem';
import { DashboardValueDisplayComponent } from '../../widgets/dashboard-value-display/dashboard-value-display.component';
import { DashboardImageComponent } from '../../widgets/dashboard-image/dashboard-image.component';
import { DashboardTextComponent } from '../../widgets/dashboard-text/dashboard-text.component';
import { DashboardGraphComponent } from '../../widgets/dashboard-graph/dashboard-graph.component';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { DashboardMultipleValueDisplayComponent } from '../../widgets/dashboard-multiple-value-display/dashboard-multiple-value-display.component';

@Component({
  selector: 'app-dashboard-item-content',
  templateUrl: './dashboard-item-content.component.html',
  styleUrls: ['./dashboard-item-content.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    NgSwitchCase,
    DashboardGraphComponent,
    DashboardTextComponent,
    DashboardImageComponent,
    DashboardValueDisplayComponent,
    DashboardMultipleValueDisplayComponent,
  ],
})
export class DashboardItemContentComponent {
  @Input() item: DashboardItem;

  DashboardItemType = DashboardItemType;
}
