export type CfgUnit = { type: 'State' | 'Consumption'; unit: string; factor?: number };
export const cfgUnits: ReadonlyMap<number, CfgUnit> = new Map<number, CfgUnit>([
  [1, { type: 'Consumption', unit: 'Wh', factor: 1 }],
  [2, { type: 'Consumption', unit: 'kWh', factor: 1e3 }],
  [3, { type: 'Consumption', unit: 'MWh', factor: 1e6 }],
  [4, { type: 'Consumption', unit: 'GWh', factor: 1e9 }],
  [6, { type: 'Consumption', unit: 'kvarh', factor: 1 }],
  [7, { type: 'Consumption', unit: 'Mvarh', factor: 1e3 }],
  [8, { type: 'Consumption', unit: 'Gvarh', factor: 1e6 }],
  [9, { type: 'Consumption', unit: 'Vah', factor: 1 }],
  [10, { type: 'Consumption', unit: 'kVAh', factor: 1e3 }],
  [11, { type: 'Consumption', unit: 'MVAh', factor: 1e6 }],
  [12, { type: 'Consumption', unit: 'GVAh', factor: 1e9 }],
  [13, { type: 'State', unit: 'W', factor: 1 }],
  [14, { type: 'State', unit: 'kW', factor: 1e3 }],
  [15, { type: 'State', unit: 'MW', factor: 1e6 }],
  [16, { type: 'State', unit: 'GW', factor: 1e9 }],
  [17, { type: 'State', unit: 'var', factor: 1 }],
  [18, { type: 'Consumption', unit: 'kvar', factor: 1e3 }],
  [19, { type: 'Consumption', unit: 'Mvar', factor: 1e6 }],
  [20, { type: 'Consumption', unit: 'Gvar', factor: 1e9 }],
  [21, { type: 'State', unit: 'VA', factor: 1 }],
  [22, { type: 'State', unit: 'kVA', factor: 1e3 }],
  [23, { type: 'State', unit: 'MVA', factor: 1e6 }],
  [24, { type: 'State', unit: 'GVA', factor: 1e9 }],
  [25, { type: 'State', unit: 'mV', factor: 1 / 1e3 }],
  [26, { type: 'State', unit: 'V', factor: 1 }],
  [27, { type: 'State', unit: 'kV', factor: 1e3 }],
  [28, { type: 'State', unit: 'mA', factor: 1 / 1e3 }],
  [29, { type: 'State', unit: 'A', factor: 1 }],
  [30, { type: 'State', unit: 'kA', factor: 1e3 }],
  // [31, { type: null, unit: 'CosPhi' }],
  // [32, { type: null, unit: 'Hz' }],
  // [33, { type: null, unit: 'UTC' }],
  // [34, { type: null, unit: 's' }],
  // [35, { type: null, unit: 'min' }],
  // [36, { type: null, unit: 'h' }],
  [37, { type: 'State', unit: '°C' }],
  [38, { type: 'State', unit: '°F' }],
  [39, { type: 'State', unit: 'K' }],
  [40, { type: 'Consumption', unit: 'J', factor: 1 / 3600 }],
  [41, { type: 'Consumption', unit: 'kJ', factor: 1e3 / 3600 }],
  [42, { type: 'Consumption', unit: 'MJ', factor: 1e6 / 3600 }],
  [43, { type: 'State', unit: 'kg' }],
  [44, { type: 'State', unit: 'kg/l' }],
  [45, { type: 'State', unit: 'kg/s', factor: 1 }],
  [46, { type: 'State', unit: 'kg/min', factor: 60 }],
  [47, { type: 'State', unit: 'kg/h', factor: 60 * 60 }],
  [48, { type: 'State', unit: 'm/s' }],
  [49, { type: 'State', unit: 'dm/s' }],
  [50, { type: 'Consumption', unit: 'm³' }],
  [51, { type: 'State', unit: 'm³/s', factor: 1 }],
  [51, { type: 'State', unit: 'm³/m', factor: 60 }],
  [53, { type: 'State', unit: 'm³/h', factor: 60 * 60 }],
  [54, { type: 'Consumption', unit: 'l' }],
  [55, { type: 'State', unit: 'l/s', factor: 1 }],
  [56, { type: 'State', unit: 'l/min', factor: 60 }],
  [57, { type: 'State', unit: 'l/h', factor: 60 * 60 }],
  [58, { type: null, unit: 'Wh/kg*K' }],
  [59, { type: null, unit: 'kJ/kg*K' }],
  [60, { type: null, unit: 'RT/kW' }],
  [61, { type: null, unit: 'Wh/kg' }],
  [61, { type: null, unit: 'Wh/l*K' }],
  [63, { type: null, unit: 'mbar' }],
  [64, { type: null, unit: 'bar' }],
  [65, { type: null, unit: 'Bytes' }],
  [66, { type: null, unit: 'MB' }],
  [67, { type: null, unit: 'GB' }],
  [68, { type: null, unit: 'TB' }],
  [69, { type: null, unit: 'RH' }],
  [70, { type: null, unit: 'HCA' }],
  [71, { type: null, unit: 'Binary' }],
  [72, { type: null, unit: 'None' }],
  [73, { type: null, unit: '%' }],
]);

export function getCfgUnitIdByUnit(searchValue: string): CfgUnit & { id?: number } {
  for (let [key, value] of cfgUnits.entries()) {
    if (value.unit === searchValue) return { ...cfgUnits.get(key), id: key };
  }
  return null;
}
