import { ListFilterRequestDto } from '../../../../../api-main';

export function getLoggerListBody(description?: string): ListFilterRequestDto {
  return {
    filter: description ? [{ field: 'name', value: description }] : [],
    sort: [],
    page_size: 25,
    sort_direction: 'asc',
    page_index: 0,
  };
}

export function getLoggerListBodyById(id: number): ListFilterRequestDto {
  return {
    filter: [{ field: 'system_id', value: `${id}` }],
    sort: [],
    page_size: 25,
    sort_direction: 'asc',
    page_index: 0,
  };
}

export function getEmptyBody(): ListFilterRequestDto {
  return {
    filter: [],
    sort: [],
    page_size: 25,
    sort_direction: 'asc',
    page_index: 0,
  };
}
