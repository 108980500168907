import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../shared/services/config.service';
import {
  DataService,
  DataRequestDto,
  SingleDataRequestDto,
  SingleDataResponseDto,
} from '../../../api-main';
import { Observable } from 'rxjs';
import { UplotResponseData } from '../../shared/components/graph-uplot/graph/models/UplotResponseData.model';
import { LocaleSessionService } from '../../shared/services/localeSession.service';
import { formatUplotDateForRequest } from '../../shared/utils/formatDateForRequest';

@Injectable({
  providedIn: 'root',
})
export class DataServiceProvider {
  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private localeSession: LocaleSessionService,
  ) {
    this.dataService = new DataService(this.http, ConfigService.getApiConfig(), undefined);
  }

  getData(request: DataRequestDto): Observable<UplotResponseData> {
    request.min = formatUplotDateForRequest(request.min, this.localeSession);
    request.max = formatUplotDateForRequest(request.max, this.localeSession);

    return <Observable<UplotResponseData>>(<any>this.dataService.dataControllerList(request));
  }

  getSingleData(request: SingleDataRequestDto): Observable<SingleDataResponseDto[]> {
    return this.dataService.dataControllerGetSingleData(request);
  }
}
