import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import {
  catchError,
  debounceTime,
  finalize,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { GridsterConfig, GridsterComponent } from 'angular-gridster2';
import { DashboardItem } from '../models/DashboardItem';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardDto, OrganizationDto } from '../../../../api-main';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardPreviewService } from '../services/dashboard-preview.service';
import { DashboardItemComponent } from '../dashboard-item/dashboard-item.component';
import { ItemSettingsComponent } from './item-settings/item-settings.component';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { NgIf, NgFor, NgOptimizedImage, AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DateDisplayComponent } from '../../../shared/components/date-display/date-display.component';
import { PrivilegesService } from '../../../shared/services/privileges.service';
import { OrganizationService } from '../../organization/organization.service';
import { WaitService } from '../../../shared/services/wait.service';
import { PageLinksService } from '../../../layout/old/page-links-tree/page-links.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    DashboardHeaderComponent,
    ItemSettingsComponent,
    GridsterComponent,
    NgFor,
    DashboardItemComponent,
    NgOptimizedImage,
    MatIconModule,
    TranslateModule,
    DateDisplayComponent,
    AsyncPipe,
  ],
})
export class DashboardComponent implements OnDestroy, AfterViewInit {
  unsubscribe: Subject<void> = new Subject<void>();

  @Input() id: number;

  @Input() isInPortal: boolean = false;

  @Input() showHeader: boolean = true;

  loading = true;

  isPublic: boolean = false;

  private loadDashboard$ = new Subject<number>();

  get dashboard(): Array<DashboardItem> {
    return this.dashboardService.items;
  }

  get editMode(): boolean {
    return this.dashboardService.editMode$.value;
  }

  get options(): GridsterConfig {
    return this.dashboardService.options;
  }

  get noItemsMessage() {
    return !(this.dashboardService.items?.length > 0) && !this.editMode;
  }

  organization$: Observable<OrganizationDto> = of(null).pipe(
    switchMap(() => {
      if (this.privilegesService.organization()) return of(this.privilegesService.organization());
      if (this.organziationRequested) return timer(100).pipe(switchMap(() => this.organization$));
      this.organziationRequested = true;
      return this.organizationService.getState().pipe(
        switchMap((data) =>
          data.organization_id ? this.organizationService.get(data.organization_id) : of(null),
        ),
        tap((data) => {
          this.privilegesService.organization.set(data);
        }),
        finalize(() => (this.organziationRequested = false)),
      );
    }),
    takeUntil(this.unsubscribe),
  );

  private organziationRequested = false;

  constructor(
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private router: Router,
    private dashboardPreviewService: DashboardPreviewService,
    private pageLinksService: PageLinksService,
    private privilegesService: PrivilegesService,
    private organizationService: OrganizationService,
    private waitService: WaitService,
  ) {
    this.isPublic = !!route.snapshot.data['public'];
    this.dashboardService.dashboardIsDownloading.set(false);
    this.initDashboardLoadingSubscription();
    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
      const id = params['id'];
      this.id = id;
      this.loadNewDashboard(id);
    });

    this.dashboardService.editMode$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.savePreview(0));
  }

  ngAfterViewInit() {
    if (this.id) this.loadNewDashboard(this.id);
  }

  private loadNewDashboard(id: number) {
    this.dashboardService.leavingCurrentDashboard$.next();
    this.loadDashboard$.next(id);
  }

  //Save preview to see dashboard in dashboard-management (does screenshot of dashboard)
  private savePreview(timeout = 4000) {
    timer(timeout)
      .pipe(take(1))
      .pipe(
        switchMap(() => {
          const container = <HTMLElement>document.querySelector('.dashboard-container');
          return this.dashboardPreviewService.saveRender(this.id, container);
        }),
      )
      .pipe(takeUntil(this.dashboardService.leavingCurrentDashboard$), takeUntil(this.unsubscribe))
      .subscribe();
  }

  private initDashboardLoadingSubscription() {
    this.loadDashboard$
      .asObservable()
      .pipe(
        tap(() => {
          this.waitService.start();
          this.loading = true;
          this.dashboardService.clearSelectedItem();
          setTimeout(() => {
            if (!this.isInPortal) this.pageLinksService.setPageName('');
          }, 0);
        }),
        switchMap((id) =>
          // this.editMode ? this.dashboardService.saveDashboard().pipe(map(() => id)) : of(id),
          this.editMode
            ? this.dashboardService.restoreDashboard().pipe(
                tap(() => this.dashboardService.editMode$.next(false)),
                map(() => id),
              )
            : of(id),
        ),
      )
      .pipe(
        debounceTime(100),
        switchMap((id: number) =>
          this.dashboardService.getDashboard(id).pipe(
            catchError(() => {
              return of(null);
            }),
          ),
        ),
        finalize(() => this.waitService.stop),
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (dashboard: DashboardDto) => {
          this.waitService.stop();
          this.loading = false;
          // if (!dashboard) return;
          this.dashboardService.setDashboard(dashboard);

          if (!this.isInPortal) {
            this.savePreview();
            this.pageLinksService.setPageName(dashboard?.name);
          }
        },
        error: () => {
          // this.router.navigate(['dashboards']).catch(console.error);
        },
      });
  }

  async ngOnDestroy() {
    this.dashboardService.leavingCurrentDashboard$.next();
    this.dashboardService.clearDashboard();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected readonly Date = Date;
}
