interface CompanyInfoInputs {
  displayName: Uppercase<string>;
  name: string;
  autocompleteTag: string;
  type?: 'number';
}
export const companyInfoInputs: Array<CompanyInfoInputs> = [
  {
    displayName: 'ORGANIZATION.COMPANY_NAME',
    name: 'companyName',
    autocompleteTag: 'organization',
  },
  {
    displayName: 'ORGANIZATION.STREET',
    name: 'street',
    autocompleteTag: 'address-level3',
  },
  {
    displayName: 'ORGANIZATION.HOUSE_NUMBER',
    name: 'houseNumber',
    autocompleteTag: 'address-level4',
  },
  {
    displayName: 'ORGANIZATION.ZIP',
    name: 'zip',
    autocompleteTag: 'postal-code',
  },
  {
    displayName: 'ORGANIZATION.CITY',
    name: 'city',
    autocompleteTag: 'address-level2',
  },
  {
    displayName: 'ORGANIZATION.COUNTRY',
    name: 'country',
    autocompleteTag: 'country-name',
  },
  {
    displayName: 'ORGANIZATION.VAT_NUMBER',
    name: 'vatNumber',
    autocompleteTag: 'off',
  },
  {
    displayName: 'ORGANIZATION.EMAIL_FOR_INVOICE',
    name: 'emailForInvoice',
    autocompleteTag: 'email',
  },
  {
    displayName: 'ORGANIZATION.ACCOUNT',
    name: 'account',
    autocompleteTag: 'off',
  },
  {
    displayName: 'ORGANIZATION.PAYMENT_TERM',
    name: 'paymentTerm',
    autocompleteTag: 'off',
    type: 'number',
  },
];
