/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MetadataKeys {
  /**
   * Key of metadata
   */
  key: string;
  /**
   * id
   */
  id?: number;
  /**
   * metadata_id
   */
  metadata_id: number;
  /**
   * Key of metadata
   */
  type: MetadataKeys.TypeEnum;
  /**
   * MetadataSource
   */
  preferred_source?: MetadataKeys.PreferredSourceEnum;
  /**
   * visible
   */
  visible?: boolean;
  /**
   * visible
   */
  inherit?: boolean;
}
export namespace MetadataKeys {
  export type TypeEnum =
    | 'string'
    | 'location'
    | 'number'
    | 'date'
    | 'date_range'
    | 'tariff'
    | 'boolean'
    | 'config_iot';
  export const TypeEnum = {
    String: 'string' as TypeEnum,
    Location: 'location' as TypeEnum,
    Number: 'number' as TypeEnum,
    Date: 'date' as TypeEnum,
    DateRange: 'date_range' as TypeEnum,
    Tariff: 'tariff' as TypeEnum,
    Boolean: 'boolean' as TypeEnum,
    ConfigIot: 'config_iot' as TypeEnum,
  };
  export type PreferredSourceEnum = 'INHERITED' | 'DEVICE' | 'DIRECT';
  export const PreferredSourceEnum = {
    INHERITED: 'INHERITED' as PreferredSourceEnum,
    DEVICE: 'DEVICE' as PreferredSourceEnum,
    DIRECT: 'DIRECT' as PreferredSourceEnum,
  };
}
