import { Component, OnInit } from '@angular/core';
import { DashboardItemSettingsComponent } from '../dashboard-item-settings.component';
import { DashboardItemDataImage } from '../../models/DashboardItemDataImage.model';
import { TranslateModule } from '@ngx-translate/core';
import { MatSliderModule } from '@angular/material/slider';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EmuInputComponent } from '../../../../shared/components/inputs/emu-input/emu-input.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-dashboard-image-settings',
  templateUrl: './dashboard-image-settings.component.html',
  styleUrls: ['../../styles/widget-settings.scss', './dashboard-image-settings.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    EmuInputComponent,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatSliderModule,
    TranslateModule,
  ],
})
export class DashboardImageSettingsComponent
  extends DashboardItemSettingsComponent
  implements OnInit
{
  override setDefaultStyling() {}

  get maxRadius(): number {
    return 100;
  }

  override get data(): DashboardItemDataImage {
    return this.itemData?.image;
  }

  protected override setDefault() {
    if (this.data == undefined)
      this.itemData.image = {
        url: '',
        borderRadius: 0,
      };
  }

  formatLabel(value: number): string {
    return `${value}px`;
  }
}
