import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageLinksService {
  #pageName$ = new BehaviorSubject<string>('');

  public pageName$: Observable<string> = this.#pageName$.asObservable().pipe(debounceTime(50));

  getPageName() {
    return this.#pageName$.value;
  }

  setPageName(pageName: string) {
    this.#pageName$.next(pageName);
  }
}
