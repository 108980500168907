<app-modal
  [title]="'ORGANIZATION.NO_ORGANIZATION' | translate"
  [saveButtonExists]="false"
  [cancelButtonExists]="false"
  [isDataLoaded]="true"
>
  <ng-container body>
    <mat-dialog-content>
      <!--      <p>It looks like you don't have access to any organization yet.</p>-->
      <!--      <p>To use application create your own organization or enter invitation code.</p>-->

      <p>{{ 'ORGANIZATION.NO_ORGANIZATION_ACCESS_INFO' | translate }}</p>
      <p>{{ 'ORGANIZATION.NO_ORGANIZATION_CREATE_NEW' | translate }}</p>
      <p>{{ 'ORGANIZATION.ADD_CODE_LATER' | translate }}</p>

      <mat-divider></mat-divider>

      <div class="links">
        <a (click)="close()" [routerLink]="'/organization'" [queryParams]="{ add: '' }">{{
          'ORGANIZATION.CREATE_ORGANIZATION' | translate
        }}</a>
        <a href="#" onclick="return false;" (click)="close(); insertInvitationCode()">{{
          'ORGANIZATION.ENTER_INVITATION_CODE' | translate
        }}</a>
      </div>
    </mat-dialog-content>
  </ng-container>

  <!--  <div class="bottom-button" footer>-->
  <!--    <button class="button-secondary" mat-button (click)="selectNoneOrganization()"-->
  <!--    >{{'ORGANIZATION.DONT_USE_ANY' | translate}}</button>-->
  <!--  </div>-->
</app-modal>
