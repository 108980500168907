/**
 * EMU API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProfileIdentityDto {
  /**
   * Date and time when this user was created (ISO_8601 format).
   */
  connection: string;
  /**
   * User identification string
   */
  user_id: string;
  /**
   * Provider
   */
  provider: string;
  /**
   * Is social
   */
  is_social: boolean;
}
