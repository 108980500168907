import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LangInterceptor implements HttpInterceptor {
  constructor(private translate: TranslateService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.url;
    if (blackList.some((key) => url.includes(key))) return next.handle(request);

    const language =
      this.translate.currentLang != undefined
        ? this.translate.currentLang
        : this.translate.getBrowserLang();
    request = request.clone({
      headers: request.headers.set('Accept-Language', language).set('X-Accept-Language', language),
    });
    return next.handle(request);
  }
}

const blackList = ['auth0'];
