import { UplotData } from '../models/UplotData.model';
import { UplotRange } from '../models/UplotRange.model';
export function getFilteredData(data: UplotData, range: UplotRange): UplotData {
  const newArr = [];

  for (let i = 0; i < data[0].length; i++) {
    if (Number(data[0][i]) >= range.min && Number(data[0][i]) < range.max) {
      data.forEach((item, index) => {
        if (!newArr[index]) newArr[index] = [];
        newArr[index].push(item[i]);
      });
    }
  }
  return newArr;
}
