import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ConfigService } from '../../shared/services/config.service';
import { NgIf, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-loading-circle',
  templateUrl: './loading-circle.component.html',
  styleUrls: ['./loading-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgOptimizedImage],
})
export class LoadingCircleComponent {
  constructor(private cdr: ChangeDetectorRef) {}

  oemId: string = ConfigService.getIdConfig();

  @Input() MIN_DISPLAY_TIME: number = 1500;

  private startTime: number;

  private _display: boolean = true;

  @Input() set display(value: boolean) {
    if (value == true) {
      this.startTime = new Date().getTime();
      this._display = true;
    } else {
      // if(this.startTime)
      const timeAfterStart = new Date().getTime() - this.startTime;

      if (isNaN(timeAfterStart) || timeAfterStart < 0)
        this.setDisplayAfterXSec(false, this.MIN_DISPLAY_TIME);
      else if (timeAfterStart > this.MIN_DISPLAY_TIME) this._display = false;
      else this.setDisplayAfterXSec(false, this.MIN_DISPLAY_TIME - timeAfterStart);

      this.cdr.detectChanges();
    }
  }

  setDisplayAfterXSec(value: boolean, delay: number) {
    setTimeout(() => {
      this._display = value;
      this.cdr.detectChanges();
    }, delay);
  }

  get display(): boolean {
    return this._display;
  }
}
