import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { DictionaryService } from '../../services/dictionary.service';
import { LocaleSessionService } from '../../services/localeSession.service';
import { ProfileService } from '../../services/profile.service';
import { NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'app-language-button',
  templateUrl: './language-button.component.html',
  styleUrls: ['./language-button.component.scss'],
  standalone: true,
  imports: [DropdownComponent, MatButtonModule, MatIconModule, NgFor, TranslateModule],
})
export class LanguageButtonComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean;

  unsubscribe: Subject<void> = new Subject<void>();

  langList: string[] = [];

  @Input() position: 'left' | 'right' = 'left';

  constructor(
    private http: HttpClient,
    public translate: TranslateService,
    private auth: AuthService,
    private profileService: ProfileService,
    private dictionaryService: DictionaryService,
    private localeSession: LocaleSessionService,
  ) {}

  ngOnInit(): void {
    this.auth.isAuthenticated$.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.isAuthenticated = res;
    });
    this.dictionaryService
      .getLanguageList()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (result) => {
          this.langList = result.map((langArray) => langArray.lng);
        },
      });
  }

  changeLanguage(lang: string) {
    this.localeSession.changeLanguage(lang);
    if (this.isAuthenticated) {
      this.profileService.setLang({ lang }).subscribe();
    }
  }

  isSelected(lang: string): boolean {
    return this.localeSession.lang == lang;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
