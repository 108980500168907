import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
} from '@angular/core';
import { TreeNode } from '../models/TreeNode';
import {
  catchError,
  filter,
  map,
  Observable,
  of,
  retry,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { TreeNodeService } from '../TreeNodeService';
import { TreeNodeActionType } from '../models/TreeNodeActionType';
import { TreeNodeRefreshOptions } from '../models/TreeNodeRefreshOptions';
import { TreeNodeMutateOptions } from '../models/TreeNodeMutateOptions';
import { sortTreeNodes } from '../utils/sortTreeNodes';
import { addNodeToCache, removeFromCache } from './CacheHandle';
import { PresetTreeElementsService } from '../preset-tree-elements.service';
import { getNodeUrl } from './getNodeUrl';
import { TreeService } from '../../../tree.service';
import { TranslateModule } from '@ngx-translate/core';
import { SimpleLoadingComponent } from '../../../../../shared/components/simple-loading/simple-loading.component';
import { TreeNodePermissionComponent } from './tree-node-permission/tree-node-permission.component';
import { TreeNodeActionsComponent } from './tree-node-actions/tree-node-actions.component';
import { PreventClickPropagationDirective } from '../../../../../shared/directives/prevent-click-propagation.directive';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { PrivilegesService } from '../../../../../shared/services/privileges.service';
import { MatTableModule } from '@angular/material/table';
import { DashboardPreviewComponent } from '../../../../dashboard/dashboards-management/dashboard-preview/dashboard-preview.component';
import { LayoutService } from '../../../../../layout/layout.service';
import { IconModule } from '../../../../../../assets-common/modules/icon.module';
import { UserGuideDirective } from '../../../../../shared/app-support/user-guide/user-guide.directive';

@Component({
  selector: 'app-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatRippleModule,
    MatTooltipModule,
    RouterLink,
    MatIconModule,
    PreventClickPropagationDirective,
    TreeNodeActionsComponent,
    TreeNodePermissionComponent,
    SimpleLoadingComponent,
    AsyncPipe,
    TranslateModule,
    MatTableModule,
    DashboardPreviewComponent,
    IconModule,
    UserGuideDirective,
  ],
})
export class TreeNodeComponent implements OnDestroy {
  @Input() new = false;

  @Input() set data(data: { node: TreeNode; treeNodeService: TreeNodeService }) {
    if (this.isDeleted || !data || !data.node || !data.treeNodeService) return;
    this.node = data.node;
    this.treeNodeService = data.treeNodeService;
    this.nodeAndOptionsSet();
  }

  temporaryChildren: TreeNode[] = [];

  children$: Observable<TreeNode[]>;

  expanded: boolean = false;

  node: TreeNode;

  treeNodeService: TreeNodeService;

  childrenLoading: boolean = false;

  private isDeleted: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private treeService: TreeService,
    private cd: ChangeDetectorRef,
    private presetElements: PresetTreeElementsService,
    private privilegesService: PrivilegesService,
    private layoutService: LayoutService,
    private iconModule: IconModule,
  ) {}

  @HostBinding('class.visible')
  get display(): boolean {
    if (this.isDeleted) return false;
    if (!this.node || !this.treeNodeService) return false;

    const filterOptions = this.treeNodeService.filterOptions;

    if (filterOptions) {
      if (filterOptions.dontDisplayEmptyFolders && this.isFolder() && !this.hasChildren())
        return false;
      if (filterOptions.filterIds && !filterOptions.visibleIds?.includes(this.node.id))
        return false;
    }
    if (this.node.presetChildren && this.node.is_authorized == false) return false;
    return true;
  }

  get hidden() {
    return false; //this.node.isPreSetElement && this.childrenLoading && !this.node.children_count;
  }

  get blocked(): boolean {
    const filterOptions = this.treeNodeService.filterOptions;

    if (
      filterOptions.typeFilter != null &&
      !filterOptions.typeFilter.includes(this.node.item_type_code)
    ) {
      return true;
    }

    if (filterOptions.parentFilter != null && filterOptions.parentFilter != this.node.parent_id) {
      return true;
    }

    if (filterOptions.idFilter != null && !filterOptions.idFilter.includes(this.node.id)) {
      return true;
    }
    //item move blocks

    //root element only for admin
    if (this.isRootElement() && this.privilegesService.isOrganizationAdmin()) return false;

    if (
      (this.showOverlay() &&
        !this.isMovingItem() &&
        filterOptions.moveNodeDestinations != null &&
        !filterOptions.moveNodeDestinations.includes(this.node.id)) ||
      this.treeNodeService.config.moveHandling
    )
      return true;

    return false;
  }

  isTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  isRootElement(): boolean {
    return this.node.id == 0;
  }

  showOverlay() {
    return this.isMoveMode() || this.treeNodeService.filterOptions.showOverlay === true;
  }

  isMoveMode() {
    return this.treeNodeService.filterOptions.moveNodeFilter != null;
  }

  isMovingItem() {
    return this.treeNodeService?.filterOptions?.moveNodeFilter?.id == this.node.id;
  }

  displayRoles() {
    return this.treeNodeService?.config?.displayRoles == true;
  }

  hoveredDashboardId = null;

  onMouseIn() {
    if (this.blocked) return;
    if (this.node.item_type_code == 'DASHBOARD') this.hoveredDashboardId = this.node.item_id;
    this.updateView();
  }

  onMouseOut() {
    this.hoveredDashboardId = null;
    this.updateView();
  }

  getUrl(): string {
    return getNodeUrl(this.node);
    // return this.node.pointingUrl ?? 'tree/inspect';
  }

  getMoveTooltipValue(): string {
    if (!this.showOverlay()) return null;

    const moveItemParentId = this.treeNodeService?.filterOptions?.moveNodeFilter?.parent_id;
    const isParentOfMovingItem =
      moveItemParentId == this.node.id ||
      (moveItemParentId == null && this.node.id == this.presetElements.rootId);

    if (this.isMovingItem() || isParentOfMovingItem) return 'BUTTON.CANCEL';
    return 'TREE.MOVE_HERE';
  }

  isItem(): boolean {
    return this.node.item_id != null;
  }

  isFolder(): boolean {
    return this.node.item_type_code == 'FOLDER';
  }

  isPermissionNode(): boolean {
    return this.node.item_type_code == 'FOLDER';
  }

  hasChildren(): boolean {
    if (!this.node) return false;
    if (isNaN(this.node?.children_count)) {
      this.node.children_count = undefined;
    }
    return (
      this.node?.children_count > 0 ||
      (this.node?.children_count == undefined && this.node?.is_end_type == false)
    );
  }

  hasParent(): boolean {
    return this.node.parent_id != null;
  }

  isAuthorized(): boolean {
    return true; //this.node?.is_authorized;
  }

  isNodeWithNoAccessHidden(): boolean {
    return this.treeNodeService.config.hideNodesWithNoAccess;
  }

  hasRole(): boolean {
    if (!this.treeNodeService?.config?.roleItems) return false;
    return (
      this.treeNodeService.config.roleItems
        .filter((o) => !!o.role)
        .find((o) => o.item_tree_id == this.node.id)?.role != null
    );
  }

  isNew(): boolean {
    if (this.new) return true;

    //It's also considered new if its logger which was not setup yet
    return TreeService.isLoggerNode(this.node.item_type_code) && !this.node.item_id;
  }

  isHighlighted(): boolean {
    return this.treeNodeService?.config?.highlightedNodes?.has(+this.node.id);
  }

  areNodeHoverDisplayed() {
    return (
      this.treeNodeService?.config?.selectNodes == true ||
      this.treeNodeService?.filterOptions?.showOverlay == true
    );
  }

  nodeClicked() {
    if (this.blocked) return;
    this.new = false;
    if (!this.treeNodeService.nodeAction$ || !this.isAuthorized()) return;
    this.nodeAction(TreeNodeActionType.ON_CLICK);
  }

  toggleExpand(force: boolean = null) {
    if (this.isRootElement()) {
      this.treeNodeService.expandedNodes.add(this.node.id);
      return;
    }
    if (!this.hasChildren()) return;

    this.expanded = force == null ? !this.expanded : force;
    if (this.expanded) this.treeNodeService.expandedNodes.add(this.node.id);
    else {
      this.treeNodeService.expandedNodes.delete(this.node.id);
      this.treeNodeService.expandAll$.next(null);
    }

    this.nodeAction(TreeNodeActionType.ON_EXPAND);
    this.updateView();
  }

  private resetTemporaryChildren() {
    this.temporaryChildren = [];
  }

  private tryLoadFromCache(callValue: any): Observable<TreeNode[]> {
    const childrenNodes = this.treeNodeService.cachedNodes.filter(
      (o) => o.parent_id == this.node.id,
    );

    const makeApiCall =
      this.node.children_count == undefined ||
      this.node.children_count != childrenNodes.length ||
      callValue != null;

    // console.log('-> makeApiCall', makeApiCall, this.node);
    if (makeApiCall) {
      if (this.node.presetChildren) return this.node.presetChildren;
      return this.treeService
        .getTreeList({ parent_id: this.node.id })
        .pipe(map((response: any) => response.data));
    }

    return of(childrenNodes);
  }

  private setUpChildrenRequest() {
    this.children$ = this.treeNodeService.refresh$.pipe(
      filter(
        (refresh) =>
          refresh.mode == TreeNodeRefreshOptions.CHILDREN && refresh.nodeId == this.node.id,
      ),
      startWith(null),
      tap(() => {
        this.childrenLoading = true;
        this.updateView();
      }),
      switchMap((callValue) => this.tryLoadFromCache(callValue)),
      map((nodes) => sortTreeNodes(nodes)),
      tap((nodes) => {
        this.node.children_count = nodes?.length ?? 0;
      }), //set children length
      retry({ count: 1, delay: () => timer(1000) }), //retry twice on error
      tap(() => {
        this.resetTemporaryChildren();
        this.childrenLoading = false;
        this.updateView();
      }),
      catchError((error: any) => {
        console.error(`Failed to get ${this.node.item_name} children:`, error);
        return of(null);
      }),
    );

    //handle updating children if there is none
    this.treeNodeService.refresh$
      .pipe(
        filter(
          (refresh) =>
            !this.hasChildren() &&
            refresh.mode == TreeNodeRefreshOptions.CHILDREN &&
            refresh.nodeId == this.node.id,
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.node.children_count = undefined;
        this.refreshSelf();
        // if (this.treeNodeService.config?.expandAfterAddChild) this.toggleExpand(true);
        this.updateView();
      });
  }

  private setExpanded() {
    if (!this.hasChildren()) {
      this.expanded = false;
    } else this.expanded = this.treeNodeService.expandedNodes.has(this.node.id);
  }

  private setupObservables() {
    this.setupObservables = () => {};
    this.treeNodeService.refresh$.pipe(takeUntil(this.unsubscribe$)).subscribe((refresh) => {
      if (refresh.nodeId == null) refresh.nodeId = 0;
      switch (refresh.mode) {
        case TreeNodeRefreshOptions.NODE:
          if (
            refresh.nodeId == this.node.id ||
            (refresh.itemId != null && refresh.itemId == this.node.item_id)
          )
            this.refreshSelf();

          break;
        case TreeNodeRefreshOptions.VIEW:
          this.updateView();
          break;
        case TreeNodeRefreshOptions.UPDATE_CHILDREN_COUNT:
          if (refresh.nodeId == this.node.id) this.node.children_count += refresh.value;
          this.updateView();
          break;
        case TreeNodeRefreshOptions.FORCE_CLICK:
          if (refresh.nodeId != this.node.id) return;
          this.nodeClicked();
          this.updateView();
          break;
        case TreeNodeRefreshOptions.TOGGLE_EXPAND:
          if (refresh.nodeId != this.node.id) return;
          this.toggleExpand(refresh.value);
          this.updateView();
          break;
        // case TreeNodeRefreshOptions.ALL:
        //   this.checkPresetElementChildren();
        //   break;
      }
    });

    this.treeNodeService.mutateNode$.pipe(takeUntil(this.unsubscribe$)).subscribe((mutation) => {
      if (mutation.node.parent_id == null) mutation.node.parent_id = 0; //handle root element
      switch (mutation.mode) {
        case TreeNodeMutateOptions.ADD:
          if (mutation.node.parent_id !== this.node.id) return;
          this.addTemporaryChild(mutation.node);
          break;
        case TreeNodeMutateOptions.UPDATE:
          if (mutation.node.id !== this.node.id) return;
          Object.keys(this.node).forEach((key) => {
            let mutationValue = mutation.node[key as string];
            if (mutationValue !== undefined) this.node[key as string] = mutationValue;
          });
          this.updateView();
          addNodeToCache(this.node, this.treeNodeService);
          this.nodeAction(TreeNodeActionType.ON_INIT);
          break;
        case TreeNodeMutateOptions.DELETE:
          if (mutation.node.id !== this.node.id) return;
          this.deleteThisNode();
          break;
      }
    });

    this.treeNodeService.expandAll$
      .pipe(
        filter((val) => val != null),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((value) => {
        this.toggleExpand(value);
      });
  }

  private deleteThisNode() {
    this.isDeleted = true;
    this.hoveredDashboardId = null;
    removeFromCache(this.node, this.treeNodeService);
    this.unselectNode();

    // if (TreeService.isLoggerNode(this.node.item_type_code)) {
    //   this.treeNodeService.refresh(TreeNodeRefreshOptions.LOGGER_LIST);
    //   this.layoutService?.treeNodeService.refresh(TreeNodeRefreshOptions.CHILDREN, {
    //     nodeId: 'loggers',
    //   });
    // }

    if (this.node.parent_id)
      this.treeNodeService.refresh(TreeNodeRefreshOptions.UPDATE_CHILDREN_COUNT, {
        nodeId: this.node.parent_id,
        value: -1,
      });

    this.ngOnDestroy();
    this.updateView();
  }

  private addTemporaryChild(node: TreeNode) {
    if (!this.hasChildren()) {
      this.node.children_count += 1;
      if (this.treeNodeService.config?.expandAfterAddChild) this.toggleExpand(true);
      this.updateView();
      this.treeNodeService.nodeAction$.next({ node: node, action: TreeNodeActionType.ON_CLICK });
      return;
    }

    this.treeService
      .getTreeList({ id: node.id })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        node = res.data[0];
        this.node.children_count += 1;
        this.temporaryChildren.push(node);
        if (this.treeNodeService.config?.expandAfterAddChild) this.toggleExpand(true);
        this.updateView();
        // Redirect user to new created item
        // this.nodeAction(TreeNodeActionType.ON_CLICK);
        this.treeNodeService.nodeAction$.next({ node: node, action: TreeNodeActionType.ON_CLICK });
      });
  }

  private refreshSelf() {
    if (this.node.isPreSetElement) return;
    this.treeService
      .getTreeList({ id: this.node.id })
      .pipe(
        map((response) => response.data[0]),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((node) => {
        if (node == null) {
          this.deleteThisNode();
          return;
        }
        this.node = { ...this.node, ...node };
        this.nodeAction(TreeNodeActionType.ON_INIT);
        this.updateView();
        // if (this.node.children_count > 0) this.refreshChildren$.next();
      });
  }

  private updateView() {
    this.setExpanded();
    this.cd.detectChanges();
  }

  private unsetUnauthorized() {
    if (this.node.id == this.treeNodeService.selectedNodeId$.value && !this.isAuthorized())
      this.unselectNode();
  }

  private unselectNode() {
    if (this.treeNodeService.selectedNodeId$.value !== this.node.id) return;
    this.treeNodeService.selectedNodeId$.next(null);
    this.treeNodeService.nodeAction$.next({ node: null, action: TreeNodeActionType.ON_CLICK });
  }

  private nodeAndOptionsSet() {
    addNodeToCache(this.node, this.treeNodeService);
    this.setupExpandValues();
    this.setupObservables();
    this.unsetUnauthorized();
    this.resetTemporaryChildren();
    this.setUpChildrenRequest();
    this.nodeAction(TreeNodeActionType.ON_INIT);
    this.updateView();
    if (this.isRootElement()) this.toggleExpand(true);
    // this.checkPresetElementChildren();
  }

  private setupExpandValues() {
    this.treeNodeService.expandableNodes.delete(this.node.id);
    if (this.hasChildren()) this.treeNodeService.expandableNodes.add(this.node.id);
  }

  private nodeAction(action: TreeNodeActionType) {
    this.treeNodeService.nodeAction$.next({ node: this.node, action });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.node) this.treeNodeService.expandableNodes.delete(this.node.id);
    this.node = null;
  }

  getIcon(icon: string): string {
    // Check if icon exists in our list
    const exists: boolean = this.iconModule.icons.includes(icon);
    if (!exists) return 'file';
    return icon;
  }

  isNodeSelected() {
    return this.treeNodeService?.selectedNodeId$.value == this.node.id;
  }

  isParentItemSelected(): boolean {
    //Its for displaying connected devices - in device tab  in logger
    if (this.node.item_type_code != 'DEVICE') return false;
    return this.treeNodeService?.config?.selectHighlightDevices?.has(+this.node.item_id);
  }
}
