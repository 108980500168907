import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { DataDisplayDefaultRanges } from '../../../../models/DataDisplayDefaultRanges';
import { getRangeFromDataDisplayDefaultRanges } from '../../../../utils/getRangeFromDataDisplayDefaultRanges';

export function userDefinedRangePlugin(
  userDefinedRange$: BehaviorSubject<DataDisplayDefaultRanges>,
) {
  let range;
  const unsubscribe = new Subject<void>();
  return {
    hooks: {
      init: (u) => {
        userDefinedRange$.pipe(takeUntil(unsubscribe)).subscribe((value) => {
          if (value === DataDisplayDefaultRanges.CUSTOM) return;

          range = getRangeFromDataDisplayDefaultRanges(value);
          u.setScale('x', {
            min: range.min,
            max: range.max,
          });
        });
      },
      destroy: () => {
        unsubscribe.next();
        unsubscribe.complete();
      },
      setScale: (u) => {
        if (range == undefined) return;
        const scales = u.scales.x;

        const maxDifference = 60;
        if (
          Math.abs(scales.min - range.min) > maxDifference ||
          Math.abs(scales.max - range.max) > maxDifference
        )
          userDefinedRange$.next(DataDisplayDefaultRanges.CUSTOM);
      },
    },
  };
}
