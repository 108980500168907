<div
  class="input-container"
  [ngClass]="className"
  [class.invalid]="!isValid && isTouched()"
  [class.disabled]="disabled"
  [class.readonly]="readonly"
>
  <mat-progress-bar *ngIf="loading$ | async" mode="indeterminate"></mat-progress-bar>
  @if (autocomplete) {
    <span *ngIf="name" class="input-name">{{ name | translate }}</span>
    <input
      [(ngModel)]="value"
      (ngModelChange)="updateValue($event)"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      [matAutocomplete]="auto"
      [disabled]="disabled"
      [attr.inert]="readonly?true:null"
      (focusout)="focusOut()"
    />

    <mat-autocomplete
      (opened)="listOpened.emit()"
      (closed)="listClosed.emit()"
      #auto="matAutocomplete"
      [displayWith]="displayWith"
      (optionSelected)="optionSelected.emit($event.option.value)"
    >
    @if(!readonly){
      <mat-option
        *ngFor="let value of filteredOptions$ | async"
        [value]="valueFnc ? valueFnc(value) : value"
      >
        <ng-template
          [ngTemplateOutlet]="templateRef"
          [ngTemplateOutletContext]="{ $implicit: value }"
        ></ng-template>
      </mat-option>
    }
    </mat-autocomplete>
    <button
      *ngIf="!clearButtonHidden && !readonly"
      class="warn clear-button"
      onclick="this.blur()"
      (click)="clear()"
      [attr.inert]="readonly?true:null"
      [class.disabled]="!clearButtonAvailable || disabled"
    >
      <mat-icon>close</mat-icon>
    </button>
  } @else {
    <span *ngIf="name" class="input-name">{{ name | translate }}</span>

    <mat-select
      panelClass="panel-class"
      [(ngModel)]="value"
      (ngModelChange)="updateValue($event)"
      (selectionChange)="optionSelected.emit($event.value)"
      [disabled]="disabled"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      (focusout)="focusOut()"
    >


      @if(displayedValueTemplateRef){
        <mat-select-trigger>
          <ng-template
            [ngTemplateOutlet]="displayedValueTemplateRef"
            [ngTemplateOutletContext]="{ $implicit: value }"
          ></ng-template>
        </mat-select-trigger>
      }

      <mat-option
        *ngFor="let value of filteredOptions$ | async"
        [value]="valueFnc ? valueFnc(value) : value"
      >
        <ng-template
          [ngTemplateOutlet]="templateRef"
          [ngTemplateOutletContext]="{ $implicit: value }"
        ></ng-template>
      </mat-option>

    </mat-select>

    <button
      *ngIf="!clearButtonHidden && !readonly"
      class="warn clear-button"
      onclick="this.blur()"
      (click)="clear()"
      [class.disabled]="!clearButtonAvailable || disabled"
    >
      <mat-icon>close</mat-icon>
    </button>
  }
</div>
<!--value -&#45;&#45; {{value}}-->
