import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatMiniFabButton } from '@angular/material/button';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';
import { DataStructureTree, RootDataStructureTree } from '../sankey-chart-config.component';
import { SeriesDataStructureDto } from '../../../../../../../api-main';
import { SankeyChartConfigSeriesComponent } from './sankey-chart-config-series/sankey-chart-config-series.component';

@Component({
  selector: 'app-sankey-chart-config-item',
  standalone: true,
  imports: [
    MatIcon,
    MatMiniFabButton,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    TranslateModule,
    MatTooltip,
    SankeyChartConfigSeriesComponent,
  ],
  templateUrl: './sankey-chart-config-item.component.html',
  styleUrl: './sankey-chart-config-item.component.scss',
})
export class SankeyChartConfigItemComponent {
  seriesVisible = false;

  @Input({ required: true }) item: RootDataStructureTree;

  @Input() parent: DataStructureTree;

  @Input() isRoot: boolean = false;

  @Input() isSource: boolean = false;

  @Output() removeItem = new EventEmitter<{
    parent: DataStructureTree;
    item: DataStructureTree;
    prop: 'children' | 'parents';
  }>();

  @Output() removeSeries = new EventEmitter<{
    item: DataStructureTree;
    series: SeriesDataStructureDto;
  }>();

  // removeSeries(item: DataStructureTree, series: SeriesDataStructureDto)
  @Output() addItem = new EventEmitter<DataStructureTree>();

  @Output() editItem = new EventEmitter<DataStructureTree>();

  @Output() addItemToParents = new EventEmitter<DataStructureTree>();

  @Output() addSeries = new EventEmitter<DataStructureTree>();

  showSeries() {
    this.seriesVisible = true;
  }

  hideSeries() {
    this.seriesVisible = false;
  }

  protected readonly Object = Object;
}
