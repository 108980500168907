import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Auth0Service,
  ProfileDto,
  ProfileLanguageRequestDto,
  ProfileSaveRequestDto,
} from '../../../api-auth';
import { ConfigService } from './config.service';
import { AuthService } from '@auth0/auth0-angular';
import { Configuration } from '../../../api-main';
import { Observable, of, switchMap, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private readonly config: Configuration;

  constructor(
    private http: HttpClient,
    private auth0: Auth0Service,
    private ngAuth0: AuthService,
  ) {
    this.config = new Configuration();
    this.auth0 = new Auth0Service(this.http, ConfigService.getApiAuthConfig(), this.config);
    // this.getToken();
  }

  setAccessToken() {
    // delay requests if not authenticated
    return this.ngAuth0.isAuthenticated$.pipe(
      switchMap((data) =>
        data ? of(null) : timer(200).pipe(switchMap(() => this.setAccessToken())),
      ),
    );

    //its actually not needed
    // return (
    //   this.ngAuth0
    //     // .getAccessTokenWithPopup()
    //     .getAccessTokenSilently()
    //     .pipe(tap((token) => (this.config.accessToken = token)))
    // );
  }

  get(): Observable<{ data: ProfileDto }> {
    return this.setAccessToken().pipe(switchMap(() => this.auth0.auth0ControllerGetProfile()));
  }

  save(body: ProfileSaveRequestDto) {
    return this.setAccessToken().pipe(switchMap(() => this.auth0.auth0ControllerSaveProfile(body)));
  }

  setLang(body: ProfileLanguageRequestDto) {
    return this.setAccessToken().pipe(
      switchMap(() => this.auth0.auth0ControllerSetProfileLang(body)),
    );
  }

  delete() {
    return this.setAccessToken().pipe(switchMap(() => this.auth0.auth0ControllerDeleteProfile()));
  }

  sendVerificationEmail(email: string): Observable<boolean> {
    return this.auth0.auth0ControllerSendVerificationEmail({ email: email });
  }

  getUserById(id: string): Observable<ProfileDto> {
    return this.auth0.auth0ControllerGetProfileById(id);
  }
}
