import { Injectable } from '@angular/core';
import { DeviceDto, DeviceValuesDto } from '../../../../../../api-main';

@Injectable({
  providedIn: 'root',
})
export class SelectingValueDataService {
  savedDevice: { [key: string]: Array<DeviceDto> } = {};

  savedDeviceValues: { [key: string]: Array<DeviceValuesDto> } = {};

  // constructor() {}
}
