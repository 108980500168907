/**
 * EMU API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserMetadataDto {
  /**
   * Locale (language) used by user
   */
  locale: string;
  /**
   * Phone number
   */
  phone_number: string;
  /**
   * User's role
   */
  role: string;
  /**
   * Timezone used by user
   */
  timezone: string;
}
