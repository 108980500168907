export const uplotLineInterpolations = {
  linear: 0,
  stepAfter: 1,
  stepBefore: 2,
  spline: 3,
  //	spline2:    4,
};

export const uplotDrawStyles = {
  line: 0,
  bars: 1,
  points: 2,
  barsLeft: 3,
  barsRight: 4,
  multiBars: 5,
};

export const uplotPalette = [
  '#7EB26D', // 0: pale green
  '#EAB839', // 1: mustard
  '#6ED0E0', // 2: light blue
  '#EF843C', // 3: orange
  '#E24D42', // 4: red
  '#1F78C1', // 5: ocean
  '#BA43A9', // 6: purple
  '#705DA0', // 7: violet
  '#508642', // 8: dark green
  '#CCA300', // 9: dark sand
];
