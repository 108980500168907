import { Injectable } from '@angular/core';
import { LayoutService } from '../../../layout/layout.service';
import { map } from 'rxjs';
import { PrivilegesService } from '../../services/privileges.service';
import { toSignal } from '@angular/core/rxjs-interop';

export enum UserGuideHint {
  AddTreeNode,
}
@Injectable({
  providedIn: 'root',
})
export class UserGuideService {
  constructor(
    private layoutService: LayoutService,
    private privilegesService: PrivilegesService,
  ) {}

  public userGuideContent = {
    [UserGuideHint.AddTreeNode]: {
      name: 'POPOVER.ADD_TREE_NODE',
      isVisible: toSignal(
        this.layoutService.treeItemsCount$.pipe(
          map((o) => o === 0 && this.privilegesService.isOrganizationAdmin()),
        ),
      ),
    },
  };
}
