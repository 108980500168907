<mat-card appearance="outlined" class="logger-mat-card">
  <!--  <mat-card-content *ngIf="!itemDoesNotExist && (treeItemSelected$ | async)">-->
  <mat-card-content>
    <div class="no-item-selected">
      <app-skeleton></app-skeleton>
      <div class="info-container">
        <p>
          <mat-icon svgIcon="info"></mat-icon>
          <span class="info"> {{ 'NOTIFICATION.INFO' | translate }}</span>
        </p>
        <span class="message">{{ 'INSPECT.PLEASE_CLICK_ON_ITEM_TO_SEE_DETAILS' | translate }}</span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
