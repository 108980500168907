import { Component } from '@angular/core';
import { LayoutService } from '../layout/layout.service';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingCircleComponent } from '../layout/loading-circle/loading-circle.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor } from '@angular/common';

interface PrivacyFile {
  name: string;
  file: string;
}
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  standalone: true,
  imports: [NgIf, MatCardModule, NgFor, MatIconModule, LoadingCircleComponent, TranslateModule],
})
export class PrivacyPolicyComponent {
  constructor(private layoutService: LayoutService) {}

  display() {
    return this.layoutService.languageSet;
  }

  privacyFiles: PrivacyFile[] = [
    // { name: 'Policy document 13.04.2023.pdf', file: 'policy-files/123123' },
    { name: 'GDPR 25.06.2023.pdf', file: 'policy-files/641223' },
    // { name: 'Some_other_policy_document_13_06_2022.pdf', file: 'policy-files/65431' },
    // { name: 'Some_even_otherer_policy_document_15_06_2022.pdf', file: 'policy-files/431612' },
  ];

  download(file: PrivacyFile) {
    console.log('download file', file);
  }
}
