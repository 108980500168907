import { Pipe, PipeTransform } from '@angular/core';
import { LocaleSessionService } from '../services/localeSession.service';
const MINIMUM_FRACTION_DIGITS = 0;
const MAXIMUM_FRACTION_DIGITS = 3;
let localeSessionService: LocaleSessionService;
@Pipe({
  name: 'formatValue',
  pure: true,
  standalone: true,
})
//NOTICE: when using pipe .transform in component / adding it to constructor it requires to also add pipe to 'providers' array
export class FormatValuePipe implements PipeTransform {
  constructor(private localeSession: LocaleSessionService) {
    localeSessionService = localeSession;
  }

  transform(value: number, digits?: number, forceDigits?: boolean): string {
    const decimalPoints: number = digits != null ? digits : MAXIMUM_FRACTION_DIGITS;
    if (typeof value !== 'number') value = Number(value);

    if (isNaN(value)) value = null;
    // value = Number(value);
    if (value == null) return '-';

    return value.toLocaleString(localeSessionService.locale, {
      minimumFractionDigits: forceDigits ? digits : MINIMUM_FRACTION_DIGITS,
      maximumFractionDigits: decimalPoints,
    });
  }
}
