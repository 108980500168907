import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';

// We need this interceptor to prevent application crash, when Auth0 throw access_denied error
@Injectable()
export class Auth0Interceptor extends AuthHttpInterceptor {
  WHITELISTED_URLS: string[] = [
    'api/dict',
    'api/version',
    'auth-api/auth/verify-email',
    'assets/i18n',
  ];

  private isWhitelisted(url: string): boolean {
    return this.WHITELISTED_URLS.some((whitelisted: string) => url.includes(whitelisted));
  }

  override intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this.isWhitelisted(request.url)) return next.handle(request);

    return super.intercept(request, next).pipe(
      catchError((error) => {
        console.warn(
          `[Auth0Interceptor] ${typeof error == 'object' ? JSON.stringify(error) : error}`,
        );
        // fixed to rethrow error
        //todo check if this not creating new issues
        throw error;
        // return EMPTY;
      }),
    );
  }
}
