import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TreeNodeService } from '../../TreeNodeService';
import { TreeNodeFilterOptions } from '../../models/TreeNodeFilterOptions';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';
import { ModalComponent } from '../../../../../../shared/components/dialogs/modal/modal.component';

@Component({
  selector: 'app-tree-filter-modal',
  templateUrl: './tree-filter-modal.component.html',
  styleUrls: ['./tree-filter-modal.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    NgIf,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
  ],
})
export class TreeFilterModalComponent {
  private treeNodeService: TreeNodeService;

  readonly filterOptions: TreeNodeFilterOptions;

  constructor(
    private dialogRef: MatDialogRef<TreeFilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: { treeNodeService: TreeNodeService },
  ) {
    this.treeNodeService = data.treeNodeService;
    if (!this.treeNodeService.filterOptions) {
      this.dialogRef.close();
      return;
    }
    this.filterOptions = JSON.parse(JSON.stringify(this.treeNodeService.filterOptions));
  }

  onSave() {
    this.treeNodeService.filterOptions = this.filterOptions;
    this.dialogRef.close();
  }
}
