<div class="container" *ngIf="(auth.isAuthenticated$ | async) === false">
  <mat-card appearance="outlined" class="box" style="overflow: hidden">
    <mat-card-content>
      <div class="item">
        <img mat-card-image class="logo-img" src="{{ logoURL }}" alt="OEM logo" />
      </div>

      <div class="item">
        <app-skeleton *ngIf="isLoading"></app-skeleton>
        <div class="container-btn" *ngIf="!isLoading">
          <button
            class="btn button-primary"
            mat-flat-button
            *ngIf="(auth.isAuthenticated$ | async) === false"
            (click)="login()"
            data-cy="login"
          >
            {{ 'NAVBAR.LOGIN' | translate }}
          </button>
          <div class="spacer">
            <div class="line"></div>
            <span>{{ 'LOGIN.OR' | translate }}</span>
            <div class="line"></div>
          </div>
          <button class="btn button-primary" mat-flat-button (click)="signup()" data-cy="register">
            {{ 'NAVBAR.SIGNUP' | translate }}
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
