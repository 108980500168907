/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MetadataTag {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * data_point_id
   */
  id: number;
  /**
   * key
   */
  key: string;
  /**
   * value
   */
  value: string;
  /**
   * type
   */
  type: MetadataTag.TypeEnum;
  /**
   * from
   */
  from: string;
  /**
   * to
   */
  to: string;
  /**
   * order
   */
  order: number;
  /**
   * MetadataSource
   */
  source?: MetadataTag.SourceEnum;
  /**
   * visible
   */
  visible?: boolean;
  /**
   * inherit
   */
  inherit?: boolean;
  /**
   * effective
   */
  effective?: boolean;
  /**
   * MetadataSource
   */
  preferred_source?: MetadataTag.PreferredSourceEnum;
  /**
   * metadata_id
   */
  metadata_id?: number;
}
export namespace MetadataTag {
  export type TypeEnum =
    | 'string'
    | 'location'
    | 'number'
    | 'date'
    | 'date_range'
    | 'tariff'
    | 'boolean'
    | 'config_iot'
    | 'file'
    | 'image'
    | 'pricing'
    | 'customer';
  export const TypeEnum = {
    String: 'string' as TypeEnum,
    Location: 'location' as TypeEnum,
    Number: 'number' as TypeEnum,
    Date: 'date' as TypeEnum,
    DateRange: 'date_range' as TypeEnum,
    Tariff: 'tariff' as TypeEnum,
    Boolean: 'boolean' as TypeEnum,
    ConfigIot: 'config_iot' as TypeEnum,
    File: 'file' as TypeEnum,
    Image: 'image' as TypeEnum,
    Pricing: 'pricing' as TypeEnum,
    Customer: 'customer' as TypeEnum,
  };
  export type SourceEnum = 'INHERITED' | 'DEVICE' | 'DIRECT';
  export const SourceEnum = {
    INHERITED: 'INHERITED' as SourceEnum,
    DEVICE: 'DEVICE' as SourceEnum,
    DIRECT: 'DIRECT' as SourceEnum,
  };
  export type PreferredSourceEnum = 'INHERITED' | 'DEVICE' | 'DIRECT';
  export const PreferredSourceEnum = {
    INHERITED: 'INHERITED' as PreferredSourceEnum,
    DEVICE: 'DEVICE' as PreferredSourceEnum,
    DIRECT: 'DIRECT' as PreferredSourceEnum,
  };
}
