<span>
  <span>
    <mat-icon [style]="data.action === 'confirm' ? 'color:green;' : 'color:red;'">
      {{ data.action === 'confirm' ? 'check_circle_outline' : 'error' }}
    </mat-icon>
  </span>
  <span class="spacer"></span>
  <span>
    {{ data.message }}
  </span>
  <span class="spacer"></span>
  <button style="display: flex" mat-icon-button (click)="dismiss()">
    <mat-icon class="close" data-cy="close-toast">close</mat-icon>
  </button>
</span>
