import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrganizationService as ApiOrganizationService } from '../../../api-main/api/organization.service';
import { ConfigService } from '../../shared/services/config.service';
import {
  ListFilterRequestDto,
  OrganizationDto,
  OrganizationMemberDto,
  OrganizationInvitationRequest,
  CustomerDto,
} from '../../../api-main';
import { Observable, tap } from 'rxjs';
import { UserService } from '../../shared/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private apiOrganizationService: ApiOrganizationService, // private waitService: WaitService,
  ) {
    this.apiOrganizationService = new ApiOrganizationService(
      this.http,
      ConfigService.getApiConfig(),
      undefined,
    );
  }

  get(id: number) {
    return this.apiOrganizationService.organizationControllerGet(id);
  }

  leave(id: number) {
    return this.apiOrganizationService.organizationControllerLeaveOrganization(id);
  }

  save(organization: OrganizationDto) {
    return this.apiOrganizationService.organizationControllerSave(organization);
  }

  list(filter: ListFilterRequestDto = null) {
    filter = this.getDefaultFilter(filter);
    return this.apiOrganizationService.organizationControllerList(filter);
  }

  customerList(filter: ListFilterRequestDto = null) {
    filter = this.getDefaultFilter(filter);
    return this.apiOrganizationService.organizationCustomerControllerList(filter);
  }

  customerDelete(id: number) {
    return this.apiOrganizationService.organizationCustomerControllerDelete(id);
  }

  customerGet(id: number): Observable<CustomerDto> {
    return this.apiOrganizationService.organizationCustomerControllerGet(id);
  }

  customerSave(customer: CustomerDto) {
    return this.apiOrganizationService.organizationCustomerControllerSave(customer);
  }

  delete(id: number) {
    return this.apiOrganizationService.organizationControllerDelete(id);
  }

  membersList(organizationId: number, filter: ListFilterRequestDto = null) {
    filter = this.getDefaultFilter(filter);
    return this.apiOrganizationService.organizationMemberControllerList(filter, organizationId);
  }

  saveMember(organizationId: number, member: OrganizationMemberDto) {
    return this.apiOrganizationService.organizationMemberControllerSave(member, organizationId);
  }

  deleteMember(organizationId: number, userId: number) {
    return this.apiOrganizationService.organizationMemberControllerDelete(organizationId, userId);
  }

  acceptInvitation(invitation: OrganizationInvitationRequest) {
    return this.apiOrganizationService.organizationMemberControllerAcceptInvitation(invitation);
  }

  setState(organizationId: number) {
    // console.log('Choose organization', organizationId);
    this.userService.organizationId.next(organizationId);
    return this.apiOrganizationService.organizationControllerSetState(organizationId);
  }

  getState() {
    return this.apiOrganizationService.organizationControllerGetState().pipe(
      tap((data) => {
        // console.log('Get organization state', data);
        this.userService.organizationId.next(data.organization_id);
      }),
    );
  }

  private getDefaultFilter(filter: ListFilterRequestDto = null) {
    if (filter == null)
      filter = {
        page_index: 0,
        page_size: 100,
        sort_direction: 'asc',
      };
    return filter;
  }
}
