/**
 * EMU Main API
 * EMU Joulio-Web API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.4
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DeviceDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * id
   */
  id: number;
  /**
   * Id of connected tree folder
   */
  tree_item_id?: number;
  /**
   * Id of tree-device connection
   */
  tree_item_device_id?: number;
  /**
   * address_mode
   */
  address_mode: number;
  /**
   * primary_address
   */
  primary_address: number;
  /**
   * manufacturer_id
   */
  manufacturer_id: string;
  /**
   * serial
   */
  serial: string;
  /**
   * version
   */
  version: string;
  /**
   * medium
   */
  medium: string;
  /**
   * active
   */
  active: number;
  /**
   * readout_cycle
   */
  readout_cycle: number;
  /**
   * logger_readout_cycle
   */
  logger_readout_cycle: number;
  /**
   * baud_rate
   */
  baud_rate: number;
  /**
   * bac_net_dev_inst_number
   */
  bac_net_dev_inst_number: number;
  /**
   * name
   */
  name: string;
  /**
   * site
   */
  site: string;
  /**
   * cost_unit
   */
  cost_unit: string;
  /**
   * comment_str
   */
  comment_str: string;
  /**
   * medium_group
   */
  medium_group: number;
  /**
   * battery
   */
  battery: boolean;
  /**
   * last_connection
   */
  last_connection: string;
  /**
   * status
   */
  status: DeviceDto.StatusEnum;
}
export namespace DeviceDto {
  export type StatusEnum = 'NEVER_SEEN' | 'ACTIVE' | 'INACTIVE' | 'PENDING_SSL' | 'EXPIRED';
  export const StatusEnum = {
    NEVERSEEN: 'NEVER_SEEN' as StatusEnum,
    ACTIVE: 'ACTIVE' as StatusEnum,
    INACTIVE: 'INACTIVE' as StatusEnum,
    PENDINGSSL: 'PENDING_SSL' as StatusEnum,
    EXPIRED: 'EXPIRED' as StatusEnum,
  };
}
