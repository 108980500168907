import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TreeNodeService } from '../pages/tree/tree-controller/tree-async/TreeNodeService';
import { TreeNode } from '../pages/tree/tree-controller/tree-async/models/TreeNode';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  treeNodeService: TreeNodeService;

  languageSet: boolean = false;

  treeItemsCount$ = new BehaviorSubject<number>(null);

  private _opened$ = new BehaviorSubject<boolean>(this.sidenavState);
  // constructor() {}

  private _selectedItem$ = new BehaviorSubject<TreeNode>(null);

  organizationChanged$ = new Subject<void>();

  organizationLoaded = signal(false);

  routeReload = signal(true);

  reloadCurrentContent() {
    this.routeReload.set(false);
    setTimeout(() => this.routeReload.set(true), 100);
  }

  set selectedItem(value: TreeNode) {
    if (this.treeNodeService) this.treeNodeService.selectedNodeId$.next(value?.id);
    this._selectedItem$.next(value);
  }

  get selectedItem(): TreeNode {
    return this._selectedItem$.value;
  }

  get selectedItem$(): Observable<TreeNode> {
    return this._selectedItem$.asObservable();
  }

  get opened$(): Observable<boolean> {
    return this._opened$.asObservable();
  }

  toggleSidenav(): void {
    this._opened$.next(!this._opened$.value);
  }

  closeSidenav(): void {
    this._opened$.next(false);
  }

  openSidenav(): void {
    this._opened$.next(true);
  }

  get openedValue(): boolean {
    return this._opened$.value;
  }

  set openedValue(value: boolean) {
    this._opened$.next(value);
  }

  private get sidenavState(): boolean {
    const state = localStorage.getItem('side-nav-open-state');
    return state && state !== 'false';
  }
}
