import { DashboardItem } from '../models/DashboardItem';

export function cloneItem(selectedItem: DashboardItem): DashboardItem {
  const clone = new DashboardItem();
  clone.cols = selectedItem.cols;
  clone.rows = selectedItem.rows;
  clone.type = selectedItem.type;
  clone.minItemCols = selectedItem.minItemCols;
  clone.minItemRows = selectedItem.minItemRows;
  clone.data = JSON.parse(JSON.stringify(selectedItem.data));
  clone.styles = Object.assign({}, selectedItem.styles);
  return clone;
}
