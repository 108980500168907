/**
 * EMU Main API
 * EMU Joulioweb API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DeviceValuesDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * id
   */
  id: number;
  /**
   * position
   */
  position: string;
  /**
   * description_str
   */
  description_str: string;
  /**
   * unit_str
   */
  unit_str: string;
  /**
   * scale_power
   */
  scale_power: number;
  /**
   * scale_mantissa
   */
  scale_mantissa: number;
  /**
   * encode_type
   */
  encode_type: number;
  /**
   * value_type
   */
  value_type: number;
  /**
   * storage_num
   */
  storage_num: number;
  /**
   * tariff
   */
  tariff: string;
  /**
   * active
   */
  active: number;
  /**
   * cfg_description
   */
  cfg_description: number;
  /**
   * cfg_unit
   */
  cfg_unit: number;
  /**
   * cfg_phase
   */
  cfg_phase: number;
  /**
   * cfg_factor
   */
  cfg_factor: number;
  /**
   * cfg_storage_num
   */
  cfg_storage_num: number;
  /**
   * cfg_tariff
   */
  cfg_tariff: string;
  /**
   * description
   */
  description: string;
  /**
   * last import
   */
  last_import: string;
  /**
   * cfg_obis_code
   */
  cfg_obis_code: string;
  /**
   * last_connection
   */
  last_connection: string;
  /**
   * last_value
   */
  last_value: string;
}
