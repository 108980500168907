<app-dashboard-preview
  *ngIf="hoveredDashboardId && !isTouchDevice()"
  #renderer
  [dashboardId]="hoveredDashboardId"
></app-dashboard-preview>

<ng-container *ngIf="display">
  <div
    class="tree-node-container"
    [class.is-hidden]="hidden"
    [class.is-not-authorized]="!isAuthorized()"
    [class.is-item]="isItem()"
    [class.not-selectable]="!areNodeHoverDisplayed() || blocked"
    [class.role-view]="treeNodeService?.config?.displayRoles"
    [attr.data-id]="node.id"
    [attr.data-expanded]="expanded"
    [class.is-expanded]="expanded"
    [class.has-parent]="hasParent()"
    [class.is-preset-element]="node.isPreSetElement"
    [class.is-moving-item]="isMovingItem()"
    [class.no-role-hidden]="isNodeWithNoAccessHidden() && !hasRole()"
    (mouseenter)="onMouseIn()"
    (mouseleave)="onMouseOut()"
  >
<!--      [display]="isRootElement()"-->
    <div
      appUserGuide
      [userGuideDisplay]="isRootElement()"
      [userGuideId]="0"
      userGuideTooltipPosition="right"
      class="tree-node"
      [class.selected]="isNodeSelected()"
      [class.blocked]="blocked"
    >
      <div class="node" matRipple [class.new]="isNew()" (click)="nodeClicked()">
        <div
          appPreventClickPropagation
          class="move-overlay"
          [class.move-mode]="showOverlay()"
          (click)="nodeClicked()"
          [matTooltip]="(isMoveMode() ? getMoveTooltipValue() : 'TREE.SELECT') | translate"
        ></div>

        <a class="node-name" [routerLink]="getUrl()">
          <mat-icon svgIcon="{{ getIcon(node.icon) }}"></mat-icon>
          <span class="name">{{ node.item_name | translate }}</span>
          <!--          children count:: {{node.children_count}}-->
        </a>
        <span
          [attr.data-cy]="node.item_name + '-node-actions'"
          class="node-actions"
          [class.touch-device]="isTouchDevice()"
          [class.always-visible]="displayRoles() || (isRootElement() && isTouchDevice())"
          appPreventClickPropagation
        >
          <app-tree-node-actions
            *ngIf="
              !displayRoles() &&
              (!isTouchDevice() || (isTouchDevice() && isNodeSelected()) || (isRootElement() && isTouchDevice()))
             "
            [node]="node"
            [treeNodeService]="treeNodeService"
          ></app-tree-node-actions>

          <app-tree-node-permission
            *ngIf="displayRoles() && isPermissionNode()"
            [node]="node"
            [treeNodeService]="treeNodeService"
          ></app-tree-node-permission>
        </span>

        @if (hasChildren() && !isRootElement()) {
          <button
            class="tree-action-button expand-toggle-button"
            appPreventClickPropagation
            [attr.data-cy]="node.item_name + '-node-toggle'"
            [attr.aria-label]="'Toggle ' + node.item_name"
            (click)="toggleExpand()"
            [class.expanded]="expanded"
          >
            <mat-icon class="mat-icon-rtl-mirror"> expand_more</mat-icon>
          </button>
        } @else {
          <div class="spacer"></div>
        }
      </div>

      <div class="node-spacer"></div>
    </div>

    <div class="expandable" *ngIf="hasChildren() && expanded">
      @if (children$ | async; as children) {
        @for (child of children; track child.id) {
          <app-tree-node
            [attr.data-cy]="child.item_name + '-node'"
            [data]="{ node: child, treeNodeService: treeNodeService }"
          ></app-tree-node>
        }

        @for (temp of temporaryChildren; track temp.id) {
          <app-tree-node
            [new]="true"
            [attr.data-cy]="temp.item_name + '-node'"
            [data]="{ node: temp, treeNodeService: treeNodeService }"
          ></app-tree-node>
        }
      } @else {
        <app-simple-loading></app-simple-loading>
      }
    </div>
  </div>
</ng-container>
