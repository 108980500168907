<div *ngIf="item" class="structure-item">

  <div class="parent" *ngIf="item.parents">
      <ng-container *ngFor="let parent of item.parents">

        <app-sankey-chart-config-item [item]="parent" [parent]="item" [isSource]="true"  (editItem)="editItem.emit($event)"
                                      (addItem)="addItem.emit($event)" (addSeries)="addSeries.emit($event)"
                                      (removeItem)="removeItem.emit(Object.assign($event,{prop:'parents'}))" (removeSeries)="removeSeries.emit($event)"
        ></app-sankey-chart-config-item>

      </ng-container>


    <button (click)="addItemToParents.emit(item)" mat-mini-fab class="primary-fab add-item-button"
            [matTooltip]="'BUTTON.ADD' | translate">
      <mat-icon svgIcon="add"></mat-icon>
    </button>
  </div>

  <div class="item">

    <div class="buttons">


<!--      <button  mat-mini-fab class="primary-fab action-button"  (click)="isRoot ? editItem.emit(item) : showSeries()"-->
      <button *ngIf="!isRoot"  mat-mini-fab class="primary-fab action-button"  (click)="showSeries()"
              [matTooltip]="'BUTTON.EDIT' | translate">
        <mat-icon svgIcon="edit"></mat-icon>
      </button>
      <button *ngIf="parent && !isRoot" mat-mini-fab class="warn-fab action-button" (click)="removeItem.emit({parent,item, prop:'children'})"
              [matTooltip]="'BUTTON.DELETE' | translate">
        <mat-icon svgIcon="delete"></mat-icon>
      </button>
    </div>

    <span class="name">{{ item.item_name | translate }}</span>

  </div>

  <app-sankey-chart-config-series *ngIf="seriesVisible" [item]="item" (addSeries)="addSeries.emit($event)"
                                  (editItem)="editItem.emit($event)"
                                  (hideSeries)="hideSeries()"
                                  (removeSeries)="removeSeries.emit($event)"></app-sankey-chart-config-series>


    <div class="children">
      @if(item.children && item.children.length>0){
        <ng-container *ngFor="let child of item.children">

          <app-sankey-chart-config-item [item]="child" [parent]="item" (editItem)="editItem.emit($event)"
          (addItem)="addItem.emit($event)" (addSeries)="addSeries.emit($event)"
            (removeItem)="removeItem.emit($event)" (removeSeries)="removeSeries.emit($event)"
          ></app-sankey-chart-config-item>

        </ng-container>
      }

      <button *ngIf="!isSource" (click)="addItem.emit(item)" mat-mini-fab class="primary-fab add-item-button"
              [matTooltip]="'BUTTON.ADD' | translate">
        <mat-icon svgIcon="add"></mat-icon>
      </button>
    </div>
</div>

