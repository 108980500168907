import { UplotResponseData } from '../../shared/components/graph-uplot/graph/models/UplotResponseData.model';
import {
  cfgUnits,
  getCfgUnitIdByUnit,
} from '../../shared/components/graph-uplot/graph/models/CfgUnits';
import { UplotSeriesConfig } from '../../shared/components/graph-uplot/graph/models/UplotSeriesConfig.model';

export function formatDataByUnit(
  data: UplotResponseData,
  downloadedSeries: Array<UplotSeriesConfig>,
): UplotResponseData {
  // console.log('-> data start', JSON.parse(JSON.stringify(data)));
  // data.series.forEach((series, i) => {
  for (const [i, series] of data.series.entries()) {
    if (!downloadedSeries[i]) continue;
    // console.log('-> series', JSON.parse(JSON.stringify(series)));
    // console.log('-> downloadedSeries', downloadedSeries);

    const oldCfgUnit = (<any>downloadedSeries[i]).cfgUnit
      ? cfgUnits.get(Number((<any>downloadedSeries[i]).cfgUnit))
      : getCfgUnitIdByUnit(downloadedSeries[i]?.unit);

    // console.log('-> oldCfgUnit', oldCfgUnit);
    const cfgUnit = series.cfg_unit
      ? cfgUnits.get(Number(series.cfg_unit))
      : getCfgUnitIdByUnit(series.unit);

    if (!cfgUnit || !oldCfgUnit) continue;

    // if(series.unit!=cfgUnit.unit)
    series.unit = cfgUnit.unit;

    if (!cfgUnit || !oldCfgUnit) {
      console.warn('no cfg found for:', series);
      continue;
    }

    const factor = cfgUnit.factor / oldCfgUnit.factor;

    if (factor) data.data[i + 1] = data.data[i + 1].map((o) => o / factor);
  }

  // console.log('-> data', data);
  return data;
}
