import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmuListInputComponent } from '../../../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import { MatIconModule } from '@angular/material/icon';
import { DashboardDto } from '../../../../../../api-main';
import { DashboardService } from '../../../services/dashboard.service';
import { DashboardTemplateService } from '../../../services/dashboard-template.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent } from '../../../../../shared/components/dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { AlertService } from '../../../../../shared/services/alert.service';
import { WaitService } from '../../../../../shared/services/wait.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, map, take } from 'rxjs';

@Component({
  selector: 'app-dashboard-templates-select',
  standalone: true,
  imports: [CommonModule, EmuListInputComponent, MatIconModule],
  templateUrl: './dashboard-templates-select.component.html',
  styleUrl: './dashboard-templates-select.component.scss',
})
export class DashboardTemplatesSelectComponent {
  templates$ = this.dashboardTemplateService.list();

  public inputPlaceholder = new BehaviorSubject<string>('');

  constructor(
    private dashboardService: DashboardService,
    private dashboardTemplateService: DashboardTemplateService,
    private dialog: MatDialog,
    private alertService: AlertService,
    public waitService: WaitService,
    private translate: TranslateService,
  ) {
    this.checkForTemplates();
  }

  templateSelected(selectedDashboard: DashboardDto) {
    this.waitService.start();
    this.dashboardTemplateService.get(selectedDashboard.id).subscribe({
      next: (dashboard) => {
        dashboard.id = null;
        this.dashboardService.editMode$.next(true);
        this.dashboardService.dashboard = {
          ...dashboard,
          name: this.dashboardService.dashboard.name,
          id: this.dashboardService.dashboard.id,
        };
        this.dashboardService.items = dashboard.config;
        this.dashboardService.changedOptions();
      },
      error: () => this.alertService.error(this.translate.instant('TEMPLATE.SELECT_ERROR')),
      complete: () => {
        this.waitService.stop();
      },
    });
  }

  deleteTemplate(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { id: 0 };
    this.dialog
      .open(ConfirmDeleteDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (!result) return;

        this.waitService.start();
        this.dashboardTemplateService.delete(id).subscribe({
          next: () => this.alertService.info(this.translate.instant('DASHBOARD.TEMPLATE_DELETED')),
          error: () => this.alertService.error(this.translate.instant('TEMPLATE.DELETE_ERROR')),
          complete: () => {
            this.templates$ = this.dashboardTemplateService.list();
            this.checkForTemplates();
            this.waitService.stop();
          },
        });
      });
  }

  checkForTemplates() {
    this.templates$
      .pipe(
        map((templates) =>
          templates.length > 0
            ? this.translate.instant('DASHBOARD.TEMPLATE_SELECT')
            : this.translate.instant('DASHBOARD.TEMPLATE_EMPTY'),
        ),
      )
      .subscribe((placeholder) => {
        this.inputPlaceholder.next(placeholder);
      });
  }
}
