<app-modal
  [title]="getTitle()"
  [saveButtonName]="getSaveButtonName()"
  [cancelButtonName]="getCancelButtonName()"
  [shouldSaveBeDisabled]="!isNextAvailable() || blockInput"
  (save)="onNextButtonClick()"
  (cancel)="onCancelButtonClick()"
  customCancelBehavior
  [timestamp]="isEdit() ? data.item : null"
>
  <ng-container body>
    <main [class.block-input]="blockInput">
      @switch (formStage) {
        @case (FormStages.TYPE) {
          <app-tree-form-select-node-type
            [data]="data"
            (changes)="update()"
          ></app-tree-form-select-node-type>
        }
        @case (FormStages.NAME_AND_ICON) {
          <p>{{ 'TREE.PICK_NAME' | translate }}</p>
          <app-tree-form-name-input [data]="data"></app-tree-form-name-input>
          <p>{{ 'TREE.CHOOSE_ICON' | translate }}</p>
          <app-tree-form-icon-select [data]="data"></app-tree-form-icon-select>
        }
        @case (FormStages.TYPE_METADATA) {
          <p>{{ 'TREE.SETUP_METADATA' | translate }}</p>
          <app-type-metadata [data]="data"></app-type-metadata>
        }
      }
    </main>
  </ng-container>
</app-modal>
