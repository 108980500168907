import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RefreshValue } from '../dashboard-graph.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { MatProgressBar } from '@angular/material/progress-bar';
import { UplotRangePickerComponent } from '../../../../../shared/components/graph-uplot/uplot-range-picker/uplot-range-picker.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DashboardItemDataGraph } from '../../../models/DashboardItemDataGraph.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UplotRange } from '../../../../../shared/components/graph-uplot/graph/models/UplotRange.model';
import { Chart, ChartConfiguration } from 'chart.js';
import { DataServiceProvider } from '../../../../data/api-data.service';
import { DashboardService } from '../../../services/dashboard.service';
import { SeriesGraphSettingsModel } from '../../../models/SeriesGraphSettings.model';
import { getRangeFromDataDisplayDefaultRanges } from '../../../../../shared/utils/getRangeFromDataDisplayDefaultRanges';
import { defaultSankeyChartConfig } from './defaultSankeyChartConfig';
import { SkeletonComponent } from '../../../../../shared/components/skeleton/skeleton.component';
import { SankeyChartData } from '../../../widgets-settings/dashboard-graph-settings/sankey-chart-config/SankeyChartData.dto';
import { SingleDataRequestDto, SingleDataResponseDto } from '../../../../../../api-main';
import { DashboardItem } from '../../../models/DashboardItem';
import { DataDisplayDefaultRanges } from '../../../../../shared/models/DataDisplayDefaultRanges';

interface DataFormat {
  id: string;
  label: string;
  unit: string;
  color: string;
  flows?: Array<{ to: string; value: number; unit: string }>;
  total?: number;
  depth: number;
}

@Component({
  selector: 'app-dashboard-sankey',
  standalone: true,
  imports: [
    AsyncPipe,
    BaseChartDirective,
    MatProgressBar,
    NgIf,
    UplotRangePickerComponent,
    TranslateModule,
    SkeletonComponent,
  ],
  templateUrl: './dashboard-sankey.component.html',
  styleUrl: './dashboard-sankey.component.scss',
})
export class DashboardSankeyComponent implements OnInit {
  @Input({ required: true }) data: DashboardItemDataGraph;

  @Input({ required: true }) item: DashboardItem;

  @Input({ required: true }) refresh$: Observable<RefreshValue>;

  private unsubscribe = takeUntilDestroyed();

  protected rangePickDisabled: boolean;

  currentRange: UplotRange;

  loading$ = new BehaviorSubject<boolean>(true);

  config: ChartConfiguration = defaultSankeyChartConfig();

  noData = true;

  constructor(
    protected dataService: DataServiceProvider,
    protected dashboardService: DashboardService,
    protected cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  get series(): Array<SeriesGraphSettingsModel> {
    return this.data?.series ?? [];
  }

  ngOnInit() {
    this.currentRange = this.getCurrentRange();
    this.cd.detectChanges();
    if (this.dashboardService.editMode$.value == false) this.getData();
    this.refresh$.pipe(this.unsubscribe).subscribe((val: string) => {
      // console.log('=>(dashboard-pie-chart.component.ts:79) val', val);
      switch (val) {
        case 'series':
          this.config.options.animation = false;
          //update series
          break;
        case 'graph':
          this.config.options.animation = Chart.defaults.animation;
          this.currentRange = this.getCurrentRange();
          this.getData();
          break;
        default:
      }
      this.cd.detectChanges();
    });
  }

  private getCurrentRange() {
    return this.data.userDefinedRange != DataDisplayDefaultRanges.CUSTOM
      ? getRangeFromDataDisplayDefaultRanges(this.data.userDefinedRange)
      : this.data.range;
  }

  // setData labels description:
  // .labels are the default chart labels
  // ._labels are custom labels used by the datalabels plugin to modify their appearance
  // labels for first and last nodes are the default chart labels in order to fit inside the chart canvas automatically
  // labels for inside nodes are rotated and reduced in size in order not to overlap each other

  private setData(series: DataFormat[]) {
    if (series.length == 0) return;
    const datasets: any = this.config.data.datasets[0];

    datasets.labels = {};
    datasets._labels = {};
    datasets.colors = {};
    datasets.column = {};
    datasets.data = [];

    series.forEach((s) => {
      const valueString = `${(s.total ?? 0).toFixed(2)} [${s.unit ?? ''}]`;

      // datasets.labels[s.id] = `\n`;
      const label = this.translate.instant(s.label);
      datasets.labels[s.id] = `${label}\n${valueString}`;
      datasets.column[s.id] = s.depth;
      datasets.colors[s.id] = s.color;

      if (s.flows) {
        if (s.flows.length > 0) {
          s.flows.forEach((flow) => {
            const index = datasets.data.findIndex(
              (o) => o.from == s.id && o.to == flow.to && o.unit == flow.unit,
            );
            // eslint-disable-next-line security/detect-object-injection
            if (index >= 0) datasets.data[index].flow += flow.value;
            else datasets.data.push({ from: s.id, to: flow.to, flow: flow.value, unit: flow.unit });
          });
        } else {
          datasets.labels[s.id] = ` ${label}\n${valueString}`;
        }
      }
    });

    // const firstNode = series?.find((s) => s.total == Math.max(...series.map((o) => o.total)));
    // console.log('=>(dashboard-sankey.component.ts:138) firstNode', firstNode);
    // if (firstNode) {
    //   datasets.labels[firstNode.id] =
    //     `${firstNode.label}\n${firstNode.total.toFixed(2)} [${firstNode.unit}]`;
    //   datasets._labels[firstNode.id] = `\n`;
    // }
    // console.log('=>(dashboard-sankey.component.ts:130) datasets', datasets);
    setTimeout(() => this.dashboardService.itemLoaded(this.item), 1e3);
  }

  changeRange(range: UplotRange) {
    if (
      range.max < range.min ||
      (this.currentRange.max == range.max && this.currentRange.min == range.min)
    )
      return;
    this.currentRange = range;
    this.getData();
  }

  getSeriesId(series: SankeyChartData['series'][0]) {
    return `dv-${series.logger_id}-${series.device_value_id}`;
  }

  private getSeriesForRequest(): SankeyChartData['series'] {
    if (!this.data.sankeyData) return null;

    const getSeries = (data: SankeyChartData) => {
      const series: SankeyChartData['series'] = [];

      if (data.series) series.push(...data.series);

      if (data.children)
        data.children.forEach((child) => {
          const childSeries = getSeries(child);
          if (childSeries) series.push(...childSeries);
        });

      if (data.parents)
        data.parents.forEach((parent) => {
          const childSeries = getSeries(parent);
          if (childSeries) series.push(...childSeries);
        });

      return series;
    };

    const series = getSeries(this.data.sankeyData);
    return series.filter(
      (o, i, self) =>
        o.device_value_id != null &&
        o.logger_id != null &&
        self.findIndex(
          (s) => s.logger_id == o.logger_id && s.device_value_id == o.device_value_id,
        ) == i,
    );
  }

  private getData() {
    const series = this.getSeriesForRequest();

    this.loading$.next(true);

    const min = Math.floor(this.currentRange.min);
    const max = Math.ceil(this.currentRange.max);

    if (!series) {
      this.noData = true;
      this.loading$.next(false);
      return;
    }

    const request: SingleDataRequestDto = {
      dashboard_id: this.dashboardService.dashboard?.id,
      requests: series.map((value) => {
        return {
          id: this.getSeriesId(value),
          logger_id: value.logger_id,
          value_id: value.device_value_id,
          range_start: min,
          range_end: max,
          mode: 'total',
          consumption: true,
        };
      }),
    };

    this.noData = false;

    this.dataService
      .getSingleData(request)
      .pipe(this.unsubscribe)
      .subscribe({
        next: (responseData: SingleDataResponseDto[]) => {
          const isDataEmpty =
            responseData.map((o) => o.value).filter((value) => value > 0)?.length == 0;

          if (isDataEmpty) {
            this.noData = true;
            this.setData([]);
            this.loading$.next(false);
            return;
          }
          const chartData = this.getChartData(responseData);
          this.setData(chartData);
          this.cd.detectChanges();
          this.loading$.next(false);
        },
        error: (err: any) => {
          console.error('Failed to download sankey chart data', err);
          this.loading$.next(false);
        },
      });
  }

  private getColor(i: number, type: SankeyChartData['type']): string {
    if (type === 'photovoltaic') return '#ffd400';
    if (type === 'photovoltaic-export') return '#ff8800';

    const palette = [
      '#538BF3',
      '#5E94D5',
      '#689EB8',
      '#73A79A',
      '#7EB07C',
      '#88B95E',
      '#93C341',
      '#9DCC23',
      '#A8D505',
    ];

    return palette[i % palette.length];
  }

  private getChartData(responseData: SingleDataResponseDto[]) {
    const root: SankeyChartData = this.data.sankeyData;
    const chartData: DataFormat[] = [];

    const getValueFromSeries = (
      series: SankeyChartData['series'][0],
    ): { value: number; unit: string } => {
      const data = responseData.find(
        (r) => r.value_id == series.device_value_id && r.logger_id == series.logger_id,
      );
      if (!data) return null;
      else return { value: data.value, unit: data.unit };
    };

    const getIdForItem = (item: SankeyChartData): string => 't-' + item.id;
    const getIdForMissingItem = (item: SankeyChartData): string => getIdForItem(item) + '-unknown';

    const assignData = (
      item: SankeyChartData,
      iteration: number = 0,
      flows: DataFormat['flows'] = [],
    ) => {
      const unit = item.series
        ?.map(getValueFromSeries)
        ?.map((o) => o?.unit)
        ?.filter((o) => !!o)[0];

      const data: DataFormat = {
        id: getIdForItem(item),
        unit,
        color: this.getColor(iteration, item.type),
        label: item.item_name,
        flows: flows,
        depth: item.order,
      };
      // console.log('=>(dashboard-sankey.component.ts:297) data', data);

      if (item.parents) {
        item.parents.forEach((parent) => {
          assignData(
            parent,
            iteration + 1,
            (parent.series ?? []).map((s) => {
              const seriesData = getValueFromSeries(s);
              // console.log('=>(dashboard-sankey.component.ts:306) seriesData', seriesData, s);
              return {
                to: getIdForItem(item),
                value: seriesData?.value ?? 0,
                unit: seriesData?.unit,
              };
            }),
          );
        });
      }

      if (item.children) {
        item.children.forEach((child) => {
          assignData(child, iteration + 1);
          if (child.series) {
            child.series.forEach((series) => {
              const seriesData = getValueFromSeries(series);
              if (seriesData)
                data.flows.push({
                  to: getIdForItem(child),
                  value: seriesData.value,
                  unit: seriesData.unit,
                });
            });
          }
        });
      }

      data.total = item.series
        ?.map(getValueFromSeries)
        .reduce((acc, curr) => acc + curr?.value ?? 0, 0);

      if (data.total <= 0 && item.parents) {
        const series = item.parents
          .map((p) => {
            // console.log('=>(dashboard-sankey.component.ts:377) p', p);
            let mod = 1;
            if (p.type == 'photovoltaic-export') mod = -1;

            return p.series
              .flat()
              .map(getValueFromSeries)
              .map((o) => ({ ...o, value: o.value * mod }));
          })
          .flat();

        data.total = series.reduce((acc, curr) => acc + curr?.value ?? 0, 0);
        data.unit = series.map((o) => o.unit).filter((o) => !!o)[0];
        // console.log('=>(dashboard-sankey.component.ts:350) ata.total', data.total);
      }

      const childTotal = chartData
        .filter((o) => (item.children ?? []).map(getIdForItem).includes(o.id))
        .reduce((acc, curr) => acc + curr.total, 0);
      const diff = data.total - childTotal;
      // console.log('item', item);
      // console.log('=>(dashboard-sankey.component.ts:349) diff', diff);
      if (diff > 0 && item.children?.length > 0) {
        data.flows.push({
          to: getIdForMissingItem(item),
          value: diff,
          unit: data.unit,
        });

        chartData.push({
          id: getIdForMissingItem(item),
          unit: data.unit,
          total: diff,
          flows: [],
          label: `${this.translate.instant(item.item_name)} (${this.translate.instant('FIELD.MISSING')})`,
          color: 'grey',
          depth: item.order + 1,
        });
      }

      chartData.push(data);
    };

    assignData(root);

    const simplifiedFlowsData: DataFormat[] = chartData
      // @ts-ignore
      .map((o) => ({ ...o, flows: Object.groupBy(o.flows, ({ to }) => to) }))
      .map((o) => ({
        ...o,
        flows: Object.keys(o.flows).map((f: string) => {
          // eslint-disable-next-line security/detect-object-injection
          if (!o.flows[f]) return [];
          else {
            // eslint-disable-next-line security/detect-object-injection
            return o.flows[f].reduce(
              (acc: DataFormat['flows'][0], curr: DataFormat['flows'][0]) => {
                if (!curr) return acc;
                acc.unit = curr.unit;
                acc.value += curr.value;
                return acc;
              },
              { to: f, value: 0, unit: '' },
            );
          }
        }),
      }))
      .map((o: DataFormat) => ({
        ...o,
        total: !!o.total
          ? o.total
          : o.flows.reduce((acc, curr: DataFormat['flows'][0]) => {
              if (!curr) return acc;
              acc += curr.value;
              return acc;
            }, 0),
        unit: !!o.unit ? o.unit : o.flows.map((f) => f.unit).filter((f) => !!f)[0],
      }));

    // console.log('=>(dashboard-sankey.component.ts:377) simplifiedFlowsData', simplifiedFlowsData);

    return simplifiedFlowsData;
  }
}
