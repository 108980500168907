<div class="modal-container" cdkTrapFocus>
  <header>
    <h1>{{ title | translate }}</h1>
    <app-language-button *ngIf="showLanguageButton"></app-language-button>
  </header>

  <div class="modal-body">
    <app-skeleton *ngIf="!isDataLoaded"></app-skeleton>
    <ng-container *ngIf="isDataLoaded">
      <ng-content select="[body]"></ng-content>
    </ng-container>
  </div>

  <footer *ngIf="isDataLoaded">
    <ng-content select="[footer]"></ng-content>

    <mat-dialog-actions>
      <button
        class="button-secondary"
        *ngIf="cancelButtonExists && !_customCancelBehavior"
        mat-dialog-close
        mat-button
        data-cy="cancel-button"
      >
        {{ cancelButtonName | translate }}
      </button>

      <button
        class="button-secondary"
        *ngIf="cancelButtonExists && _customCancelBehavior"
        mat-button
        (click)="onCancel()"
        data-cy="cancel-button"
      >
        {{ cancelButtonName | translate }}
      </button>

      <button
        class="button-primary"
        *ngIf="saveButtonExists"
        mat-flat-button
        (click)="onSave()"
        [disabled]="shouldSaveBeDisabled"
        data-cy="save-button"
      >
        {{ saveButtonName | translate }}
      </button>
    </mat-dialog-actions>
  </footer>
</div>
