import { DashboardItemDataGraph } from '../../models/DashboardItemDataGraph.model';
import { DataDisplayDefaultRanges } from '../../../../shared/models/DataDisplayDefaultRanges';
import { DashboardItemDataGraphTypesModel } from '../../models/DashboardItemDataGraphTypes.model';
import { getDefinedRanges } from '../../../../shared/components/graph-uplot/graph/utils/getDefinedRanges';

export function getAvailableRangesForGraphType(
  data: DashboardItemDataGraph,
): DataDisplayDefaultRanges[] {
  const defaultRanges = getDefinedRanges(false);
  switch (data.type) {
    case DashboardItemDataGraphTypesModel.PIE_MONTHLY_AGG:
      return [
        DataDisplayDefaultRanges.CURRENT_YEAR,
        DataDisplayDefaultRanges.LAST_YEAR,
        DataDisplayDefaultRanges.BEFORE_LAST_YEAR,
        DataDisplayDefaultRanges.LAST_12_MONTHS,
      ];
    case DashboardItemDataGraphTypesModel.PIE_WEEKLY_AGG: {
      const excludedRanges: DataDisplayDefaultRanges[] = [
        DataDisplayDefaultRanges.LAST_12_HOURS,
        DataDisplayDefaultRanges.LAST_24_HOURS,
        DataDisplayDefaultRanges.CURRENT_DAY,
        DataDisplayDefaultRanges.LAST_DAY,
        DataDisplayDefaultRanges.DAY_BEFORE_YESTERDAY,
      ];
      return defaultRanges.filter((o) => !excludedRanges.includes(o));
    }
    case DashboardItemDataGraphTypesModel.PIE: {
      const excludedRanges: DataDisplayDefaultRanges[] = [
        DataDisplayDefaultRanges.LAST_12_HOURS,
        DataDisplayDefaultRanges.LAST_24_HOURS,
      ];
      return defaultRanges.filter((o) => !excludedRanges.includes(o));
    }
    case DashboardItemDataGraphTypesModel.HEATMAP_DAILY:
      return [
        DataDisplayDefaultRanges.LAST_30_DAYS,
        DataDisplayDefaultRanges.CURRENT_MONTH,
        DataDisplayDefaultRanges.LAST_MONTH,
        DataDisplayDefaultRanges.BEFORE_LAST_MONTH,
        DataDisplayDefaultRanges.LAST_3_MONTHS,
        DataDisplayDefaultRanges.LAST_6_MONTHS,
        DataDisplayDefaultRanges.LAST_12_MONTHS,
        DataDisplayDefaultRanges.CURRENT_QUARTER,
        DataDisplayDefaultRanges.LAST_QUARTER,
        DataDisplayDefaultRanges.BEFORE_LAST_QUARTER,
        DataDisplayDefaultRanges.CURRENT_YEAR,
        DataDisplayDefaultRanges.LAST_YEAR,
        DataDisplayDefaultRanges.BEFORE_LAST_YEAR,
      ];
    default:
      return defaultRanges;
  }

  //TODO enforce choosing one of above if different is selected
}
