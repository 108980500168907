<div class="tree-node-types">
  @if (loaded) {
    <div class="node-types">
      <div
        class="node-type"
        *ngFor="let type of nodeTypes"
        (click)="typeClicked(type)"
        [class.selected]="type.id === data?.typeSelected$.value?.id"
      >
        <mat-icon svgIcon="{{ type.icon }}" color="primary"></mat-icon>
        <p>{{ type.name }}</p>
      </div>
    </div>
  } @else {
    <app-skeleton></app-skeleton>
  }
</div>
