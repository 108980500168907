<div class="select-container">
  <app-emu-list-input
    [name]="'DASHBOARD.TEMPLATES'"
    [autocomplete]="false"
    [clearButtonHidden]="true"
    [filteredOptions$]="templates$"
    [className]="'dashboard-settings'"
    (optionSelected)="templateSelected($event)"
    [placeholder]="inputPlaceholder | async"
  >
    <ng-template let-item>
      <p class="device-name">
        {{ item.name }}
        <mat-icon
          (click)="deleteTemplate(item.id); $event.preventDefault(); $event.stopPropagation()"
          svgIcon="delete"
        ></mat-icon>
      </p>
    </ng-template>
  </app-emu-list-input>
</div>
