import { ChartConfiguration } from 'chart.js';
import { SeriesGraphSettingsModel } from '../../../models/SeriesGraphSettings.model';
import { Context } from 'chartjs-plugin-datalabels/types/context';

export const defaultPieChartConfig: () => ChartConfiguration = () => {
  return {
    type: 'pie',
    // type: 'doughnut',
    options: {
      layout: {
        padding: 0,
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              const value = context.formattedValue;
              const series: Array<SeriesGraphSettingsModel> = (<any>context.dataset).series;
              if (!series) return value;
              const unit = series[context.dataIndex]?.unit;
              if (!unit) return value;
              return `${value} [${unit}]`;
            },
          },
          usePointStyle: true,
          borderWidth: 0,
          boxPadding: 5,
          padding: 5,
        },
        datalabels: {
          formatter: (value, ctx) => {
            const datapoints = ctx.chart.data.datasets[0].data;
            const total: number = <number>(
              datapoints.reduce((t: number, datapoint: number) => t + datapoint, 0)
            );
            const percentage = (value / total) * 100;
            return percentage.toFixed(2) + '%';
          },
          font: (context: Context) => {
            const ratio = 1 / 6;
            const minSize = 10;
            const minGraphSize = Math.min(context.chart.width, context.chart.height);
            const seriesCount = Math.max((<any>context.dataset)?.series?.length, 2);

            // console.log('=>(defaultPieChartConfig.ts:70) minGraphSize', minGraphSize);
            // console.log('=>(defaultPieChartConfig.ts:65) seriesCount', seriesCount);
            const calculatedSize = Math.ceil((minGraphSize * ratio) / seriesCount);
            return {
              size: Math.max(calculatedSize, minSize),
            };
          },
          color: '#fff',
        },
        legend: {
          position: 'bottom',
          labels: {
            padding: 30,
            boxWidth: 10,
            boxHeight: 10,
            usePointStyle: true,
            pointStyle: 'circle',
          },
        },
      },
    },
    data: {
      labels: [],
      datasets: [],
    },
  };
};
