<app-modal
  title="{{ 'BUTTON.FILTERS' | translate }}"
  saveButtonName="{{ 'BUTTON.SAVE' | translate }}"
  [cancelButtonExists]="false"
  (save)="onSave()"
>
  <ng-container body>
    <main *ngIf="filterOptions">
      <section class="fixed-width">
        <mat-checkbox [(ngModel)]="filterOptions.dontDisplayEmptyFolders">
          {{ 'TREE.DONT_DISPLAY_EMPTY_FOLDERS' | translate }}
        </mat-checkbox>
      </section>
    </main>
  </ng-container>
</app-modal>
