import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { distinctUntilChanged, map, Observable, startWith } from 'rxjs';
import * as getTimezoneOffset from 'get-timezone-offset';
import timezonesList from '../../../../../configs/timezones_list/timezones_list';

//@ts-ignore
export const timezoneList: string[] = timezonesList;

export function timezoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // const found = timezoneList.find((o) => o == control.value);
    return timezoneValidateFn(control.value) ? null : { notInTheList: { value: control.value } };
  };
}

export function timezoneValidateFn(value: string): boolean {
  const found = timezoneList.find((o) => o == value);
  return found != undefined;
}

export function filteredTimezones(input$: Observable<string>) {
  return input$.pipe(
    startWith(''),
    distinctUntilChanged(),
    map((term: string) => {
      const transform = (s: string) => s.toLowerCase().replaceAll(' ', '').replaceAll('/', '');
      return timezoneList.filter((o: string) => transform(o).includes(transform(term)));
    }),
  );
}

export function getTimezoneOffsetInHours(timezone: string, date = new Date()): number {
  return -getTimezoneOffset(timezone, date) / 60;
}
