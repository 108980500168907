import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { filter, race, Subject, takeUntil } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PaginatorI18n extends MatPaginatorIntl implements OnDestroy {
  unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private router: Router,
  ) {
    super();

    race(
      this.translateService.onLangChange,
      router.events.pipe(filter((event) => event instanceof NavigationStart)),
    )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          this.translate();
        },
      });
  }

  private translate() {
    this.itemsPerPageLabel = this.translateService.instant('ITEMS_PER_PAGE_LABEL');
    this.nextPageLabel = this.translateService.instant('NEXT_PAGE_LABEL');
    this.previousPageLabel = this.translateService.instant('PREVIOUS_PAGE_LABEL');
    this.firstPageLabel = this.translateService.instant('FIRST_PAGE_LABEL');
    this.lastPageLabel = this.translateService.instant('LAST_PAGE_LABEL');
    this.changes.next();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
