<ng-container [ngSwitch]="item.type">
  <app-dashboard-graph-settings
    *ngSwitchCase="DashboardItemType.GRAPH"
    [item]="item"
  ></app-dashboard-graph-settings>
  <app-dashboard-text-settings
    *ngSwitchCase="DashboardItemType.TEXT"
    [item]="item"
  ></app-dashboard-text-settings>
  <app-dashboard-image-settings
    *ngSwitchCase="DashboardItemType.IMAGE"
    [item]="item"
  ></app-dashboard-image-settings>
  <app-dashboard-value-display-settings
    *ngSwitchCase="DashboardItemType.VALUE_DISPLAY"
    [item]="item"
  ></app-dashboard-value-display-settings>
  <app-dashboard-multiple-value-display-settings
    *ngSwitchCase="DashboardItemType.MULTI_VALUE_DISPLAY"
    [item]="item"
  ></app-dashboard-multiple-value-display-settings>
</ng-container>
