import { getPointsPos } from './getPointsPos';

export const getUplotCursorOptions = (showPoints = true) => {
  return {
    points: {
      size: (u, seriesIdx) => u.series[seriesIdx].points.size * 2,
      width: (u, seriesIdx, size) => size / 4,
      stroke: (u, seriesIdx) => u.series[seriesIdx].points.stroke(u, seriesIdx),
      fill: () => '#fff',
      bbox: getPointsPos,
      ...(!showPoints && { show: false }),
    },
    // sync: {
    //   key: 0,
    // },
    drag: {
      dist: 10,
      x: true,
      __x: true,
    },
    dblclick: false,
  };
};
