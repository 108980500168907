import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { DashboardService } from './api/dashboard.service';
import { DashboardcyclicReportService } from './api/dashboardcyclicReport.service';
import { DashboardreportService } from './api/dashboardreport.service';
import { DashboardtemplateService } from './api/dashboardtemplate.service';
import { DataService } from './api/data.service';
import { DeviceValuesService } from './api/deviceValues.service';
import { DictionaryService } from './api/dictionary.service';
import { GeneralService } from './api/general.service';
import { LoggerService } from './api/logger.service';
import { LoggerLoraService } from './api/loggerLora.service';
import { MetadataService } from './api/metadata.service';
import { OrganizationService } from './api/organization.service';
import { TreeService } from './api/tree.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    DashboardService,
    DashboardcyclicReportService,
    DashboardreportService,
    DashboardtemplateService,
    DataService,
    DeviceValuesService,
    DictionaryService,
    GeneralService,
    LoggerService,
    LoggerLoraService,
    MetadataService,
    OrganizationService,
    TreeService,
  ],
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575',
      );
    }
  }
}
