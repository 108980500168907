import { DeviceValueDtoExpandable } from '../models/DeviceValueDtoExpandable.model';
import { SeriesCalculationMode } from '../../../../shared/components/graph-uplot/graph/models/SeriesCalculationMode';
import { TranslateService } from '@ngx-translate/core';
import { SelectedSeriesModel } from '../models/SelectedSeries.model';
import { isSeriesConsumptionValue } from '../../../../shared/components/graph-uplot/graph/utils/isSeriesConsumptionValue';

export const consumptionGraphPathStyle = 'bars+1';
let translateService: TranslateService;

export function addSubsetsToSeries(
  item: DeviceValueDtoExpandable | SelectedSeriesModel,
  translate: TranslateService,
) {
  translateService = translate;
  if (!item) return;
  const addConsumptionSubset = isSeriesConsumptionValue(item);

  if (
    !item.subsets ||
    item.subsets.length == 0 ||
    (addConsumptionSubset && item.subsets.length == 1)
  )
    item.subsets = [
      ...(addConsumptionSubset ? [getConsumptionSubset(item)] : []),
      {
        ...item,
        mode: SeriesCalculationMode.DEFAULT,
        subsets: null,
        // subsetName: translate.instant('GRAPH.CONSUMPTION_CALCULATION'),
        pathStyle: 'linear',
        display: !addConsumptionSubset,
        uniqueId: getUniqueId(),
      },
    ];
}

function getConsumptionSubset(item: DeviceValueDtoExpandable | SelectedSeriesModel) {
  return {
    ...item,
    mode: SeriesCalculationMode.CONSUMPTION_CALC,
    subsets: null,
    subsetName: translateService.instant('GRAPH.CONSUMPTION_CALCULATION'),
    pathStyle: consumptionGraphPathStyle,
    display: true,
    uniqueId: getUniqueId(),
  };
}

function getUniqueId() {
  return window?.crypto?.randomUUID() ?? Math.random().toString();
}
