<div
  *ngIf="tooltipVisibility()"
  [style.left]="tooltipXPosition()"
  [style.top]="tooltipYPosition()"
  [class]="arrowPosition()"
  [id]="'user-guide-' + id"
  class="user-guide-tooltip">
  <span class="user-guide-tooltip__description"> {{ content().name | translate }} </span>
  <button (click)="hideTooltip()" class="user-guide-tooltip__close-btn" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
