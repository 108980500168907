import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { fromEvent, merge, Subject, take, takeUntil, timer } from 'rxjs';
import { DashboardPreviewService } from '../../services/dashboard-preview.service';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf } from '@angular/common';
import { DashboardService } from '../../services/dashboard.service';
import { WaitService } from '../../../../shared/services/wait.service';
import { SingleValueRequestService } from '../../services/single-value-request.service';

@Component({
  selector: 'app-dashboard-preview',
  templateUrl: './dashboard-preview.component.html',
  styleUrls: ['./dashboard-preview.component.scss'],
  standalone: true,
  imports: [NgIf, MatProgressBarModule, DashboardComponent],
  providers: [DashboardService, WaitService, SingleValueRequestService], //provide new instance of dashboard service (to avoid overwriting current dashboard) and waitservice to has independent loading
})
export class DashboardPreviewComponent implements AfterViewInit, OnDestroy {
  // private topOffset: number = 25;

  private unsubscribe = new Subject<void>();

  private _dashboardId = null;

  setPos() {
    let val = 0;
    const el: HTMLElement = this.elRef.nativeElement;
    const elDiv: HTMLElement = el?.querySelector('div');
    if (el && elDiv) {
      const offsetTop = el.offsetTop;
      const elHeight = elDiv.getBoundingClientRect()?.height;
      const diff = window.innerHeight - offsetTop - elHeight;
      if (diff < 0) val = diff - 40;
    }
    this.elRef.nativeElement.style.transform = `translateY(${val}px)`;
  }

  get dashboardId() {
    return this._dashboardId;
  }

  @Input() set dashboardId(value) {
    if (this._dashboardId !== null) {
      this.loaded = false;
      this._dashboardId = value;
      this.setupContent();
    }
    this._dashboardId = value;
  }

  loaded = false;

  src = null;

  constructor(
    private cd: ChangeDetectorRef,
    private dashboardPreviewService: DashboardPreviewService,
    private elRef: ElementRef,
  ) {
    merge(fromEvent(document, 'mousemove'), timer(0, 500))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.updateView());
  }

  ngAfterViewInit() {
    timer(500)
      .pipe(take(1), takeUntil(this.unsubscribe))
      .subscribe(() => this.setupContent());
  }

  private setupContent() {
    this.src = null;
    if (!this.dashboardId) return;
    this.loaded = true;
    this.updateView();

    // this.dashboardPreviewService.getRender(this.dashboardId).subscribe((data) => {
    //   // const savedData = data;
    //   // if (savedData) {
    //   //   this.src = savedData.src;
    //   // }
    //   this.src = data.src;
    //   this.loaded = true;
    //   this.updateView();
    //   setTimeout(() => this.updateView(), 100);
    // });
  }

  updateView() {
    this.setPos();
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
