import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Host,
  Input,
  Optional,
  Output,
  SkipSelf,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { Observable, Subject, takeUntil, timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';

/*
Usage:

autocomplete:
  <app-emu-list-input
    [loading$]="loading observable"
    [value]="initial value"
    (valueChange)="search input"
    [name]="name - will be translated"
    (optionSelected)="option selected, $event will provide selected object"
    [displayWith]="handle display in input"
    [filteredOptions$]="filtered table of searched results"
    (optionCleared)="clear button clicked"
    [className]="optional classes"
    [disabled]="block edit"
  >
    <ng-template let-item>
      <p>{{ item display on list }}</p>
    </ng-template>
  </app-emu-list-input>

  autocomplete off:

    <app-emu-list-input
    [autocomplete]="false"
    [loading$]="loading observable"
    [(value)]="binded value"
    [name]="name - will be translated"
    [className]="optional classes"
    [disabled]="block edit"
    (optionSelected)="option selected, $event will provide selected object()"
    [clearButtonHidden]="hide clear button"
    [filteredOptions$]="table of selectable items, ussualy: of(deviceValues)"
  >
    <ng-template let-item>
      <p>{{  item display on list }}</p>
    </ng-template>

    <ng-template let-item #displayedValue>
        <p>{{ handle display in input }}</p>
    </ng-template>

  </app-emu-list-input>


 */
@Component({
  selector: 'app-emu-list-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    TranslateModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressBarModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EmuListInputComponent,
      multi: true,
    },
  ],
  templateUrl: './emu-list-input.component.html',
  styleUrl: './emu-list-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EmuListInputComponent implements AfterViewInit, ControlValueAccessor {
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    private cdr: ChangeDetectorRef,
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
  ) {}

  @Input() name: string;

  @Input() placeholder: string;

  // @Input({ required: true }) value: any;
  @Input() value: any;

  @Input({ required: true }) filteredOptions$: Observable<any>;

  @Output() valueChange = new EventEmitter<any>();

  @Output() listFocused = new EventEmitter<any>();

  @Output() changes = new EventEmitter<string>();

  @Output() optionSelected = new EventEmitter<any>();

  @Output() optionCleared = new EventEmitter<any>();

  @Output() listOpened = new EventEmitter<void>();

  @Output() listClosed = new EventEmitter<void>();

  @Input() disabled: boolean;

  @Input() readonly: boolean;

  @Input() valueFnc: (value: any) => any; //mat option value at select

  clearButtonAvailable = false;

  @Input() displayWith: any; // autocomplete needs display with as displayed value can't be handled by html (#displayedValue)

  @Input() autocomplete: boolean = true;

  @Input() clearButtonHidden: boolean = false;

  @Input() loading$: Observable<boolean>;

  @Input() className: string;

  @Input() valueDisabledFn: (value: any) => boolean;

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  @ContentChild('displayedValue') displayedValueTemplateRef: TemplateRef<any>;

  private unsubscribe$ = takeUntilDestroyed();

  private endInterval$ = new Subject<void>();

  private selectedOption: any;

  isValid = true;

  @Input() formControlName: string;

  @Input() validator: (value: string) => boolean;

  //control can be provided instead of formControlName
  @Input() control: AbstractControl;

  private onChangeFormControl: Function;

  private onBlurFormControl: Function;

  protected focusedOut: boolean = false;

  closePanel() {
    this.autocompleteTrigger.closePanel();
  }

  ngAfterViewInit() {
    if (this.controlContainer && this.formControlName && !this.control)
      this.control = this.controlContainer.control.get(this.formControlName);

    timer(0, 300)
      .pipe(this.unsubscribe$, takeUntil(this.endInterval$))
      .subscribe(() => {
        this.updateClearButton();
        if (this.clearButtonAvailable == true) this.stopInterval();
      });
  }

  updateValue(value: any) {
    if (this.disabled || this.readonly) return;
    this.stopInterval();
    this.valueChange.emit(value);
    this.changes.emit(value);
    if (this.onChangeFormControl) this.onChangeFormControl(value);
    this.updateClearButton();
    this.validate();
  }

  private stopInterval() {
    //this could break displaying button
    this.endInterval$.next();
  }

  validate() {
    const validatorValue = this.validator ? this.validator(this.value) : true;
    this.isValid = validatorValue && this.isFromControlValid();
    this.cdr.detectChanges();
  }

  isValueDisabled(value: any) {
    if (this.valueDisabledFn) return this.valueDisabledFn(value);
    return false;
  }

  clear() {
    this.value = '';
    this.updateValue(this.value);
    this.optionSelected.emit(null);
    this.optionCleared.emit(null);
    this.cdr.detectChanges();
  }

  private updateClearButton() {
    // console.log('-> this.value', this.value);
    this.clearButtonAvailable =
      this.value && (this.value.length > 0 || typeof this.value === 'object');
    this.cdr.detectChanges();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChangeFormControl = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlurFormControl = fn;
  }

  writeValue(value: any): void {
    // if (value && typeof value !== 'string') {
    //   console.log('value: ', value);
    //   throw new Error('THIS COMPONENT HANDLES STRING ONLY');
    // }
    this.value = value;
    this.updateValue(value);
    // console.log('=>(emu-input.component.ts:174) obj', obj);
  }

  isFromControlValid() {
    if (!this.control) return true;
    return this.control.valid;
  }

  isTouched(): boolean {
    return this.control ? this.control.touched : this.focusedOut;
  }

  //for autocomplete
  onFocus() {
    this.listFocused.emit();
  }

  //for autocomplete
  onFocusOut() {
    if (!this.selectedOption) return;
    //timeout prevents blinking when selecting different value
    setTimeout(() => (this.value = this.selectedOption));
  }

  onOptionSelected(value: any) {
    this.selectedOption = value;
    this.optionSelected.emit(value);
  }

  focusOut(): void {
    if (this.onBlurFormControl && typeof this.onBlurFormControl == 'function')
      this.onBlurFormControl();
    this.focusedOut = true;
  }
}
