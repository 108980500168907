import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, map, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { DeviceService } from '../../../../../logger/device.service';
import { DeviceDto, LoggerDto } from '../../../../../../../api-main';
import { getEmptyBody } from '../../getRequestBodys';
import { ValueDataModel } from '../../../../models/ValueData.model';
import { DisabledDirective } from '../../../../../../shared/directives/disabled.directive';
import { EmuListInputComponent } from '../../../../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import { TimestampRelativeComponent } from '../../../../../../shared/components/timestamp-relative/timestamp-relative.component';
import { SelectingValueDataService } from '../selecting-value-data.service';

@Component({
  selector: 'app-settings-device',
  templateUrl: './value-display-settings-device.component.html',
  styleUrls: ['./value-display-settings-device.component.scss'],
  standalone: true,
  imports: [EmuListInputComponent, DisabledDirective, TimestampRelativeComponent],
})
export class ValueDisplaySettingsDeviceComponent implements OnInit, OnDestroy {
  @Input() data: ValueDataModel;

  @Input() reloadDeviceValues$: Subject<number>;

  @Input() reloadDevice$: Subject<number>;

  loading$ = new BehaviorSubject(false);

  devices: DeviceDto[] = [];

  private _selectedDevice: DeviceDto;

  private unsubscribe = new Subject<void>();

  get selectedDevice(): DeviceDto {
    if (this.data.deviceId != this._selectedDevice?.id) return null;
    return this._selectedDevice;
  }

  set selectedDevice(value: DeviceDto) {
    this._selectedDevice = value;
    if (value?.id != null) {
      this.assignDeviceId(value.id);
      this.reloadDeviceValues$.next(value.id);
    }
  }

  constructor(
    private deviceService: DeviceService,
    private cd: ChangeDetectorRef,
    private selectingValueDataService: SelectingValueDataService,
  ) {}

  ngOnInit() {
    this.loadDevices();
  }

  assignDeviceId(id: number) {
    if (this.data.deviceId == id) return;
    this.data.deviceId = id;
    this.data.valueId = null;
  }

  private loadDevices() {
    let loggerId: number;
    const device$ = this.reloadDevice$.pipe(
      tap(() => {
        this.selectedDevice = null;
        this.loading$.next(true);
      }),
      takeUntil(this.unsubscribe),
      switchMap((id) => {
        loggerId = id;

        // eslint-disable-next-line security/detect-object-injection
        if (this.selectingValueDataService.savedDevice[loggerId])
          // eslint-disable-next-line security/detect-object-injection
          return of(this.selectingValueDataService.savedDevice[loggerId]);

        return this.deviceService.deviceList(getEmptyBody(), id).pipe(
          map((o) =>
            o.data.sort((a, b) =>
              a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base',
              }),
            ),
          ),
        );
      }),
      tap((data) => {
        // eslint-disable-next-line security/detect-object-injection
        this.selectingValueDataService.savedDevice[loggerId] = data;
      }),
    );
    device$.pipe(takeUntil(this.unsubscribe)).subscribe((devices) => {
      this.loading$.next(false);
      this.devices = devices;

      if (this.data.deviceId != null)
        this.selectedDevice = devices.find((o) => o.id == this.data.deviceId);
    });
  }

  displayFn(item: LoggerDto): string {
    return item ? `${item.name}` : '';
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected readonly of = of;
}
